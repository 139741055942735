import generalMutations from "./generalMutations";
import generalActions from "./generalActions";
import Parse from "parse";

export default {
  namespaced: true,
  state: {
    objects: {},
    className: "BookPerson",
  },

  mutations: {
    ...generalMutations,
  },
  actions: {
    ...generalActions,
    replacePeople({ commit, dispatch, state }, obj) {
      const people2remove = _.filter(state.objects, (bp) => {
        return bp.belongsTo(obj.book, "book");
      });

      _.forEach(people2remove, (bp) => {
        commit("deleteObj", bp);
      });

      _.forEach(obj.people, (bp) => {
        commit("upsertObject", bp);
      });
      console.log("obj.count", obj.count);

      const book = obj.book;
      book.set("pax", obj.count);
      commit("books/upsertObject", book, { root: true });
    },
  },
};
