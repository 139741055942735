import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
type Props = {
  onPlus: () => void;
  onMinus: () => void;
  qty: number;
};
export function PlusAndMinus({ onPlus, onMinus, qty }: Props) {
  return (
    <div
      className={"flex items-center border border-slate-200 rounded space-x-3"}
    >
      <div
        onClick={onPlus}
        className={
          "rounded flex justify-center flex-1 p-[0.50em] cursor-pointer"
        }
      >
        {/*@ts-ignore*/}
        <PlusIcon className={"w-5 h-5 hover:text-red-500 text-gray-500"} />
      </div>
      <span className={"select-none flex-shrink-0"}>{qty}</span>
      <div
        onClick={onMinus}
        className={
          "rounded flex justify-center flex-1 p-[0.50em] cursor-pointer"
        }
      >
        {/*@ts-ignore*/}
        <MinusIcon className={"w-5 h-5 hover:text-red-500 text-gray-500"} />
      </div>
    </div>
  );
}
