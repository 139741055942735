<template>
  <div>
    <div class="top-bar-tutorial" v-if="isTutorial">
      <el-steps :active="activeTutorialStep" align-center>
        <el-step
          :title="
            $mq === 'xs' || $mq === 'sm' || $mq === 'md'
              ? ''
              : 'Crea il tuo albergo'
          "
        ></el-step>
        <el-step
          :title="
            $mq === 'xs' || $mq === 'sm' || $mq === 'md'
              ? ''
              : 'Aggiungi un piatto alla raccolta'
          "
        ></el-step>
        <el-step
          :title="
            $mq === 'xs' || $mq === 'sm' || $mq === 'md'
              ? ''
              : 'Disponi un menu sul calendario'
          "
        ></el-step>
        <el-step
          :title="
            $mq === 'xs' || $mq === 'sm' || $mq === 'md'
              ? ''
              : 'Aggiungi una prenotazione'
          "
        ></el-step>
        <!-- <el-step title="Fine" description="Fine"></el-step> -->
      </el-steps>
      <p v-if="activeTutorialStep !== 5" class="end-training">
        <span v-on:click="skipTutorial">Salta addestramento</span>
      </p>
    </div>

    <div
      v-if="!$route.meta.noBar && !isTutorial"
      class="outer-wrapper no-print"
    >
      <div
        :class="{
          //offset2: $mq === 'xl' && hotel,
          offset2: $mq === 'mxl' && hotel,
          offset5: $mq === 'md' && hotel,
          offset4: $mq === 'lg' /*|| $mq === 'mxl'*/ && hotel,
          mobileTopBar: $mq === 'xs' || $mq === 'sm',
          mobileOpened: $store.state.mobileMenuOpened,
        }"
        class="top-bar d-flex align-items-center"
      >
        <mq-layout v-if="hotel" :mq="['xs', 'sm']">
          <div class="burger-wrapper">
            <span
              :class="{
                mobileOpened: $store.state.mobileMenuOpened,
              }"
              v-on:click="toggleMenu"
              class="icon-burger-menu"
            ></span>
          </div>
        </mq-layout>

        <div v-if="!hotel" class="hotel-link-wrapper">
          <img
            v-on:click="toHotels"
            style="
              width: 50px;
              height: 50px;
              margin-right: 20px;
              cursor: pointer;
            "
            src="../../assets/JpastaIconMask.svg"
          />
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            margin-right: 10px;
          "
        >
          <h2 class="hotelName" v-if="hotel">{{ hotel.get("name") }}</h2>
          <BreadcrumbsBar style="margin-top: 4px"></BreadcrumbsBar>
        </div>

        <div class="hotel-link-wrapper" v-if="$route.meta.cpanel">
          <span class="hotel-link" v-on:click.prevent.stop="toLabelsCfg"
            >Etichette predefinite</span
          >
        </div>
        <el-dropdown
          v-if="getLoggedUser()"
          class="profileDropdown"
          v-on:command="handleProfileCommand($event)"
          trigger="click"
        >
          <div style="display: flex; align-items: center; cursor: pointer">
            <div
              style="display: flex; flex-direction: column; margin-left: auto"
            >
              <p
                style="margin-left: auto; margin-right: 0.5em; font-weight: 500"
              >
                {{
                  getLoggedUser().get("name") || getLoggedUser().get("surname")
                    ? getLoggedUser().get("name") +
                      " " +
                      getLoggedUser().get("surname")
                    : getLoggedUser().get("username")
                }}
              </p>
              <p style="margin-right: 0.5em; margin-left: auto; opacity: 0.7">
                {{ getLoggedUser().get("username") }}
              </p>
            </div>
            <javatar
              style="margin-right: 1.3em"
              :user="getLoggedUser()"
            ></javatar>
          </div>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item
            v-if="getLoggedUser().isSync('admin', $store.state.roles.usersRelation)"
            command="cpanel"
            >Pannello di Controllo</el-dropdown-item>-->
            <el-dropdown-item command="logout">Esci</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import Vue from "vue";
import moment from "moment-timezone";
import BreadcrumbsBar from "./BreadcrumbsBar.vue";
import {
  JPASTA_MEALS,
  JPASTA_COURSES,
  User,
  Jpasta,
  findJCourseByLocalId,
  findJMealByLocalId,
} from "@/jpasta-sdk";

export default {
  components: {
    BreadcrumbsBar,
  },
  data: function () {
    return {
      showMenu: false,
      topAdvice: {
        content: "",
        opened: "",
      },
      showTopTooltip: true,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    skipTutorial: function () {
      const user = this.$store.state.users.current;

      user.set("tutorialCompleted", true);

      this.$store.dispatch("users/saveObj", user);

      this.$router
        .push({
          name: "Hotels",
        })
        .catch((e) => {});
    },
    toAllHotels: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "Hotels",
        })
        .catch((e) => {});
    },
    toDishesList: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "HotelDishes",
        })
        .catch((e) => {});
    },
    toStructureEdit: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "HotelEdit",
        })
        .catch((e) => {});
    },
    toTablesView: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "TablesView",
        })
        .catch((e) => {});
    },

    checkIfNeedConfirmation: function () {
      if (
        this.$route.query.saved !== undefined &&
        !parseInt(this.$route.query.saved)
      ) {
        return this.$dialog
          .confirm(
            "Hai modificato il menù, ma non hai salvato. Continuando perderai le modifiche, vuoi procedere?",
            {
              okText: "Continua",
              cancelText: "Annulla",
            }
          )
          .then(function () {
            return Promise.resolve();
          })
          .catch(function () {
            return Promise.reject();
          });
      } else if (
        this.$route.query.savedPrice !== undefined &&
        !parseInt(this.$route.query.savedPrice)
      ) {
        return this.$dialog
          .confirm(
            "Hai modificato i prezzi, ma non hai salvato. Continuando perderai le modifiche, vuoi procedere?",
            {
              okText: "Continua",
              cancelText: "Annulla",
            }
          )
          .then(function () {
            return Promise.resolve();
          })
          .catch(function () {
            return Promise.reject();
          });
      } else {
        return Promise.resolve();
      }
    },
    toggleMenu: function () {
      this.$store.commit(
        "setMobileMenuOpened",
        !this.$store.state.mobileMenuOpened
      );
      //this.showMenu = !this.showMenu;
    },
    getLoggedUser: function () {
      return User.current();
    },
    toLabelsCfg: function () {
      this.$router
        .push({
          name: "Labels",
        })
        .catch((e) => {});
    },
    toStructure: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "Structure",
        })
        .catch((e) => {});
    },
    toHotelPrices: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router
        .push({
          name: "HotelPrices",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch((e) => {});
    },
    toHotelMealBook: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router
        .push({
          name: "HotelMealBooks",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch((e) => {});
    },
    toHotelEdit: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router
        .push({
          name: "HotelEdit",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch((e) => {});
    },
    toHotelRank: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router
        .push({
          name: "HotelRank",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch((e) => {});
    },
    toHotelDishes: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router
        .push({
          name: "HotelDishes",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch((e) => {});
    },

    toHotelCalendar: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }

      this.$router
        .push({
          name: "HotelCalendar",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch((e) => {});
    },
    toHotels: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router
        .push({
          name: "Hotels",
        })
        .catch((e) => {});
    },
    handleProfileCommand: function (command) {
      switch (command) {
        case "logout":
          return this.checkIfNeedConfirmation().then(() => {
            this.$store.dispatch("users/logOut").then(() => {
              this.$router
                .push({
                  name: "Login",
                })
                .catch((e) => {});
            });
          });
        case "cpanel":
          return this.toLabelsCfg();
      }
    },
    getBooksOf: function (hotel) {
      return _.filter(this.$store.state.books.objects, (book) => {
        return book.belongsTo(hotel, "hotel") && !book.get("stafferBook");
      });
    },
    getDishesOf: function (hotel) {
      return _.filter(this.$store.state.dishes.objects, (dish) => {
        return dish.belongsTo(hotel, "hotel");
      });
    },
    getCalendarMealsOf: function (hotel) {
      return _.filter(this.$store.state.calendarMeals.objects, (cMeal) => {
        return cMeal.belongsTo(hotel, "hotel");
      });
    },
    resize: function () {
      var w = window.innerWidth;
      if (w > 768) {
        this.showTopTooltip = true;
      } else {
        this.showTopTooltip = false;
      }
    },
  },
  computed: {
    activeTutorialStep: function () {
      switch (this.$route.name) {
        case "HotelEdit":
        case "Hotels":
          return 0;
        case "HotelDishes":
          return 1;
        case "HotelCalendar":
          return 2;
        case "BookList":
          return 3;
        case "TutorialQrCode":
          return 5;
        default:
          return 0;
      }
    },

    isTutorial: function () {
      return this.$route.query.tutorial == "1";
    },
    currHotelBooks: function () {
      return this.hotel ? this.getBooksOf(this.hotel) : null;
    },

    currHotelDishes: function () {
      return this.hotel ? this.getDishesOf(this.hotel) : null;
    },

    currHotelCalendarMeals: function () {
      return this.hotel ? this.getCalendarMealsOf(this.hotel) : null;
    },

    currHotelMissData: function () {
      return this.hotel
        ? !this.hotel.get("name") || !this.hotel.get("name").length
        : false;
    },
    hotel: function () {
      return this.$route.params.hotelid
        ? this.$store.state.hotels.objects[this.$route.params.hotelid]
        : null;
    },

    meal: function () {
      return this.$route.params.mealid
        ? findJMealByLocalId(this.$route.params.mealid)
        : null;
    },

    book: function () {
      return this.$route.params.bookid
        ? this.$store.state.books.objects[this.$route.params.bookid]
        : null;
    },
    template: function () {
      return this.$route.params.templateid
        ? this.$store.state.templates.objects[this.$route.params.templateid]
        : null;
    },
    calendarMeal: function () {
      return this.$route.params.calendarmealid
        ? this.$store.state.calendarMeals.objects[
            this.$route.params.calendarmealid
          ]
        : null;
    },
  },
  watch: {
    $route: function (newRoute, oldRoute) {
      if (newRoute.name === oldRoute.name) return;

      var refs = [
        "calendar-advice",
        "dishes-advice",
        "books-advice",
        "settings-advice",
      ];
      _.forEach(refs, (ref) => {
        if (this.$refs[ref]) this.$refs[ref].forceOpen();
      });
    },
  },
};
</script>

<style>
.bm-burger-button {
  display: none;
}
</style>

<style lang="scss" scoped>
@import "../../assets/scss/constants.scss";

.icon-burger-menu {
  &.mobileOpened {
    color: map-get($colors, "primary");
  }
}
.logoWrapper {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.end-training {
  text-decoration: underline;
  opacity: 0.4;
  text-align: center;
  margin-top: 1.5em;
  font-size: 14px;
  span {
    cursor: pointer;
  }
}

.burger-wrapper {
  margin-left: 15px;
  font-size: 22px;
  opacity: 0.5;
  line-height: 1;
}

.outer-wrapper {
  position: relative;
  margin-bottom: 64px;
  z-index: 1;
}
.hotel-link-wrapper {
  padding: 0 10px;
  display: flex;
  align-items: center;
  .hotel-link {
    margin-right: 18px;
    font-weight: 800;
    opacity: 0.9;
    cursor: pointer;
  }

  .hotel-link:hover,
  .hotel-link.selected {
    color: map-get($colors, "primary");
  }
}

.top-bar-tutorial {
  padding: 20px 5px;
  z-index: 3000;
  background: white;
  position: relative;
  height: 162px;

  @include media-breakpoint-down(sm) {
    height: 84px;
  }
}

.top-bar {
  .hotelName {
    font-size: 18px;
    opacity: 0.6;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }

    // @include media-breakpoint-down(md) {
    //   margin-left: auto;
    //   margin-right: auto;
    // }
  }
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  background-color: white;
  &.mobileTopBar {
    transition: left 0.2s;
    &.mobileOpened {
      left: 200px !important;
      width: 100%;
    }
  }
  &.offset4 {
    left: 16.6666667%;
  }
  &.offset3 {
    left: 12.5%;
  }
  &.offset2 {
    left: 12.5%;
  }
  &.offset5 {
    left: 20.8333333%;
  }
}

.sub-bar {
  min-height: 32px;
  /* border-bottom: 1px solid rgba(0,0,0,0.2);
		border-top: 1px solid rgba(0,0,0,0.2); */
  /* box-shadow: 1px 1px 3px rgba(0,0,0,0.15); */
  background-color: rgb(250, 251, 251);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  left: 0;
  right: 0;
  top: 64px;
  padding: 0 10px;
}

@include media-breakpoint-down(md) {
  .top-bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .sub-bar {
    top: 0;
  }
}
</style>
