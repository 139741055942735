
import Vue from "vue";
import mixins from "vue-typed-mixins";
import moment, { Moment } from "moment-timezone";
import { Hotel, Room, User, Book } from "@/jpasta-sdk";
import _, { Dictionary } from "lodash";
import Parse from "parse";

export default mixins().extend({
  data: function (): {
    text?: string;
    sending: boolean;
    checkinBefore: Moment;
    checkoutAfter: Moment;
    selectedRoomsNames: string[];
    updatingList: boolean;
    fetching: boolean;
    timeout?: NodeJS.Timeout;
    reachedBooks: Book[];
    showReachedBooks: boolean;
  } {
    return {
      text: undefined,
      sending: false,
      showReachedBooks: false,
      checkinBefore: moment(),
      checkoutAfter: moment(),
      selectedRoomsNames: [],
      updatingList: false,
      timeout: undefined,
      fetching: false,
      reachedBooks: [],
    };
  },
  mounted() {
    this.updateList();
  },
  methods: {
    buildInput() {
      const params: Dictionary<any> = {
        sessionToken: (
          this.$store.state.users.current as User
        ).getSessionToken(),
        hotelId: this.hotel.id,
      };

      if (this.selectedRoomsNames.length > 0) {
        // const roomNames = _.map(this.selectedRoomsLocalIds, lId => {
        //   const room = this.roomsMapByLocalId[lId];
        //   return room.get("name") as string;
        // });
        const roomNames = this.selectedRoomsNames;

        params.rooms = roomNames;
      }

      if (this.checkoutAfter) {
        params.checkoutMin = this.checkoutAfter.toISOString();
      }

      if (this.checkinBefore) {
        params.checkinMax = this.checkinBefore.toISOString();
      }

      params.text = this.text;

      return params;
    },

    formatDate(date: string) {
      const momDate = moment(date);
      if (!momDate.isValid()) return "";
      return momDate.format("DD MMM YYYY HH:mm");
    },
    closeReachedBooks() {
      this.showReachedBooks = false;
    },
    openReachedBooks() {
      this.showReachedBooks = true;
    },
    updateList() {
      this.updatingList = true;

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.timeout = undefined;

        await this.getBooksThatWillReceiveNotification();
        this.updatingList = false;
      }, 500);
    },

    async getBooksThatWillReceiveNotification() {
      this.fetching = true;

      try {
        const res = await Parse.Cloud.run(
          "getbooksthatwillbenotified",
          this.buildInput()
        );
        this.reachedBooks = res.books;
        console.log("res", res);
      } catch (e) {
        this.reachedBooks = [];
      } finally {
        this.fetching = false;
      }
    },
    async send() {
      try {
        this.sending = true;

        const res = await Parse.Cloud.run("massnotifybooks", this.buildInput());

        this.text = undefined;
        this.$message({
          type: "success",
          message: "Notifica inviata",
          customClass: "bottomNotification",
          duration: 4000,
        });
      } catch (e) {
        this.$message({
          type: "error",
          message: "Errore durante l'invio della notifica",
          customClass: "bottomNotification",
          duration: 4000,
        });
      } finally {
        this.sending = false;
      }
    },
    changeText(text: string) {
      this.text = text;
    },
  },
  computed: {
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    rooms: function (): Room[] {
      return _.filter(
        this.$store.state.rooms.objects as Dictionary<Room>,
        (r) => r.belongsTo(this.hotel, "hotel")
      );
    },
    roomsUniqByName: function (): Room[] {
      return _.unionBy(this.rooms, (r) => r.get("name"));
    },
    roomsMapByLocalId: function (): Dictionary<Room> {
      return _.keyBy(this.rooms, (r) => r.get("localId") as string);
    },
  },
  watch: {
    selectedRoomsNames: function () {
      this.updateList();
    },
    checkinBefore: function () {
      this.updateList();
    },
    checkoutAfter: function () {
      this.updateList();
    },
  },
});
