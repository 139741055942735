var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('JModal',{attrs:{"before-close":_vm.onClose,"title":"Modifica turno","visible":!!_vm.turnLocalId}},[(_vm.turn)?_c('el-row',{attrs:{"gutter":16}},[_c('el-form',{attrs:{"label-position":"top"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"Nome turno"}},[_c('jinput',{ref:"turnNameInput",attrs:{"value":_vm.turn.get('name'),"type":"text","showSpinnerOnDelay":true,"placeholder":"es. 1"},on:{"change":function($event){return _vm.setTurnProperty('name', $event)}}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('h3',{staticStyle:{"text-align":"center","margin-bottom":"16px"}},[_vm._v("Orari turni")]),_vm._l((_vm.meals),function(meal){return _c('el-row',{key:meal.id,attrs:{"gutter":16}},[_c('el-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"},attrs:{"span":24}},[_c('div',{staticStyle:{"height":"1px","flex":"1","background-color":"rgba(0, 0, 0, 0.15)","margin-right":"8px"}}),_c('h4',[_vm._v(" "+_vm._s(_vm.getLocalizedName(meal.name))+" ")]),_c('div',{staticStyle:{"height":"1px","flex":"1","background-color":"rgba(0, 0, 0, 0.15)","margin-left":"8px"}})]),_c('el-col',{attrs:{"xs":24,"sm":24,"md":12,"lg":12,"xl":12}},[_c('el-form-item',{attrs:{"label":"Dalle"}},[_c('el-time-select',{staticStyle:{"width":"100%"},attrs:{"size":"medium","editable":false,"value":_vm.turn.getFromTime(meal.id),"picker-options":{
                  start: '00:00',
                  step: '00:15',
                  end: _vm.turn.getToTime(meal.id)
                    ? _vm.turn.getToTime(meal.id)
                    : `23:59`,
                },"placeholder":"Seleziona inizio turno"},on:{"input":function($event){return _vm.setFromTime(_vm.turn, $event, meal.id)}}})],1)],1),_c('el-col',{attrs:{"xs":24,"sm":24,"md":12,"lg":12,"xl":12}},[_c('el-form-item',{attrs:{"label":"Alle"}},[_c('el-time-select',{staticStyle:{"width":"100%"},attrs:{"editable":false,"size":"medium","value":_vm.turn.getToTime(meal.id),"picker-options":{
                  start: _vm.turn.getFromTime(meal.id)
                    ? _vm.turn.getFromTime(meal.id)
                    : '00:00',
                  step: '00:15',
                  end: `23:59`,
                },"placeholder":"Seleziona fine turno"},on:{"input":function($event){return _vm.setToTime(_vm.turn, $event, meal.id)}}})],1)],1)],1)})],2)],1)],1):_vm._e(),_c('div',{staticClass:"footerButtonWrapper",attrs:{"slot":"footer"},slot:"footer"},[_c('el-row',[_c('el-col',[_c('jbutton',{attrs:{"type":"border-primary"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.newTurn.apply(null, arguments)}}},[_vm._v("Nuovo turno")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }