var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-row',{staticClass:"dish-card-row",attrs:{"gutter":16}},_vm._l((_vm.dishes),function(dish){return _c('el-col',{key:dish.id,attrs:{"xs":24,"sm":8,"md":6,"lg":6,"xl":6},nativeOn:{"click":function($event){return _vm.$emit('dishSelected', dish.id)}}},[_c('el-card',{staticClass:"dish-card",attrs:{"body-style":{ padding: '0px' }}},[_c('el-row',[_c('el-col',{staticClass:"headerWrapper",attrs:{"span":24}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isUploading(dish.id)),expression:"isUploading(dish.id)"}],staticClass:"image",class:{
              'no-image': !dish.image,
            },style:({
              ..._vm.getBgImage(dish.image),
            })},[_c('div',{staticClass:"courseBadge",class:{
                small:
                  _vm.$mq === 'md' ||
                  _vm.$mq === 'sm' ||
                  _vm.$mq === 'xs' ||
                  _vm.$mq === 'lg',
              },style:({
                'background-color': _vm.getColor(dish.courseName),
              })},[_vm._v(" "+_vm._s(dish.courseName)+" ")]),_c('span',{staticClass:"icon-cross delete-item",staticStyle:{"font-size":"8px","margin-left":"auto","font-weight":"bold","cursor":"pointer"},on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteDish(dish)}}}),_c('div',{staticClass:"allergenWrapper"},_vm._l((dish.allergens),function(a){return _c('img',{key:a,staticClass:"allergenIcon",attrs:{"title":_vm.getAllergenTitle(a),"src":_vm.getAllergenIcon(a)}})}),0)])]),_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"dish-title-wrapper",staticStyle:{"padding":"14px","padding-top":"14px"},attrs:{"title":_vm.getDishName(dish.name)}},[_c('span',{staticStyle:{"word-break":"break-all","font-weight":"bold","display":"flex","align-items":"center","justify-content":"space-between"}},[_c('span',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.getDishName(dish.name))+" ")]),(
                  !_vm.isTutorial &&
                  dish.courseIndex === undefined &&
                  _vm.canSetCourse
                )?_c('jtooltip',{staticStyle:{"margin-left":"10px","margin-right":"3px"},attrs:{"autoHide":true,"title":"Portata mancante","trigger":"hover","content":"I piatti senza portata non potranno essere disposti sul calendario.<br/>Clicca su questo piatto per selezionarne una."}},[_c('span',{staticClass:"icon-warning text-primary",attrs:{"slot":"target"},slot:"target"})]):_vm._e(),(
                  !_vm.isTutorial &&
                  dish.courseIndex !== undefined &&
                  _vm.getDishName(dish.name) === _vm.noTitlePlaceholder
                )?_c('jtooltip',{staticStyle:{"margin-left":"10px","margin-right":"3px"},attrs:{"autoHide":true,"title":"Nome mancante","trigger":"hover","content":"I piatti senza nome non potranno essere disposti sul calendario."}},[_c('span',{staticClass:"icon-warning text-primary",attrs:{"slot":"target"},slot:"target"})]):_vm._e()],1)])])],1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }