import { render, staticRenderFns } from "./EditMealBook.vue?vue&type=template&id=782fd43a&scoped=true&"
import script from "./EditMealBook.vue?vue&type=script&lang=ts&"
export * from "./EditMealBook.vue?vue&type=script&lang=ts&"
import style0 from "./EditMealBook.vue?vue&type=style&index=0&id=782fd43a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "782fd43a",
  null
  
)

export default component.exports