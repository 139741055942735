import generalMutations from "./generalMutations";
import generalActions from "./generalActions";
// import Parse from "parse";

export default {
  namespaced: true,
  state: {
    objects: {},
    className: "Hotel",
  },

  mutations: {
    ...generalMutations,
  },
  actions: {
    ...generalActions,
    async saveObj(vuexObj, obj) {
      //var existed = !!obj.get("localId");

      var hotel = await generalActions.saveObj(vuexObj, obj);
      // if (!existed) {
      // 	var predefinedTemplates = vuexObj.rootGetters['templates/getPredefined']

      // 	if (predefinedTemplates.length) {
      // 		vuexObj.dispatch('startConfigTemplate', undefined, { root: true })
      // 		vuexObj.commit('templates/setLoadingConfigTextIndex', 1, { root: true })
      // 		setTimeout(async () => {

      // 			for (var template of predefinedTemplates) {
      // 				await vuexObj.dispatch('templates/addTemplateToHotel', {
      // 					template: template,
      // 					hotel: hotel
      // 				}, { root: true })
      // 			}

      // 			vuexObj.dispatch('stopConfigTemplate', undefined, { root: true })
      // 		}, 2500)
      // 	}
      // }

      return hotel;
    },
  },
};
