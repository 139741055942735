
import moment from "moment";
import advices from "../advices";
import copy from "copy-to-clipboard";
import mixins from "vue-typed-mixins";
import _, { Dictionary } from "lodash";
import { BookPerson, Hotel, Book } from "@/jpasta-sdk";
let mouseGoUp = false;

export default mixins().extend({
  props: {
    book: {
      type: Object as () => Book,
    },
    hotel: {
      type: Object as () => Hotel,
    },
    advices: {
      type: Object as () => any,
    },
  },

  methods: {
    copy(string): void {
      copy(string);
      this.$msg("Copiato negli appunti");
    },
    goToSummary(): void {
      this.$router.push({
        name: "Summary",
        params: {
          bookid: this.book.get("localId"),
          hotelid: this.hotel.get("localId"),
        },
      });
    },
    getPeopleOfBook: function (book: Book): number {
      return book.getPax();
    },
    formatDate: function (date): string {
      return moment(date).format("D MMMM YYYY");
    },
    deleteBook: function (book): Promise<any> {
      return this.$dialog
        .confirm("Vuoi davvero eliminare questa prenotazione?", {
          okText: "Continua",
          cancelText: "Annulla",
        })
        .then(() => {
          return this.$store.dispatch("books/deleteObj", book);
        })
        .catch(() => {});
    },
    handleAction: function (book, action): any {
      switch (action) {
        case "toSummary":
          return this.goToSummary();
        case "delete":
          return this.deleteBook(book);
      }
    },
    getBookTitle: function (book: Book): string {
      const res: string[] = [];
      const name = book.get("refName");
      const email = book.get("refEmail");
      if (name && name.length) {
        res.push(name);
      } /* else {
        res.push("Email mancante");
      }*/

      res.push(`${this.getPeopleOfBook(book)} PAX`);

      if (
        book.getTableNumber() !== undefined &&
        book.getTableNumber() !== null
      ) {
        res.push(`Tav ${book.getTableNumber()}`);
      }
      if (book.getRoomNumber() !== undefined && book.getRoomNumber() !== null) {
        res.push(`Cam ${book.getRoomNumber()}`);
      }

      return res.join(" - ");
    },
    mouseUp: function (): void {
      mouseGoUp = true;
    },
    goToBook: function (book, e): void {
      if (e.button === 2) return;
      if (e.target && e.target.closest(".dropdownBookWrapper")) return;
      mouseGoUp = false;
      setTimeout(() => {
        if (!mouseGoUp) return;
        this.$emit("bookClicked", book);
      }, 150);
    },
  },
});
