var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('JLayout',{attrs:{"loadingForSync":_vm.loadingForSync}},[_c('span',{staticClass:"no-print",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.getLocalizedName(_vm.meal.name))+" di "+_vm._s(_vm.getFormattedDate(_vm.calendarMeal.get("date")))+" ")]),(!_vm.orderFinished)?_c('span',{staticClass:"top-order-warn",attrs:{"slot":"below-title"},slot:"below-title"},[_vm._v("L'ordine è ancora in corso.")]):_vm._e(),_c('template',{slot:"mainCtaWrapper"},[_c('el-dropdown',{staticClass:"no-print",attrs:{"trigger":"click"},on:{"command":_vm.handlePrintCommand}},[_c('jbutton',[_vm._v("Stampa")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"kitchen"}},[_c('div',{staticClass:"detail-action"},[_vm._v(" Report Cucina "),_c('span',{staticClass:"advice"},[_vm._v("Oppure premi CTRL + U")])])]),_c('el-dropdown-item',{attrs:{"command":"waiter"}},[_c('div',{staticClass:"detail-action"},[_vm._v(" Report Sala "),_c('span',{staticClass:"advice"},[_vm._v("Oppure premi CTRL + S")])])]),_c('el-dropdown-item',{attrs:{"command":"waiterByName"}},[_c('div',{staticClass:"detail-action"},[_vm._v(" Report Sala con nomi "),_c('span',{staticClass:"advice"},[_vm._v("Oppure premi CTRL + M")])])]),_c('el-dropdown-item',{attrs:{"command":"waiterByTable"}},[_c('div',{staticClass:"detail-action"},[_vm._v("Report Sala per tavolo")])])],1)],1)],1),_c('div',[_c('div',{staticClass:"hidableWrapper mb-2",class:{
        hideHidable: !_vm.showKitchenReport,
      }},[_c('KitchenReport',{staticClass:"mt-0",class:{
          print: _vm.printing === 'kitchen',
        },attrs:{"turn":_vm.turn,"mealOrders":_vm.mealOrders,"mealOrderDishesMap":_vm.mealOrderDishesMap,"calendarMeal":_vm.calendarMeal}})],1),_c('WaiterReport',{ref:"waiterReport",staticClass:"hidableWrapper",class:{
        hideHidable: !_vm.showWaiterReport,
      },attrs:{"books":_vm.books,"showNotIncludedOrders":_vm.showNotIncludedOrders,"showUncompletedOrders":_vm.showUncompletedOrders,"showCompletedOrders":_vm.showCompletedOrders,"tables":_vm.tables,"ranks":_vm.ranks,"bookPeopleMap":_vm.bookPeopleMap,"mealOrders":_vm.mealOrders,"mealOrderDishesMap":_vm.mealOrderDishesMap,"calendarMeal":_vm.calendarMeal}}),_c('WaiterReportTable',{class:{
        print: _vm.printing === 'waiter',
      },attrs:{"ranks":_vm.ranks,"tables":_vm.tables,"hotel":_vm.hotel,"turn":_vm.turn,"books":_vm.books,"mealOrders":_vm.normalMealOrders,"mealOrderDishesMap":_vm.mealOrderDishesMap,"calendarMeal":_vm.calendarMeal}}),_c('WaiterReportTable',{class:{
        print: _vm.printing === 'waiter',
      },attrs:{"ranks":_vm.ranks,"tables":_vm.tables,"hotel":_vm.hotel,"turn":_vm.turn,"isDeliveryOrTakeaway":true,"title":"Delivery","books":_vm.books,"mealOrders":_vm.deliveryOrders,"mealOrderDishesMap":_vm.mealOrderDishesMap,"calendarMeal":_vm.calendarMeal}}),_c('WaiterReportTable',{class:{
        print: _vm.printing === 'waiter',
      },attrs:{"ranks":_vm.ranks,"tables":_vm.tables,"hotel":_vm.hotel,"turn":_vm.turn,"isDeliveryOrTakeaway":true,"title":"Takeaway","books":_vm.books,"mealOrders":_vm.takeAwayOrders,"mealOrderDishesMap":_vm.mealOrderDishesMap,"calendarMeal":_vm.calendarMeal}}),_c('WaiterReportByName',{class:{
        print: _vm.printing === 'waiterByName',
      },attrs:{"turn":_vm.turn,"mealOrders":_vm.mealOrders,"hotel":_vm.hotel,"calendarMeal":_vm.calendarMeal}}),_c('ReportPerTable',{class:{
        print: _vm.printing === 'waiterByTable',
      },attrs:{"hotel":_vm.hotel,"mealOrders":_vm.normalMealOrders,"mealOrderDishesMap":_vm.mealOrderDishesMap,"calendarMeal":_vm.calendarMeal}}),_c('ReportPerTable',{class:{
        print: _vm.printing === 'waiterByTable',
      },attrs:{"title":"Delivery","hotel":_vm.hotel,"mealOrders":_vm.deliveryOrders,"mealOrderDishesMap":_vm.mealOrderDishesMap,"calendarMeal":_vm.calendarMeal}}),_c('ReportPerTable',{class:{
        print: _vm.printing === 'waiterByTable',
      },attrs:{"title":"Take Away","hotel":_vm.hotel,"mealOrders":_vm.takeAwayOrders,"mealOrderDishesMap":_vm.mealOrderDishesMap,"calendarMeal":_vm.calendarMeal}})],1),_c('div',{attrs:{"slot":"rightMenu"},slot:"rightMenu"},[_c('el-form',{ref:"form",attrs:{"label-position":"top","label-width":"120px"}},[_c('el-form-item',[_c('h4',{attrs:{"slot":"label"},slot:"label"},[_vm._v("Visualizzazione")]),_c('el-row',{staticStyle:{"width":"100%"},attrs:{"gutter":16}},[_c('el-col',{attrs:{"span":24}},[_c('el-checkbox',{staticClass:"jcheckbox",model:{value:(_vm.showKitchenReport),callback:function ($$v) {_vm.showKitchenReport=$$v},expression:"showKitchenReport"}},[_vm._v("Mostra report cucina")])],1),_c('el-col',{attrs:{"span":24}},[_c('el-checkbox',{staticClass:"jcheckbox",model:{value:(_vm.showWaiterReport),callback:function ($$v) {_vm.showWaiterReport=$$v},expression:"showWaiterReport"}},[_vm._v("Mostra report sala")])],1)],1)],1),_c('el-form-item',[_c('h4',{attrs:{"slot":"label"},slot:"label"},[_vm._v("Filtra report sala")]),_c('el-row',{staticStyle:{"width":"100%"},attrs:{"gutter":16}},[_c('el-col',{attrs:{"span":24}},[_c('el-checkbox',{model:{value:(_vm.showUncompletedOrders),callback:function ($$v) {_vm.showUncompletedOrders=$$v},expression:"showUncompletedOrders"}},[_vm._v("Mostra ordini incompleti")])],1),_c('el-col',{attrs:{"span":24}},[_c('el-checkbox',{attrs:{"label":"Online activities"},model:{value:(_vm.showCompletedOrders),callback:function ($$v) {_vm.showCompletedOrders=$$v},expression:"showCompletedOrders"}},[_vm._v("Mostra ordini completi")])],1),_c('el-col',{attrs:{"span":24}},[_c('el-checkbox',{attrs:{"label":"Online activities"},model:{value:(_vm.showNotIncludedOrders),callback:function ($$v) {_vm.showNotIncludedOrders=$$v},expression:"showNotIncludedOrders"}},[_vm._v("Mostra ordini non inclusi")])],1)],1)],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }