
import VisibleAreaHeightMixin from "@/mixins/VisibleAreaHeightMixin";
import mixins from "vue-typed-mixins";
export default mixins(VisibleAreaHeightMixin).extend({
  data: function () {
    return {};
  },

  computed: {
    hotel: function () {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
  },
});
