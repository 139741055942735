
import Vue from "vue";
import mixins from "vue-typed-mixins";
import { ExtraMealOrder, Hotel, Dish, Jpasta } from "@/jpasta-sdk";
import _ from "lodash";
import { Dictionary } from "vue-router/types/router";
import moment from "moment-timezone";
export default mixins().extend({
  props: {
    order: {
      type: Object as () => ExtraMealOrder,
    },

    hotel: {
      type: Object as () => Hotel,
    },
  },
  methods: {
    getDishes: function (order: ExtraMealOrder) {
      return _.uniqBy(order.getDishes(), (d) => {
        return d.get("localId");
      });
    },
    markAsDelivered: async function () {
      const order = this.order;
      order.markAsDelivered();
      this.$store.dispatch("extraMealOrders/saveObj", order);
    },
    getStatusText: function (): string | undefined {
      switch (this.order.getStatus()) {
        case "rejected":
          return "Rifiutato";
        case "cancelled":
          return "Annullato dal cliente";
        case "approved":
          return "Approvato";
      }
    },
    getStatusBadgeColor(): string | undefined {
      switch (this.order.getStatus()) {
        case "rejected":
          return "#f4443f";
        case "cancelled":
          return "#f4443f";
        case "approved":
          return "#2DC300";
      }
    },
    approve: async function () {
      try {
        await this.$dialog.confirm(
          `Sei sicuro di approvare quest'ordine, questa operazione non può essere annullata`,
          {
            okText: "Approva",
            cancelText: "Annulla",
          }
        );
      } catch (e) {
        return;
      }

      const order = this.order;
      order.approve();
      this.$store.dispatch("extraMealOrders/saveObj", order);
    },
    reject: async function () {
      try {
        await this.$dialog.confirm(
          `Sei sicuro di rifiutare quest'ordine, questa operazione non può essere annullata`,
          {
            okText: "Rifiuta",
            cancelText: "Annulla",
          }
        );
      } catch (e) {
        return;
      }

      const order = this.order;
      order.reject();
      this.$store.dispatch("extraMealOrders/saveObj", order);
    },
    getMinor() {
      return "<";
    },
    getLocationNotes() {
      //return this.order.getDeliveryLocationNotes()
    },
    getNotes() {
      //return this.order
    },
    getLocalizedName(name) {
      return Jpasta.getLocalizedName(name, "it", "Piatto senza nome");
    },

    getDishQty(dish: Dish): number {
      return this.order.getDishQty(dish);
    },
    getBadgeText() {
      return this.order.isDelivery() ? "Da consegnare" : "Da asporto";
    },

    getCreationDate() {
      const creation = moment(this.order.get("createdAt"))
        .tz(this.hotel.getTimezone())
        .format("DD MMM YYYY HH:mm");
      return `Inviato il ${creation}`;
    },
    getDueDate() {
      const dueDate = moment(this.order.getDueDate()).format(
        "DD MMM YYYY HH:mm"
      );
      return `Data richiesta: ${dueDate}`;
    },
  },

  computed: {
    total: function () {
      return this.order.getTotalCost();
    },
    notes: function () {
      return this.order.get("notes");
    },
    urgencyLevel: function () {
      return this.order.urgencyLevel();
    },
  },
});
