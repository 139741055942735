
import Vue from "vue";
import mixins from "vue-typed-mixins";
import { Hotel } from "@/jpasta-sdk";
export default mixins().extend({
  methods: {
    get5StelleEndpoint(): string | undefined {
      return this.hotel.get5StatsEndpoint();
    },
    get5StelleClientId(): string | undefined {
      return this.hotel.get5StatsClientId();
    },
    getPmsType() {
      return this.hotel.getPmsType();
    },
    set5StelleEndpoint(val) {
      const hotel = this.hotel;
      hotel.set5starsEndpoint(val);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    set5StelleClientId(val) {
      const hotel = this.hotel;
      hotel.set5starsClientId(val);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    setPmsType(val) {
      const hotel = this.hotel;

      hotel.setPmsType(val);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
  },
  computed: {
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
  },
});
