
import Parse from "parse";
import _, { Dictionary } from "lodash";
import moment from "moment";
import Vue from "vue";
import { Book, BookPerson, Hotel, User } from "@/jpasta-sdk";
import search from "approx-string-match";
import Cookies from "js-cookie";
import BookListItemComponent from "./BookListItemComponent.vue";
import advices from "../advices";
import setScrollTopMixin from "@/mixins/setScrollTopMixin";
import FilterBook from "./FilterBook.vue";
import AdviceMixin from "@/mixins/AdviceMixin";
import mixins from "vue-typed-mixins";
import UploadBookCsv from "./UploadBookCsv.vue";
import BookQrCode from "./BookQrCode.vue";
const CHECKIN_BETWEEN = 0;
const CHECKOUT_BETWEEN = 1;
const DATE_BETWEEN = 2;

const MAX_STRING_ERRORS = 2;
let mouseGoUp = false;
let keyDownPressed = false;
let keyDownPressedTimeout;
export default mixins(AdviceMixin, setScrollTopMixin).extend({
  // mixins: [setScrollTopMixin],
  components: {
    FilterBook,
    BookListItemComponent,
    UploadBookCsv,
    BookQrCode,
  },
  data: function (): {
    manualImporting: boolean;
    showQrCodeModal: boolean;
    syncingPeople: boolean;
    searchBook:
      | { type: "room" | "table" | "email" | "name"; value: string }
      | undefined;
    selectedBookId: string | undefined;
    searchBookString: string | undefined;
    bookEditDialogVisible: boolean;
    advices: any;
    dateFilter: any;
    dateFilterType: any;
    showPastBookings: boolean;
    showOnlyBookingsWithoutTable: boolean;
    loadingForSync: boolean;
    isNew: boolean;
    creatingBook: boolean;
    filterBookOptions: any[];
    dateRangeOptions: { label: string; value: number }[];
  } {
    return {
      syncingPeople: false,
      showQrCodeModal: false,
      manualImporting: false,
      searchBook: undefined,
      selectedBookId: undefined,
      bookEditDialogVisible: false,
      advices: advices,
      dateFilter: undefined,
      dateFilterType: CHECKIN_BETWEEN,
      showPastBookings: false,
      showOnlyBookingsWithoutTable: false,
      loadingForSync: true,
      filterBookOptions: [],
      isNew: false,
      searchBookString: undefined,
      creatingBook: false,
      dateRangeOptions: [
        { label: "Ha il checkin tra", value: CHECKIN_BETWEEN },
        { label: "Ha il checkout tra", value: CHECKOUT_BETWEEN },
        { label: "Ha un giorno qualsiasi tra", value: DATE_BETWEEN },
      ],
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);

    setTimeout(() => {
      this.loadingForSync = false;
      this.$nextTick(() => {
        this.parseQueryData();
      });
      this.setScrollTop();
    }, 200);
  },
  beforeDestroy: function () {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    triggerManualImport: async function (): Promise<void> {
      this.manualImporting = true;
      try {
        await this.$store.dispatch("books/manualImportPms", {
          hotelId: this.hotel.id,
        });
      } catch (e) {
        console.log("Import error");
      }

      this.manualImporting = false;
    },
    parseQueryData(): void {
      const selectedBookLocalId = this.$route.query.selectedBook;
      if (selectedBookLocalId === undefined) return;

      const book =
        this.$store.state.books.objects[selectedBookLocalId as string];
      if (!book) return;

      this.editBook(book);
    },
    goToSummary(): void {
      this.checkIfSyncingPeople()
        .then(() => {
          if (!this.selectedBook) return;
          this.$router.push({
            name: "Summary",
            params: {
              bookid: this.selectedBook.get("localId"),
              hotelid: this.hotel.get("localId"),
            },
          });
        })
        .catch(() => {});
    },
    onStartSyncingPeople(): void {
      this.syncingPeople = true;
    },
    onStopSyncingPeople(): void {
      this.syncingPeople = false;
    },
    checkIfSyncingPeople(): Promise<any> {
      if (!this.syncingPeople) return Promise.resolve();
      return this.$dialog
        .confirm(
          "La modifica al numero di persone non è ancora stata salvata, procedento perderai le modifiche",
          {
            okText: "Continua",
            cancelText: "Annulla",
          }
        )
        .then(function () {
          return Promise.resolve();
        })
        .catch(function () {
          return Promise.reject();
        });
    },
    deleteBookFromModal(): void {
      this.checkIfSyncingPeople()
        .then(async () => {
          if (await this.deleteBook(this.selectedBook)) {
            this.beforeCloseBookEditDialog();
          }
        })
        .catch(() => {});
    },
    resetTimeout(): void {
      if (keyDownPressedTimeout) clearTimeout(keyDownPressedTimeout);
      keyDownPressedTimeout = setTimeout(() => {
        keyDownPressed = false;
        keyDownPressedTimeout = undefined;
      }, 500);
    },
    keyDownDebounce(): boolean {
      if (keyDownPressed) {
        this.resetTimeout();
        return true;
      }

      keyDownPressed = true;

      this.resetTimeout();

      return false;
    },
    handleKeyDown: function (e): void {
      if (e.keyCode === 13 && e.shiftKey) {
        e.preventDefault();
        if (this.keyDownDebounce()) return;
        this.newBook();
      }
    },

    goToBookPeople: function (): void {
      if (!this.selectedBook) return;
      this.$router
        .push({
          name: "BookPeople",
          params: {
            bookid: this.selectedBook.get("localId"),
            hotelid: this.hotel.get("localId"),
          },
        })
        .catch((e) => {});
    },

    beforeCloseBookEditDialogWrapper: function (): void {
      this.checkIfSyncingPeople()
        .then(() => {
          this.beforeCloseBookEditDialog();
        })
        .catch(() => {});
    },
    beforeCloseBookEditDialog: function (): void {
      this.bookEditDialogVisible = false;
      this.selectedBookId = undefined;
      this.isNew = false;
    },
    setDateFilter: function (val): void {
      this.dateFilter = val;
    },

    deleteBook: async function (book): Promise<any> {
      try {
        await this.$dialog.confirm(
          "Vuoi davvero eliminare questa prenotazione?",
          {
            okText: "Continua",
            cancelText: "Annulla",
          }
        );
        this.$store.dispatch("books/deleteObj", book);
        return true;
      } catch (e) {
        return false;
      }
    },
    editBook: function (book: Book, isNew = false): void {
      this.isNew = isNew;
      this.selectedBookId = book.get("localId");
      this.bookEditDialogVisible = true;
    },

    realNewBook: async function (): Promise<void> {
      const book = new Book();
      book.set("hotel", this.hotel);
      book.set("pax", 1);
      this.creatingBook = true;
      this.$store.dispatch("books/saveObjSync", book).then((newBook) => {
        this.creatingBook = false;
        const bookPerson = new BookPerson();
        bookPerson.set("book", newBook);
        bookPerson.set("isHolder", true);
        this.$store.dispatch("bookPersons/saveObj", bookPerson);
        this.editBook(newBook, true);
      });
    },

    newBook: function (): void {
      this.checkIfSyncingPeople()
        .then(() => {
          this.realNewBook();
        })
        .catch((e) => {});
    },

    getPeopleOfBook: function (book): BookPerson[] {
      return _.filter(this.$store.state.bookPersons.objects, (bookPerson) => {
        return (
          bookPerson.get("book") &&
          ((bookPerson.get("book").id &&
            bookPerson.get("book").id === book.id) ||
            bookPerson.get("book").get("localId") === book.get("localId"))
        );
      });
    },
    matchSearchString: function (book: Book): boolean {
      const search = this.searchBook;
      if (!search) return true;
      let target;
      switch (search.type) {
        case "room":
          target = book.getRoomNumber() || "";
          break;
        case "table":
          target = book.getTableNumber() || "";
          break;
        case "email":
          target = book.get("refEmail") || "";
          break;
        case "name":
          target = book.get("refName") || "";
          break;
      }

      return target.toLowerCase().includes(search.value.toLowerCase());
    },
    matchPastBookingsFilter: function (book): boolean {
      if (this.showPastBookings) return true;

      return (
        book.get("checkout") && moment(book.get("checkout")).isAfter(moment())
      );
    },
    matchDateFilter: function (book): boolean {
      if (
        this.dateFilterType === undefined ||
        !this.dateFilter ||
        !this.dateFilter.length
      )
        return true;
      const [start, end] = this.dateFilter;

      switch (this.dateFilterType) {
        case CHECKIN_BETWEEN:
          return moment(book.get("checkin")).isBetween(
            start,
            end,
            undefined,
            "[]"
          );
          break;
        case CHECKOUT_BETWEEN:
          return moment(book.get("checkout")).isBetween(
            start,
            end,
            undefined,
            "[]"
          );
          break;
        case DATE_BETWEEN:
          return (
            moment(start).isBetween(
              book.get("checkin"),
              book.get("checkout"),
              undefined,
              "[]"
            ) ||
            moment(end).isBetween(
              book.get("checkin"),
              book.get("checkout"),
              undefined,
              "[]"
            ) ||
            (moment(book.get("checkin")).isBetween(
              start,
              end,
              undefined,
              "[]"
            ) &&
              moment(book.get("checkout")).isBetween(
                start,
                end,
                undefined,
                "[]"
              ))
          );
          break;
      }
      return false;
    },
  },

  computed: {
    canImportPms(): boolean {
      if (!this.user.get("lastManualPMSImport")) return true;
      return moment().isAfter(
        moment(this.user.get("lastManualPMSImport")).add(2, "minutes")
      );
    },
    user: function (): User {
      return this.$store.state.users.current;
    },
    selectedBook: function (): Book | undefined {
      return this.selectedBookId
        ? this.$store.state.books.objects[this.selectedBookId]
        : undefined;
    },
    appUrl: function (): string {
      return process.env.VUE_APP_APP_URL
        ? process.env.VUE_APP_APP_URL
        : "exp://192.168.1.100:19000?code=";
    },

    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },

    booksToRender: function (): {
      book: Book;
      key: string;
    }[] {
      var books = this.books;

      return _.map(books, (b) => {
        return {
          book: b,
          key: b.get("localId"),
        };
      });
    },

    books: function (): Book[] {
      return _.sortBy(
        _.filter(
          this.$store.state.books.objects as Dictionary<Book>,
          (book) => {
            if (!book.belongsTo(this.hotel, "hotel")) return false;

            if (book.get("stafferBook")) return false;

            if (!this.matchSearchString(book)) return false;

            if (!this.matchPastBookingsFilter(book)) return false;

            if (this.showOnlyBookingsWithoutTable) {
              if (book.getTableNumber() !== undefined) return false;
            }

            if (!this.matchDateFilter(book)) return false;

            return true;
          }
        ),
        (book) => {
          return book.get("checkin")
            ? moment(
                book.get("checkin")
              ).valueOf() /*moment().unix() - moment(book.get("checkin")).unix()*/
            : -Infinity;
        }
      );
    },
  },
  watch: {},
});
