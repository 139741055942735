import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyA90SsqcCQMxPW1KPUYY-Kjzh8Qdm9SQzQ",
  authDomain: "jpasta-ba55c.firebaseapp.com",
  databaseURL: "https://jpasta-ba55c.firebaseio.com",
  projectId: "jpasta-ba55c",
  storageBucket: "jpasta-ba55c.appspot.com",
  messagingSenderId: "331815204098",
  appId: "1:331815204098:web:0b1eec29cbec8447133e1b",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
