<template>
  <el-popover placement="bottom" trigger="click">
    <div class="input-field">
      <span class="label">Da quanti giorni prima si può ordinare?</span>
      <jinput
        :min="minDays"
        :max="maxDays"
        :value="days"
        v-on:input="confirm($event, time)"
        :inputStyle="{
          'background-color': 'transparent',
        }"
        type="number"
        class="hotel-input"
        placeholder="Numero Ore"
      ></jinput>
    </div>

    <div class="input-field">
      <span class="label">Da che ora del giorno si può ordinare?</span>
      <el-time-select
        v-on:input="confirm(days, $event)"
        :value="time"
        :clearable="false"
        placeholder="Seleziona orario"
        :picker-options="pickerOptions"
      ></el-time-select>
    </div>

    <span slot="reference" class="book-range-hour">
      <slot>Prova</slot>
    </span>
  </el-popover>
</template>

<script>
const MILLISECONDS_IN_ONE_MINUTE = 1000 * 60;
const MILLISECONDS_IN_ONE_DAY = 1000 * 60 * 60 * 24;
const MINUTES_IN_ONE_DAY = 60 * 24;
const MINUTES_IN_ONE_HOUR = 60;
import moment from "moment-timezone";
export default {
  props: {
    minutesBeforeEvent: {}, //Rappresenta il valore di questo componente
    evTime: {},
    instant: {},
    minInstant: {},
    maxInstant: {},
  },
  data: function () {
    return {
      pickerDisabled: false,
      basePickerOptions: {
        start: "00:00",
        step: "00:15",
        end: "23:59",
      },
    };
  },
  computed: {
    minDays: function () {
      let minTime = this.minInstant
        ? this.instant2timestring(this.minInstant)
        : this.evTime;
      let minDays = this.minInstant ? this.minInstant[0] : 0;
      return minTime < this.time ? minDays + 1 : minDays;
    },
    maxDays: function () {
      let maxTime = this.maxInstant
        ? this.instant2timestring(this.maxInstant)
        : this.evTime;
      let maxDays = this.maxInstant ? this.maxInstant[0] : Infinity;
      return maxTime > this.time ? maxDays - 1 : maxDays;
    },
    evHours: function () {
      try {
        return parseInt(this.evTime.split(":")[0]);
      } catch (e) {
        return 0;
      }
    },
    evMinutes: function () {
      try {
        return parseInt(this.evTime.split(":")[1]);
      } catch (e) {
        return 0;
      }
    },
    pickerOptions: function () {
      this.minInstant;
      this.days;
      this.basePickerOptions;
      this.maxInstant;
      this.evTime;
      if (this.minInstant !== undefined && this.days == this.minInstant[0]) {
        return {
          ...this.basePickerOptions,
          end: this.instant2timestring(this.minInstant),
        };
      } else if (
        this.maxInstant !== undefined &&
        this.days == this.maxInstant[0]
      ) {
        return {
          ...this.basePickerOptions,
          start: this.instant2timestring(this.maxInstant),
        };
      } else if (this.days == 0) {
        return {
          ...this.basePickerOptions,
          end: this.evTime,
        };
      } else {
        return this.basePickerOptions;
      }
    },
    days: function () {
      try {
        return this.instant[0];
      } catch (e) {
        return 0;
      }
    },
    time: function () {
      try {
        return `${this.pad(this.instant[1])}:${this.pad(this.instant[2])}`;
      } catch (e) {
        return `00:00`;
      }
    },
  },
  mounted() {},
  methods: {
    instant2timestring(instant) {
      return `${this.pad(instant[1])}:${this.pad(instant[2])}`;
    },
    confirm(days, time) {
      return this.$emit("change", [
        parseInt(days),
        parseInt(time.split(":")[0]),
        parseInt(time.split(":")[1]),
      ]);
    },
    pad: function (number) {
      number = typeof number == "number" ? number.toString() : number;
      return number < 10 ? "0" + number : number;
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.input-field:not(:first-child) {
  margin-top: 1em;
}

.input-field {
  display: flex;
  flex-direction: column;
  .label {
    font-size: 0.7em;
    padding-left: 10px;
    margin-bottom: 0.2em;
  }
}

.confirm-button {
  margin-top: 1em !important;
}
</style>
