
import _, { Dictionary } from "lodash";
import {
  Jpasta,
  findJMealByLocalId,
  MealOrderDish,
  CalendarMeal,
  MealOrder,
  Turn,
  Hotel,
  Table,
  Book,
  findJCourseByLocalId,
  Dish,
  Course,
} from "@/jpasta-sdk";
import moment from "moment-timezone";
import mixins from "vue-typed-mixins";

type ReportAnnotation = {
  text: string;
  halfPortion: boolean;
  qty: number;
};
type SeparatorItem = { type: "separator"; courseIndex };
type ReportDataItem = {
  dish: Dish;
  qty: number;

  annotations: ReportAnnotation[];
  totalNormal: number;
  totalNormalHalfPortion: number;
  pos: number;
  courseIndex: number;
};

type CourseItem = {
  courseName: string;
  courseIndex: string;
  dishes: ReportDataItem[];
};

type TurnItem = {
  turnName: string;

  turnLocalId: string;

  data: {
    [key: string]: ReportDataItem;
  };
};

export default mixins().extend({
  props: {
    turn: {
      type: Object as () => Turn | undefined,
    },
    calendarMeal: {
      type: Object as () => CalendarMeal,
    },
    mealOrders: {
      type: Array as () => MealOrder[],
    },
    mealOrderDishesMap: {
      type: Object as () => { [mealOrderLocalId: string]: MealOrderDish[] },
    },
  },
  mounted() {},
  methods: {
    formatTurnName: function (): string {
      if (!this.turn) return "Senza turno";
      return _.size(this.turn.get("name"))
        ? this.turn.get("name")
        : "Turno senza nome";
    },
    sizeOf(object: any) {
      return _.size(object);
    },
    getMealName(): any {
      const mealIndex = this.calendarMeal.get("mealIndex");
      if (mealIndex === undefined) return "";
      const meal = findJMealByLocalId(mealIndex);
      if (!meal) return "";
      return meal.name.it;
    },
    formatDate(date) {
      return moment(date).format("ddd D MMMM YYYY [ore] HH:mm");
    },
    getLocalizedName: function (name) {
      return Jpasta.getLocalizedName(name, "it", "Nessun nome");
    },
    getMealOrdersByTurn: function (
      mealOrders: MealOrder[],
      turn?: Turn
    ): MealOrder[] {
      return _.filter(mealOrders, (o) => {
        const book: Book = o.get("book");
        if (!book) return false;
        const tableName = book.getTableNumber();
        if (tableName === undefined && turn === undefined) return true;
        const tableTurn = this.turnByTable[tableName as string];
        if (tableTurn === undefined && turn === undefined) return true;
        return !!(
          turn &&
          tableTurn &&
          turn.get("localId") === tableTurn.get("localId")
        );
      });
    },
    getCourseName(courseIndex: number) {
      const jCourse = findJCourseByLocalId(courseIndex);

      if (!jCourse) return "";

      return Jpasta.getLocalizedName(jCourse.name, "it", "");
    },
  },
  computed: {
    mealOrdersByLocalId: function (): Dictionary<MealOrder> {
      return _.keyBy(this.mealOrders, (mo) => mo.get("localId") as string);
    },
    reportDataCourses: function (): CourseItem[] {
      const results: Dictionary<CourseItem> = {};

      const orderedDishes: Dictionary<MealOrderDish[]> = {};

      _.forEach(this.mealOrderDishesMap, (oDishes) => {
        _.forEach(oDishes, (odish) => {
          const dish = odish.get("dish") as Dish;
          if (!dish) return;
          orderedDishes[dish.get("localId")] =
            orderedDishes[dish.get("localId")] || [];
          orderedDishes[dish.get("localId")].push(odish);
        });
      });

      _.forEach(this.orderableDishes, (dishes, courseIndex) => {
        if (courseIndex === undefined) return;
        const jCourse = findJCourseByLocalId(courseIndex);
        if (!jCourse) return;
        const course = results[courseIndex] || {
          courseName: Jpasta.getLocalizedName(jCourse, "it", ""),
          courseIndex: courseIndex,
          dishes: [],
        };

        results[courseIndex] = course;

        _.forEach(dishes, (d) => {
          const oDishes = orderedDishes[d.get("localId")];

          const dataItem: ReportDataItem = _.reduce(
            oDishes,
            (res: ReportDataItem, odish) => {
              const qty = odish.get("quantity") || 0;
              const annotation = odish.get("annotation");

              const order: MealOrder = odish.get("order");
              if (!order) return res;
              if (!this.mealOrdersByLocalId[order.get("localId")]) return res;

              res.qty += qty;
              if (_.size(annotation) > 0) {
                res.annotations.push({
                  text: annotation,
                  halfPortion: odish.isHalfPortion(),
                  qty: qty,
                });
              } else if (odish.isHalfPortion()) {
                res.totalNormalHalfPortion += qty;
              } else {
                res.totalNormal += qty;
              }

              return res;
            },
            {
              dish: d,
              pos: 0 /*jCourse.pos,*/,
              qty: 0,
              totalNormal: 0,
              totalNormalHalfPortion: 0,

              annotations: [],
              courseIndex: jCourse.id,
            }
          );

          course.dishes.push(dataItem);
        });
      });

      const res = _.sortBy(results, (course) => {
        const jCourse = findJCourseByLocalId(course.courseIndex);
        if (!jCourse) return Infinity;
        return jCourse.pos;
      });

      console.log("res", res);
      return res;
    },
    orderableDishes: function (): Dictionary<Dish[]> {
      const cMealDishes: Dish[] = this.calendarMeal.get("dishes") || [];

      const dishesMap = _.keyBy(cMealDishes, (d) => d.get("localId") as string);

      const dishesGroupedByCourses = _.groupBy(cMealDishes, (d) => {
        return d.get("courseIndex");
      });

      _.forEach(this.mealOrders, (mo) => {
        const dishes = this.mealOrderDishesMap[mo.get("localId")];
        _.forEach(dishes, (d) => {
          const dish: Dish = d.get("dish");
          if (!dish) return;
          if (dishesMap[dish.get("localId")]) return;

          const courseIndex = dish.get("courseIndex");
          if (courseIndex === undefined) return;
          dishesMap[dish.get("localId")] = dish;
          dishesGroupedByCourses[courseIndex] =
            dishesGroupedByCourses[courseIndex] || [];
          dishesGroupedByCourses[courseIndex].push(dish);
        });
      });

      return dishesGroupedByCourses;
    },
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    tables: function (): Table[] {
      return _.filter(this.$store.state.tables.objects, (t: Table) => {
        return t.belongsTo(this.hotel, "hotel");
      });
    },
    turns: function (): Turn[] {
      return _.filter(this.$store.state.turns.objects, (t: Turn) => {
        return t.belongsTo(this.hotel, "hotel");
      });
    },
    turnByTable: function (): {
      [key: string]: Turn | undefined;
    } {
      return _.reduce(
        this.tables,
        (res, t) => {
          res[t.get("name")] = t.get("turn");

          return res;
        },
        {}
      );
    },
    dishesPosMap: function (): Dictionary<number> {
      const dishes: Dish[] = this.calendarMeal.get("dishes") || [];
      return _.reduce(
        dishes,
        (res: Dictionary<number>, dish, index) => {
          res[dish.get("localId")] = index;
          return res;
        },
        {}
      );
    },
  },
});
