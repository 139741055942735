
import Vue from "vue";
import mixins from "vue-typed-mixins";
import { JPASTA_COURSES, Jpasta, Hotel } from "@/jpasta-sdk";
export default mixins().extend({
  methods: {
    setUnlimited(courseLocalId, value) {
      const hotel = this.hotel;
      hotel.setUnlimitedDishes(courseLocalId, value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    isUnlimited(courseLocalId): boolean {
      return this.hotel.getMaxDishesByCourseLocalId(courseLocalId) >= Infinity;
    },
    setMaxDishes(courseLocalId, value) {
      const hotel = this.hotel;
      hotel.setMaxDishesOf(courseLocalId, value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    getMaxDishesOf(courseLocalId) {
      return this.hotel.getMaxDishesByCourseLocalId(courseLocalId);
    },
    localizeName(name) {
      return Jpasta.getLocalizedName(name, "it", "");
    },
  },

  computed: {
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    courses: function () {
      return JPASTA_COURSES;
    },
  },
});
