
import ViewDishesQrCode from "../Hotel/ViewDishesQrCode.vue";
import _ from "lodash";
import mixins from "vue-typed-mixins";
import { Hotel, ExtraMealOrder } from "@/jpasta-sdk";
import { Dictionary } from "lodash";
import moment from "moment-timezone";
export default mixins().extend({
  components: {
    ViewDishesQrCode,
  },
  data: function (): {
    showQrCodeModal: boolean;
  } {
    return {
      showQrCodeModal: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.resize);

    this.adjustWidth();

    const config = {
      selector: ".updatesWrapper",
      account: "JlRmMx",
      trigger: ".updatesText",
      translations: {
        title: "Cambiamenti",
        readMore: "Scopri di più",
        labels: {
          new: "Novità",
          improvement: "Aggiornamento",
          fix: "Risoluzione Bug",
        },
        footer: "Scopri di più 👉",
      },
    };
    //@ts-ignore
    (Headway as any).init(config);
  },
  computed: {
    hotel: function (): Hotel {
      return this.$route.params.hotelid
        ? this.$store.state.hotels.objects[this.$route.params.hotelid]
        : undefined;
    },
    notSeenExtraMealOrders: function (): ExtraMealOrder[] {
      if (!this.hotel) return [];
      return _.filter(
        this.$store.state.extraMealOrders.objects as Dictionary<ExtraMealOrder>,
        (mo) => {
          const hotel = this.hotel;
          const lastSeenDate = hotel.get("lastSeenExtraMealOrders");
          //console.log("lastSeenDate", lastSeenDate);
          return (
            mo.belongsTo(hotel, "hotel") &&
            (!lastSeenDate ||
              moment(lastSeenDate).isBefore(mo.get("createdAt")))
          );
        }
      );
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    getQrCodeUrl: function (): string {
      return `${process.env.VUE_APP_VIEW_MENU_BASE_URL}/view?hotelid=${this.hotel.id}`;
    },
    openQrCodeModal: function () {
      this.showQrCodeModal = true;
    },
    toMassNotifyView: function () {
      this.$router
        .push({
          name: "MassNotify",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch(() => {});
    },
    toTurnsView: function () {
      this.$router
        .push({
          name: "Turns",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch(() => {});
    },
    toExtraMealOrder: function () {
      this.$router
        .push({
          name: "ExtraMealOrder",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch(() => {});
    },
    adjustWidth: function (): void {
      if (!this.$refs.menuWrapper) return;
      const width = (this.$refs.menuWrapper as any).clientWidth;

      this.$store.commit("setLeftBarWidth", width);
    },
    resize: function (): void {
      _.debounce(this.adjustWidth, 500)();
    },
    checkIfNeedConfirmation: function (): any {
      if (
        this.$route.query.saved !== undefined &&
        !parseInt(this.$route.query.saved as string)
      ) {
        return this.$dialog
          .confirm(
            "Hai modificato il menù, ma non hai salvato. Continuando perderai le modifiche, vuoi procedere?",
            {
              okText: "Continua",
              cancelText: "Annulla",
            }
          )
          .then(function () {
            return Promise.resolve();
          })
          .catch(function () {
            return Promise.reject();
          });
      } else if (
        this.$route.query.savedPrice !== undefined &&
        !parseInt(this.$route.query.savedPrice as string)
      ) {
        return this.$dialog
          .confirm(
            "Hai modificato i prezzi, ma non hai salvato. Continuando perderai le modifiche, vuoi procedere?",
            {
              okText: "Continua",
              cancelText: "Annulla",
            }
          )
          .then(function () {
            return Promise.resolve();
          })
          .catch(function () {
            return Promise.reject();
          });
      } else {
        return Promise.resolve();
      }
    },
    toStructure: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "Structure",
        })
        .catch((e) => {});
    },
    toRoomsView: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "HotelRooms",
        })
        .catch((e) => {});
    },
    toDishesList: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "HotelDishes",
        })
        .catch((e) => {});
    },
    toStructureEdit: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "HotelEdit",
        })
        .catch((e) => {});
    },
    toTablesView: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "TablesView",
        })
        .catch((e) => {});
    },

    toHotelPlanning: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "Planning",
        })
        .catch((e) => {});
    },
    toAllHotels: async function (forced = false) {
      if (this.$mq === "xs" && !forced) return;
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "Hotels",
        })
        .catch((e) => {});
    },
    toBookList: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "BookList",
        })
        .catch((e) => {});
    },
    toHotelCalendar: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      return this.$router
        .push({
          name: "HotelCalendar",
        })
        .catch((e) => {});
    },
    toHotelPrices: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router
        .push({
          name: "HotelPrices",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch((e) => {});
    },
    toHotelMealBook: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router
        .push({
          name: "HotelMealBooks",
          params: {
            hotelid: this.$route.params.hotelid,
          },
        })
        .catch((e) => {});
    },
  },
});
