var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-hotel-wrapper"},[(_vm.step === 0)?_c('jcard',{staticClass:"new-hotel-card"},[_c('h2',{staticClass:"text-center",attrs:{"slot":"header"},slot:"header"},[_vm._v("Inizia addestramento")]),_c('div',{staticClass:"body"},[_c('p',{staticClass:"training-copy"},[_vm._v(" Inizia la tua esperienza su Jpasta esplorando le sue caratteristiche in 4 semplici passaggi! ")]),_c('jbutton',{staticClass:"new-hotel-button",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.$router
            .push({
              name: 'Hotels',
              query: {
                tutorial: '1',
              },
            })
            .catch((e) => {})}}},[_vm._v("Inizia")]),_c('p',{staticClass:"end-training"},[_c('span',{on:{"click":_vm.skipTutorial}},[_vm._v("Salta addestramento")])])],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }