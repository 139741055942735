import React from "react";

export function PrimaryLoadingSpinner() {
  return (
    <div
      className={
        "border-t-red-500 animate-spin rounded-full border-slate-300 border-4 h-10 w-10"
      }
    />
  );
}
