import Parse from "parse";
import Jpasta from "./Jpasta";

export default class Room extends Jpasta {
  constructor() {
    super("Room");
  }
}

Parse.Object.registerSubclass("Room", Room);
