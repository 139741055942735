var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.opened)?_c('div',[_c('div',{staticClass:"jmodal",style:({
        'z-index': _vm.zindex ? _vm.zindex : 3000,
      })},[_c('div',{staticClass:"container",class:{
          'min-size': _vm.minSize,
          'full-screen': _vm.fullScreen,
          'force-full-screen': _vm.forceFullScreen,
          md: _vm.size === 'md',
          lg: _vm.size === 'lg',
        }},[_c('div',{staticClass:"header"},[_c('div',{staticStyle:{"margin-right":"0.5em","display":"flex","align-items":"center","width":"100%"}},[_vm._t("header")],2),_c('span',{staticClass:"icon clickable",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onClose.apply(null, arguments)}}},[_vm._v("✕")])]),_c('div',{staticClass:"jmodal-body"},[_vm._t("default")],2),_c('div',{staticClass:"footer"},[_vm._t("footer")],2)]),_c('div',{staticClass:"mask",on:{"click":_vm.onClose}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }