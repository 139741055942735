import Parse from "parse";
import Jpasta from "./Jpasta";

import {
  findHotelRateByLocalId,
  findJCourseByLocalId,
  DRINK,
  //findJMealByLocalId,
} from "./constants";

import _ from "lodash";
import { Book, CalendarMeal, Hotel } from ".";
import { AgeType } from "./BookPerson";
// declare module "parse" {
//   interface Object {
//     addLabel: (label: string, labelIndex: string) => void;
//     deleteLabel: (labelIndex: string) => void;
//     getPrice: () => number;
//     getType: () => string;
//     isIncluded: (rateIndex: string, mealIndex: string) => boolean;
//   }
// }
export default class Dish extends Jpasta {
  constructor() {
    super("Dish");
  }

  public isExtra() {
    return !!this.get("isExtra");
  }

  public canBeOrderedOutOfMeal(): boolean {
    return !!this.get("canOrderOutOfMeal");
  }

  public setCanOrderExtraMeal(val: boolean) {
    this.set("canOrderOutOfMeal", val);
  }

  // Se il piatto è un extra costa in ogni caso. Se la prenotazione include cMeal, e il piatto
  // è incluso nella prenotazione allora è gratis. Altrimenti costa (non ho l'opzione di poter pagare a pasto
  // in questo caso perchà il pasto è incluso nella mia prenotazione)
  // Se il pasto non è incluso nella mia prenotazione e il piatto non è incluso nella mia prenotazione
  // e non è un extra, allora il prezzo è determinato dal valore di paymentType sull'hotel. Se il prezzo è a pasto
  // allota il piatto è gratis, altrimenti il piatto ha un costo
  public getDishPrice(book?: Book, cMeal?: CalendarMeal): number {
    if (!book || !cMeal) return 0;
    const price = this.getPrice();

    if (this.isExtra()) {
      return price;
    }

    const isIncluded = !!this.isIncluded(
      book.getBookType(),
      cMeal.get("mealIndex")
    );

    if (book.isIncluded(cMeal)) {
      if (isIncluded) return 0;
      else return price;
    } else if (isIncluded) return 0;

    const hotel: Hotel = this.get("hotel");
    const paymentType = hotel.getMealPaymentType();

    switch (paymentType) {
      case "per_meal":
        return 0;
      case "per_dish":
        return price;
      default:
        return 0;
    }
  }

  public canEatInDiningRoom(): boolean {
    return this.get("canEatInDiningRoom") === undefined
      ? true
      : this.get("canEatInDiningRoom");
  }
  public canTakeAway(): boolean {
    return this.get("canTakeAway") === undefined
      ? true
      : this.get("canTakeAway");
  }

  public canDelivery(): boolean {
    return this.get("canDelivery") === undefined
      ? true
      : this.get("canDelivery");
  }

  public setCanTakeAway(value: boolean) {
    this.set("canTakeAway", value);
  }

  public setCanDelivery(value: boolean) {
    this.set("canDelivery", value);
  }
  public setCanEatInDiningRoom(value: boolean) {
    this.set("canEatInDiningRoom", value);
  }

  public isAgeType(type: AgeType): boolean {
    switch (type) {
      case "baby":
        return this.isForBaby();
      case "child":
        return this.isForChildren();
      case "normal":
        return this.isNormal();
    }
  }

  public setIsForChildren(value: boolean) {
    this.set("forChildren", value);
  }
  public setIsForBaby(value: boolean) {
    this.set("forBaby", value);
  }

  public isForChildren(): boolean {
    return !!this.get("forChildren");
  }

  public isForBaby(): boolean {
    return !!this.get("forBaby");
  }

  public isNormal(): boolean {
    return !this.isForBaby() && !this.isForChildren();
  }

  addLabel = (label, labelIndex) => {
    const labels = this.get("labels") || {};
    labels[labelIndex] = label;
    this.set("labels", _.cloneDeep(labels));
  };

  deleteLabel = (labelIndex) => {
    const labels = this.get("labels") || {};
    delete labels[labelIndex];
    this.set("labels", _.cloneDeep(labels));
  };

  getPrice = () => {
    const price = parseFloat(this.get("price") || 0);
    return isNaN(price) ? 0 : price;
  };

  getType = () => {
    return this.get("type") || "dish";
  };

  private isIncludedStandard(rateIndex, mealIndex) {
    const courseIndex = this.get("courseIndex");
    const jCourse =
      courseIndex !== undefined ? findJCourseByLocalId(courseIndex) : undefined;
    const rate = findHotelRateByLocalId(rateIndex);

    if (!rate) return false;
    const type = jCourse && jCourse.id === DRINK ? "drink" : "dish";
    //let type = this.getType();
    //let rate = findHotelRateByLocalId(rateIndex);

    switch (type) {
      case "drink":
        return (
          rate.includedMeals.includes(parseInt(mealIndex)) && rate.withDrink
        );
      case "dish":
        return rate.includedMeals.includes(parseInt(mealIndex));
      default:
        return false;
    }
  }

  setIsIncluded = (rateIndex: number, mealIndex: number, value: boolean) => {
    const dishIncludedOptions = this.get("bookIncludedOptions") || {};
    dishIncludedOptions[rateIndex] = dishIncludedOptions[rateIndex] || {};
    dishIncludedOptions[rateIndex][mealIndex] = value;
    this.set("bookIncludedOptions", dishIncludedOptions);
  };

  isIncluded = (rateIndex: number, mealIndex: number) => {
    if (this.isExtra()) return false;
    const dishIncludedOptions = this.get("bookIncludedOptions") || {};
    if (
      dishIncludedOptions[rateIndex] === undefined ||
      dishIncludedOptions[rateIndex][mealIndex] === undefined
    ) {
      return this.isIncludedStandard(rateIndex, mealIndex);
    }

    return dishIncludedOptions[rateIndex][mealIndex];
  };
}

Parse.Object.registerSubclass("Dish", Dish);
