<template>
  <div class="breadcrumbs-items">
    <span
      :class="{
        first: index === 0,
      }"
      class="breadcrumbs-item-wrapper"
      :key="index"
      v-for="(item, index) in brItems"
    >
      <span
        class="breadcrumbs-item"
        :class="{
          clickable: item.to,
        }"
        v-on:click.prevent.stop="to(item.to)"
        >{{ item.name }}</span
      ><span class="breadcrumbs-divider" v-if="index < brItems.length - 1"
        >/</span
      >
    </span>
  </div>
</template>

<script>
export default {
  props: ["items"],
  computed: {
    brItems: function () {
      return this.items.length <= 5
        ? this.items
        : [
            this.items[0],
            { name: "...", to: this.items[this.items.length - 4].to },
            ...this.items.slice(-3),
          ];
    },
  },
  methods: {
    to: function (to) {
      if (to) this.$router.push(to).catch((e) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs-item,
.breadcrumbs-divider {
  margin-right: 0.5em;
}

/*	.breadcurmbs-item-wrapper {
		color: rgba(44,62,80, 0.9);
	}*/

.breadcrumbs-item-wrapper:not(.first),
.breadcrumbs-divider {
  color: darken(#cccccc, 20%);
}

.breadcrumbs-item-wrapper .breadcrumbs-item {
  font-weight: 600;
}

.clickable {
  cursor: pointer;
}

.breadcrumbs-item.clickable:hover {
  opacity: 0.6;
}
</style>
