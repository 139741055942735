var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{ref:"main-wrapper",staticClass:"main rootContainer w-100",attrs:{"type":"flex","justify":"center"}},[_c('el-col',{directives:[{name:"jpasta-loading",rawName:"v-jpasta-loading",value:(_vm.loadingForSync),expression:"loadingForSync"}],staticClass:"left-wrapper",attrs:{"xs":_vm.showMenu ? 12 : 23,"sm":_vm.showMenu ? 12 : 23,"md":_vm.showMenu ? 12 : 23,"lg":_vm.showMenu ? 14 : 20,"xl":_vm.showMenu ? 16 : 18}},[_vm._t("default")],2),(_vm.showMenu)?_c('el-col',{staticClass:"menu-wrapper no-print",style:({
      ..._vm.menuStyle,
      top:
        _vm.isTutorial && (_vm.$mq === 'xs' || _vm.$mq === 'sm' || _vm.$mq === 'md')
          ? '84px'
          : '64px',
      bottom:
        _vm.isTutorial && (_vm.$mq === 'xs' || _vm.$mq === 'sm' || _vm.$mq === 'md')
          ? '-2px'
          : '',
    }),attrs:{"xs":12,"sm":12,"md":12,"lg":10,"xl":8}},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu-header"},[_vm._t("menu-header"),_vm._t("menu-header-control",function(){return [_c('span',{staticClass:"icon",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.autoclose ? _vm.close : _vm.$emit('close')}}},[_vm._v("✕")])]})],2),_c('div',{ref:"menu-body",staticClass:"menu-body"},[_vm._t("menu-body")],2),_c('div',{staticClass:"menu-footer",style:(_vm.footerStyle)},[_vm._t("menu-footer")],2)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }