import generalMutations from "./generalMutations";
import generalActions from "./generalActions";
import _ from "lodash";
import Parse from "parse";
import { Book, BookPerson, User } from "@/jpasta-sdk";
export default {
  namespaced: true,
  state: {
    objects: {},
    className: "Book",
    version: 0,
  },

  mutations: {
    ...generalMutations,
  },
  actions: {
    ...generalActions,
    csvUpload: async function (
      { commit, dispatch, rootState, ...rest },
      { books, hotelId }: { books: any[]; hotelId: string }
    ) {
      const res = await Parse.Cloud.run("csv-upload", {
        books: books,
        hotelId,
      });

      const booksReturned: Book[] = _.get(res, "books", []);
      const bookPeople: BookPerson[] = _.get(res, "people", []);

      _.forEach(bookPeople, (person) =>
        commit("bookPersons/upsertObject", person, { root: true })
      );
      _.forEach(booksReturned, (book) =>
        commit("books/upsertObject", book, { root: true })
      );
    },
    manualImportPms: async function (
      { commit, dispatch, rootState, ...rest },
      { hotelId }: { books: any[]; hotelId: string }
    ) {
      const res = await Parse.Cloud.run("manual-pms-import", {
        hotelId,
      });

      if (res.status === "error") return;
      const user: User = res.user;
      if (user) commit("users/setCurrent", user, { root: true });

      const booksReturned: Book[] = _.get(res, "books", []);
      const bookPeople: BookPerson[] = _.get(res, "people", []);

      _.forEach(bookPeople, (person) =>
        commit("bookPersons/upsertObject", person, { root: true })
      );
      _.forEach(booksReturned, (book) =>
        commit("books/upsertObject", book, { root: true })
      );
    },
  },
};
