import {
  User,
  Jpasta,
  Hotel,
  CalendarMeal,
  ExtraMealOrder,
} from "@/jpasta-sdk";
import _ from "lodash";
import moment from "moment-timezone";
import Parse from "parse";
import Vue from "vue";
import Vuex from "vuex";
import bookPersons from "./bookPersons";
import books from "./books";
import calendarMeals from "./calendarMeals";
import dishes from "./dishes";
import dishImages from "./dishImages";
import hotels from "./hotels";
import labels from "./labels";
import mealOrders from "./mealOrders";
import extraMealOrders from "./extraMealOrders";
import mealOrderDishes from "./mealOrderDishes";
import roles from "./roles";
import turns from "./turns";
import templates from "./templates";
import users from "./users";
import rooms from "./rooms";
import tables from "./tables";
import roomTypes from "./roomTypes";
import rates from "./rates";
import calendarPrices from "./calendarPrices";
import ranks from "./ranks";

import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idmap: {},
    configTemplate: false,
    showErrorScreen: false,
    showDrawer: false,
    sticked: false,
    leftBarWidth: 0,
    mobileMenuOpened: false,
    scrollPositions: {},
    saving: false,
  },
  mutations: {
    startSaving(state) {
      state.saving = true;
    },
    stopSaving(state) {
      state.saving = false;
    },
    toggleDrawer(state) {
      state.showDrawer = true;
    },
    setSticked(state, value) {
      state.sticked = value;
    },
    setScrollPosition(state, { key, value }) {
      state.scrollPositions[key] = value;

      state.scrollPositions = {
        ...state.scrollPositions,
      };
    },
    setMobileMenuOpened(state, value) {
      state.mobileMenuOpened = value;
    },
    setLeftBarWidth(state, value) {
      state.leftBarWidth = value;
    },

    setShowErrorScreen(state, value) {
      state.showErrorScreen = value;
    },
    startConfigTemplate(state) {
      state.configTemplate = true;
    },

    stopConfigTemplate(state) {
      state.configTemplate = false;
    },
    map(state, obj) {
      state.idmap[obj.id] = obj.localId;
    },
  },
  actions: {
    handleParseError({ commit }, e) {
      console.warn(e);

      if (e.code == 209) {
        return User.logOut()
          .then(() => {
            return router.push({
              name: "Login",
            });
          })
          .catch(() => {
            return router.push({
              name: "Login",
            });
          });
      }

      if (e.code >= 500) {
        return commit("setShowErrorScreen", true);
      }

      switch (e.code) {
        case 100:
          return commit("setShowErrorScreen", true);
      }
    },
    startConfigTemplate({ commit }) {
      commit("startConfigTemplate");
    },

    stopConfigTemplate({ commit }) {
      commit("stopConfigTemplate");
    },

    map({ commit, dispatch }, obj) {
      commit("map", obj);
    },

    downSyncAll: ({ commit, dispatch, state }) => {
      const hotelsQuery = new Parse.Query(Hotel);
      hotelsQuery.equalTo("owner", state.users.current);
      const calendarMealsQuery = new Parse.Query(CalendarMeal);
      calendarMealsQuery.matchesKeyInQuery("hotel", "objectId", hotelsQuery);
      const extraMealOrderQuery = new Parse.Query(ExtraMealOrder);
      extraMealOrderQuery.matchesKeyInQuery("hotel", "objectId", hotelsQuery);

      extraMealOrderQuery.greaterThanOrEqualTo(
        "dueDate",
        moment().subtract(3, "hours").toDate()
      );

      return dispatch("hotels/getObjects", {
        query: hotelsQuery,
      }).then(() => {
        var classes = [
          { namespace: "books" },
          { namespace: "bookPersons", skip: true },
          { namespace: "dishes" },
          { namespace: "rooms" },
          { namespace: "tables" },
          { namespace: "roomTypes" },
          { namespace: "rates" },
          { namespace: "turns" },
          { namespace: "calendarPrices" },
          { namespace: "extraMealOrders", query: extraMealOrderQuery },
          { namespace: "ranks" },
          // { namespace: "templates" },
          // { namespace: "labels" },
          { namespace: "dishImages" },
          { namespace: "calendarMeals", query: calendarMealsQuery },
          { namespace: "mealOrders", skip: true },
          { namespace: "mealOrderDishes", skip: true },

          {
            namespace: "roles",
            params: [{ method: "equalTo", key: "name", value: "admin" }],
            getAction: "roles/getRoles",
          },
        ];
        var promises = [];
        _.forEach(classes, (cl) => {
          commit(cl.namespace + "/emptyObjects");

          if (cl.skip)
            //Verrà gestito sulle viste in cui serve
            return;

          if (cl.getAction) {
            promises.push(
              dispatch(cl.getAction, {
                params: cl.params,
                includes: cl.includes,
              })
            );
          } else {
            promises.push(
              dispatch(cl.namespace + "/getObjects", {
                ...(cl.query
                  ? {
                      query: cl.query,
                    }
                  : {
                      params: cl.params,
                      includes: cl.includes,
                    }),
                notRecursive: cl.notRecursive,
              })
            );
          }
        });
        return Promise.all(promises);
      });
    },
  },
  modules: {
    hotels: hotels,
    books: books,
    turns: turns,
    bookPersons: bookPersons,
    dishes: dishes,
    templates: templates,
    labels: labels,
    roles: roles,
    dishImages: dishImages,
    users: users,
    calendarMeals: calendarMeals,
    mealOrders: mealOrders,
    extraMealOrders: extraMealOrders,
    mealOrderDishes: mealOrderDishes,
    rooms: rooms,
    tables: tables,
    roomTypes: roomTypes,
    rates: rates,
    calendarPrices: calendarPrices,
    ranks: ranks,
  },
});
