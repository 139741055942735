var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rootContainer",class:{
    fullWidth: _vm.fullWidth,
    hasRightMenu: (_vm.hasRightMenu || _vm.$slots.rightMenu) && !_vm.isTutorial,
  },attrs:{"sticky-container":""}},[_c('el-row',{staticStyle:{"width":"100%"}},[_c('el-col',{attrs:{"xs":_vm.getMainContainerSize('xs'),"sm":_vm.getMainContainerSize('sm'),"md":_vm.getMainContainerSize('md'),"lg":_vm.getMainContainerSize('lg'),"xl":_vm.getMainContainerSize('xl')}},[(_vm.$slots.modalContent)?_c('JModal',{attrs:{"before-close":_vm.beforeCloseModal ? _vm.beforeCloseModal : undefined,"title":_vm.modalTitle ? _vm.modalTitle : '',"visible":_vm.dialogVisible}},[_vm._t("modalContent")],2):_vm._e(),(_vm.backAction && _vm.backLabel)?_c('jbutton',{staticStyle:{"margin-bottom":"16px"},attrs:{"type":"link"},on:{"click":_vm.backAction}},[_vm._v(_vm._s(_vm.backLabel))]):_vm._e(),_vm._t("titleWrapper",function(){return [(_vm.$slots.title || _vm.$slots.mainCtaWrapper || _vm.$slots.maincta)?_c('div',{directives:[{name:"sticky",rawName:"v-sticky",value:(
            _vm.$mq !== 'xs' &&
            _vm.$mq !== 'sm' &&
            _vm.sticky &&
            !_vm.isTutorial &&
            _vm.$slots.maincta
          ),expression:"\n            $mq !== 'xs' &&\n            $mq !== 'sm' &&\n            sticky &&\n            !isTutorial &&\n            $slots.maincta\n          "}],staticClass:"no-print",class:{
            stickedTitle: _vm.stickedTitle && !_vm.isTutorial,
          },attrs:{"on-stick":"onStick","sticky-side":"both","sticky-offset":"stickyOffset"}},[_c('el-row',{staticClass:"titleWrapperRow"},[(_vm.$slots.title)?_c('el-col',{attrs:{"xs":24,"sm":_vm.$slots.maincta || _vm.$slots.mainCtaWrapper ? 12 : 24,"md":_vm.$slots.maincta || _vm.$slots.mainCtaWrapper ? 12 : 24,"lg":_vm.$slots.maincta || _vm.$slots.mainCtaWrapper ? 12 : 24,"xl":_vm.$slots.maincta || _vm.$slots.mainCtaWrapper ? 12 : 24}},[_c('h3',{staticClass:"text-sm-left text-center w-100 layoutTitle"},[_vm._t("title")],2)]):_vm._e(),(_vm.$slots.maincta || _vm.$slots.mainCtaWrapper)?_c('el-col',{staticClass:"mainCtaWrapper",class:{
                //justifyCenter: stickedTitle
              },attrs:{"xs":24,"sm":_vm.$slots.title && !_vm.stickedTitle ? 12 : 24,"md":_vm.$slots.title && !_vm.stickedTitle ? 12 : 24,"lg":_vm.$slots.title && !_vm.stickedTitle ? 12 : 24,"xl":_vm.$slots.title && !_vm.stickedTitle ? 12 : 24}},[_vm._t("mainCtaWrapper",function(){return [_c('Jadvice',{attrs:{"placement":"top","title":_vm.buttonAdviceTitle,"visible":_vm.buttonAdviceVisible}},[_c('jbutton',{ref:"main-cta-ref",staticClass:"mt-sm-0",class:{
                      'tutorial-button': _vm.blinkingButton,
                    },attrs:{"slot":"trigger","loading":_vm.loadingCta},on:{"click":function($event){return _vm.$emit('click')}},slot:"trigger"},[_vm._t("maincta")],2),_vm._t("buttonAdviceContent")],2)]})],2):_vm._e()],1)],1):_vm._e(),(_vm.$slots['below-title'])?_c('el-row',{staticClass:"flex-wrap mt-1 no-print",attrs:{"type":"flex"}},[_c('el-col',{staticClass:"text-right justify-content-center justify-content-sm-start d-flex align-items-center",attrs:{"xs":24,"sm":24,"md":24,"lg":24,"xl":24}},[_vm._t("below-title")],2)],1):_vm._e(),(
            _vm.$slots.maincta ||
            _vm.$slots['below-title'] ||
            _vm.$slots.title ||
            _vm.$slots.mainCtaWrapper
          )?_c('el-row',{staticClass:"mt-2 no-print no-shrink separatorLine",staticStyle:{"height":"1px"}}):_vm._e()]}),_c('el-row',{staticClass:"mainSlotWrapper justifyCenter"},[_c('el-col',{directives:[{name:"jpasta-loading",rawName:"v-jpasta-loading",value:(_vm.loadingForSync),expression:"loadingForSync"}],staticClass:"mainSlotWrapper",class:{
            'mt-2': _vm.$slots.maincta || _vm.$slots['below-title'] || _vm.$slots.title,
          },style:({
            ...(_vm.loadingForSync
              ? {
                  'min-height': '300px',
                }
              : {}),
            ...(_vm.mainSlotWrapperStyle || {}),
          }),attrs:{"xs":24,"sm":24,"md":24,"lg":24,"xl":24}},[(!_vm.loadingForSync)?_vm._t("default"):_vm._e()],2)],1)],2),(!_vm.isTutorial)?_c('el-col',{staticClass:"no-print",attrs:{"xs":24 - _vm.xs,"sm":24 - _vm.sm,"md":24 - _vm.md,"lg":24 - _vm.lg,"xl":24 - _vm.xl}},[(_vm.$slots.rightMenu && !_vm.isTutorial)?_c('RightMenu',[_vm._t("rightMenu"),_c('div',{staticStyle:{"width":"100%"},attrs:{"slot":"footer"},slot:"footer"},[_vm._t("rightMenuFooter")],2)],2):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }