
import moment from "moment-timezone";
import BookPeopleListItem from "./BookPeopleListItem.vue";
import mixins from "vue-typed-mixins";
import { BookPerson, Book, Hotel } from "@/jpasta-sdk";
import _ from "lodash";
import Parse from "parse";
export default mixins().extend({
  components: {
    BookPeopleListItem,
  },
  data: function (): {
    loadingForSync: boolean;
  } {
    return {
      loadingForSync: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.fetchData();
    }, 500);
  },
  methods: {
    fetchData: async function (): Promise<void> {
      this.loadingForSync = true;

      const bookPeopleQuery = new Parse.Query(BookPerson);
      bookPeopleQuery.equalTo("book", this.book);

      // const mealOrdersQuery = new Parse.Query(MealOrder);
      // mealOrdersQuery.equalTo("book", this.book);

      const batch: Promise<any>[] = [];

      batch.push(
        this.$store.dispatch("bookPersons/getObjects", {
          query: bookPeopleQuery,
          //clear: true
        })
      );

      await Promise.all(batch);

      this.loadingForSync = false;
    },
    getTitle(): string {
      const res = [`Persone (${this.bookPersons.length})`];
      const email = this.book.get("refEmail");
      if (email && email.length) res.push(email);
      return res.join(" - ");
    },
  },
  computed: {
    items2render: function (): any {
      return _.map(this.bookPersons, (bp) => {
        return {
          bp: bp,
          key: bp.get("localId"),
        };
      });
    },

    book: function (): Book {
      return this.$store.state.books.objects[this.$route.params.bookid];
    },
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    bookPersons: function (): BookPerson[] {
      return _.filter(this.$store.state.bookPersons.objects, (bookPerson) => {
        return bookPerson.belongsTo(this.book);
      });
    },
  },
});
