
import _, { Dictionary } from "lodash";
import Vue from "vue";
import moment from "moment-timezone";
import ReportPerTable from "./ReportPerTable.vue";
import {
  getMealToDate,
  JPASTA_MEALS,
  JPASTA_COURSES,
  Jpasta,
  findJMealByLocalId,
  findJCourseByLocalId,
  MealOrder,
  MealOrderDish,
  Book,
  Table,
  Rank,
  BookPerson,
  Dish,
  CalendarMeal,
  Meal,
  Hotel,
  Turn,
} from "@/jpasta-sdk";
import WaiterReportTable from "./WaiterReportTable.vue";
import Parse from "parse";
import WaiterReport from "./WaiterReport.vue";
import WaiterReportByName from "./WaiterReportByName.vue";
import KitchenReport from "./KitchenReport.vue";
import mixins from "vue-typed-mixins";
let mouseGoUp = false;
export default mixins().extend({
  props: {
    turn: {
      type: Object as () => Turn | undefined,
    },
    bookPeople: {
      type: Array as () => BookPerson[],
      required: true,
    },
    books: {
      type: Array as () => Book[],
      required: true,
    },
    hotel: {
      type: Object as () => Hotel,
      required: true,
    },
    mealOrders: {
      type: Array as () => MealOrder[],
      required: true,
    },
  },
  components: {
    WaiterReportTable,
    WaiterReport,
    ReportPerTable,
    KitchenReport,
    WaiterReportByName,
  },
  mounted() {
    _.debounce(this.fetchData, 200)();

    window.addEventListener("keydown", this.handleKeyPressed);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyPressed);
  },
  data: function (): {
    showUncompletedOrders: boolean;
    showCompletedOrders: boolean;
    showNotIncludedOrders: boolean;
    showWaiterReport: boolean;
    showKitchenReport: boolean;
    loadingForSync: boolean;
    bookPeopleCacheMap: Dictionary<any>;
    bookOrderCacheMap: Dictionary<any>;
    printing: string | undefined;
    activeNames: string[];
    filters: Dictionary<boolean>;
  } {
    return {
      showUncompletedOrders: true,
      showCompletedOrders: true,
      showNotIncludedOrders: true,
      activeNames: ["kitchen", "waiter"],
      showWaiterReport: true,
      showKitchenReport: true,
      loadingForSync: true,
      bookPeopleCacheMap: {},
      bookOrderCacheMap: {},

      printing: undefined,
      filters: {
        showOnlyNotCompletedOrders: false,
        showNotIncludedOrders: true,
      },
    };
  },

  methods: {
    handleKeyPressed: function (e): any {
      if (this.loadingForSync) return;

      if (e.keyCode === 83 && e.ctrlKey) {
        //CTRL + S
        e.preventDefault();
        this.printWaiterReport();
      } else if (e.keyCode === 85 && e.ctrlKey) {
        //CTRL + U
        e.preventDefault();
        this.printKitchenReport();
      } else if (e.keyCode === 77 && e.ctrlKey) {
        //CTRL + M
        e.preventDefault();
        this.printWaiterReportByName();
      }
    },
    handlePrintCommand: function (command): any {
      if (this.loadingForSync) return;
      switch (command) {
        case "kitchen":
          return this.printKitchenReport();
        case "waiter":
          return this.printWaiterReport();
        case "waiterByName":
          return this.printWaiterReportByName();
        case "waiterByTable":
          return this.printWaiterReportByTable();
      }
    },

    fetchData: async function (): Promise<any> {
      const mealOrdersQuery = new Parse.Query(MealOrder);
      mealOrdersQuery.equalTo("calendarMeal", this.calendarMeal);
      const bookPeopleQuery = new Parse.Query(BookPerson);
      bookPeopleQuery.containedIn("book", this.books);
      const batch: Promise<any>[] = [];

      batch.push(
        this.$store.dispatch("mealOrders/getObjects", {
          query: mealOrdersQuery,
          clear: true,
        })
      );

      batch.push(
        this.$store.dispatch("bookPersons/getObjects", {
          query: bookPeopleQuery,
        })
      );

      const mealOrderDishesQuery = new Parse.Query(MealOrderDish);
      mealOrderDishesQuery.matchesKeyInQuery(
        "order",
        "objectId",
        mealOrdersQuery
      );

      batch.push(
        this.$store.dispatch("mealOrderDishes/getObjects", {
          query: mealOrderDishesQuery,
          clear: true,
        })
      );

      // Promise.all(batch).then(() => {

      // });

      await Promise.all(batch);

      this.loadingForSync = false;
    },

    getFormattedDate: function (date): string {
      return moment(date)
        .tz(this.hotel.get("timezone"))
        .format("ddd D MMMM YYYY [ore] HH:mm");
    },

    assureIsVisible(collapseName): any {
      return new Promise<void>((resolve, reject) => {
        switch (collapseName) {
          case "kitchen":
            this.showKitchenReport = true;
            break;
          case "waiter":
            this.showWaiterReport = true;
            //this.$refs.waiterReport.clearAllFilters();
            break;
          case "waiterByName":
            this.showWaiterReport = true;
            //this.$refs.waiterReport.clearAllFilters();
            break;
        }

        resolve();
      });
    },
    printKitchenReport: function (): void {
      this.printing = "kitchen";
      this.assureIsVisible("kitchen").then(() => {
        this.$nextTick(() => {
          window.print();
        });
      });
    },

    printWaiterReport: function (): void {
      this.filters.showOnlyNotCompletedOrders = false;
      this.printing = "waiter";
      this.assureIsVisible("waiter").then(() => {
        this.$nextTick(() => {
          window.print();
        });
      });
    },
    printWaiterReportByName: function (): void {
      this.filters.showOnlyNotCompletedOrders = false;
      this.printing = "waiterByName";
      this.assureIsVisible("waiterByName").then(() => {
        this.$nextTick(() => {
          window.print();
        });
      });
    },
    printWaiterReportByTable: function (): void {
      this.filters.showOnlyNotCompletedOrders = false;
      this.printing = "waiterByTable";
      this.assureIsVisible("waiterByTable").then(() => {
        this.$nextTick(() => {
          window.print();
        });
      });
    },
    getLocalizedName: function (name): string {
      return Jpasta.getLocalizedName(name, "it", "Nessun nome");
    },
  },
  computed: {
    tables: function (): Table[] {
      return _.filter(this.$store.state.tables.objects, (t) => {
        return t.belongsTo(this.hotel, "hotel");
      });
    },
    ranks: function (): Rank[] {
      return _.filter(this.$store.state.ranks.objects, (r) => {
        return r.belongsTo(this.hotel, "hotel");
      });
    },
    bookPeopleMap: function (): Dictionary<BookPerson[]> {
      return _.reduce(
        this.bookPeople,
        (res, bp) => {
          if (!bp.get("book")) return res;
          const key = bp.get("book").get("localId");

          res[key] = res[key] || [];
          res[key].push(bp);
          return res;
        },
        {}
      );
    },
    normalMealOrders: function (): MealOrder[] {
      return _.filter(this.mealOrders, (mo) => {
        return !mo.isDelivery() && !mo.isTakeAway();
      });
    },
    deliveryOrders: function (): MealOrder[] {
      return _.filter(this.mealOrders, (mo) => {
        return mo.isDelivery();
      });
    },
    takeAwayOrders: function (): MealOrder[] {
      return _.filter(this.mealOrders, (mo) => {
        return mo.isTakeAway();
      });
    },
    mealOrderDishesMap: function (): Dictionary<MealOrderDish[]> {
      return _.reduce(
        this.$store.state.mealOrderDishes.objects,
        (res, mod) => {
          if (!mod.get("order")) return res;
          const key = mod.get("order").get("localId");
          res[key] = res[key] || [];
          res[key].push(mod);
          return res;
        },
        {}
      );
    },

    calendarMeal: function (): CalendarMeal {
      return this.$store.state.calendarMeals.objects[
        this.$route.params.calendarmealid
      ];
    },
    meal: function (): Meal | undefined {
      return findJMealByLocalId(this.calendarMeal.get("mealIndex"));
    },
    // hotel: function(): Hotel {
    //   return this.$store.state.hotels.objects[this.$route.params.hotelid];
    // },

    orderFinished: function (): boolean {
      var now = moment().tz(this.hotel.get("timezone"));
      return now.isAfter(
        moment(this.calendarMeal.get("toDate")).tz(this.hotel.get("timezone"))
      );
    },
  },
});
