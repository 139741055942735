
/* eslint-disable */
import * as Parse from "parse";
import _, { Dictionary } from "lodash";
import Vue from "vue";
import Jadvice from "@/components/ui/JAdvice.vue";
import {
  JPASTA_LANGUAGES,
  JPASTA_COURSES,
  JPASTA_ALLERGENS,
  Dish,
  User,
  findJCourseByLocalId,
  VEGETARIAN,
  VEGAN,
  ISLAM,
  Jpasta,
  HOTEL_RATES,
  JPASTA_MEALS,
  Course,
  DishImage,
} from "@/jpasta-sdk";

import moment from "moment";
import DishComponent from "./DishComponent.vue";
import Fuse from "fuse.js";
import setScrollTopMixin from "@/mixins/setScrollTopMixin";
import AdviceMixin from "@/mixins/AdviceMixin";
import mixins from "vue-typed-mixins";
import languages from "./languages";

type LanguageItem = { code: string; name: string; code2: string };

export default mixins(AdviceMixin, setScrollTopMixin).extend({
  // mixins: [setScrollTopMixin],
  components: {
    DishComponent: DishComponent,
    Jadvice: Jadvice,
  },
  props: {
    unlockFilter: { type: Function },

    hasRightMenu: {},
    courseFilters: {
      type: Object,
    },
    defaultCourseLocalId: {},
    showImageFilterTitle: { default: "Visualizza immagini dei piatti" },

    itemPriceLabel: { default: "Prezzo del piatto" },
    itemNamePlaceholder: { default: "Nome Piatto" },
    searchBarLabel: { default: "Cerca piatto" },
    mainCtaLabel: { default: "Nuovo Piatto" },
    title: { default: "Piatti" },
    filterByTypes: { default: [] },
    newItemType: { default: "dish" },
    canSetCourse: { default: true },
  },

  data: function (): {
    uploading: boolean;
    keyDownPressed: boolean;
    showInclusionModal: boolean;
    dialogVisibleTranslations: boolean;
    dialogVisibleInclusions: boolean;
    dialogVisible: boolean;
    loadingForSync: boolean;
    keyDownPressedTimeout: any;
    VEGAN: number;
    VEGETARIAN: number;
    ISLAM: number;
    chunkSize: number;
    tutorialCreatedDish: any;
    fileList: any[];
    editingDishId: string | undefined;
    languages: Dictionary<LanguageItem>;
    JPASTA_COURSES: Course[];
    searchDishString: string;
    searchableSelect: any;
  } {
    return {
      uploading: false,
      keyDownPressed: false,
      showInclusionModal: false,
      keyDownPressedTimeout: undefined,
      VEGAN: VEGAN,
      VEGETARIAN: VEGETARIAN,
      ISLAM: ISLAM,

      tutorialCreatedDish: undefined,
      chunkSize: 3,
      loadingForSync: true,
      dialogVisibleTranslations: false,
      dialogVisibleInclusions: false,
      fileList: [] as any[],
      dialogVisible: false,

      editingDishId: undefined,

      languages: languages,
      JPASTA_COURSES: JPASTA_COURSES,

      searchDishString: "",

      searchableSelect: {},
    };
  },

  mounted() {
    setTimeout(() => {
      this.loadingForSync = false;

      //this.setScrollTop();
    }, 250);

    window.addEventListener("keydown", this.handleTutorialEnterPress);

    //var user = User.current();
  },
  beforeDestroy: function () {
    window.removeEventListener("keydown", this.handleTutorialEnterPress);
  },
  methods: {
    deleteImage(dish: Dish) {
      dish.set("images", []);
      this.$store.dispatch("dishes/saveObj", dish);
    },
    getFirstImageBg(dish: Dish) {
      const images = dish.get("images") || [];
      if (!images.length) return;
      return {
        "background-image": `url(${(_.first(images) as DishImage)
          .get("thumb")
          .url()})`,
      };
    },
    setCanOrderOutOfMeal: function (val: boolean) {
      const dish = this.editingDish as Dish;
      dish.setCanOrderExtraMeal(val);
      this.$store.dispatch("dishes/saveObj", dish);
    },
    focusSearchableSelect: function (ref: string) {
      const r = this.$refs[ref] as any;
      if (!r) return;
      r.focus();
    },
    addLanguage: function (dish: Dish, l: any) {
      if (!l) return;
      this.changeDishLanguage(dish, {
        [l.localId]: "",
      });
    },
    enabledLanguages: function (dish: Dish): LanguageItem[] {
      if (!dish) return [];

      return _.reduce(
        dish.get("name"),
        (res: LanguageItem[], name, langCode) => {
          if (this.languages[langCode]) res.push(this.languages[langCode]);
          return res;
        },
        []
      );
    },
    onUploadError: function (e) {
      this.$message({
        type: "error",
        message: e.message,
        customClass: "bottomNotification",
        duration: 4000,
      });
    },
    setCanDelivery: function (value: boolean): void {
      const dish = this.editingDish as Dish;
      dish.setCanDelivery(value);
      this.$store.dispatch("dishes/saveObj", dish);
    },
    setCanEatInDiningRoom: function (value: boolean): void {
      const dish = this.editingDish as Dish;
      dish.setCanEatInDiningRoom(value);
      this.$store.dispatch("dishes/saveObj", dish);
    },
    setCanTakeAway: function (value: boolean): void {
      const dish = this.editingDish as Dish;
      dish.setCanTakeAway(value);
      this.$store.dispatch("dishes/saveObj", dish);
    },
    setDishIsForChildren: function (v): void {
      const dish = this.editingDish as Dish;
      dish.setIsForChildren(v);
      this.$store.dispatch("dishes/saveObj", dish);
    },
    setDishIsForBaby: function (v) {
      const dish = this.editingDish as Dish;
      dish.setIsForBaby(v);
      this.$store.dispatch("dishes/saveObj", dish);
    },
    setIsIncluded: function (rateIndex, meealIndex, val) {
      const dish = this.editingDish;
      if (!dish) return;
      dish.setIsIncluded(rateIndex, meealIndex, val);
      this.$store.dispatch("dishes/saveObj", dish);
    },
    beforeCloseInclusionsModal: function () {
      this.showInclusionModal = false;
    },
    getLocalizedName: function (name) {
      return Jpasta.getLocalizedName(name, "it", "");
    },
    stepAfterPhoto: function () {
      this.$router.push({
        name: "HotelCalendar",
        params: {
          hotelid: this.$route.params.hotelid,
        },
        query: {
          tutorial: "1",
        },
      });
    },
    stepAfterPrice: function () {
      this.nextTutorialStep();
      //@ts-ignore
    },
    stepAfterAllergens: function () {
      this.nextTutorialStep();
      //@ts-ignore
      //this.$refs.dishPriceInputRef.focus();
    },
    stepAfterDishCourse: function () {
      this.nextTutorialStep();
      //@ts-ignore
      //this.$refs.selectDishAllergensRef.toggleMenu();
    },
    stepAfterDishName: function () {
      this.nextTutorialStep();
      //@ts-ignore
      //this.$refs.selectDishCourseRef.toggleMenu();
    },
    mergeSpecialOptions: function (dish, newAllergens) {
      const oldAllergens = dish.get("allergens");
      const specialOptions = _.filter(oldAllergens, (oa) => {
        return oa === ISLAM || oa === VEGAN || oa === VEGETARIAN;
      });

      return [...specialOptions, ...(newAllergens || [])];
    },
    sanitizeAllergens: function (allergens) {
      return _.filter(allergens, (aId) => {
        return aId !== ISLAM && aId !== VEGETARIAN && aId !== VEGAN;
      });
    },
    hasNext: function (dish: Dish): boolean {
      if (!dish) return false;

      return this.dishIndexMap[dish.get("localId")] < this.dishes.length - 1;
    },
    hasPrevious: function (dish) {
      if (!dish) return false;
      return this.dishIndexMap[dish.get("localId")] > 0;
    },
    next: function (dish): any {
      const index = this.dishIndexMap[dish.get("localId")] + 1;
      const newDish = this.dishes[index];
      return this.editDish(newDish.get("localId"));
    },
    previous: function (dish): void {
      const index = this.dishIndexMap[dish.get("localId")] - 1;
      const newDish = this.dishes[index];
      return this.editDish(newDish.get("localId"));
    },
    setDishIsExtra: function (value: boolean) {
      const dish = this.editingDish as Dish;

      dish.set("isExtra", value);

      this.$store.dispatch("dishes/saveObj", dish);
    },
    setDishSpecialOption: function (optionId, value) {
      const allergens: any[] = this.editingDish?.get("allergens") || [];
      if (!value) {
        _.remove(allergens, (aId) => {
          return aId === optionId;
        });
      } else if (
        !_.find(allergens, (aId) => {
          return aId === optionId;
        })
      ) {
        allergens.push(optionId);
      }

      const dish = this.editingDish as Dish;
      dish.set("allergens", allergens);

      this.$store.dispatch("dishes/saveObj", dish);
    },
    hasAllergen: function (allergenId) {
      return !!_.find((this.editingDish as Dish).get("allergens"), (aId) => {
        return aId === allergenId;
      });
    },
    onEnterPressedDishNameInput: function () {
      this.newDish();
    },

    openTranslations: function () {
      this.dialogVisibleTranslations = true;
    },
    onUploadSuccess: function (res, file, fileList) {
      this.fileList = [file];
    },

    wait: function () {
      return new Promise<void>((resolve) =>
        setTimeout(() => {
          resolve();
        }, 5000)
      );
    },

    uploadFile: async function (data) {
      this.uploading = true;
      const dish = _.cloneDeep(this.editingDish);
      // data.onProgress();
      // await this.wait();
      try {
        // const uploadRef = this.$refs.uploadDishImage as any;
        // if (uploadRef) await uploadRef.clearFiles();
        const res = await this.addFileToDish(dish, data.file);
        if (this.editingDish) data.onSuccess();
      } catch (e) {
        if (this.editingDish) data.onError(e);
      } finally {
        this.uploading = false;
      }
    },
    clearEditingModal: function () {
      this.dialogVisible = false;
      this.editingDishId = undefined;
    },
    clearEditingModalTranslations: function () {
      this.dialogVisibleTranslations = false;
    },
    clearEditingModalInclusions: function () {
      this.dialogVisibleInclusions = false;
    },
    openInclusions: function () {
      this.dialogVisibleInclusions = true;
    },
    // checkIfCanClose: function () {
    //   return new Promise((resolve, reject) => {
    //     if (!this.uploading) resolve()
    //     else return this.$dialog
    //     .confirm(
    //       "Stai ancora caricando un'immagine. Aspetta che l'upload finisca per chiudere",
    //       {
    //         okText: "Continua",
    //         cancelText: "Annulla"
    //       }
    //     )
    //     .then(function() {
    //       return Promise.resolve();
    //     })
    //     .catch(function() {
    //       return Promise.reject();
    //     });
    //   })
    // },
    beforeCloseEditModal: function (done): void {
      if (this.isTutorial) return;

      this.clearEditingModal();
      done();
    },
    beforeCloseEditModalTranslations: function (done) {
      this.clearEditingModalTranslations();
      done();
    },
    beforeCloseEditModalInclusions: function (done) {
      this.clearEditingModalInclusions();
      done();
    },
    editDish: function (dishLocalId) {
      this.dialogVisible = true;
      this.uploading = false;
      this.fileList = [];
      this.editingDishId = dishLocalId;
      this.$nextTick(() => {
        if (this.$refs.dishNameInput && this.$mq !== "xs") {
          //@ts-ignore
          this.$refs.dishNameInput.focus();
          const uploadRef = this.$refs.uploadDishImage as any;
          if (uploadRef) uploadRef.clearFiles();
        }
      });
    },
    resetTimeout(): any {
      if (this.keyDownPressedTimeout) clearTimeout(this.keyDownPressedTimeout);
      this.keyDownPressedTimeout = setTimeout(() => {
        this.keyDownPressed = false;
        this.keyDownPressedTimeout = undefined;
      }, 500);
    },
    keyDownDebounce() {
      if (this.keyDownPressed) {
        this.resetTimeout();
        return true;
      }

      this.keyDownPressed = true;

      this.resetTimeout();

      return false;
    },
    handleTutorialEnterPress(e: any): any {
      if (!this.isTutorial) {
        if (e.keyCode === 13 && e.shiftKey) {
          e.preventDefault();
          if (this.keyDownDebounce()) return;

          this.newDish();
        }
        return;
      }

      if (this.tutorialStep > 5 || this.tutorialStep === 0) return;

      if (e.keyCode === 13) {
        const editingDish = this.editingDish;
        if (!editingDish) return;

        const name = editingDish.get("name");

        if (this.tutorialStep === 1 && (!name || !name.it || !name.it.length))
          return;

        if (
          this.tutorialStep === 2 &&
          editingDish.get("courseIndex") === undefined
        )
          return;

        this.nextTutorialStep();
      }
    },

    // nextTutorialStep: function() {
    //   if (this.tutorialStep === 4) {
    //     return this.$router
    //       .push({
    //         name: "HotelCalendar",
    //         params: {
    //           hotelid: this.$route.params.hotelid
    //         },
    //         query: {
    //           tutorial: "1"
    //         }
    //       })
    //       .catch(e => {});
    //   }

    //   this.tutorialStep++;

    //   if (this.tutorialStep === 2) {
    //     this.$nextTick(() => {
    //       if (this.$refs.selectDishCourseRef) {
    //         //@ts-ignore
    //         this.$refs.selectDishCourseRef.focus();
    //       }
    //     });
    //   }
    //   if (this.tutorialStep === 3) {
    //
    //     this.$nextTick(() => {
    //       if (this.$refs.selectDishAllergensRef) {
    //         //@ts-ignore
    //         this.$refs.selectDishAllergensRef.focus();
    //       }
    //     });
    //   }
    //   if (this.tutorialStep === 4) {
    //     this.$nextTick(() => {
    //       if (this.$refs.dishPriceInputRef) {
    //         //@ts-ignore
    //         this.$refs.dishPriceInputRef.focus();
    //       }
    //     });
    //   }
    // },
    deleteDish: function (dishLocalId) {
      const dish = this.$store.state.dishes.objects[dishLocalId];
      if (!dish) return;
      dish.set("deleted", true);
      this.$store.dispatch("dishes/saveObj", dish);
    },

    setMealIncludedOptionForDish: function (
      dish: Dish,
      rateIndex: any,
      mealIndex: any,
      val
    ) {
      let dishIncludedOptions = dish.get("bookIncludedOptions") || {};
      dishIncludedOptions[rateIndex] = dishIncludedOptions[rateIndex] || {};

      dishIncludedOptions[rateIndex][mealIndex] = val;

      dish.set("bookIncludedOptions", dishIncludedOptions);

      this.$store.dispatch("dishes/saveObj", dish);
    },
    getMealIncludedOptionForDish: function (dish, rateIndex, mealIndex) {
      return dish.isIncluded(rateIndex, mealIndex);
    },

    addFileToDish: function (dish, file) {
      return this.$store
        .dispatch("dishes/attachImage", {
          dish: dish,
          file: file,
        })
        .catch((e) => {
          //this.$msg(e.message);
          throw new Error(e.message);
        });
    },

    setDishProperty(propertyName: string, dish: Dish, value: any): void {
      if (this.unlockFilter) this.unlockFilter();
      this.searchDishString = "";
      if (value === undefined) {
        dish.unset(propertyName);
      } else {
        dish.set(propertyName, value);
      }

      this.$store.dispatch("dishes/saveObj", dish);
    },

    selectCourse: function (courseLocalId, dishLocalId: string) {
      if (!dishLocalId) return;

      const dish = this.$store.state.dishes.objects[dishLocalId];

      dish.set("courseIndex", courseLocalId);

      this.$store.dispatch("dishes/saveObj", dish);

      // this.searchableSelect[`dish-search-${dishIndex}`] = false;
    },

    changeDishLanguageDescription: function (dish, languageObject) {
      dish.set("description", {
        ...dish.get("description"),
        ...languageObject,
      });
      this.$store.dispatch("dishes/saveObj", dish);
    },

    changeDishLanguage: function (dish, languageObject) {
      this.searchDishString = "";
      dish.set("name", {
        ...dish.get("name"),
        ...languageObject,
      });
      this.$store.dispatch("dishes/saveObj", dish);
    },

    newDish(): void {
      this.searchDishString = "";
      const dish = new Dish();
      dish.set("hotel", this.hotel);
      dish.set("type", this.newItemType);

      if (this.defaultCourseLocalId !== undefined) {
        dish.set("courseIndex", this.defaultCourseLocalId);
      }

      if (this.unlockFilter) this.unlockFilter();
      this.$store.dispatch("dishes/saveObj", dish).then((dish) => {
        if (this.isTutorial) {
          this.tutorialCreatedDish = dish;
        }

        this.editDish(dish.get("localId"));

        this.$nextTick(() => {
          this.nextTutorialStep();
        });
      });
    },
    matchCourseIndex: function (dish): boolean {
      const courseIndex = dish.get("courseIndex");
      if (courseIndex === undefined && !this.courseFilters["noCourse"])
        return false;
      if (courseIndex === undefined && this.courseFilters["noCourse"])
        return true;
      return !!this.courseFilters[courseIndex];
    },
  },
  computed: {
    languageOptions(): { label: string; localId: string }[] {
      if (!this.editDish) return [];
      return _.reduce(
        this.languages,
        (res: { label: string; localId: string }[], l) => {
          const name = this.editingDish?.get("name") || [];
          if (name[l.code]) return res;
          res.push({
            label: l.name,
            localId: l.code,
          });

          return res;
        },
        []
      );
    },
    rates(): {
      id: number;
      withDrink: boolean;
      name: {
        it: string;
      };
      includedMeals: number[];
    }[] {
      return HOTEL_RATES;
    },

    meals(): {
      id: number;
      name: any;
    }[] {
      return JPASTA_MEALS;
    },

    editingDish(): Dish | undefined {
      return this.editingDishId
        ? this.$store.state.dishes.objects[this.editingDishId]
        : undefined;
    },
    items2render: function (): any[] {
      const dishArray = _.chunk(this.dishes, this.chunkSize);

      return _.map(dishArray, (dishes, index) => {
        return {
          key: index,
          dishes: _.map(dishes, (d: Dish) => {
            const course =
              d.get("courseIndex") !== undefined
                ? findJCourseByLocalId(d.get("courseIndex"))
                : null;

            return {
              id: d.get("localId"),
              name: d.get("name"),
              image: _.get(this.dishesImages, `${d.get("localId")}.0`),
              courseName: course ? course.name.it : "Nessuna portata",
              courseIndex: d.get("courseIndex"),
              allergens: d.get("allergens") || [],
            };
          }),
        };
      });
    },
    dishesImages: function (): { [key: string]: Dish } {
      return _.reduce(
        this.dishes,
        (images, dish) => {
          // let time1 = moment();

          const imgs = _.filter(dish.get("images"), (im) => {
            return (
              im &&
              im.get("localId") &&
              !!this.$store.state.dishImages.objects[im.get("localId")]
            );
          });
          images[dish.get("localId")] = imgs;

          return images;
        },
        {}
      );
    },

    hotel: function () {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },

    /* Contiene la lista delle portate tra cui scegliere quella da assegnare a un piatto */
    coursesList: function () {
      return _.map(
        JPASTA_COURSES,
        // _.filter(JPASTA_COURSES, course => {
        //   return !course.isSpecial;
        // }),
        (course) => {
          return {
            label: course.name.it,
            localId: course.id,
          };
        }
      );
    },

    allergensList(): any {
      const allergens = _.filter(JPASTA_ALLERGENS, (a) => {
        return a.id !== VEGETARIAN && a.id !== VEGAN && a.id !== ISLAM;
      });

      return _.map(allergens, (allergen) => {
        return {
          label: allergen.name.it,
          localId: allergen.id,
        };
      });
    },
    hotelDishes: function (): Dish[] {
      const dishes = _.filter(this.$store.state.dishes.objects, (d) => {
        return d.belongsTo(this.hotel, "hotel") && !d.get("deleted");
      });
      return _.sortBy(dishes, (d) => {
        return -moment(d.get("createdAt")).valueOf();
      });
    },

    dishIndexMap: function (): { [key: string]: any } {
      return _.reduce(
        this.dishes,
        (res: { [key: string]: number }, d: any, index: number) => {
          res[d.get("localId")] = index;
          return res;
        },
        {}
      );
    },

    dishes: function (): any[] {
      let dishes = this.hotelDishes;

      const options = {
        keys: ["attributes.name.it"],
      };

      const fuse = new Fuse(dishes, options);

      if (fuse && this.searchDishString && this.searchDishString.length) {
        dishes = _.reduce(
          fuse.search(this.searchDishString),
          (dishes: Dish[], dish) => {
            const _dish = this.$store.state.dishes.objects[dish.get("localId")];

            dishes.push(_dish);
            return dishes;
          },
          []
        );
      }

      return _.filter(
        dishes,

        (dish) => {
          const type = dish.get("type") || "dish";

          const typeMatch = !!_.find(this.filterByTypes, (t) => {
            return t == type;
          });

          if (!typeMatch) return false;

          if (!this.matchCourseIndex(dish)) return false;
          return true;
        }
      );
    },
  },
  watch: {
    $mq: {
      immediate: true,
      handler: function (newVal) {
        switch (newVal) {
          case "xs":
            this.chunkSize = 1;
            break;
          case "sm":
            this.chunkSize = 2;
            break;
          case "md":
            this.chunkSize = 3;
            break;
          case "lg":
            this.chunkSize = 4;
            break;
          case "mxl":
            this.chunkSize = 4;
            break;
          case "xl":
            this.chunkSize = 4;
            break;
        }
      },
    },
  },
});
