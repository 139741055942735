const languages = {
  //ab: { name: "Abkhaz", code: "ab", code2: "abk" },
  //aa: { name: "Afar", code: "aa", code2: "aar" },
  //af: { name: "Afrikaans", code: "af", code2: "afr" },
  //ak: { name: "Akan", code: "ak", code2: "aka" },
  //sq: { name: "Albanese", code: "sq", code2: "sqi" },
  //am: { name: "Amarico", code: "am", code2: "amh" },
  //ar: { name: "Arabo", code: "ar", code2: "ara" },
  //an: { name: "Aragonese", code: "an", code2: "arg" },
  //hy: { name: "Armeno", code: "hy", code2: "hye" },
  //as: { name: "Assamese", code: "as", code2: "asm" },
  //av: { name: "Avaro", code: "av", code2: "ava" },
  //ae: { name: "Avestico", code: "ae", code2: "ave" },
  //ay: { name: "Aymara", code: "ay", code2: "aym" },
  //az: { name: "Azero", code: "az", code2: "aze" },
  //bm: { name: "Bambara", code: "bm", code2: "bam" },
  //ba: { name: "Bashkir", code: "ba", code2: "bak" },
  //eu: { name: "Basco", code: "eu", code2: "eus" },
  //be: { name: "Bielorusso", code: "be", code2: "bel" },
  //bn: { name: "Bengalese", code: "bn", code2: "ben" },
  //bh: { name: "Bihari", code: "bh", code2: "bih" },
  //bi: { name: "Bislama", code: "bi", code2: "bis" },
  //bs: { name: "Bosniaco", code: "bs", code2: "bos" },
  //br: { name: "Bretone", code: "br", code2: "bre" },
  //bg: { name: "Bulgaro", code: "bg", code2: "bul" },
  //my: { name: "Birmano", code: "my", code2: "mya" },
  //ca: { name: "Catalano", code: "ca", code2: "cat" },
  //ch: { name: "Chamorro", code: "ch", code2: "cha" },
  //ce: { name: "Ceceno", code: "ce", code2: "che" },
  //ny: { name: "Chichewa, Chewa, Nyanja", code: "ny", code2: "nya" },
  //zh: { name: "Cinese", code: "zh", code2: "zho" },
  //cv: { name: "Ciuvascio", code: "cv", code2: "chv" },
  //kw: { name: "Cornico", code: "kw", code2: "cor" },
  //co: { name: "Corso", code: "co", code2: "cos" },
  //cr: { name: "Cree", code: "cr", code2: "cre" },
  //hr: { name: "Croato", code: "hr", code2: "hrv" },
  //cs: { name: "Ceco", code: "cs", code2: "ces" },
  //da: { name: "Danese", code: "da", code2: "dan" },
  //dv: { name: "Divehi, Dhivehi, Maldiviano", code: "dv", code2: "div" },
  //nl: { name: "Olandese", code: "nl", code2: "nld" },
  //dz: { name: "Dzongkha", code: "dz", code2: "dzo" },
  en: { name: "Inglese", code: "en", code2: "eng" },
  //eo: { name: "Esperanto", code: "eo", code2: "epo" },
  //et: { name: "Estone", code: "et", code2: "est" },
  //ee: { name: "Ewe", code: "ee", code2: "ewe" },
  //fo: { name: "Faroese", code: "fo", code2: "fao" },
  //fj: { name: "Figiano", code: "fj", code2: "fij" },
  //fi: { name: "Finlandese", code: "fi", code2: "fin" },
  fr: { name: "Francese", code: "fr", code2: "fra" },
  //ff: { name: "Fula, Fulah, Pulaar, Pular", code: "ff", code2: "ful" },
  //gl: { name: "Galiziano", code: "gl", code2: "glg" },
  //ka: { name: "Georgiano", code: "ka", code2: "kat" },
  de: { name: "Tedesco", code: "de", code2: "deu" },
  //el: { name: "Greco, moderno", code: "el", code2: "ell" },
  //gn: { name: "Guaraní", code: "gn", code2: "grn" },
  //gu: { name: "Gujarati", code: "gu", code2: "guj" },
  //ht: { name: "Creolo haitiano", code: "ht", code2: "hat" },
  //ha: { name: "Hausa", code: "ha", code2: "hau" },
  //he: { name: "Ebraico (moderno)", code: "he", code2: "heb" },
  //hz: { name: "Herero", code: "hz", code2: "her" },
  //hi: { name: "Hindi", code: "hi", code2: "hin" },
  //ho: { name: "Hiri Motu", code: "ho", code2: "hmo" },
  //hu: { name: "Ungherese", code: "hu", code2: "hun" },
  //ia: { name: "Interlingua", code: "ia", code2: "ina" },
  //id: { name: "Indonesiano", code: "id", code2: "ind" },
  //ie: { name: "Interlingue", code: "ie", code2: "ile" },
  //ga: { name: "Irlandese", code: "ga", code2: "gle" },
  //ig: { name: "Igbo", code: "ig", code2: "ibo" },
  //ik: { name: "Inupiaq", code: "ik", code2: "ipk" },
  //io: { name: "Ido", code: "io", code2: "ido" },
  //is: { name: "Islandese", code: "is", code2: "isl" },
  it: { name: "Italiano", code: "it", code2: "ita" },
  //iu: { name: "Inuktitut", code: "iu", code2: "iku" },
  //ja: { name: "Giapponese", code: "ja", code2: "jpn" },
  //jv: { name: "Giavanese", code: "jv", code2: "jav" },
  //kl: { name: "Kalaallisut, Groenlandia", code: "kl", code2: "kal" },
  //kn: { name: "Kannada", code: "kn", code2: "kan" },
  //kr: { name: "Kanuri", code: "kr", code2: "kau" },
  //ks: { name: "Kashmiri", code: "ks", code2: "kas" },
  //kk: { name: "Kazaco", code: "kk", code2: "kaz" },
  //km: { name: "Khmer", code: "km", code2: "khm" },
  //ki: { name: "Kikuyu, Gikuyu", code: "ki", code2: "kik" },
  //rw: { name: "Kinyarwanda", code: "rw", code2: "kin" },
  //ky: { name: "Chirghiso", code: "ky", code2: "kir" },
  //kv: { name: "Komi", code: "kv", code2: "kom" },
  //kg: { name: "Kikongo", code: "kg", code2: "kon" },
  //ko: { name: "Coreano", code: "ko", code2: "kor" },
  //ku: { name: "Curdo", code: "ku", code2: "kur" },
  //kj: { name: "Kwanyama, Kuanyama", code: "kj", code2: "kua" },
  //la: { name: "Latino", code: "la", code2: "lat" },
  //lb: { name: "Lussemburghese, Letzeburgesch", code: "lb", code2: "ltz" },
  //lg: { name: "Luganda, Ganda", code: "lg", code2: "lug" },
  //li: { name: "Limburghese", code: "li", code2: "lim" },
  //ln: { name: "Lingala, Mangala", code: "ln", code2: "lin" },
  //lo: { name: "Lao", code: "lo", code2: "lao" },
  //lt: { name: "Lituano", code: "lt", code2: "lit" },
  //lu: { name: "Luba-Katanga", code: "lu", code2: "lub" },
  //lv: { name: "Lettone", code: "lv", code2: "lav" },
  //gv: { name: "Mannese", code: "gv", code2: "glv" },
  //mk: { name: "Macedone", code: "mk", code2: "mkd" },
  //mg: { name: "Malgascio", code: "mg", code2: "mlg" },
  //ms: { name: "Malese", code: "ms", code2: "msa" },
  //ml: { name: "Malayalam", code: "ml", code2: "mal" },
  //mt: { name: "Maltese", code: "mt", code2: "mlt" },
  //mi: { name: "Maori", code: "mi", code2: "mri" },
  //mr: { name: "Marathi (Marāṭhī)", code: "mr", code2: "mar" },
  //mh: { name: "Marshallese", code: "mh", code2: "mah" },
  //mn: { name: "Mongolo", code: "mn", code2: "mon" },
  //na: { name: "Nauruano", code: "na", code2: "nau" },
  //nv: { name: "Navajo, Navaho", code: "nv", code2: "nav" },
  //nb: { name: "Norvegese Bokmål", code: "nb", code2: "nob" },
  //nd: { name: "Ndebele del Nord", code: "nd", code2: "nde" },
  //ne: { name: "Nepalese", code: "ne", code2: "nep" },
  //ng: { name: "Ndonga", code: "ng", code2: "ndo" },
  //nn: { name: "Norvegese Nynorsk", code: "nn", code2: "nno" },
  //no: { name: "Norvegese", code: "no", code2: "nor" },
  //ii: { name: "Nuosu", code: "ii", code2: "iii" },
  //nr: { name: "Ndebele del Sud", code: "nr", code2: "nbl" },
  //oc: { name: "Occitano", code: "oc", code2: "oci" },
  //oj: { name: "Ojibwe, Ojibwa", code: "oj", code2: "oji" },
  //om: { name: "Oromo", code: "om", code2: "orm" },
  //or: { name: "Oriya", code: "or", code2: "ori" },
  //os: { name: "Osseto", code: "os", code2: "oss" },
  //pa: { name: "Panjabi, Punjabi", code: "pa", code2: "pan" },
  //pi: { name: "Pāli", code: "pi", code2: "pli" },
  //fa: { name: "Persiano, Fārsì", code: "fa", code2: "fas" },
  //pl: { name: "Polacco", code: "pl", code2: "pol" },
  //ps: { name: "Pashtu", code: "ps", code2: "pus" },
  //pt: { name: "Portoghese", code: "pt", code2: "por" },
  //qu: { name: "Quechua", code: "qu", code2: "que" },
  //rm: { name: "Romancio", code: "rm", code2: "roh" },
  //rn: { name: "Kirundi", code: "rn", code2: "run" },
  //ro: { name: "Rumeno, Moldavo", code: "ro", code2: "ron" },
  //ru: { name: "Russo", code: "ru", code2: "rus" },
  //sa: { name: "Sanscrito (Saṁskṛta)", code: "sa", code2: "san" },
  //sc: { name: "Sardo", code: "sc", code2: "srd" },
  //sd: { name: "Sindhi", code: "sd", code2: "snd" },
  //se: { name: "Sami settentrionale", code: "se", code2: "sme" },
  //sm: { name: "Samoano", code: "sm", code2: "smo" },
  //sg: { name: "Sango", code: "sg", code2: "sag" },
  //sr: { name: "Serbo", code: "sr", code2: "srp" },
  //gd: { name: "Gaelico scozzese, Gaelico", code: "gd", code2: "gla" },
  //sn: { name: "Shona", code: "sn", code2: "sna" },
  //si: { name: "Singalese", code: "si", code2: "sin" },
  //sk: { name: "Slovacco", code: "sk", code2: "slk" },
  //sl: { name: "Sloveno", code: "sl", code2: "slv" },
  //so: { name: "Somalo", code: "so", code2: "som" },
  //st: { name: "Sesotho del Sud", code: "st", code2: "sot" },
  es: { name: "Spagnolo", code: "es", code2: "spa" },
  //su: { name: "Sondanese", code: "su", code2: "sun" },
  //sw: { name: "Swahili", code: "sw", code2: "swa" },
  //ss: { name: "Swazi", code: "ss", code2: "ssw" },
  //sv: { name: "Svedese", code: "sv", code2: "swe" },
  //ta: { name: "Tamil", code: "ta", code2: "tam" },
  //te: { name: "Telugu", code: "te", code2: "tel" },
  //tg: { name: "Tagiko", code: "tg", code2: "tgk" },
  //th: { name: "Thailandese", code: "th", code2: "tha" },
  //ti: { name: "Tigrino", code: "ti", code2: "tir" },
  //bo: { name: "Tibetano", code: "bo", code2: "bod" },
  //tk: { name: "Turkmeno", code: "tk", code2: "tuk" },
  //tl: { name: "Tagalog", code: "tl", code2: "tgl" },
  //tn: { name: "Tswana", code: "tn", code2: "tsn" },
  //to: { name: "Tongano (Isole Tonga)", code: "to", code2: "ton" },
  //tr: { name: "Turco", code: "tr", code2: "tur" },
  //ts: { name: "Tsonga", code: "ts", code2: "tso" },
  //tt: { name: "Tataro", code: "tt", code2: "tat" },
  //tw: { name: "Twi", code: "tw", code2: "twi" },
  //ty: { name: "Tahitiano", code: "ty", code2: "tah" },
  //ug: { name: "Uiguro, Uyghur", code: "ug", code2: "uig" },
  //uk: { name: "Ucraino", code: "uk", code2: "ukr" },
  //ur: { name: "Urdu", code: "ur", code2: "urd" },
  //uz: { name: "Usbeco", code: "uz", code2: "uzb" },
  //ve: { name: "Venda", code: "ve", code2: "ven" },
  //vi: { name: "Vietnamita", code: "vi", code2: "vie" },
  //vo: { name: "Volapük", code: "vo", code2: "vol" },
  //wa: { name: "Vallone", code: "wa", code2: "wln" },
  //cy: { name: "Gallese", code: "cy", code2: "cym" },
  //wo: { name: "Wolof", code: "wo", code2: "wol" },
  //fy: { name: "Frisone occidentale", code: "fy", code2: "fry" },
  //xh: { name: "Xhosa", code: "xh", code2: "xho" },
  //yi: { name: "Yiddish", code: "yi", code2: "yid" },
  //yo: { name: "Yoruba", code: "yo", code2: "yor" },
  //za: { name: "Zhuang, Chuang", code: "za", code2: "zha" },
  //zu: { name: "Zulu", code: "zu", code2: "zul" },
};

export default languages;
