var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"orderRowWrapper",class:{
    first: _vm.index === 0,
  }},[_c('div',{key:_vm.index,staticClass:"order-row"},[_c('div',{staticClass:"header"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.order && _vm.order.get("person") && _vm.order.get("person").get("name") && _vm.order.get("person").get("name").length ? "Ordine per " + _vm.order.get("person").get("name") : "Ordine #" + (_vm.index + 1))+" ")]),_c('div',{staticStyle:{"display":"flex","align-items":"center","flex-wrap":"wrap"}},[(_vm.order && _vm.order.get('completed'))?_c('el-tag',{staticStyle:{"color":"white","margin-right":"4px"},attrs:{"size":"mini","color":"#00B70C"}},[_vm._v("Completo")]):_c('el-tag',{staticStyle:{"color":"white","margin-right":"4px"},attrs:{"size":"mini","color":"#f4443f"}},[_vm._v("Incompleto")]),(_vm.getModeOfString(_vm.order).length)?_c('el-tag',{staticStyle:{"color":"white","margin-right":"4px"},attrs:{"size":"mini","color":"#4E97CE"}},[_vm._v(_vm._s(_vm.getModeOfString(_vm.order)))]):_vm._e()],1)]),_c('span',{staticClass:"completed-order"},[_c('i',{staticClass:"el-icon-edit",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.openEditModal(_vm.order ? _vm.order.get('localId') : undefined)}}})])]),_c('div',{staticClass:"body"},[_vm._l((_vm.coursesWithDishes),function(course,index){return _c('div',{key:index,staticClass:"course-row"},[_c('span',{staticStyle:{"font-weight":"bold","padding":"10px","display":"block"}},[_vm._v(_vm._s(_vm.getLocalizedName(_vm.findJCourseByLocalId(course.id).name)))]),_vm._l((_vm.getDishOfCourse(course.id)),function(dish,index){return _c('div',{key:index,staticClass:"dish-wrapper",class:{
            firstDish: index === 0,
          }},[_c('div',{staticClass:"dishFirstRow"},[_c('span',{staticClass:"dishName"},[_vm._v(_vm._s(_vm.getLocalizedName(dish.get("name"))))]),_c('span',{staticStyle:{"margin-left":"auto","display":"flex","align-items":"center"}},[_c('el-input-number',{attrs:{"size":"mini","value":_vm.getDishQuantity(
                    _vm.order.get('localId'),
                    course.id,
                    dish.get('localId')
                  ),"min":0},on:{"input":function($event){_vm.setDishQuantity(
                    _vm.order.get('localId'),
                    dish.get('localId'),
                    $event
                  )}}})],1)]),(_vm.getDishAnnotation(_vm.order, dish))?_c('div',{staticClass:"annotationWrapper"},[_c('p',[_vm._v(_vm._s(_vm.getDishAnnotation(_vm.order, dish)))])]):_vm._e()])})],2)}),(
          _vm.order.get('delivery') &&
          _vm.order.getLocDeliveryExpl() &&
          _vm.order.getLocDeliveryExpl().length
        )?_c('div',{staticStyle:{"padding":"10px","border-top":"1px solid rgba(0, 0, 0, 0.15)"}},[_vm._v(" Luogo di consegna: "+_vm._s(_vm.order.getLocDeliveryExpl())+" ")]):_vm._e(),_c('div',{staticStyle:{"padding":"10px","border-top":"1px solid rgba(0, 0, 0, 0.15)","display":"flex","align-items":"center","justify-content":"center"}},[_c('el-checkbox',{attrs:{"value":!!_vm.order && _vm.order.get('completed')},on:{"input":_vm.setOrderCompleted}},[_vm._v("Ordine completato")])],1)],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }