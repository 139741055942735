import Vue from "vue";
import { Jpasta } from "@/jpasta-sdk";
import _ from "lodash";
import store from "./VuexStore";
import moment from "moment";

export default {
  emptyObjects(state) {
    state.objects = {};
    if (state.version !== undefined) {
      state.version++;
    }
    if (state.length !== undefined) {
      state.length = 0;
    }
  },

  upsertObject(state, obj) {
    if (!obj.get("localId")) {
      obj.set("localId", Jpasta.randomLocalUUID());
    }

    // obj = _.cloneDeep(obj);

    let existed = !!state.objects[obj.get("localId")];

    var key = obj.getStoreKey ? obj.getStoreKey() : obj.get("localId");

    if (obj.get("localId") && obj.id) {
      store.commit("map", {
        id: obj.id,
        localId: obj.get("localId"),
      });
    }

    state.objects = {
      ...state.objects,
      [key]: obj,
    };

    if (state.version !== undefined) {
      state.version++;
    }

    if (!existed && state.length !== undefined) {
      state.length++;
    }

    return obj;
  },

  deleteObj(state, obj) {
    if (!obj.get("localId")) {
      return console.warn("Cannot delete object without localId");
    }

    let existed = !!state.objects[obj.get("localId")];

    Vue.delete(state.objects, obj.get("localId"));

    if (state.version !== undefined) {
      state.version++;
    }

    if (existed && state.length !== undefined) {
      state.length--;
    }
  },
};
