
import Vue from "vue";
import mixins from "vue-typed-mixins";
import {
  Turn,
  Hotel,
  CalendarMeal,
  Book,
  Table,
  BookPerson,
  MealOrder,
  MealOrderDish,
} from "@/jpasta-sdk";
import _ from "lodash";
import Parse from "parse";
import { Dictionary } from "vue-router/types/router";
import moment from "moment-timezone";
import ProgressBar from "@/components/ui/ProgressBar.vue";
export default mixins().extend({
  components: {
    ProgressBar,
  },
  data: function (): {
    loadingForSync: boolean;
  } {
    return {
      loadingForSync: true,
    };
  },
  mounted() {
    _.debounce(() => {
      this.fetchData();
    }, 200)();
  },
  methods: {
    fetchData: async function () {
      this.loadingForSync = true;

      const bookPeopleQuery = new Parse.Query(BookPerson);
      bookPeopleQuery.containedIn("book", this.books);
      const batch: Promise<any>[] = [];

      const mealOrdersQuery = new Parse.Query(MealOrder);
      mealOrdersQuery.equalTo("calendarMeal", this.calendarMeal);

      batch.push(
        this.$store.dispatch("mealOrders/getObjects", {
          query: mealOrdersQuery,
          clear: true,
        })
      );

      const mealOrderDishesQuery = new Parse.Query(MealOrderDish);
      mealOrderDishesQuery.matchesKeyInQuery(
        "order",
        "objectId",
        mealOrdersQuery
      );

      batch.push(
        this.$store.dispatch("mealOrderDishes/getObjects", {
          query: mealOrderDishesQuery,
          clear: true,
        })
      );

      batch.push(
        this.$store.dispatch("bookPersons/getObjects", {
          query: bookPeopleQuery,
        })
      );

      await Promise.all(batch);

      this.loadingForSync = false;
    },
    getLocalBook(book: Book): Book | undefined {
      if (!book) return undefined;
      if (!book.get("localId")) return undefined;

      return this.$store.state.books.objects[book.get("localId")];
    },
    getProgress(turn?: Turn) {
      const peopleCount = this.getPeopleCount(turn);

      if (peopleCount <= 0) return 100;
      const percent =
        (this.getCompletedOrdersCount(turn) / this.getPeopleCount(turn)) * 100;

      return parseFloat(percent.toFixed(2));
    },
    getBooksLabel(count: number) {
      if (count !== 1) return "persone";
      else return "persona";
    },
    getBooksCount(turn?: Turn) {
      return _.size(this.booksByTurn[turn ? turn.get("localId") : undefined]);
    },
    getPeopleCount(turn?: Turn) {
      return _.size(
        this.bookPeopleByTurn[turn ? turn.get("localId") : undefined]
      );
    },
    getCompletedOrdersCount(turn?: Turn) {
      return _.size(
        this.completedOrdersByTyrn[turn ? turn.get("localId") : undefined]
      );
    },
    goToMealBook(turn?: Turn): void {
      if (!turn) {
        this.$router.push({
          name: "HotelMealBook",
          params: {
            hotelid: this.hotel.get("localId"),
            calendarmealid: this.calendarMeal.get("localId"),
          },
        });

        return;
      }
      this.$router.push({
        name: "HotelMealBookScreenTurn",
        params: {
          turnid: turn.get("localId"),
          hotelid: this.hotel.get("localId"),
          calendarmealid: this.calendarMeal.get("localId"),
        },
      });
    },

    formatTurnName(turn: Turn): string {
      const mealIndex = this.calendarMeal.get("mealIndex");
      if (mealIndex === undefined) return "";
      const fromTime = turn.getFromTime(mealIndex);
      const toTime = turn.getToTime(mealIndex);

      const res = [`${turn.get("name") || "Senza nome"}`];
      if (fromTime && toTime) {
        res.push(`(${fromTime} - ${toTime})`);
      }

      return res.join(" ");
    },
  },
  computed: {
    completedOrders: function (): MealOrder[] {
      return _.filter(
        this.$store.state.mealOrders.objects as Dictionary<MealOrder>,
        (mo) => {
          const person: BookPerson = mo.get("person");
          const book = this.getLocalBook(mo.get("book"));

          return (
            !!person &&
            !!book &&
            book.isIncluded(this.calendarMeal) &&
            book.get("checkin") &&
            book.get("checkout") &&
            mo.belongsTo(this.calendarMeal, "calendarMeal") &&
            mo.get("completed")
          );
        }
      );
    },

    completedOrdersByTyrn: function (): Dictionary<MealOrder[]> {
      return _.groupBy(this.completedOrders, (mo) => {
        const b = mo.get("book") as Book;

        const tableName = b.getTableNumber();
        if (!tableName) return undefined;
        const turn = this.turnByTableName[tableName];
        if (!turn) return undefined;

        return turn.get("localId");
      });
    },
    tables: function (): Table[] {
      return _.filter(
        this.$store.state.tables.objects as Dictionary<Table>,
        (t) => {
          return _.size(t.get("name")) > 0 && t.belongsTo(this.hotel, "hotel");
        }
      );
    },
    turnByTableName: function (): Dictionary<Turn | undefined> {
      return _.reduce(
        this.tables,
        (res: Dictionary<Turn | undefined>, t) => {
          const turn = t.get("turn");
          res[t.get("name")] = turn;

          return res;
        },
        {}
      );
    },
    bookPeopleByTurn: function (): Dictionary<BookPerson[]> {
      return _.groupBy(this.bookPeople, (bp) => {
        const b = bp.get("book") as Book;
        const tableName = b.getTableNumber();
        if (!tableName) return undefined;
        const turn = this.turnByTableName[tableName];

        if (!turn) return undefined;

        return turn.get("localId");
      });
    },
    bookPeople: function (): BookPerson[] {
      return _.filter(
        this.$store.state.bookPersons.objects as Dictionary<BookPerson>,
        (bp) => {
          const book = this.getLocalBook(bp.get("book"));

          if (!book) return false;
          if (!book.isIncluded(this.calendarMeal)) return false;
          if (!book.belongsTo(this.hotel, "hotel")) return false;
          if (book.get("stafferBook")) return false;
          return (
            book.get("checkin") &&
            book.get("checkout") &&
            bp.canOrder(this.calendarMeal, undefined)
          );
        }
      );
    },
    books: function (): Book[] {
      return _.filter(
        this.$store.state.books.objects as Dictionary<Book>,
        (b) =>
          b.get("checkin") &&
          b.get("checkout") &&
          b.belongsTo(this.hotel, "hotel") &&
          moment(this.calendarMeal.get("date")).isBetween(
            b.get("checkin"),
            b.get("checkout")
          )
      );
    },
    booksByTurn: function (): Dictionary<Book[]> {
      return _.groupBy(this.books, (b) => {
        const tableName = b.getTableNumber();
        if (!tableName) return undefined;
        const turn = this.turnByTableName[tableName];
        if (!turn) return undefined;

        return turn.get("localId");
      });
    },
    calendarMeal: function (): CalendarMeal {
      return this.$store.state.calendarMeals.objects[
        this.$route.params.calendarmealid
      ];
    },
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    turns: function (): Turn[] {
      return _.filter(
        this.$store.state.turns.objects as Dictionary<Turn>,
        (turn) => {
          return turn.belongsTo(this.hotel, "hotel");
        }
      );
    },
  },
});
