import React from "react";
import { WhiteLoadingSpinner } from "@/ui/WhiteLoadingSpinner";
type Props = {
  disabled?: boolean;
  onClick: () => void;
  label: string;
  loading?: boolean;
};
export function PrimaryButton({ disabled, onClick, label, loading }: Props) {
  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={[
        "rounded-md px-10 whitespace-nowrap justify-center py-2 text-white font-bold flex items-center space-x-2",
        !disabled
          ? "bg-red-500 hover:bg-red-700 cursor-pointer"
          : "bg-gray-300 cursor-not-allowed",
      ].join(" ")}
    >
      <span>{label}</span>
      {loading ? <WhiteLoadingSpinner /> : null}
    </a>
  );
}
