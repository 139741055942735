<template>
  <i
    v-if="loading"
    :class="{
      'border-secondary': type === 'border-secondary',
    }"
    style="margin-left: 5px"
    class="el-icon-loading jspinner"
  ></i>
  <!-- <div
    v-if="loading"
    style="margin-left: 5px;"
    :class="{
      'ispinner--white': type != 'text' && type != 'border',
      'ispinner--red': type == 'border',
      'loading-small': type == 'border',
      'loading-normal': type != 'border',
      'ispinner--dark':
        type == 'link' || type == 'border-secondary' || type == 'border-grey'
    }"
    class="ispinner ispinner--animating"
  >
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
    <div class="ispinner__blade"></div>
  </div> -->
</template>

<script>
export default {
  props: {
    type: {},
    loading: {},
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";
.jspinner {
  &.border-secondary {
    color: map-get($colors, "secondary");
  }
}
.loading-normal {
  width: 14px;
  height: 16px;
}

.loading-small {
  width: 10px;
  height: 12px;
}
</style>
