
import _, { Dictionary } from "lodash";
import Vue from "vue";
import moment from "moment-timezone";
import { Moment } from "moment";
import Parse from "parse";
import MealBookComponent from "./MealBookComponent.vue";
import setScrollTopMixin from "@/mixins/setScrollTopMixin";
import {
  getMealDate,
  getMealFromDate,
  JPASTA_MEALS,
  Jpasta,
  findJMealByLocalId,
  findJCourseByLocalId,
  MealOrder,
  MealOrderDish,
  CalendarMeal,
  Hotel,
  BookPerson,
  Book,
} from "@/jpasta-sdk";
import mixins from "vue-typed-mixins";
let mouseGoUp = false;
export default mixins().extend({
  mixins: [setScrollTopMixin],
  components: {
    MealBookComponent,
  },
  mounted() {
    _.debounce(this.fetchData, 200)();
  },
  data: function (): {
    loadingForSync: boolean;
  } {
    return {
      loadingForSync: true,
    };
  },
  methods: {
    fetchData: async function (): Promise<void> {
      const mealOrdersQuery = new Parse.Query(MealOrder);

      mealOrdersQuery.containedIn("calendarMeal", this.activeCalendarMeals);
      const before = moment();
      const bookPeopleQuery = new Parse.Query(BookPerson);
      bookPeopleQuery.containedIn("book", this.books);

      const batch: Promise<any>[] = [];

      batch.push(
        this.$store.dispatch("mealOrders/getObjects", {
          query: mealOrdersQuery,
          clear: true,
        })
      );
      batch.push(
        this.$store.dispatch("bookPersons/getObjects", {
          query: bookPeopleQuery,
        })
      );

      const mealOrderDishesQuery = new Parse.Query(MealOrderDish);
      mealOrderDishesQuery.matchesKeyInQuery(
        "order",
        "objectId",
        mealOrdersQuery
      );

      batch.push(
        this.$store.dispatch("mealOrderDishes/getObjects", {
          query: mealOrderDishesQuery,
          clear: true,
        })
      );

      await Promise.all(batch);

      this.loadingForSync = false;
      this.setScrollTop();
    },

    isInCurrentPeriod(cMeal): boolean {
      return moment().isBetween(cMeal.get("fromDate"), cMeal.get("toDate"));
    },
    hasToIncludeBook(book: Book): boolean {
      const checkin = book.get("checkin");
      const checkout = book.get("checkout");
      const firstDate = this.firstCmealDate;
      const lastDate = this.lastCmealDate;
      if (!firstDate || !lastDate) return false;
      return (
        moment(checkin).isBetween(firstDate, lastDate) ||
        moment(checkout).isBetween(firstDate, lastDate)
      );
    },
  },
  computed: {
    sortedCMealsDates(): string[] {
      const dates = _.map(this.lastWeekCMeals, (cMeal) => cMeal.get("date"));

      return _.sortBy(dates, (d) => moment(d).valueOf());
    },

    firstCmealDate(): Moment | undefined {
      if (!this.sortedCMealsDates.length) return undefined;
      return moment(_.first(this.sortedCMealsDates));
    },
    lastCmealDate(): Moment | undefined {
      if (!this.sortedCMealsDates.length) return undefined;
      return moment(_.last(this.sortedCMealsDates));
    },

    books: function (): Book[] {
      return _.filter(
        this.$store.state.books.objects as Dictionary<Book>,
        (book) => {
          return (
            book.belongsTo(this.hotel, "hotel") && this.hasToIncludeBook(book)
            // !!_.find(this.lastWeekCMeals, cMeal => {
            //   return moment(cMeal.get("date")).isBetween(
            //     book.get("checkin"),
            //     book.get("checkout")
            //   );
            // })
          );
        }
      );
    },
    items2render: function (): { key: string; meals: CalendarMeal[] }[] {
      return _.map(this.bookableMealsGrouped, (meals) => {
        return {
          key: moment(meals[0].get("date")).format("YYYY-MM-DD"),
          meals: meals,
        };
      });
    },
    activeCalendarMeals: function (): CalendarMeal[] {
      return _.filter(this.calendarMeals, (cMeal) => {
        return this.isInCurrentPeriod(cMeal);
      });
    },
    bookPeopleMap: function (): {
      [key: string]: BookPerson;
    } {
      return _.reduce(
        this.$store.state.bookPersons.objects,
        (res, bookPerson) => {
          if (!bookPerson.get("book")) return res;

          const bookLocalId = bookPerson.get("book").get("localId");
          res[bookLocalId] = res[bookLocalId] || [];
          res[bookLocalId].push(bookPerson);
          return res;
        },
        {}
      );
    },

    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    calendarMeals: function (): CalendarMeal[] {
      return _.filter(this.$store.state.calendarMeals.objects, (cMeal) => {
        return cMeal.belongsTo(this.hotel, "hotel");
      });
    },
    lastWeekCMeals: function (): CalendarMeal[] {
      return _.filter(this.calendarMeals, (cMeal) => {
        return moment()
          .subtract(1, "week")
          .isBefore(moment(cMeal.get("date")));
      });
    },

    bookableMealsGrouped: function (): CalendarMeal[][] {
      const mealsMap: { [key: string]: CalendarMeal[] } = _.reduce(
        this.lastWeekCMeals,
        (res, cMeal) => {
          var currentDate = moment().tz(this.hotel.get("timezone"));
          var fromDate = moment(cMeal.get("fromDate")).tz(
            this.hotel.get("timezone")
          );

          if (
            currentDate.isBefore(fromDate) ||
            !findJMealByLocalId(cMeal.get("mealIndex"))
          )
            return res;
          const key = moment(cMeal.get("date"))
            .tz(this.hotel.get("timezone"))
            .format("YYYY-MM-DD");
          res[key] = res[key] || [];
          res[key].push(cMeal);
          return res;
        },
        {}
      );

      _.forEach(mealsMap, (v, k) => {
        mealsMap[k] = _.sortBy(v, (cMeal) => {
          return moment(cMeal.get("date"))
            .tz(this.hotel.get("timezone"))
            .unix();
        });
      });

      return _.sortBy(mealsMap, (v) => {
        if (!v.length) return Infinity;
        const k = moment(v[0].get("date")).format("YYYY-MM-DD");
        const date = k + " 00:00:00";

        return -moment(date).unix();
      });
    },
  },
});
