import generalMutations from "./generalMutations";
import generalActions from "./generalActions";

export default {
  namespaced: true,
  state: {
    objects: {},
    className: "Rate",
  },

  mutations: {
    ...generalMutations,
  },
  actions: {
    ...generalActions,
  },
};
