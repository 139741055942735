import Vue from "vue";
import { Hotel, Table, Turn, Book } from "@/jpasta-sdk";
import _, { Dictionary } from "lodash";
export default Vue.extend({
  methods: {
    belongsToTurn: function (book: Book, turn?: Turn) {
      const _tableName = book.get("tableNumber");
      const tableName = _.size(_tableName) > 0 ? _tableName : undefined;

      const turnLocalId = turn ? turn.get("localId") : undefined;

      const tableTurn = this.turnByTable[tableName];
      const tableTurnLocalId = tableTurn ? tableTurn.get("localId") : undefined;
      return tableTurnLocalId === turnLocalId;
    },
  },
  computed: {
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },

    lTables: function (): Table[] {
      return _.filter(this.$store.state.tables.objects, (t: Table) => {
        return t.belongsTo(this.hotel, "hotel");
      });
    },
    turnByTable: function (): Dictionary<Turn | undefined> {
      return _.reduce(
        this.lTables,
        (res, t) => {
          res[t.get("name")] = t.get("turn");

          return res;
        },
        {}
      );
    },
  },
});
