import useSWR, { useSWRConfig } from "swr";
import { Book, CalendarMeal } from "@/jpasta-sdk";
import Parse from "parse";
import Axios from "axios";
import { useCallback } from "react";
export type GetEditableMealOrderDishDTO = {
  objectId: string;
  name: Record<string, string>;
  courseIndex: number;
  allergens: number[];
};
export type CompletedOrdersInfoDTO = {
  totalCompletedOrders: number;
  totalPax: number;
};
type Response = {
  mealOrders: Array<GetEditableMealOrderDTO>;
  dishes: Array<GetEditableMealOrderDishDTO>;
  completedOrdersInfo: CompletedOrdersInfoDTO;
};

export type GetEditableMealOrderDTO = {
  order: {
    completed: boolean;
    objectId: string;
  };
  dishes: Array<{
    objectId: string;
    quantity: number;
    annotation?: string;
    dish: GetEditableMealOrderDishDTO;
  }>;
};
export function useGetEditableMealBooks(
  calendarMeal: CalendarMeal,
  book: Book
) {
  const user = Parse.User.current();
  const key = `calendarMeal/${calendarMeal.id}/books/${book.id}/mealOrders`;
  const { mutate } = useSWRConfig();
  const { data, isLoading, error } = useSWR(key, async () => {
    const { data } = await Axios.get<{
      data: Response;
    }>(`${process.env.VUE_APP_API_BASE_URL}/custom/editable-orders`, {
      headers: {
        "x-parse-session-token": user?.getSessionToken(),
      },
      params: {
        bookId: book.id,
        calendarMealId: calendarMeal.id,
      },
    });
    return data;
  });

  const revalidate = useCallback(async () => {
    await mutate(key);
  }, [mutate]);

  return {
    mealOrders: data?.data?.mealOrders ?? [],
    dishes: data?.data?.dishes ?? [],
    completedOrdersInfo: data?.data?.completedOrdersInfo ?? {
      totalCompletedOrders: 0,
      totalPax: 0,
    },
    error,
    isLoading,
    revalidate,
  };
}
