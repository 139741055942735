var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-row',{staticClass:"no-print"},[_c('div',{staticClass:"sectionTitleWrapper"},[_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v("Report sala")])]),_c('div',{staticClass:"filterWrapper mb-2 mt-1"},[_c('el-select',{staticClass:"searchStringInput",attrs:{"remote":"","clearable":"","value":_vm.searchMealBookString,"remote-method":_vm.doFilterWaiterReportOptions,"filterable":"","placeholder":"Cerca per tavolo o camera"},on:{"clear":function($event){return _vm.changeSearchMealBookString('all')},"input":_vm.changeSearchMealBookString}},_vm._l((_vm.filterWaiterReportOptions),function(option){return _c('el-option',{key:option.value,attrs:{"label":option.label,"value":option.value}})}),1)],1),_c('DynamicScroller',{ref:"recycler-scroller",staticClass:"scroller",staticStyle:{"height":"100%"},attrs:{"page-mode":true,"items":_vm.reportDataNew,"minItemSize":10,"buffer":1000,"keyField":"key"},scopedSlots:_vm._u([{key:"default",fn:function({ item: book, index, active }){return [_c('DynamicScrollerItem',{attrs:{"size-dependencies":[
          book.dishes,
          book.mounted,
          book.rateName,
          book.isIncluded,
          book.book,
          book.type,
          book.firstOfRank,
        ],"item":book,"active":active,"data-index":index}},[(book.type === 'rank')?_c('el-row',{staticClass:"rankItem",class:{
            firstRank: book.firstRank,
          }},[_c('div',{staticClass:"rankNameWrapper"},[_c('h3',[_vm._v(_vm._s(_vm.formatRankName(book.rank)))])])]):(book.type === 'group-block')?_c('el-row',{staticClass:"rankItem",class:{
            firstRank: book.firstRank,
          }},[_c('div',{staticClass:"rankNameWrapper"},[_c('h3',[_vm._v(_vm._s(book.name))])])]):(book.type === 'missing')?_c('el-row',[_c('el-col',{staticClass:"mealBookRowMissing",attrs:{"span":24}},[_c('div',{staticClass:"reportHeader"},[_c('h4',{staticStyle:{"font-weight":"normal","text-align":"center","width":"100%"}},[_vm._v(" Non ci sono prenotazioni nel "+_vm._s(_vm.formatRankName(book.rank))+" ")])])])],1):(book.type === 'book')?_c('el-row',[_c('el-col',{staticClass:"mealBookRow",class:{
              firstOfRank: book.firstOfRank,
            },attrs:{"span":24},nativeOn:{"mousedown":function($event){return _vm.mouseDown(book.book, $event)},"mouseup":function($event){return _vm.mouseUp.apply(null, arguments)}}},[_c('div',{staticClass:"reportHeader"},[_c('h4',{domProps:{"innerHTML":_vm._s(_vm.getBookTitle(book.book))}}),_c('div',{staticStyle:{"margin-left":"auto"}},[(!book.isIncluded)?_c('el-tag',{staticStyle:{"color":"white"},attrs:{"title":`Questo pasto non è incluso nella tipologia ${book.rateName}. Jpasta annoterà come extra gli ordini di questo tavolo.`,"size":"mini","color":"#2c3e50"}},[_vm._v("Non incluso")]):_vm._e(),(book.completed && book.isIncluded)?_c('el-tag',{staticStyle:{"color":"white","margin-left":"4px"},attrs:{"size":"mini","color":"#00B70C"}},[_vm._v("Completo ("+_vm._s(book.completedMealOrdersLength)+"/"+_vm._s(book.peopleLength)+")")]):(book.isIncluded)?_c('el-tag',{staticStyle:{"color":"white","margin-left":"4px"},attrs:{"size":"mini","color":_vm.getCompletedColor(
                      book.completedMealOrdersLength / book.peopleLength
                    )}},[_vm._v("Incompleto ("+_vm._s(book.completedMealOrdersLength)+"/"+_vm._s(book.peopleLength)+")")]):_vm._e()],1)]),_c('div',_vm._l((book.dishes),function(dish,dishIndex){return _c('el-row',{key:dishIndex,staticClass:"dishRecord"},[_c('el-col',{staticClass:"dishName",attrs:{"span":12}},[_vm._v(_vm._s(_vm.getLocalizedName(dish.dish.get("dish").get("name"))))]),_c('el-col',{staticClass:"dishQty",attrs:{"span":12}},[_vm._v("x"+_vm._s(dish.qty))]),(dish.annotations.length)?_c('el-col',{staticStyle:{"margin-top":"4px","font-style":"italic"},attrs:{"span":24}},[_vm._v(_vm._s(dish.annotations.join(" - ")))]):_vm._e()],1)}),1),(
                book.deliveryExplanations && book.deliveryExplanations.length
              )?_c('div',[_c('el-row',{staticClass:"dishRecord"},[_c('el-col',{staticClass:"dishName",attrs:{"span":24}},[_c('h4',[_vm._v("Note per raggiungere posizione")])]),_c('el-col',{staticClass:"dishName",attrs:{"span":12}},[_vm._v(_vm._s(book.deliveryExplanations.join(" - ")))])],1)],1):_vm._e()])],1):(book.type === 'turn')?_c('el-row',{staticClass:"turnRow",class:{
            first: book.firstTurn,
          }},[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"turnNameWrapper"},[_c('div',{staticClass:"horizontalLine"}),_c('h2',[_vm._v(_vm._s(_vm.formatTurnName(book.turnName)))]),_c('div',{staticClass:"horizontalLine"})])])],1):_vm._e()],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }