var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:{
    'column-layout': _vm.columnLayout,
  }},[_vm._l((_vm.labels),function(label,index){return _c('span',{key:index,staticClass:"label-item",class:{
      last: index === _vm.labels.length - 1,
    },style:({
      'background-color': _vm.colors[label.get('colorIndex')]
        ? _vm.colors[label.get('colorIndex')].hex
        : _vm.colors[0],
    })},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(label.get("name").it))]),(_vm.canEdit && !label.get('predefined'))?_c('img',{staticStyle:{"cursor":"pointer","width":"10px","margin-left":"auto"},style:({
        'margin-left': 'auto',
      }),attrs:{"src":require("../../assets/edit-white.svg")},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onEdit(label)}}}):_vm._e(),(_vm.canDelete && !label.get('predefined'))?_c('span',{staticClass:"remove-label-wrapper",style:({
        'margin-left': _vm.canEdit ? '0.2em' : 'auto',
      }),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onDelete(label)}}},[_c('span',{staticClass:"cross"},[_vm._v("✕")])]):_vm._e()])}),(_vm.canAddLabel)?_c('span',{staticClass:"add-label",on:{"click":function($event){return _vm.$emit('addlabel')}}},[_vm._v("Aggiungi etichetta")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }