
import Vue from "vue";
import mixins from "vue-typed-mixins";
import VueVirtualTable from "vue-virtual-table";
import _ from "lodash";
import {
  FULL_BOARD,
  HALF_BOARD_WITH_DINNER,
  HALF_BOARD_WITH_DINNER_AND_DRINK,
  BEB,
  ONLY_BED,
  FULL_BOARD_WITH_DRINK,
  Hotel,
} from "@/jpasta-sdk";
import moment from "moment-timezone";
import validator from "email-validator";
import JpastaCsvParser from "@/classes/JpastaCsvParser";
import H2000Parser from "@/classes/H2000Parser";
import CSVParser from "../../classes/CSVParser";
import HotelCubeParser from "../../classes/HotelCubeParser";
import HQParser from "../../classes/HQParser";
import Ericsoft3Parser from "../../classes/Ericsoft3Parser";
import Ericsoft4Parser from "../../classes/Ericsoft4Parser";
import { Dictionary } from "vue-router/types/router";
const DELETED = 0;
const BOOK_ID = 1;
const CHECKIN = 2;
const CHECKOUT = 3;
const ROOM_NUMBER = 4;
const TABLE_NUMBER = 5;
const BOOK_TYPE = 6;
const REF_NAME = 7;
const EMAIL = 8;
const PAX = 9;
const LANG = 10;
const MOBILE = 11;

const BOOK_TYPES_MAP = {
  FB: FULL_BOARD,
  HB: HALF_BOARD_WITH_DINNER,
  BB: BEB,
  NT: ONLY_BED,
  FBD: FULL_BOARD_WITH_DRINK,
  HBD: HALF_BOARD_WITH_DINNER_AND_DRINK,
};

export default mixins().extend({
  components: {
    VueVirtualTable,
  },
  mounted() {},
  data: function (): {
    skipImport: Dictionary<boolean>;
    tableConfig: any[];
    books: BookPlain[];
    showBookRecapModal: boolean;
    uploading: boolean;
    loading: boolean;
    lastUploaded: File | undefined;
    startRow: number;
    parser: CSVParser | undefined;
    errors: {
      [rowIndex: number]: {
        [fieldKey: string]: string[];
      };
    };
  } {
    return {
      skipImport: {},
      startRow: 1,
      lastUploaded: undefined,
      tableConfig: [
        { prop: "_index", name: "#", width: 80 },
        {
          prop: "_action",
          name: "Importa",
          actionName: "selected",
          width: 150,
        },
        {
          prop: "_action",
          name: "Cancellata",
          actionName: "deleted",
          width: 150,
        },
        { prop: "_action", name: "ID prenotazione", actionName: "bookId" },
        { prop: "_action", name: "Check In", actionName: "checkIn" },
        { prop: "_action", name: "Check Out", actionName: "checkOut" },
        { prop: "_action", name: "Camera", actionName: "room" },
        { prop: "_action", name: "Tavolo", actionName: "table" },
        { prop: "_action", name: "Trattamento", actionName: "bookType" },
        { prop: "_action", name: "Nome", actionName: "name" },
        { prop: "_action", name: "Email", actionName: "email" },
        { prop: "_action", name: "Mobile", actionName: "mobile" },
        { prop: "_action", name: "Pax", actionName: "pax" },
        { prop: "_action", name: "Lingua", actionName: "lang" },
        // {
        //   prop: "user",
        //   name: "User",
        //   searchable: true,
        //   sortable: true,
        //   summary: "COUNT"
        // },
        // { prop: "age", name: "Age", numberFilter: true },
        // { prop: "city", name: "City", filterable: true },
        // { prop: "_action", name: "Action", actionName: "actionCommon" }
      ],
      parser: undefined,
      loading: false,
      books: [],
      errors: {},
      showBookRecapModal: false,
      uploading: false,
    };
  },
  methods: {
    setSkip: function (id: string) {
      this.skipImport = {
        ...this.skipImport,
        [id]: !this.skipImport[id],
      };
    },

    getBooks2import() {
      return _.filter(this.books, (b) => {
        return !this.skipImport[b.bookId as string];
      });
    },
    uploadBookings: async function (): Promise<void> {
      this.uploading = true;
      try {
        const books2import = this.getBooks2import();

        await this.$store.dispatch("books/csvUpload", {
          books: books2import,
          hotelId: this.hotel.id,
        });
        this.showBookRecapModal = false;
      } catch (e) {
      } finally {
        this.uploading = false;
      }
    },
    hasSomeError: function () {
      return _.size(this.errors) > 0 || this.books.length <= 0;
    },
    getCellStyle: function ({ row, column }: { row: BookPlain; column: any }) {
      if (_.get(this.errors, `${row.rowIndex}.${column.property}`, []).length) {
        return {
          border: "2px solid red",
        };
      }
    },
    getCellClass: function ({ row, column }: { row: BookPlain; column: any }) {
      if (_.get(this.errors, `${row.rowIndex}.${column.property}`, []).length) {
        return {
          cellWithError: true,
        };
      }
    },
    getErrorOf: function (rowIndex: number, field: string) {
      return _.get(this.errors, `${rowIndex}.${field}`, []).join(",\n");
    },
    formatDate: function (date: string) {
      return date && moment(date).isValid()
        ? moment(date).format("DD MM YYYY HH:mm")
        : date;
    },
    beforeCloseRecapModal: function (done) {
      this.showBookRecapModal = false;
      done();
    },
    onUploadError: function () {},

    getParser: function (file: File): CSVParser {
      switch (this.hotel.getPmsType()) {
        case "h2k":
          return new H2000Parser(file);
        case "hotelcube":
          return new HotelCubeParser(file);
        case "hq":
          return new HQParser(file);
        case "ericsoft3":
          return new Ericsoft3Parser(file, this.hotel);
        case "ericsoft4csv":
          return new Ericsoft4Parser(file, this.hotel);
        default:
          return new JpastaCsvParser(file);
      }
    },

    parseCsv: async function (file: File, guessFirstRow: boolean = false) {
      const parser = this.getParser(file);
      this.parser = parser;

      if (guessFirstRow) {
        try {
          const firstRow = await parser.guessFirstRow();
          if (firstRow !== this.startRow) {
            this.startRow = firstRow;
            return;
          }
        } catch (e) {}
      }

      try {
        parser.setStartRow(this.startRow);
        await parser.parse();

        this.books = parser.getBooks();
        this.errors = parser.getErrors();
        this.showBookRecapModal = true;
      } catch (e) {
        console.log("ERROR", e);
      }
    },

    uploadCsv: async function (data) {
      this.loading = true;
      const file = data.file;

      _.debounce(async () => {
        this.skipImport = {};
        this.lastUploaded = file;
        await this.parseCsv(file, true);
        this.loading = false;
        data.onSuccess();
      }, 200)();
    },
  },
  computed: {
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
  },
  watch: {
    startRow: function (newVal, oldVal) {
      this.loading = true;
      _.debounce(async () => {
        if (newVal === oldVal) return (this.loading = false);
        if (!this.lastUploaded) return (this.loading = false);

        await this.parseCsv(this.lastUploaded);
        this.loading = false;
      }, 300)();
    },
  },
});
