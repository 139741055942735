import CSVParser from "./CSVParser";
import _ from "lodash";
import {
  FULL_BOARD,
  HALF_BOARD_WITH_DINNER,
  BEB,
  ONLY_BED,
  FULL_BOARD_WITH_DRINK,
  HALF_BOARD_WITH_DINNER_AND_DRINK,
} from "@/jpasta-sdk";
const BOOK_TYPES_MAP = {
  FB: FULL_BOARD,
  HB: HALF_BOARD_WITH_DINNER,
  BB: BEB,
  NT: ONLY_BED,
  FBD: FULL_BOARD_WITH_DRINK,
  HBD: HALF_BOARD_WITH_DINNER_AND_DRINK,
};

const DELETED = 0;
const BOOK_ID = 1;
const CHECKIN = 2;
const CHECKOUT = 3;
const ROOM_NUMBER = 4;
const TABLE_NUMBER = 5;
const BOOK_TYPE = 6;
const REF_NAME = 7;
const EMAIL = 8;
const PAX = 9;
const LANG = 10;
const MOBILE = 11;
export default class JpastaCsvParser extends CSVParser {
  getValidBookTypes() {
    return _.map(BOOK_TYPES_MAP, (v, key) => key);
  }

  parseBookType(type?: string): number | undefined {
    if (!type) return undefined;
    return BOOK_TYPES_MAP[type];
  }

  parseRow(row: string[], index) {
    const deletedRaw = this.getRowData(row, DELETED);
    const deleted = deletedRaw === "A";
    const bookId = this.getRowData(row, BOOK_ID);
    const checkIn = this.getRowData(row, CHECKIN);
    const checkOut = this.getRowData(row, CHECKOUT);
    const roomNumber = this.getRowData(row, ROOM_NUMBER);
    const tableNumber = this.getRowData(row, TABLE_NUMBER);
    const bookType = this.getRowData(row, BOOK_TYPE);
    const name = this.getRowData(row, REF_NAME);
    const email = this.getRowData(row, EMAIL);
    const pax = this.getRowData(row, PAX);
    const lang = this.getRowData(row, LANG);
    const mobile = this.getRowData(row, MOBILE);
    const book: BookPlain = {
      bookId,
      deleted,
      checkIn,
      checkOut,
      roomNumber,
      tableNumber,
      bookType: this.parseBookType(bookType) || ONLY_BED,
      rawBookType: bookType,
      name,
      email,
      pax: parseInt(pax || "0") || 0,
      lang,
      rowIndex: index,
      mobile,
    };

    return book;
  }
}
