import { MealOrderDish } from "@/jpasta-sdk";
import _ from "lodash";
import {
  CellValue,
  IPopulateReportCellValueStrategy,
} from "@/components/Hotel/IPopulateReportCellValueStrategy";

export class AnnotationNearQuantityPopulateCellValueStrategy
  implements IPopulateReportCellValueStrategy
{
  populateCellValue(value: CellValue, odish: MealOrderDish): CellValue {
    const qty = odish.get("quantity") || 0;
    const currCellValue = _.cloneDeep(value);
    if (odish.isHalfPortion()) {
      if (_.size(odish.get("annotation")) > 0) {
        currCellValue.annotations.push(
          `x${qty} m.p. (${odish.get("annotation")})`
        );
      } else {
        currCellValue.halfPortions += qty;
      }
    } else {
      if (_.size(odish.get("annotation")) > 0) {
        currCellValue.annotations.push(`x${qty} (${odish.get("annotation")})`);
      } else {
        currCellValue.qty += qty;
      }
    }

    return currCellValue;
  }
}
