import generalMutations from "./generalMutations";
import generalActions from "./generalActions";
import _ from "lodash";
// import Parse from "parse";
// import { Book, BookPerson } from "@/jpasta-sdk";
export default {
  namespaced: true,
  state: {
    objects: {},
    className: "ExtraMealOrder",
    version: 0,
  },

  mutations: {
    ...generalMutations,
  },
  actions: {
    ...generalActions,
  },
};
