
import { Table, Hotel, Book, Turn } from "@/jpasta-sdk";
import mixins from "vue-typed-mixins";
import _, { Dictionary } from "lodash";
import moment, { Moment } from "moment-timezone";
type TableOption = {
  id: string;
  name: string;
  value?: string;
};
export default mixins().extend({
  props: {
    book: {
      type: Object as () => Book,
    },
    value: {
      type: String as () => string,
    },
    tables: {
      type: Array as () => Table[],
    },
    hotel: {
      type: Object as () => Hotel,
    },
  },
  data: function (): {
    options: TableOption[];
  } {
    return {
      options: [],
    };
  },
  mounted() {
    this.initTables();
  },
  methods: {
    isBusy(tableName: string) {
      return _.size(this.busyTables[tableName]) > 0;
    },
    initTables() {
      const tables = this.filterTables("");
      this.options = tables;
    },
    search(query: string): void {
      const tables = this.filterTables(query);
      this.options = tables;
    },

    filterTables(input: string): TableOption[] {
      const tables = _.filter(this.tableOptions, (t) => {
        return _.toLower(t.name).includes(_.toLower(input));
      });

      if (!tables.length && input.length) {
        return [
          {
            id: "new",
            name: "Crea: " + input,
            value: input,
          },
        ];
      } else {
        return _.sortBy(tables, (t) => (t.name ? parseInt(t.name) : Infinity));
      }
    },
    getTable(table: TableOption): Table {
      let t;
      if (table.id === "new") {
        t = new Table();
        t.set("hotel", this.hotel);
        t.set("name", table.value);
        this.$store.dispatch("tables/saveObj", t);
      } else {
        t = _.find(this.tables, (t) => {
          return t.get("name") === table.id;
        });
      }
      return t;
    },

    async onTableChange(table): Promise<void> {
      if (!table) return;
      const t = this.getTable(table);
      this.initTables();
      this.$emit("selected", t);
    },
    formatBusyOf(tableName: string) {
      const busy = this.busyTables[tableName];

      if (!busy) return "";
      return _.map(busy, (b) => {
        return `${b.start.format("DD MMM YYYY")} - ${b.end.format(
          "DD MMM YYYY"
        )}`;
      }).join("<br/>");
    },
    formatTurnName(name: string) {
      if (!name) return name;
      if (_.toLower(name).includes("turno")) return name;
      return `Turno ${name}`;
    },
    peopleInTurnCount(turn?: Turn) {
      return _.size(
        this.bookInSamePeriodByTurn[turn ? turn.get("localId") : undefined]
      );
    },
    formatPluralPeople(count: number) {
      if (count === 1) return "persona";
      else return "persone";
    },
  },
  computed: {
    busyTables: function (): Dictionary<{ start: Moment; end: Moment }[]> {
      return _.reduce(
        this.bookInSamePeriod,
        (res: Dictionary<{ start: Moment; end: Moment }[]>, b) => {
          const tableName = b.getTableNumber();
          if (!tableName) return res;

          res[tableName] = res[tableName] || [];
          res[tableName].push({
            start: moment(b.get("checkin")),
            end: moment(b.get("checkout")),
          });

          return res;
        },
        {}
      );
    },

    bookInSamePeriod: function (): Book[] {
      if (!this.book || !this.book.get("checkin") || !this.book.get("checkout"))
        return [];
      return _.filter(this.$store.state.books.objects, (b: Book) => {
        if (b.get("localId") === this.book.get("localId")) return false;

        const currBookCheckin = this.book.get("checkin");
        const currBookCheckout = this.book.get("checkout");
        const checkIn = b.get("checkin");
        const checkOut = b.get("checkout");
        if (!checkIn || !checkOut) return false;
        return (
          b.belongsTo(this.hotel, "hotel") &&
          (moment(checkIn).isBetween(
            currBookCheckin,
            currBookCheckout,
            undefined,
            "[]"
          ) ||
            moment(currBookCheckin).isBetween(
              checkIn,
              checkOut,
              undefined,
              "[]"
            ))
        );
      });
    },
    turnByTableName: function (): Dictionary<Turn | undefined> {
      return _.reduce(
        this.tables,
        (res: Dictionary<Turn | undefined>, t) => {
          const turn = t.get("turn");
          res[t.get("name")] = turn;

          return res;
        },
        {}
      );
    },
    bookInSamePeriodByTurn(): Dictionary<Book[]> {
      return _.groupBy([...this.bookInSamePeriod, this.book], (b) => {
        const tableName = b.getTableNumber();
        if (!tableName) return undefined;
        const turn = this.turnByTableName[tableName];
        if (!turn) return undefined;

        return turn.get("localId");
      });
    },

    tableOptions: function (): TableOption[] {
      let tables = _.filter(this.tables, (t) => {
        return !!(t.get("name") && t.get("name").length);
      });

      tables = _.uniqBy(tables, (t) => {
        return t.get("name");
      });

      return _.map(tables, (t) => {
        const isBusy = this.isBusy(t.get("name"));
        const turn = t.get("turn");
        const turnName = turn
          ? turn.get("name") || "Turno senza nome"
          : undefined;
        return {
          id: t.get("name"),
          isBusy: isBusy,
          name: `${t.get("name")}${
            turnName
              ? ` (${this.formatTurnName(turnName)} - ${this.peopleInTurnCount(
                  turn
                )} ${this.formatPluralPeople(
                  this.peopleInTurnCount(turn)
                )} in questo periodo)`
              : ""
          }`,
        };
      });
    },
  },
});
