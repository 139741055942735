<template>
  <v-popover
    :popoverClass="popoverClass"
    :targetClasses="targetClasses"
    :classes="classes ? classes : undefined"
    :trigger="trigger ? trigger : 'click'"
    :open="trigger === 'manual' ? show && open : false"
    :autoHide="autoHide"
    :placement="placement"
  >
    <!-- This will be the popover target (for the events and position) -->
    <slot name="target"></slot>
    <!-- This will be the content of the popover -->
    <template slot="popover">
      <div class="tooltip-container">
        <div v-if="title && title.length" class="header">
          <p class="title">{{ title }}</p>
          <span
            v-if="trigger === 'manual' && !hideClose"
            v-on:click.prevent.stop="open = false"
            class="close"
            >&#10005;</span
          >
        </div>
        <div v-if="content && content.length" class="body">
          <p class="content" v-html="content"></p>
        </div>
        <div
          v-on:click="actionTitleDisabled ? () => {} : $emit('actionclicked')"
          :class="{
            disabled: actionTitleDisabled,
          }"
          v-if="actionTitle && actionTitle.length"
          class="action"
        >
          {{ actionTitle }}
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
export default {
  props: {
    popoverClass: {},
    targetClasses: {},
    classes: {},
    placement: {
      default: "bottom",
    },
    actionTitle: {},
    actionTitleDisabled: {},
    hideClose: {},
    title: {},
    content: {},
    trigger: {},
    show: {},
    autoHide: {
      default: false,
    },
  },
  data: function () {
    return {
      open: true,
    };
  },
  mounted() {},
  methods: {
    forceOpen: function () {
      this.open = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";
.action {
  text-decoration: underline;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  color: map-get($colors, "primary");

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

$padding: 10px;
$opacity: 0.85;
.tooltip-container {
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(230, 230, 230, 1);
    padding: $padding;
    .close {
      margin-left: auto;
      padding-left: 10px;
      opacity: 0.5;
      cursor: pointer;
    }
    .title {
      opacity: $opacity;
      font-weight: bold;
    }
  }
  .body {
    padding: $padding;
    .content {
      opacity: $opacity;
    }
  }
}
</style>
