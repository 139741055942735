<template>
  <jmodal
    :size="size"
    :iconClose="true"
    :zindex="5001"
    v-on:close="close"
    :autoClose="false"
    ref="modal"
  >
    <div v-if="title" slot="header" class="search-wrapper">
      <h4>{{ title }}</h4>
    </div>
    <jinput
      :inputStyle="{
        'background-color': 'transparent',
      }"
      v-if="searchBar"
      ref="search-input"
      v-on:enterpressed="selectFirstItem"
      :value="inputValue"
      :search="true"
      :noBorder="true"
      v-on:input="onInput"
      type="text"
      class="hotel-input meal-name-input detail-text meal-entity-input"
      :placeholder="placeholder"
    ></jinput>
    <div v-if="searchBar" class="search-divider"></div>
    <div
      class="search-items-container"
      :class="{
        'mt-0': !searchBar,
      }"
    >
      <ul v-if="itemsToRender.length" class="items-list">
        <li
          class="list-item"
          v-on:click.prevent.stop="itemClicked(item)"
          :key="index"
          v-for="(item, index) in itemsToRender"
        >
          <slot>
            <span>{{ label ? item[label] : item.label }}</span>
            <span class="sub-title" v-if="subTitle">{{
              getSubTitle(item)
            }}</span>
          </slot>
        </li>
      </ul>
      <div
        :class="{
          clickable: noResultAction,
        }"
        v-else
        class="no-result"
      >
        <span
          v-html="noResultText ? noResultText : 'Nessun risultato trovato'"
        ></span>
        <jbutton
          v-if="noResultAction"
          v-on:click.prevent.stop="$emit('noresclicked')"
          >{{ noResultButtonText ? noResultButtonText : "Aggiungi" }}</jbutton
        >
      </div>
    </div>
  </jmodal>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    filterBy: {},
    items: {},
    label: {},
    placeholder: {},
    title: {},
    noResultText: {},
    noResultAction: {},
    noResultButtonText: {},
    subTitle: {},
    size: {},
    searchBar: {
      default: true,
    },
  },
  data: function () {
    return {
      inputValue: "",
    };
  },
  methods: {
    getSubTitle: function (item) {
      try {
        var keys = this.subTitle.split(".");
        var res = item;
        for (var key of keys) {
          res = res[key];
        }
        return res;
      } catch (e) {
        return "";
      }
    },
    selectFirstItem: function () {
      if (this.itemsToRender.length) this.itemClicked(this.itemsToRender[0]);
    },
    itemClicked: function (item) {
      this.close();
      this.$emit("itemclicked", item);
    },
    onInput: function (filterText) {
      this.inputValue = filterText;
      this.$emit("input", filterText);
    },
    open: function () {
      this.$refs.modal.open();
      this.$nextTick(() => {
        if (this.$refs["search-input"]) this.$refs["search-input"].focus();
      });
    },
    close: function () {
      this.inputValue = "";
      this.$refs.modal.close();
    },
  },
  mounted() {},
  computed: {
    itemsToRender: function () {
      return _.filter(this.items, (item) => {
        var filter = this.filterBy ? this.filterBy : "label";

        if (this.subTitle) {
          var subTitle = this.getSubTitle(item);

          var subTitleMatch =
            subTitle.length &&
            subTitle.toLowerCase().indexOf(this.inputValue.toLowerCase()) != -1;
        } else {
          var subTitleMatch = false;
        }

        return (
          item[filter] &&
          item[filter].length &&
          ((item[filter] &&
            item[filter].length &&
            item[filter].toLowerCase().indexOf(this.inputValue.toLowerCase()) !=
              -1) ||
            subTitleMatch)
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  display: flex;
  flex-direction: column;

  .sub-title {
    font-size: 0.9em;
    opacity: 0.7;
  }
}

.clickable {
  cursor: pointer;
}

.search-items-container {
  margin-top: 1em;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.search-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid lighten(#ccc, 15%);
  width: 100%;
}

ul.items-list {
  list-style-type: none;
  padding: 0 5px;
  width: 100%;
  margin: 0;
  li {
    padding: 8px 5px;
    cursor: pointer;

    /* border-top: 1px solid rgba(0,0,0,0.05); */
  }

  li:hover {
    border-radius: 4px;
    background-color: rgba(244, 68, 63, 1);
    color: white;
  }
}

.no-result {
  padding: 10px;
  width: 100%;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.no-result span {
  opacity: 0.7;
}

.no-result .jbutton {
  margin-top: 0.5em;
}
</style>
