
import moment from "moment-timezone";
import { Moment } from "moment";
import Vue from "vue";
import Parse from "parse";
import _ from "lodash";
import Calendar from "./Calendar.vue";
import {
  JPASTA_MEALS,
  JPASTA_COURSES,
  Dish,
  CalendarMeal,
  Jpasta,
  JPASTA_LANGUAGES,
  findJCourseByLocalId,
  findJMealByLocalId,
  BREAKFAST,
  Meal,
} from "@/jpasta-sdk";
import setScrollTopMixin from "@/mixins/setScrollTopMixin";
import AdviceMixin from "@/mixins/AdviceMixin";
import VisibleAreaHeightMixin from "@/mixins/VisibleAreaHeightMixin";
import mixins from "vue-typed-mixins";
import Jadvice from "@/components/ui/JAdvice.vue";
import scrollToElement from "scroll-to-element";
let altTimeout;
let altPressed = false;

let cancTimeout;
let cancPressed = false;

let massCancelled = false;

const MILLISECONDS_IN_ONE_HOUR = 1000 * 60 * 60;
const MILLISECONDS_IN_ONE_MINUTE = 1000 * 60;
const MINUTES_IN_ONE_DAY = 60 * 24;

var italian = _.find(JPASTA_LANGUAGES, (language) => {
  return language.code == "it";
});

export default mixins(AdviceMixin, VisibleAreaHeightMixin).extend({
  mixins: [setScrollTopMixin],
  components: {
    Calendar: Calendar,
    Jadvice,
  },
  data: function (): {
    selectedDishes: { [localId: string]: any };
    copiedDishes: any[];
    hasPastedDetail: boolean;
    loadingForSync: boolean;
    saveLoading: boolean;
    saved: boolean;
    mouseDownOnCell: boolean;
    filters: any;
    printMenuConfig: {
      language: any;
      peopleNumber: number;
      selectedMeal: undefined | number;
    };
    languages: any;
    objectDetailActions: any;
    context2d: any;
    detailCopy: any;
    selected: any;
    month: number;
    year: number;
    day: number;
    firstDayOfSelection: any;
    lastDayOfSelection: any;
    firstDayOfCopy: any;
    lastDayOfCopy: any;
    detailObject: any;
    searchableSelect: any;
  } {
    return {
      hasPastedDetail: false,
      copiedDishes: [],
      selectedDishes: {},
      loadingForSync: true,

      filters: {
        showDishImage: false,
      },
      printMenuConfig: {
        language: italian,
        peopleNumber: 1,
        selectedMeal: Infinity,
      },

      languages: JPASTA_LANGUAGES,

      objectDetailActions: {
        ADD_MEAL: "ADD_MEAL",
        ADD_DISH: "ADD_DISH",
        DELETE_MEAL: "DELETE_MEAL",
        DELETE_DISH: "DELETE_DISH",
        PASTE_DETAIL: "PASTE_DETAIL",
        PASTE_DETAIL_SEQ: "PASTE_DETAIL_SEQ",
        COPY_DETAIL: "COPY_DETAIL",
        COPY_DETAIL_SEQ: "COPY_DETAIL_SEQ",
        CLEAR_SELECTION: "CLEAR_SELECTION",
        ELEMENT_DRAGGED: "ELEMENT_DRAGGED",
        SET_MEAL_TIME: "SET_MEAL_TIME",
        SET_MEAL_END_TIME: "SET_MEAL_END_TIME",
        SET_MEAL_FROM_INSTANT: "SET_MEAL_FROM_INSTANT",
        SET_MEAL_TO_INSTANT: "SET_MEAL_TO_INSTANT",
        SET_MEAL_BOOKING_WINDOW: "SET_MEAL_BOOKING_WINDOW",
        COPY_DISHES: "COPY_DISHES",
        PASTE_DISHES: "PASTE_DISHES",
      },

      context2d: null,
      mouseDownOnCell: false,
      detailCopy: null,
      selected: "",
      saved: true,
      saveLoading: false,
      month: 0,
      year: 2018,
      day: 1,
      firstDayOfSelection: null,
      lastDayOfSelection: null,
      firstDayOfCopy: null,
      lastDayOfCopy: null,
      detailObject: {
        meals: [],
      },
      searchableSelect: {
        "meal-search": false,
        "dish-search": false,
      },
    };
  },
  mounted(): any {
    window.addEventListener("keyup", this.handleKeyPressed);
    window.addEventListener("mouseup", this.mouseUp);

    window.addEventListener("beforeunload", this.beforeUnload);

    setTimeout(() => {
      try {
        if (this.$route.query.firstDay && this.$route.query.lastDay) {
          this.firstDayOfSelection = {
            utcDate: moment(
              parseInt(this.$route.query.firstDay as string, 10)
            ).utc(),
          };

          let calendarMeals = this.$store.getters[
            "calendarMeals/getByDayAndHotel"
          ](this.firstDayOfSelection.utcDate, this.hotel);

          this.detailObject = {
            meals: this.buildDetailObjectFromCalendarMeals(calendarMeals),
          };

          this.lastDayOfSelection = {
            utcDate: moment(
              parseInt(this.$route.query.lastDay as string, 10)
            ).utc(),
          };

          this.selectDayRange(this.lastDayOfSelection);

          this.$nextTick(() => {
            this.openCalendarDetail();
            console.log("this.detailObject", this.detailObject);
          });
        }
      } catch (e) {}
      this.loadingForSync = false;
      this.setScrollTop();
    }, 300);
  },

  beforeDestroy: function (): any {
    window.removeEventListener("keyup", this.handleKeyPressed);
    window.removeEventListener("mouseup", this.mouseUp);

    window.removeEventListener("beforeunload", this.beforeUnload);
  },
  methods: {
    isSelectedForCopy: function (dish) {
      return !!this.selectedDishes[dish.dishLocalId];
    },
    selectDishForCopy: function (dish, mealLocalId, value) {
      if (value) {
        this.selectedDishes[dish.dishLocalId] = {
          dish: {
            localId: dish.dishLocalId,
          },
          mealLocalId: mealLocalId,
        };
      } else {
        delete this.selectedDishes[dish.dishLocalId];
      }
      this.selectedDishes = {
        ...this.selectedDishes,
      };
    },
    pasteDishes: function () {
      _.forEach(this.copiedDishes, ({ dish, mealLocalId }) => {
        this.dispatchObjectDetailActions(this.objectDetailActions.ADD_MEAL, {
          meal: {
            localId: mealLocalId,
          },
        });

        const mealIndex = _.findIndex(
          this.detailObject.meals,
          (m: any) => m.mealIndex === mealLocalId
        );

        this.dispatchObjectDetailActions(this.objectDetailActions.ADD_DISH, {
          dish: dish,
          mealIndex: mealIndex,
        });
      });
    },
    copyDishes: function () {
      this.copiedDishes = _.cloneDeep(_.values(this.selectedDishes));
      if (_.size(this.copiedDishes))
        this.$message({
          message: `Piatti copiati negli appunti`,
          type: "success",
          duration: 4000,
          customClass: "bottomNotification",
          showClose: true,
        });
    },

    beforeUnload: function (e) {
      if (!this.saved) {
        const confirmationMessage =
          "Hai modificato il menù, ma non hai salvato. Continuando perderai le modifiche, vuoi procedere?";
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage;
      }
    },
    stepAfterSave: function (): any {
      this.$router
        .push({
          name: "BookList",
          params: {
            hotelid: this.$route.params.hotelid,
          },
          query: {
            tutorial: "1",
          },
        })
        .catch((e) => {});
    },
    stepAfterHour: function (): any {
      this.nextTutorialStep();
    },
    stepAfterBookingWindow: function (): any {
      this.nextTutorialStep();
    },
    getTomorrowMoment: function (): any {
      return moment().add(1, "day");
    },
    isTomorrow: function (day): any {
      return moment().add(1, "day").isSame(day, "day");
    },
    selectingOnDisabled: function (event): Promise<any> {
      return new Promise((resolve, reject) => {
        this.checkIfNeedConfirmation()
          .then(() => {
            this.markDetailAsSaved();
            switch (event.type) {
              case "single":
                this.onSelectSingleDay(event.day);

                break;

              case "range":
                this.onSelectRangeDay({
                  first: event.first,
                  last: event.last,
                });
                break;
            }
            resolve(true);
          })
          .catch((e) => {
            let res;

            if (
              this.firstDayOfSelection.utcDate.isSame(
                this.lastDayOfSelection.utcDate
              )
            ) {
              res = {
                day: this.firstDayOfSelection.utcDate,
              };
            } else {
              res = {
                first: this.firstDayOfSelection.utcDate,
                last: this.lastDayOfSelection.utcDate,
              };
            }
            resolve(res);
          });
      });
      // this.clearSelection();
    },
    getMealsOf: function (day): any {
      return this._getMealsOf(day);
    },
    getImagesOf(dish): any {
      return _.filter(dish.get("images"), (image) => {
        return !!image.get("thumb");
      });
    },
    handleDetailControlCommand(command): any {
      switch (command) {
        case "close":
          return this.clearSelection();
        case "deleteAllSelectedMeals":
          return this.deleteAllSelectedCMeals();

        case "copy":
          return this.dispatchObjectDetailActions(
            this.objectDetailActions.COPY_DETAIL
          );
        case "copySequence":
          return this.dispatchObjectDetailActions(
            this.objectDetailActions.COPY_DETAIL_SEQ
          );
          break;

        case "paste":
          return this.dispatchObjectDetailActions(
            this.objectDetailActions.PASTE_DETAIL
          );
        case "pasteSequence":
          return this.dispatchObjectDetailActions(
            this.objectDetailActions.PASTE_DETAIL_SEQ
          );
          break;
        case "copyDishes":
          return this.dispatchObjectDetailActions(
            this.objectDetailActions.COPY_DISHES
          );
          break;
        case "pasteDishes":
          return this.dispatchObjectDetailActions(
            this.objectDetailActions.PASTE_DISHES
          );
          break;
        case "print":
          return this.configPrintMenu();
        case "toggleShowDishImage":
          return this.toggleFilter("showDishImage");
      }
    },
    toggleFilter(filter): any {
      this.filters[filter] = !this.filters[filter];
      this.filters = _.cloneDeep(this.filters);
    },
    getDishesList(mealIndex): any {
      const filteredDishes = _.filter(
        this.$store.state.dishes.objects,
        (dish) => {
          if (dish.get("deleted")) return false;

          if (dish.getType() !== "dish") return;

          if (dish.get("courseIndex") === undefined) return false;
          const jCourse = findJCourseByLocalId(dish.get("courseIndex"));
          try {
            var courseInDetailObject = _.find(
              this.detailObject.meals[mealIndex].courses,
              (course) => {
                return course && course.courseIndex == dish.get("courseIndex");
              }
            );

            var alreadySelected = !!_.find(courseInDetailObject.dishes, (d) => {
              return d.dishLocalId === dish.get("localId");
            });
          } catch (e) {
            var alreadySelected = false;
          }

          return dish.belongsTo(this.hotel) && jCourse && !alreadySelected;
        }
      );

      const mappedDishes = _.map(filteredDishes, (dish) => {
        return {
          label: dish.get("name") ? dish.get("name").it : "",
          localId: dish.get("localId"),
          course: findJCourseByLocalId(dish.get("courseIndex")),
        };
      });

      return _.sortBy(mappedDishes, (dish) => {
        return dish.course ? dish.course.name.it : "";
      });
    },

    toggleSearchablePopover: function (ref, index): any {
      this.focusSearchableSelect(ref, index);
    },
    focusSearchableSelect: function (ref, index?: number): any {
      if (this.$mq !== "xs" && this.$mq !== "sm") {
        if (index !== undefined) {
          (this.$refs[ref] as HTMLInputElement)[index].focus();
        } else {
          var isArray = Array.isArray(this.$refs[ref]);

          if (
            (!isArray && this.$refs[ref]) ||
            (isArray && (this.$refs[ref] as HTMLInputElement[])[0])
          ) {
            isArray
              ? (this.$refs[ref] as HTMLInputElement[])[0].focus()
              : (this.$refs[ref] as any).focus();
          }
        }
      }
    },
    configPrintMenu: function (): any {
      (this.$refs["config-print-modal"] as any).open();

      this.$nextTick(() => {
        (this.$refs["select-print-language"] as any).focus();
      });
    },
    printCurrentMenu: function (): any {
      if (!this.firstDayOfSelection) return; // TODO notify error

      let date = this.firstDayOfSelection.utcDate.format("ddd DD MMM YYYY");

      var locale =
        this.printMenuConfig.language && this.printMenuConfig.language.code
          ? this.printMenuConfig.language.code
          : "it";

      var peopleNumber = this.printMenuConfig.peopleNumber || 1;

      var html = `<html>
        <style>
          @media print {
            @page { size: auto;  margin: 0mm; }
            p {
              margin: 0;
              padding: 0;
              margin-bottom: 0;
              margin-top: 0;
              line-height: 1;
            }

            .meal-name {
              font-size: 1.5em;
            }

            .dish-wrapper {
              display: flex;
              align-items: center;
            }

          }


        </style>

        <div style="margin-bottom: 1em;">
          <p>${date}</p>
          <p>
            Numero tavolo ____
          </p>
        </div>
      `;

      const filteredMeals = _.filter(
        this.detailObject.meals,
        (m) =>
          this.printMenuConfig.selectedMeal === Infinity ||
          m.mealIndex === this.printMenuConfig.selectedMeal
      );

      _.forEach(filteredMeals, (meal, mealIndex) => {
        let jMeal = findJMealByLocalId(meal.mealIndex);

        if (!jMeal) return;

        html += `<div style="margin-bottom: 1em;">`;
        html += `<p class="meal-name"><strong>${Jpasta.getLocalizedName(
          jMeal.name,
          locale,
          ""
        )}</strong>:</p>`;
        _.forEach(meal.courses, (course, courseIndex) => {
          if (!course) return;

          const jCourse = findJCourseByLocalId(course.courseIndex);

          html += `<p style="font-style: italic; margin-top: 2em;">${Jpasta.getLocalizedName(
            jCourse ? jCourse.name : "",
            locale,
            ""
          )}</p>`;

          _.forEach(course.dishes, (dish) => {
            var checkboxes = "";
            for (var i = 0; i < peopleNumber; i++) {
              checkboxes += '<input type="checkbox">';
            }
            const dishObject =
              this.$store.state.dishes.objects[dish.dishLocalId];
            html += `
            <div style="margin-top: 8px;" class="dish-wrapper">
            <!-- ${checkboxes} -->
            <div>
              _____
            </div>
            <div>
              <p style="margin-left: 0.5em; font-weight: bold">${Jpasta.getLocalizedName(
                dishObject.get("name"),
                locale,
                ""
              )}</p>

              <p style="margin-left: 0.5em; max-width: 300px;">
              ${Jpasta.getLocalizedName(
                dishObject.get("description"),
                locale,
                ""
              )}
              </p>
            </div>


            </div>
            `;
          });
        });
        html += "</div>";
      });

      html += "</html>";

      (this.$refs["print-container"] as HTMLElement).innerHTML = html;

      this.$nextTick(() => {
        window.print();

        //this.$refs["print-container"].innerHTML = "";
      });
    },
    calculateInputSize: function (mealIndex?, value?): any {
      const els: NodeListOf<HTMLInputElement> = document.querySelectorAll(
        ".el-date-editor--time-select input"
      );
      _.forEach(els, (el) => {
        el.size = 5;
        el.style.textAlign = "center";
      });
      // if (
      //   !this.$refs[`time-select-${mealIndex}`] ||
      //   !this.$refs[`time-select-${mealIndex}`][0]
      // )
      //   return;

      // var el = this.$refs[`time-select-${mealIndex}`][0].$el;

      // var input = el.getElementsByClassName("el-input__inner")[0];

      // var fontSize = window
      //   .getComputedStyle(input, null)
      //   .getPropertyValue("font-size");

      // var fontFamily = window
      //   .getComputedStyle(input, null)
      //   .getPropertyValue("font-family");

      // if (!this.context2d) {
      //   var canvas = document.createElement("canvas");
      //   this.context2d = canvas.getContext("2d");
      // }
      // this.context2d.font = fontSize + " " + fontFamily;

      // input.style.width = this.context2d.measureText(value).width + 25 + "px";
    },
    isSelected: function (day): any {
      if (!this.firstDayOfSelection) return false;

      if (!this.lastDayOfSelection)
        return day.utcDate.isSame(this.firstDayOfSelection.utcDate);

      return (
        (day.utcDate.isSameOrAfter(this.firstDayOfSelection.utcDate) &&
          day.utcDate.isSameOrBefore(this.lastDayOfSelection.utcDate)) ||
        (day.utcDate.isSameOrAfter(this.lastDayOfSelection.utcDate) &&
          day.utcDate.isSameOrBefore(this.firstDayOfSelection.utcDate))
      );
    },

    size: function (collection): any {
      return _.size(collection);
    },
    getCourseName: function (courseLocalId): any {
      let course = findJCourseByLocalId(courseLocalId);

      return course ? course.name.it : "";
    },
    getMealName: function (mealLocalId): any {
      let meal = findJMealByLocalId(mealLocalId);

      return meal ? meal.name.it : "";
    },
    pad: function (number): any {
      number = typeof number == "number" ? number.toString() : number;

      return number < 10 ? "0" + number : number;
    },
    getLocalizedName: function (entity): any {
      try {
        return Jpasta.getLocalizedName(entity.get("name"), "it", "Nessun nome");
      } catch (e) {
        return "";
      }
    },

    dispatchObjectDetailActions: async function (
      action,
      payload: any = {}
    ): Promise<any> {
      var mutated = false;

      switch (action) {
        case this.objectDetailActions.COPY_DISHES:
          this.copyDishes();
          break;
        case this.objectDetailActions.PASTE_DISHES:
          this.pasteDishes();
          break;
        case this.objectDetailActions.ELEMENT_DRAGGED:
          var evt = payload.evt;

          if (evt.oldIndex !== evt.newIndex) {
            mutated = true;
          }

          break;

        case this.objectDetailActions.ADD_MEAL:
          var meal = payload.meal;

          if (this.isTutorial && this.tutorialStep === 1) {
            (this.$refs["meal-search-popover"] as any).doToggle();
          }

          if (
            !this.detailObject.meals ||
            !_.find(this.detailObject.meals, (m) => {
              return m.mealIndex === meal.localId;
            })
          ) {
            this.detailObject.meals = this.detailObject.meals || [];

            let mealHours = this.hotel.getDefaultMealHour(meal.localId);
            let toInstantDays = mealHours >= 4 ? 0 : 1;
            let toInstantHours =
              mealHours >= 4 ? mealHours - 4 : 24 - (4 - mealHours);

            this.detailObject.meals.unshift({
              hours: this.hotel.getDefaultMealHour(meal.localId),
              minutes: this.hotel.getDefaultMealMinutes(meal.localId),
              endHours: this.hotel.getDefaultMealHour(meal.localId),
              endMinutes: this.hotel.getDefaultMealMinutes(meal.localId),
              fromInstant: [
                1,
                this.hotel.getDefaultMealHour(meal.localId),
                this.hotel.getDefaultMealMinutes(meal.localId),
              ],
              toInstant: [
                toInstantDays,
                toInstantHours,
                this.hotel.getDefaultMealMinutes(meal.localId),
              ],
              fromMinutes: 24 * 60,
              toMinutes: 4 * 60,
              mealIndex: meal.localId,
              bookingWindow: [-192, 0],
            });
            mutated = true;
          }

          this.$nextTick(() => {
            if (this.isTutorial && this.tutorialStep === 1)
              this.nextTutorialStep();
            this.calculateInputSize();
          });

          break;

        case this.objectDetailActions.ADD_DISH:
          var dish = payload.dish;
          var mealIndex = payload.mealIndex;
          if (this.isTutorial && this.tutorialStep === 4) {
            this.nextTutorialStep();

            this.$nextTick(() => {
              scrollToElement("#saveButton");
            });

            (this.$refs["dish-search-popover"] as HTMLElement)[
              mealIndex
            ].doToggle();
          }

          var dishObject = this.$store.state.dishes.objects[dish.localId];
          console.log("dishObject", dishObject);
          var courseIndex = dishObject.get("courseIndex");

          var jCourse = findJCourseByLocalId(courseIndex);
          console.log("jCourse", jCourse);
          if (
            jCourse &&
            mealIndex !== undefined &&
            dish &&
            (!this.detailObject.meals[mealIndex].courses ||
              !this.detailObject.meals[mealIndex].courses[jCourse.pos] ||
              !this.detailObject.meals[mealIndex].courses[jCourse.pos].dishes ||
              !_.find(
                this.detailObject.meals[mealIndex].courses[jCourse.pos].dishes,
                (d) => {
                  return d.dishLocalId === dish.localId;
                }
              ))
          ) {
            this.detailObject.meals[mealIndex].courses =
              this.detailObject.meals[mealIndex].courses || {};

            this.detailObject.meals[mealIndex].courses[jCourse.pos] = this
              .detailObject.meals[mealIndex].courses[jCourse.pos] || {
              courseIndex: courseIndex,
              dishes: [],
            };

            if (
              !_.find(
                this.detailObject.meals[mealIndex].courses[jCourse.pos].dishes,
                (d) => {
                  return d.dishLocalId === dish.localId;
                }
              )
            ) {
              this.detailObject.meals[mealIndex].courses[
                jCourse.pos
              ].dishes.push({
                dishLocalId: dish.localId,
              });

              console.log(
                this.detailObject.meals[mealIndex].courses[jCourse.pos].dishes
              );
              mutated = true;
            }
          }

          break;
        case this.objectDetailActions.DELETE_MEAL:
          var mealIndex = payload.mealIndex;
          this.detailObject.meals.splice(mealIndex, 1);

          mutated = true;
          break;
        case this.objectDetailActions.DELETE_DISH:
          var mealIndex = payload.mealIndex;
          var courseIndex = payload.courseIndex;
          var dishIndex = payload.dishIndex;

          this.detailObject.meals[mealIndex].courses[courseIndex].dishes.splice(
            dishIndex,
            1
          );

          if (
            !this.detailObject.meals[mealIndex].courses[courseIndex].dishes
              .length
          ) {
            delete this.detailObject.meals[mealIndex].courses[courseIndex];
          }

          mutated = true;
          break;
        case this.objectDetailActions.PASTE_DETAIL:
          if (
            this.detailCopy &&
            this.detailCopy.meals &&
            _.size(this.detailCopy.meals)
          ) {
            this.detailObject = this.detailCopy;
            this.hasPastedDetail = true;
            mutated = true;
          }
          break;

        case this.objectDetailActions.PASTE_DETAIL_SEQ:
          //Incolla una selezione di x giorni in modo tale che i pasti del primo giorno della selezione di copia,
          //diventino i piatti del primo giorno della selezione attuale, i secondi pasti diventino i secondi pasti e così via

          //Prendo il primo giorno della selezione
          let firstDay = this.getFirstDayOfSelection();
          //Prendo l'ultimo giorno selezionato
          let lastDay = this.getLastDayOfSelection();
          //Prendo il primo giorno da copiare
          let firstDayOfCopy = this.firstDayOfCopy;
          //Prendo l'ultimo giorno da copiare
          let lastDayOfCopy = this.lastDayOfCopy;

          //iteratore al giorno da salvare nella selezione attuale
          let currDay = moment(firstDay.utcDate);

          //iteratore al giorno da copiare nel currDay
          let itDay = moment(firstDayOfCopy.utcDate);

          //Itero fino a che non esaurisco gli elementi della selezione di copia o di quella attuale e copio giorno per giorno i pasti
          //dalla selezione di copia a quella attuale

          let toDelete: any = [];
          let toSave: any = [];
          try {
            await this.checkIfHasPreviouseMeals(firstDay, lastDay);
          } catch (e) {
            return;
          }
          while (
            currDay.isSameOrBefore(lastDay.utcDate) &&
            itDay.isSameOrBefore(lastDayOfCopy.utcDate)
          ) {
            let calendarMeals = this.$store.getters[
              "calendarMeals/getByDayAndHotel"
            ](itDay, this.hotel);
            const res = await this.saveDetail(
              { utcDate: currDay },
              { utcDate: currDay },
              this.buildDetailObjectFromCalendarMeals(calendarMeals),
              true,
              true
            );
            // if (!res) continue;

            toDelete = [...toDelete, ...res.toDelete];
            toSave = [...toSave, ...res.toSave];
            currDay = moment(currDay).add(1, "days");
            itDay = moment(itDay).add(1, "days");
          }

          await Promise.all([
            this.$store.dispatch("calendarMeals/deleteObjBatch", toDelete),
            this.$store.dispatch("calendarMeals/saveObjBatch", toSave),
          ]);
          this.markDetailAsSaved();

          break;

        case this.objectDetailActions.COPY_DETAIL:
          this.detailCopy = _.cloneDeep(this.detailObject);
          break;
        case this.objectDetailActions.COPY_DETAIL_SEQ:
          this.firstDayOfCopy = this.firstDayOfSelection;
          this.lastDayOfCopy = this.lastDayOfSelection;
          if (this.firstDayOfCopy && this.lastDayOfCopy)
            this.$message({
              message: `I giorni nel periodo ${this.firstDayOfCopy.utcDate.format(
                "DD MMMM YYYY"
              )} - ${this.lastDayOfCopy.utcDate.format(
                "DD MMMM YYYY"
              )} sono stati copiati negli appunti.`,
              type: "success",
              duration: 4000,
              customClass: "bottomNotification",
              showClose: true,
            });
          break;
        case this.objectDetailActions.CLEAR_SELECTION:
          (this.firstDayOfSelection = null), (this.lastDayOfSelection = null);

          this.detailObject.meals = [];
          this.copiedDishes = [];
          this.selectedDishes = {};
          this.$router
            .push({
              query: {
                ...(this.isTutorial ? { tutorial: "1" } : {}),
              },
            })
            .catch((e) => {});

          (this.$refs.layout as any).close();
          (this.$refs.calendar as any).clear();

          this.detailObject = _.cloneDeep(this.detailObject);

          this.markDetailAsSaved();

          break;
        case this.objectDetailActions.SET_MEAL_TIME:
          var time = payload.time.split(":");

          var hours = parseInt(time[0]);
          var minutes = parseInt(time[1]);

          var mealHours = hours;
          var toInstantDays = mealHours >= 4 ? 0 : 1;
          var toInstantHours =
            mealHours >= 4 ? mealHours - 4 : 24 - (4 - mealHours);

          var mealIndex = payload.mealIndex;

          this.detailObject.meals[mealIndex].hours = hours;

          this.detailObject.meals[mealIndex].minutes = minutes;

          this.detailObject.meals[mealIndex].fromInstant = [1, hours, minutes];
          this.detailObject.meals[mealIndex].toInstant = [
            toInstantDays,
            toInstantHours,
            minutes,
          ];

          mutated = true;

          // this.$nextTick(() => {
          //   if (this.tutorialStep === 2) this.nextTutorialStep();
          // });
          break;
        case this.objectDetailActions.SET_MEAL_END_TIME:
          var time = payload.time.split(":");

          var hours = parseInt(time[0]);
          var minutes = parseInt(time[1]);
          var mealIndex = payload.mealIndex;
          this.detailObject.meals[mealIndex].endHours = hours;

          this.detailObject.meals[mealIndex].endMinutes = minutes;

          mutated = true;

          // this.$nextTick(() => {
          //   if (this.tutorialStep === 2) this.nextTutorialStep();
          // });
          break;
        case this.objectDetailActions.SET_MEAL_FROM_INSTANT:
          var mealIndex = payload.mealIndex;
          this.detailObject.meals[mealIndex].fromInstant = payload.instant;
          mutated = true;
          break;
        case this.objectDetailActions.SET_MEAL_TO_INSTANT:
          var mealIndex = payload.mealIndex;
          this.detailObject.meals[mealIndex].toInstant = payload.instant;
          mutated = true;
          break;
        case this.objectDetailActions.SET_MEAL_BOOKING_WINDOW:
          // var mealIndex = payload.mealIndex;
          // let fromMinutes = parseInt(payload.value[0]) * 15;
          // let toMinutes = parseInt(payload.value[1]) * 15;
          // this.detailObject.meals[mealIndex].fromMinutes = fromMinutes;
          // this.detailObject.meals[mealIndex].toMinutes = toMinutes;
          // this.detailObject.meals[mealIndex].bookingWindow = payload.value;

          mutated = true;
          break;
      }

      if (mutated) {
        this.detailObject = _.cloneDeep(this.detailObject);
        this.saved = false;
        this.$router
          .push({
            query: {
              ...this.$route.query,
              saved: "0",
            },
          })
          .catch((e) => {});
      }
    },

    goToAddDish: async function (): Promise<any> {
      try {
        await this.checkIfNeedConfirmationGoToAddDish();
      } catch (e) {
        return;
      }
      if (!this.saved)
        await this.saveDetail(
          this.getFirstDayOfSelection(),
          this.getLastDayOfSelection(),
          this.detailObject.meals,
          false,
          true
        );

      this.$router
        .push({
          name: "HotelDishes",
        })
        .catch((e) => {});
    },
    getFromStore: function (namespace, localId): any {
      return this.$store.state[namespace].objects[localId];
    },

    getToHourString: function (meal): any {
      let fromInstant = meal.fromInstant;
      let hours = meal.mealIndex === BREAKFAST ? meal.endHours : meal.hours;
      let minutes =
        meal.mealIndex === BREAKFAST ? meal.endMinutes : meal.minutes;
      let toMinutes = Math.abs(meal.bookingWindow[1] * 15);

      let mealMoment = moment({
        hour: hours,
        minute: minutes,
      });

      let fromMoment = moment(mealMoment).subtract(toMinutes, "minutes");

      let minutesInMealDay = moment(mealMoment).diff(
        moment(mealMoment).startOf("day"),
        "minutes"
      );

      let minutesInOthersDay = toMinutes - minutesInMealDay;

      let dayBefore = 0;
      while (minutesInOthersDay > 0) {
        dayBefore++;
        minutesInOthersDay = minutesInOthersDay - MINUTES_IN_ONE_DAY;
      }

      let newHour = fromMoment.hours();
      let newMinute = fromMoment.minutes();

      return (
        (newHour === 1 ? "all " : "alle ") +
        newHour +
        ":" +
        (newMinute >= 10 ? newMinute : "0" + newMinute) +
        (dayBefore
          ? dayBefore === 1
            ? " del giorno prima"
            : " di " + dayBefore + " giorni prima"
          : " dello stesso giorno")
      );
    },
    getFromHourString: function (meal): any {
      let fromInstant = meal.fromInstant;
      let hours = meal.mealIndex === BREAKFAST ? meal.endHours : meal.hours;
      let minutes =
        meal.mealIndex === BREAKFAST ? meal.endMinutes : meal.minutes;
      console.log("meal.mealIndex", meal.mealIndex, hours, ":", minutes);
      let fromMinutes = Math.abs(meal.bookingWindow[0] * 15);

      let mealMoment = moment({
        hour: hours,
        minute: minutes,
      });

      let fromMoment = moment(mealMoment).subtract(fromMinutes, "minutes");

      let minutesInMealDay = moment(mealMoment).diff(
        moment(mealMoment).startOf("day"),
        "minutes"
      );

      let minutesInOthersDay = fromMinutes - minutesInMealDay;

      let dayBefore = 0;
      while (minutesInOthersDay > 0) {
        dayBefore++;
        minutesInOthersDay = minutesInOthersDay - MINUTES_IN_ONE_DAY;
      }

      let newHour = fromMoment.hours();
      let newMinute = fromMoment.minutes();

      return (
        (newHour === 1 ? "dall " : "dalle ") +
        newHour +
        ":" +
        (newMinute >= 10 ? newMinute : "0" + newMinute) +
        (dayBefore
          ? dayBefore === 1
            ? " del giorno prima"
            : " di " + dayBefore + " giorni prima"
          : " dello stesso giorno")
      );
    },

    confirmBatchDeletion: function (firstDate: Moment, lastDate: Moment) {
      return new Promise<void>((resolve, reject) => {
        return this.$dialog
          .confirm(
            `Sei sicuro di voler cancellare tutti i pasti nel periodo ${firstDate.format(
              "DD MMM YYYY"
            )} - ${lastDate.format("DD MMM YYYY")}`,
            {
              okText: "Continua",
              cancelText: "Annulla",
            }
          )
          .then(function () {
            return resolve();
          })
          .catch(function () {
            return reject();
          });
      });
    },

    deleteAllSelectedCMeals: async function (): Promise<void> {
      let firstDay = moment(this.firstDayOfSelection.utcDate);
      let lastDay = moment(this.lastDayOfSelection.utcDate);
      if (!firstDay || !lastDay) return;
      if (firstDay.isBefore(lastDay)) {
        var firstDate = moment(firstDay);
        var lastDate = moment(lastDay);
      } else {
        var firstDate = moment(lastDay);
        var lastDate = moment(firstDay);
      }

      try {
        await this.confirmBatchDeletion(firstDate, lastDate);
      } catch (e) {
        return;
      }

      const calendarMeals = _.filter(
        this.$store.state.calendarMeals.objects,
        (cm) => {
          return moment(cm.get("date")).isBetween(
            moment(firstDate).startOf("day"),
            moment(lastDate).endOf("day"),
            undefined,
            "[]"
          );
        }
      );

      if (calendarMeals.length)
        this.$store.dispatch("calendarMeals/deleteObjBatch", calendarMeals);
    },
    handleKeyPressed: function (e): any {
      /*if (e.keyCode === 8 || e.keyCode === 46) {
        //CANC or BACKSPACE
        // if (cancPressed) {
        //   //DELETE
        //   this.deleteAllSelectedCMeals();
        //   massCancelled = true;
        // }

        // else {
        if (cancTimeout) clearTimeout(cancTimeout);
        cancPressed = true;
        cancTimeout = setTimeout(() => {
          cancPressed = false;

          if (!massCancelled) {
            this.$msg(
              `Premi due volte rapidamente ${
                e.keyCode === 8 ? "BACKSPACE" : "CANC"
              } per cancellare tutti i pasti nei giorni selezionati`
            );
          }

          massCancelled = false;
        }, 150);
        // }
      }*/ if (e.keyCode === 18) {
        //ALT

        altPressed = true;
        if (altTimeout) clearTimeout(altTimeout);
        altTimeout = setTimeout(() => {
          altPressed = false;
          altTimeout = undefined;
        }, 100);
      } else if (e.keyCode === 27) {
        //ESC
        this.clearSelection();
      } else if (e.keyCode === 67 && e.ctrlKey && !e.altKey) {
        //ctrl + C
        this.dispatchObjectDetailActions(this.objectDetailActions.COPY_DETAIL);
      } else if (e.keyCode === 86 && e.ctrlKey && !e.altKey) {
        //ctrl + V

        this.dispatchObjectDetailActions(this.objectDetailActions.PASTE_DETAIL);
      } else if (e.keyCode === 67 && !e.ctrlKey && (e.altKey || altPressed)) {
        //alt + C

        this.dispatchObjectDetailActions(
          this.objectDetailActions.COPY_DETAIL_SEQ
        );
      } else if (e.keyCode === 86 && !e.ctrlKey && e.altKey) {
        //alt + V
        this.dispatchObjectDetailActions(
          this.objectDetailActions.PASTE_DETAIL_SEQ
        );
      } else if (e.keyCode === 77 && e.ctrlKey) {
        //ctrl + P
        this.configPrintMenu();
      } else if (e.keyCode === 73 && e.ctrlKey) {
        //ctrl + I
        this.toggleFilter("showDishImage");
      }
    },

    checkIfNeedConfirmation: function (): any {
      if (!this.saved) {
        return this.$dialog
          .confirm(
            "Hai modificato il menù, ma non hai salvato. Continuando perderai le modifiche, vuoi procedere?",
            {
              okText: "Continua",
              cancelText: "Annulla",
            }
          )
          .then(function () {
            return Promise.resolve();
          })
          .catch(function () {
            return Promise.reject();
          });
      } else {
        return Promise.resolve();
      }
    },
    checkIfNeedConfirmationGoToAddDish: function (): any {
      if (!this.saved) {
        return this.$dialog
          .confirm(
            "Continuando il sistema salverà in automatico e ti manderà alla lista dei piatti, vuoi procedere?",
            {
              okText: "Continua",
              cancelText: "Annulla",
            }
          )
          .then(function () {
            return Promise.resolve();
          })
          .catch(function () {
            return Promise.reject();
          });
      } else {
        return Promise.resolve();
      }
    },

    clearSelection: function (): any {
      this.checkIfNeedConfirmation().then(() => {
        this.dispatchObjectDetailActions(
          this.objectDetailActions.CLEAR_SELECTION
        );
      });
    },

    getFirstDayOfSelection(): any {
      return this.firstDayOfSelection.utcDate.isBefore(
        this.lastDayOfSelection.utcDate
      )
        ? this.firstDayOfSelection
        : this.lastDayOfSelection;
    },
    getLastDayOfSelection(): any {
      return this.firstDayOfSelection.utcDate.isBefore(
        this.lastDayOfSelection.utcDate
      )
        ? this.lastDayOfSelection
        : this.firstDayOfSelection;
    },

    hasPreviouseMeals: function (firstDay, lastDay) {
      for (
        var day = moment(firstDay.utcDate);
        day.isSameOrBefore(lastDay.utcDate);
        day = day.add(1, "days")
      ) {
        const cMeals: any[] = this.$store.getters[
          "calendarMeals/getByDayAndHotel"
        ](day, this.hotel);

        if (cMeals.length) return true;
      }

      return false;
    },

    realSaveDetail: async function (
      firstDay,
      lastDay,
      meals,
      returnInsteadOfSave = false
    ) {
      this.saveLoading = true;

      var batch: any = [];
      var objBatch: any = [];
      var deleteObjBatch: any = [];

      for (
        var day = moment(firstDay.utcDate);
        day.isSameOrBefore(lastDay.utcDate);
        day = day.add(1, "days")
      ) {
        var currentCalendarMeals = this.$store.getters[
          "calendarMeals/getByDayAndHotel"
        ](day, this.hotel);
        var savingCalendarMeals: any = [];
        _.forEach(meals, (mealInfo, pos) => {
          var calendarMeal = this.$store.getters[
            "calendarMeals/getByDayAndHotelAndMealIndex"
          ](day, this.hotel, mealInfo.mealIndex);

          var mealDate = moment(day)
            .tz(this.hotel.get("timezone") || "Europe/Rome")
            .local()
            .hours(mealInfo.hours)
            .minutes(mealInfo.minutes);

          const mealEndDate = moment(day)
            .tz(this.hotel.get("timezone") || "Europe/Rome")
            .local()
            .hours(mealInfo.endHours)
            .minutes(mealInfo.endMinutes);

          if (mealInfo.mealIndex === BREAKFAST) {
            var mealToDate = moment(mealEndDate).subtract(
              Math.abs(mealInfo.bookingWindow[1] * 15),
              "minutes"
            );

            var mealFromDate = moment(mealEndDate).subtract(
              Math.abs(mealInfo.bookingWindow[0] * 15),
              "minutes"
            );
          } else {
            var mealToDate = moment(mealDate).subtract(
              Math.abs(mealInfo.bookingWindow[1] * 15),
              "minutes"
            );
            var mealFromDate = moment(mealDate).subtract(
              Math.abs(mealInfo.bookingWindow[0] * 15),
              "minutes"
            );
          }

          if (!calendarMeal) {
            calendarMeal = new CalendarMeal();
            calendarMeal.set("hotel", this.hotel);
            calendarMeal.set("mealIndex", mealInfo.mealIndex.toString());
            calendarMeal.set("pos", pos);
          }

          calendarMeal.set("date", mealDate.toDate());
          calendarMeal.set("endDate", mealEndDate.toDate());
          calendarMeal.set("fromDate", mealFromDate.toDate());
          calendarMeal.set("toDate", mealToDate.toDate());

          calendarMeal.set("dishes", []);

          _.forEach(mealInfo.courses, (course) => {
            if (!course) return;

            _.forEach(course.dishes, (dish, dishPos) => {
              var dish = this.$store.state.dishes.objects[dish.dishLocalId];
              calendarMeal.add("dishes", dish);
            });
          });

          savingCalendarMeals.push(calendarMeal);

          objBatch.push(calendarMeal);
        });

        var cMealsToDelete = _.differenceWith(
          currentCalendarMeals,
          savingCalendarMeals,
          (cMeal1: any, cMeal2: any) => {
            return cMeal1.get("localId") === cMeal2.get("localId");
          }
        );
        _.forEach(cMealsToDelete, (cMeal) => {
          deleteObjBatch.push(cMeal);
        });
      }

      if (returnInsteadOfSave) {
        return {
          toDelete: deleteObjBatch,
          toSave: objBatch,
        };
      }

      batch.push(
        this.$store.dispatch("calendarMeals/deleteObjBatch", {
          genByProgram: true,
          objs: deleteObjBatch,
        })
      );
      batch.push(
        this.$store.dispatch("calendarMeals/saveObjBatch", {
          objs: objBatch,
          genByProgram: true,
        })
      );

      await Promise.all(batch);
      this.markDetailAsSaved();

      if (this.isTutorial) {
        this.stepAfterSave();
      }
    },

    checkIfHasPreviouseMeals(firstDay, lastDay): Promise<any> {
      if (!this.hasPreviouseMeals(firstDay, lastDay)) return Promise.resolve();
      return this.$dialog
        .confirm(
          `Tutti i menu nel periodo ${moment(firstDay.utcDate).format(
            "DD MMMM YYYY"
          )} - ${moment(lastDay.utcDate).format(
            "DD MMMM YYYY"
          )} verranno sovrascritti. Vuoi continuare?`,
          {
            okText: "Continua",
            cancelText: "Annulla",
          }
        )
        .then(function () {
          return Promise.resolve();
        })
        .catch(function () {
          return Promise.reject();
        });
    },

    //Precondition firstDay antecede lastDay e firstDay != null e lastDay != null
    saveDetail: async function (
      firstDay,
      lastDay,
      meals,
      returnInsteadOfSave = false,
      force = false
    ): Promise<any> {
      try {
        if (!force) await this.checkIfHasPreviouseMeals(firstDay, lastDay);
        return await this.realSaveDetail(
          firstDay,
          lastDay,
          meals,
          returnInsteadOfSave
        );
      } catch (e) {
        return null;
      }

      // this.saveLoading = true;

      // var batch: any = [];
      // var objBatch: any = [];
      // var deleteObjBatch: any = [];

      // for (
      //   var day = moment(firstDay.utcDate);
      //   day.isSameOrBefore(lastDay.utcDate);
      //   day = day.add(1, "days")
      // ) {
      //   var currentCalendarMeals = this.$store.getters[
      //     "calendarMeals/getByDayAndHotel"
      //   ](day, this.hotel);
      //   var savingCalendarMeals: any = [];
      //   _.forEach(meals, (mealInfo, pos) => {
      //     var calendarMeal = this.$store.getters[
      //       "calendarMeals/getByDayAndHotelAndMealIndex"
      //     ](day, this.hotel, mealInfo.mealIndex);

      //     var mealDate = moment(day)
      //       .tz(this.hotel.get("timezone") || "Europe/Rome")
      //       .local()
      //       .hours(mealInfo.hours)
      //       .minutes(mealInfo.minutes);

      //     var mealFromDate = moment(mealDate).subtract(
      //       Math.abs(mealInfo.bookingWindow[0] * 15),
      //       "minutes"
      //     );

      //     var mealToDate = moment(mealDate).subtract(
      //       Math.abs(mealInfo.bookingWindow[1] * 15),
      //       "minutes"
      //     );

      //     if (!calendarMeal) {
      //       calendarMeal = new CalendarMeal();
      //       calendarMeal.set("hotel", this.hotel);
      //       calendarMeal.set("mealIndex", mealInfo.mealIndex.toString());
      //       calendarMeal.set("pos", pos);
      //     }

      //     calendarMeal.set("date", mealDate.toDate());
      //     calendarMeal.set("fromDate", mealFromDate.toDate());
      //     calendarMeal.set("toDate", mealToDate.toDate());

      //     calendarMeal.set("dishes", []);
      //     //TODO: We can memoize dishes array to speed up saving process (not so critical)
      //     _.forEach(mealInfo.courses, course => {
      //       if (!course) return;

      //       _.forEach(course.dishes, (dish, dishPos) => {
      //         var dish = this.$store.state.dishes.objects[dish.dishLocalId];
      //         calendarMeal.add("dishes", dish);
      //       });
      //     });

      //     savingCalendarMeals.push(calendarMeal);

      //     objBatch.push(calendarMeal);
      //   });

      //   var cMealsToDelete = _.differenceWith(
      //     currentCalendarMeals,
      //     savingCalendarMeals,
      //     (cMeal1: any, cMeal2: any) => {
      //       return cMeal1.get("localId") === cMeal2.get("localId");
      //     }
      //   );
      //   _.forEach(cMealsToDelete, cMeal => {
      //     deleteObjBatch.push(cMeal);
      //   });
      // }

      // if (returnInsteadOfSave) {
      //   return {
      //     toDelete: deleteObjBatch,
      //     toSave: objBatch
      //   };
      // }

      // batch.push(
      //   this.$store.dispatch("calendarMeals/deleteObjBatch", deleteObjBatch)
      // );
      // batch.push(this.$store.dispatch("calendarMeals/saveObjBatch", objBatch));

      // await Promise.all(batch);
      // this.markDetailAsSaved();

      // if (this.isTutorial) {
      //   this.stepAfterSave();
      // }
    },
    markDetailAsSaved: function (): any {
      this.saved = true;
      this.saveLoading = false;
      this.hasPastedDetail = false;
      const q = this.$route.query;

      if (this.$route.query.saved != "1")
        this.$router
          .push({
            query: {
              ...q,
              saved: "1",
            },
          })
          .catch((e) => {});
    },

    calculateInstant(date: Moment, dateBefore: Moment): any {
      const m1 = moment(date);
      const m2 = moment(dateBefore);
      const diffInMinutes = m1.diff(m2, "minutes");

      let days = (diffInMinutes - (diffInMinutes % 1440)) / 1440;
      days =
        days +
        (m1.isSame(moment(m1).subtract(diffInMinutes % 1440, "minutes"), "day")
          ? 0
          : 1);

      return [days, m2.hours(), m2.minutes()];
    },
    buildDetailObjectFromCalendarMeals: function (calendarMeals): any {
      var cMeals: any = [];
      _.forEach(calendarMeals, (cMeal: CalendarMeal) => {
        var jMeal = findJMealByLocalId(cMeal.get("mealIndex"));

        if (!jMeal) return;

        var timezone = this.hotel.get("timezone") || "Europe/Rome";
        var date = moment.tz(cMeal.get("date"), timezone);
        const endDate = moment.tz(cMeal.getEndDate(), timezone);
        const mealLocalId = parseInt(cMeal.get("mealIndex"), 10);

        const toDate = moment.tz(cMeal.get("toDate"), timezone);
        const fromDate = moment.tz(cMeal.get("fromDate"), timezone);
        const toInstant = this.calculateInstant(
          mealLocalId === BREAKFAST ? endDate : date,
          toDate
        );
        const fromInstant = this.calculateInstant(
          mealLocalId === BREAKFAST ? endDate : date,
          fromDate
        );
        console.log("toInstant", toInstant);

        var mealIndex =
          cMeals.push({
            _debugCMealId: cMeal.id,
            pos: jMeal.id,
            courses: {},
            mealIndex: mealLocalId,
            hours: moment(cMeal.get("date")).hours(),
            endHours: moment(endDate).hours(),
            endMinutes: moment(endDate).minutes(),
            minutes: moment(cMeal.get("date")).minutes(),
            fromInstant: fromInstant,
            toInstant: toInstant,
            fromMinutes: -date.diff(fromDate, "minutes"),
            toMinutes: -date.diff(toDate, "minutes"),
            bookingWindow: [
              -(
                (mealLocalId === BREAKFAST ? endDate : date).diff(
                  fromDate,
                  "minutes"
                ) / 15
              ),
              -(
                (mealLocalId === BREAKFAST ? endDate : date).diff(
                  toDate,
                  "minutes"
                ) / 15
              ),
            ],
          }) - 1;

        _.forEach(cMeal.get("dishes"), (dish) => {
          if (dish.get("courseIndex") === undefined) return;
          const jCourse = findJCourseByLocalId(dish.get("courseIndex"));
          if (!jCourse) return;

          cMeals[mealIndex].courses = cMeals[mealIndex].courses || {};

          cMeals[mealIndex].courses[jCourse.pos] = cMeals[mealIndex].courses[
            jCourse.pos
          ] || {
            dishes: [],
            courseIndex: dish.get("courseIndex"),
          };

          cMeals[mealIndex].courses[jCourse.pos].dishes =
            cMeals[mealIndex].courses[jCourse.pos].dishes || [];

          cMeals[mealIndex].courses[jCourse.pos].dishes.push({
            dishLocalId: dish.get("localId"),
          });
        });
      });

      cMeals = _.sortBy(cMeals, (meal) => {
        return meal.pos !== undefined ? meal.pos : Infinity; /*moment()
          .hours(meal.hours)
          .minutes(meal.minutes)
          .valueOf();*/
      });

      return cMeals;
    },
    buildDetailFromFirstDayWithCalendarMeals: function (first, last): any {
      for (
        let now = moment(first).startOf("day");
        now.isSameOrBefore(moment(last).endOf("day"));
        now = moment(now).add(1, "day")
      ) {
        const calendarMeals = this.$store.getters[
          "calendarMeals/getByDayAndHotel"
        ](now, this.hotel);

        if (!calendarMeals.length) continue;

        this.detailObject = {
          meals: this.buildDetailObjectFromCalendarMeals(calendarMeals),
        };

        return;
      }
      this.detailObject = {
        meals: [],
      };
    },
    onSelectRangeDay: async function ({ first, last }): Promise<any> {
      try {
        await this.selectSingleDay(
          {
            utcDate: first,
          },
          false
        );

        this.selectDayRange({
          utcDate: last,
        });

        this.buildDetailFromFirstDayWithCalendarMeals(first, last);

        this.openCalendarDetail();
      } catch (e) {}
    },
    onSelectSingleDay: async function (day): Promise<any> {
      try {
        await this.selectSingleDay({
          utcDate: day,
        });
        this.openCalendarDetail();
      } catch (e) {}
    },

    //day indica l'ultimo giorno selezionato (i.e. su cui si è trascinato il cursore)
    selectDayRange: function (day): any {
      this.lastDayOfSelection = day;
    },

    selectSingleDay: function (day, buildDetails = true): any {
      return this.checkIfNeedConfirmation().then(() => {
        this.markDetailAsSaved();
        this.firstDayOfSelection = day;
        this.lastDayOfSelection = day;

        if (!buildDetails) return;

        let calendarMeals = this.$store.getters[
          "calendarMeals/getByDayAndHotel"
        ](day.utcDate, this.hotel);

        this.detailObject = {
          meals: this.buildDetailObjectFromCalendarMeals(calendarMeals),
        };
      });
    },
    openCalendarDetail: function (): any {
      (this.$refs.layout as any).open();

      this.$nextTick(() => {
        if (this.tutorialStep === 0) this.nextTutorialStep();
        _.forEach(this.detailObject.meals, (m, mealIndex) => {
          this.calculateInputSize(
            mealIndex,
            `${this.pad(m.hours)}:${this.pad(m.minutes)}`
          );
        });
      });
    },
    mouseUp: function (): any {
      if (this.mouseDownOnCell) {
        this.openCalendarDetail();
      }

      if (this.mouseDownOnCell) {
        this.mouseDownOnCell = false;
      }
    },

    getPeriodString: function (): any {
      if (!this.firstDayOfSelection || !this.lastDayOfSelection) {
        return "Nessuna data selezionata";
      } else if (
        this.firstDayOfSelection.utcDate.isSame(this.lastDayOfSelection.utcDate)
      ) {
        return moment(this.firstDayOfSelection.utcDate).format(
          "ddd D MMMM YYYY"
        );
      } else {
        if (
          moment(this.firstDayOfSelection.utcDate).isBefore(
            this.lastDayOfSelection.utcDate
          )
        ) {
          var firstDate = this.firstDayOfSelection.utcDate;
          var lastDate = this.lastDayOfSelection.utcDate;
        } else {
          var firstDate = this.lastDayOfSelection.utcDate;
          var lastDate = this.firstDayOfSelection.utcDate;
        }

        return `${moment(firstDate).format("ddd D MMMM YYYY")} - ${moment(
          lastDate
        ).format("ddd D MMMM YYYY")}`;
      }
    },
  },
  computed: {
    detailObjectMeals: function (): Meal[] {
      return _.reduce(
        this.detailObject.meals,
        (res: Meal[], meal) => {
          const jMeal = findJMealByLocalId(meal.mealIndex);
          if (!jMeal) return res;
          res.push(jMeal);
          return res;
        },
        []
      );
    },
    canCopyDishes: function (): boolean {
      return !!_.values(this.selectedDishes).length;
    },
    canPasteDishes: function (): boolean {
      return !!_.size(this.copiedDishes);
    },
    _getMealsOf: function (): any {
      return _.memoize(
        (day) => {
          const meals = _.filter(this.calendarMeals, (cm) => {
            return moment(cm.get("date")).isSame(day, "day");
          });

          return _.sortBy(
            _.map(meals, (meal) => {
              const jCourse = findJMealByLocalId(meal.get("mealIndex"));
              return {
                hours: moment(meal.get("date")).hours(),
                minutes: moment(meal.get("date")).minutes(),
                name: jCourse ? jCourse.name.it : "",
              };
            }),
            (meal) => {
              var date = moment();
              var hours = meal.hours;
              var minutes = meal.minutes;
              date.hour(hours);
              date.minutes(minutes);
              return date.valueOf();
            }
          );
        },
        (day) => {
          return day.valueOf() + "-" + this.$store.state.calendarMeals.version;
        }
      );
    },
    calendarMeals: function (): any {
      return _.filter(this.$store.state.calendarMeals.objects, (cm) => {
        return cm.belongsTo(this.hotel, "hotel");
      });
    },
    today: function (): any {
      return moment();
    },
    firstDish: function (): any {
      return _.find(this.hotelDishes, (d) => {
        return d.get("name") && d.get("name").it && d.get("name").it.length;
      });
    },
    hotelDishes: function (): any {
      return _.filter(this.$store.state.dishes.objects, (d) => {
        return d.belongsTo(this.hotel, "hotel");
      });
    },

    mealsList: function (): any {
      return _.filter(
        _.map(JPASTA_MEALS, (meal) => {
          return {
            label: meal.name.it,
            localId: meal.id,
          };
        }),
        (meal) => {
          return !_.find(this.detailObject.meals, (m) => {
            return m.mealIndex === meal.localId;
          });
        }
      );
    },
    hotel: function (): any {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
  },
});
