var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{ref:"rowContainer",staticClass:"rowContainer m-0",class:{
    noSelect: !!this.draggingItemEl,
  },staticStyle:{"flex-wrap":"wrap"},attrs:{"type":"flex","gutter":16}},_vm._l((_vm.rows),function(row,index){return _c('div',{key:row.id,staticClass:"rowElementContainer",class:{
      nullRow: row.id === null,
      target: _vm.targetRow === row.id,
      lastRow: index === _vm.rows.length - 1,
    },attrs:{"data-row":row.id}},[_c('div',{staticClass:"rowElementHeader",class:{
        realRow: row.id !== null,
      },attrs:{"title":row.id === null && _vm.voidRowTitle
          ? _vm.voidRowTitle
          : row.name || 'Senza nome'},on:{"click":function($event){row.id === null ? undefined : _vm.editRow(row)}}},[_c('span',[_vm._v(_vm._s(row.name || "-"))])]),_c('div',{staticClass:"rowItemsList noSelect"},_vm._l((row.items),function(item){return _c('div',{key:item.id,ref:`item-${item.id}`,refInFor:true,staticClass:"itemCell",class:{
          hide: _vm.draggingItemEl && _vm.draggingItemEl.dataset.item === item.id,
          selected:
            _vm.searchItemString &&
            _vm.searchItemString.length &&
            (item.name || '')
              .toLowerCase()
              .includes(_vm.searchItemString.toLowerCase()),
        },attrs:{"data-item":item.id},on:{"dragstart":_vm.onDragStart,"mousedown":function($event){return _vm.onMouseDown(item, $event)},"touchstart":function($event){return _vm.onMouseDown(item, $event, true)}}},[_vm._v(" "+_vm._s(item.name || "//")+" "),_c('span',{staticClass:"icon-cross delete-item",staticStyle:{"font-size":"8px","margin-left":"auto","font-weight":"bold","cursor":"pointer"},on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem(item)}}})])}),0),_c('span',{staticClass:"icon-cross deleteRowCross",staticStyle:{"font-size":"8px","margin-left":"auto","font-weight":"bold","cursor":"pointer"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteRow(row)}}})])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }