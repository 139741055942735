
import AdviceMixin from "@/mixins/AdviceMixin";
import mixins from "vue-typed-mixins";
import provinces from "./Provinces";
import HotelMaxOrder from "./HotelMaxOrder.vue";
import InAppAspect from "./InAppAspect.vue";
import OutOfDushOrdersSettings from "./OutOfDushOrdersSettings.vue";
import HotelEditPms from "./HotelEditPms.vue";
import HotelEditPrice from "./HotelEditPrice.vue";
import {
  JPASTA_MEALS,
  Currencies,
  SNACK,
  Hotel,
  Jpasta,
  Meal,
  HOTEL_RATES,
} from "@/jpasta-sdk";
import _ from "lodash";
import Jadvice from "@/components/ui/JAdvice.vue";
import { Rate } from "@/jpasta-sdk/types";
export default mixins(AdviceMixin).extend({
  components: {
    Jadvice,
    OutOfDushOrdersSettings,
    HotelMaxOrder,
    InAppAspect,
    HotelEditPms,
    HotelEditPrice,
  },
  props: [],
  data: function (): {
    takeAwayOptions: { label: string; value: number }[];
    currencies: any[];
    provinces: any[];
    pickerOptions: { start: string; end: string; step: string };
    meals: Meal[];
    selectedTab: "general" | "dishes";
    rates: Rate[];
  } {
    return {
      rates: HOTEL_RATES,
      selectedTab: "general",
      takeAwayOptions: [
        {
          label: "Sì",
          value: 1,
        },
        {
          label: "No",
          value: 0,
        },
      ],
      currencies: [Currencies.USD, Currencies.EUR],
      provinces: provinces,
      pickerOptions: {
        start: "00:00",
        step: "00:30",
        end: "23:59",
      },
      meals: _.filter(JPASTA_MEALS, (meal) => {
        return meal.id !== SNACK;
      }),
    };
  },
  computed: {
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    // meals: function() {
    //   return JPASTA_MEALS;
    // }
  },
  methods: {
    setPrintNamesInWaiterReport(v: number) {
      const hotel = this.hotel;
      hotel.setPrintNamesInWaiterReport(!!v);
      this.$store.dispatch("hotels/saveObj", this.hotel);
    },
    setTotalInKitchenReport(v: number) {
      const hotel = this.hotel;
      hotel.setPrintTotalInKitchenReport(!!v);
      this.$store.dispatch("hotels/saveObj", this.hotel);
    },
    setTypesThatHasToReceiveEmail(v: number[]) {
      const hotel = this.hotel;
      hotel.setTypesThatHasToReceiveEmail(v);
      this.$store.dispatch("hotels/saveObj", this.hotel);
    },
    setOnlyVisualization(val) {
      this.hotel.setOnlyVisualization(!!val);
      this.$store.dispatch("hotels/saveObj", this.hotel);
    },

    getOnlyVisualization() {
      return this.hotel.onlyVisualization();
    },
    setTableAsRoom: function (v: 1 | 0) {
      const hotel = this.hotel;
      hotel.setTableIsSameAsRoom(!!v);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    setAnnotationNearQuantity: function (v: 1 | 0) {
      const hotel = this.hotel;
      hotel.set("annotationNearQuantity", !!v);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    setNewInterfaceForOrderEdit: function (v: 1 | 0) {
      const hotel = this.hotel;
      hotel.set("useNewInterfaceForOrderEdit", !!v);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    setMealPrice: function (mealIndex: number, price: number) {
      const hotel = this.hotel;
      hotel.setMealPrice(mealIndex, price);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    getLocalizedName: function (name) {
      return Jpasta.getLocalizedName(name, "it", "");
    },
    getMealColSpan: function (
      size: "xs" | "sm" | "md" | "lg" | "xl",
      index: number
    ) {
      const isLast = index === this.meals.length - 1;
      const isOdd = (index + 1) % 2 > 0;

      switch (size) {
        case "xs":
          return 24;
          break;
        case "sm":
        case "md":
        case "lg":
        case "xl":
          return isLast && isOdd ? 24 : 12;
      }
    },
    setDeliveryCost: function (value) {
      const hotel = this.hotel;
      if (value === hotel.get("deliveryCost")) return;

      hotel.set("deliveryCost", value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    changeNotIncludedMealPaymentType: function (type) {
      const hotel = this.hotel;
      hotel.setNotIncludedPaymentType(type);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    toggleEnableTakeAway: function (value) {
      const hotel = this.hotel;
      hotel.set("enableTakeAway", !!value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    toggleEnableDelivery: function (value) {
      const hotel = this.hotel;
      hotel.set("enableDelivery", !!value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    toggleEnableKitchenNotes: function (value) {
      const hotel = this.hotel;
      hotel.setEnableKitchenNotes(!!value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    stepAfterHotelName: function () {
      this.nextTutorialStep();
      this.$router
        .push({
          name: "HotelDishes",
          params: {
            hotelid: this.$route.params.hotelid,
          },
          query: {
            tutorial: "1",
          },
        })
        .catch((e) => {});
    },
    done: function () {
      if (this.$route.query.fromHotels == "1") {
        this.$router
          .push({
            name: "Hotels",
          })
          .catch((e) => {});
      } else {
        this.$router
          .push({
            name: "HotelCalendar",
            params: {
              hotelid: this.$route.params.hotelid,
            },
          })
          .catch((e) => {});
      }
    },
    setProvince(pId) {
      var province = _.find(this.provinces, (p) => {
        return p.id === pId;
      });

      this.setProperty("province", province);
    },
    setDefaultMealTime(mealIndex, timeString) {
      var hotel = this.hotel;

      hotel.set("defaultMealsTime", {
        ...this.hotel.get("defaultMealsTime"),
        [mealIndex]: timeString,
      });

      this.$store.dispatch("hotels/saveObj", hotel);
    },
    getDefaultMealTime(mealIndex) {
      if (!this.hotel.get("defaultMealsTime")) return "";

      return this.hotel.get("defaultMealsTime")[mealIndex] || "";
    },

    setCurrency(newCurrencyCode) {
      var hotel = this.hotel;

      var newCurrency = _.find(this.currencies, (c) => {
        return c.code === newCurrencyCode;
      });

      if (
        !hotel.get("currency") ||
        !newCurrency ||
        hotel.get("currency").code != newCurrency.code
      ) {
        hotel.set("currency", newCurrency);
        this.$store.dispatch("hotels/saveObj", hotel);
      }
    },

    setProperty: function (propertyName, value) {
      var hotel = this.hotel;
      hotel.set(propertyName, value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$mq !== "xs") {
        //@ts-ignore
        this.$refs.nameinput.focus();
      }
    });
  },
});
