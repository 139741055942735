import CSVParser from "./CSVParser";
import {
  BEB,
  FULL_BOARD,
  HALF_BOARD_WITH_DINNER,
  ONLY_BED,
  HALF_BOARD_WITH_DINNER_AND_DRINK,
} from "@/jpasta-sdk";
import _ from "lodash";
import moment from "moment-timezone";
const BOOK_TYPES_MAP = {
  FB: FULL_BOARD,
  AI: FULL_BOARD,
  AFF: ONLY_BED,
  HB: HALF_BOARD_WITH_DINNER,
  HBD: HALF_BOARD_WITH_DINNER,
  BB: BEB,
};

enum CSVCOL {
  BOOKID,
  ROOMCODE,
  NAME = 3,
  EMAIL = 5,
  PAX,
  STATUS,
  CHECKIN,
  CHECKOUT,
  BOOKTYPE,
}

export default class HQParser extends CSVParser {
  protected getValidBookTypes() {
    return _.map(BOOK_TYPES_MAP, (v, k) => k);
  }

  protected parseBookType(type?: string): number | undefined {
    if (!type) return undefined;
    return BOOK_TYPES_MAP[type];
  }

  protected groupRows(rows: [][]): string[][] {
    const groupedByBookId = _.groupBy(rows, (r) => {
      return this.getRowData(r, CSVCOL.BOOKID);
    });

    return _.reduce(
      groupedByBookId,
      (res: string[][], sameBookRows, bookId) => {
        const pax = _.reduce(
          sameBookRows,
          (sum, r) => {
            const pax = this.getRowData(r, CSVCOL.PAX);
            return sum + parseInt(pax || "0");
          },
          0
        );

        const email = _.reduce(
          sameBookRows,
          (email: string, r) => {
            if (_.size(email) > 0) return email;
            return this.getRowData(r, CSVCOL.EMAIL) || "";
          },
          ""
        );

        const row: string[] = _.first(sameBookRows) as string[];

        this.setRowData(row, CSVCOL.PAX, pax.toString());
        this.setRowData(row, CSVCOL.EMAIL, email);
        res.push(row);

        return res;
      },
      []
    );
  }

  protected parseRow(row: string[], index: number) {
    const checkinRaw = this.getRowData(row, CSVCOL.CHECKIN);
    const checkin = moment(checkinRaw, "DD/MM/YYYY");
    const checkoutRaw = this.getRowData(row, CSVCOL.CHECKOUT);
    const checkout = moment(checkoutRaw, "DD/MM/YYYY");
    const name = this.getRowData(row, CSVCOL.NAME);
    const email = this.getRowData(row, CSVCOL.EMAIL);
    const bookTypeRaw = this.getRowData(row, CSVCOL.BOOKTYPE);
    const bookType = this.parseBookType(bookTypeRaw);
    const bookId = this.getRowData(row, CSVCOL.BOOKID);
    const roomName = this.getRowData(row, CSVCOL.ROOMCODE);
    const lang = "it"; /*this.getRowData(row, CSVCOL.LANG);*/
    const pax = parseInt(this.getRowData(row, CSVCOL.PAX) || "0");
    const newRow: BookPlain = {
      checkIn: checkin.toISOString(),
      checkOut: checkout.toISOString(),
      name,
      email,
      bookType: bookType ? bookType : ONLY_BED,
      rawBookType: bookTypeRaw,
      bookId,
      roomNumber: roomName,
      deleted: false,
      pax: pax,
      rowIndex: index,
      lang,
    };

    return newRow;
  }
}
