<template>
  <div v-if="!$route.meta.noBar && !isTutorial" class="main-wrap no-print">
    <div
      v-if="$route.params.hotelid"
      class="bottom-mobile-bar"
      :class="{
        mobileBottomBar: $mq === 'xs' || $mq === 'sm',
        mobileOpened: $store.state.mobileMenuOpened,
      }"
    >
      <div
        :class="{
          active: this.$route.name === 'HotelCalendar',
        }"
        v-if="$route.params.hotelid"
        v-on:click.prevent.stop="toHotelCalendar"
        class="icon-wrapper"
      >
        <span class="i-label">Pasti</span>
      </div>

      <div
        :class="{
          active:
            this.$route.name === 'HotelMealBooks' ||
            this.$route.name === 'HotelMealBook',
        }"
        v-if="$route.params.hotelid"
        v-on:click.prevent.stop="toHotelMealBook"
        class="icon-wrapper"
      >
        <span class="i-label">Ordini</span>
      </div>
      <div
        :class="{
          active: this.$route.name === 'BookList',
        }"
        v-if="$route.params.hotelid"
        v-on:click.prevent.stop="toHotelBook"
        class="icon-wrapper"
      >
        <span class="i-label">Prenotazioni</span>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  computed: {
    isTutorial: function () {
      return this.$route.query.tutorial == "1";
    },
  },
  methods: {
    checkIfNeedConfirmation: function () {
      if (
        this.$route.query.saved !== undefined &&
        !parseInt(this.$route.query.saved)
      ) {
        return this.$dialog
          .confirm(
            "Hai modificato il menù, ma non hai salvato. Continuando perderai le modifiche, vuoi procedere?",
            {
              okText: "Continua",
              cancelText: "Annulla",
            }
          )
          .then(function () {
            return Promise.resolve();
          })
          .catch(function () {
            return Promise.reject();
          });
      } else {
        return Promise.resolve();
      }
    },

    toHotelMealBook: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router
        .push({
          name: "HotelMealBooks",
        })
        .catch((e) => {});
    },

    toHotelBook: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router.push({
        name: "BookList",
        // query: {
        //   ...this.$route.query
        // }
        // params: {
        //   hotelid: this.$route.params.hotelid
        // }
      });
    },
    toHotelCalendar: async function () {
      try {
        await this.checkIfNeedConfirmation();
      } catch (e) {
        return;
      }
      this.$router
        .push({
          name: "HotelCalendar",
        })
        .catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";
.bottom-mobile-bar {
  display: none;
  z-index: 3000;
  height: 50px;
  bottom: -1px;
  align-items: center;

  left: 0;
  right: 0;

  &.mobileBottomBar {
    transition: left 0.2s;
    &.mobileOpened {
      left: 200px !important;
      width: 100%;
    }
  }

  background-color: white;
  box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  .icon-wrapper:not(:last-child) {
    /* border-right: 1px solid #CCCCCC; */
    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.15);
  }

  .icon-wrapper {
    padding: 5px 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    .i-label {
      font-size: 14px;
    }

    cursor: pointer;

    img {
      width: 25px;
      height: 25px;
      opacity: 0.3;
    }
  }

  .icon-wrapper.active {
    color: map-get($colors, "primary");
    img {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 767px) {
  .bottom-mobile-bar {
    display: flex;
    position: fixed;
  }

  .main-wrap {
  }
}
</style>
