import Parse from "parse";
import Jpasta from "./Jpasta";

export default class CalendarPrice extends Jpasta {
  constructor() {
    super("CalendarPrice");
  }
}

Parse.Object.registerSubclass("CalendarPrice", CalendarPrice);
