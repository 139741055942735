
import Vue from "vue";
import mixins from "vue-typed-mixins";
import {
  MealOrder,
  Hotel,
  Table,
  Turn,
  CalendarMeal,
  Dish,
  MealOrderDish,
  Jpasta,
  BookPerson,
  Book,
  findJCourseByLocalId,
  Course,
} from "@/jpasta-sdk";
import _ from "lodash";
const CHUNK_SIZE = 10;

type TurnData = {
  turnName: string;
  ranks: RankData[];
};

type RankData = {
  rankName: string;
  chunks: {
    [chunkIndex: string]: RowData[];
  };
};

type RowData = {
  rowType: "value" | "title" | "annotation";
  rowName: string;
  data: {
    qty: number;
  }[];
  annotations: {
    annotation: string;
  }[];
};

export default mixins().extend({
  props: {
    turn: {
      type: Object as () => Turn | undefined,
    },
    mealOrders: {
      type: Array as () => MealOrder[],
    },
    hotel: {
      type: Object as () => Hotel,
    },
    calendarMeal: {
      type: Object as () => CalendarMeal,
    },
  },
  mounted() {},

  methods: {
    formatTurnName: function () {
      if (!this.turn) return "Senza turno";
      return _.size(this.turn.get("name"))
        ? this.turn.get("name")
        : "Turno senza nome";
    },
    getDishName: function (name): string {
      return Jpasta.getLocalizedName(name, "it", "");
    },
  },

  computed: {
    isTableSameAsRoom: function (): boolean {
      return this.hotel.getTableIsSameAsRoom();
    },
    chunkSize: function (): number {
      return CHUNK_SIZE;
    },
    mealOrderDishesByMealOrder: function (): {
      [mealOrderId: string]: MealOrderDish[];
    } {
      return _.groupBy(
        this.$store.state.mealOrderDishes.objects,
        (mod: MealOrderDish) => {
          const order = mod.get("order");
          if (!order) return "";
          return order.get("localId");
        }
      );
    },
    bookabelDishesColumnIndex: function (): { [dishId: string]: number } {
      const res: { [dishId: string]: number } = {};
      _.forEach(this.dishesChunks, (dishes) => {
        _.forEach(dishes, (d, dishIndex) => {
          res[d.get("localId")] = dishIndex;
        });
      });

      return res;
    },
    bookableDishesChunkIndex: function (): { [dishId: string]: number } {
      return _.reduce(
        this.dishesChunks,
        (res, dishes, chunkIndex) => {
          _.forEach(dishes, (d) => {
            res[d.get("localId")] = chunkIndex;
          });
          return res;
        },
        {}
      );
    },
    dishesChunks: function (): Dish[][] {
      return _.chunk(this.bookableDishes, CHUNK_SIZE);
    },
    bookableDishes: function (): Dish[] {
      const dishes = _.keyBy(this.calendarMeal.get("dishes") as Dish[], (d) => {
        return d.get("localId") as string;
      });

      _.forEach(this.mealOrderDishesByMealOrder, (odishes) => {
        _.forEach(odishes, (odish) => {
          const dish = odish.get("dish") as Dish;
          if (!dish) return;
          dishes[dish.get("localId")] = dish;
        });
      });

      const filteredDishes = _.filter(dishes, (d) => {
        const courseIndex = d.get("courseIndex");
        if (courseIndex === undefined) return false;
        const jCourse = findJCourseByLocalId(courseIndex);
        if (!jCourse) return false;

        return true;
      });

      return _.sortBy(filteredDishes, (d) => {
        const courseIndex = d.get("courseIndex");
        const jCourse = findJCourseByLocalId(courseIndex) as Course;
        return jCourse.pos;
      });
      //return this.calendarMeal.get("dishes") || [];
    },
    tables: function (): Table[] {
      return _.filter(this.$store.state.tables.objects, (t: Table) =>
        t.belongsTo(this.hotel, "hotel")
      );
    },
    turns: function (): Turn[] {
      return _.filter(this.$store.state.turns.objects, (t: Turn) =>
        t.belongsTo(this.hotel, "hotel")
      );
    },
    tablesByName: function (): { [tableName: string]: Table } {
      return _.keyBy(this.tables, (t) => t.get("name") as string);
    },

    reportData: function (): TurnData[] {
      const noTurnTitle = "Senza turno";
      const noRankTitle = "Senza rango";
      const groupedByTurn = _.groupBy(this.mealOrders, (mo) => {
        const tableName = (mo.get("book") as Book).getTableNumber();
        if (!tableName) return noTurnTitle;

        const table = this.tablesByName[tableName];
        if (!table) return noTurnTitle;

        const turn = table.get("turn");
        if (!turn) return noTurnTitle;

        return turn.get("name");
      });

      const turns: TurnData[] = [];

      _.forEach(groupedByTurn, (morders, turnName) => {
        const turnData: TurnData = {
          ranks: [],
          turnName: turnName,
        };

        const groupedByRank = _.groupBy(morders, (mo) => {
          const tableName = (mo.get("book") as Book).getTableNumber();
          if (!tableName) return noRankTitle;

          const table = this.tablesByName[tableName];
          if (!table) return noRankTitle;

          const rank = table.get("rank");
          if (!rank) return noRankTitle;

          return rank.get("name");
        });

        _.forEach(groupedByRank, (morders, rankName) => {
          const rankData: RankData = {
            chunks: {},
            rankName: `Rango ${rankName}`,
          };

          const groupedByBook = _.groupBy(morders, (mo) => {
            return mo.get("book").get("localId");
          });
          _.forEach(this.dishesChunks, (chunkDishes, chunkIndex) => {
            const allRows: RowData[] = [];
            _.forEach(groupedByBook, (morders, bookLocalId) => {
              const book = this.$store.state.books.objects[bookLocalId] as Book;
              if (!book) return;

              allRows.push({
                rowType: "title",
                rowName: `${this.isTableSameAsRoom ? "Cam." : "Tav."} ${
                  book.getTableNumber() || ""
                }${book.get("refName") ? ` ${book.get("refName")}` : ""}`,
                data: [],
                annotations: [],
              });

              const totalRow: RowData = {
                rowType: "value",
                rowName: "Tot.",
                data: _.times(chunkDishes.length, () => {
                  return {
                    qty: 0,
                  };
                }),
                annotations: [],
              };

              _.forEach(morders, (mo) => {
                //const
                const person: BookPerson = mo.get("person");
                if (!person) return;

                const row: RowData = {
                  rowType: "value",
                  rowName: person.get("name") || "//",
                  data: _.times(chunkDishes.length, () => {
                    return {
                      qty: 0,
                      annotation: "",
                    };
                  }),
                  annotations: [],
                };

                let hasAnnotation = false;

                let hasHalfPortion = false;

                const annotationRow: RowData = {
                  rowType: "annotation",
                  rowName: `Note ${person.get("name")}`,
                  data: [],
                  annotations: _.times(chunkDishes.length, () => {
                    return {
                      annotation: "",
                    };
                  }),
                };
                const halfPortionRow: RowData = {
                  rowType: "value",
                  rowName: `Mezze porzioni x ${person.get("name") || "//"}`,
                  data: _.times(chunkDishes.length, () => {
                    return {
                      qty: 0,
                      annotation: "",
                    };
                  }),
                  annotations: [],
                };

                const moDishes =
                  this.mealOrderDishesByMealOrder[mo.get("localId")] || [];

                _.forEach(moDishes, (mod, itIndex) => {
                  const dish = mod.get("dish");
                  if (!dish) return;
                  const dishChunkIndex =
                    this.bookableDishesChunkIndex[dish.get("localId")];
                  if (dishChunkIndex !== chunkIndex) {
                    return;
                  }
                  const dishColumnIndex =
                    this.bookabelDishesColumnIndex[dish.get("localId")];

                  const qty = mod.get("quantity") || 0;

                  const annotation = mod.get("annotation") || "";
                  if (_.size(annotation)) {
                    hasAnnotation = true;
                    annotationRow.annotations[dishColumnIndex].annotation =
                      annotation;
                  }

                  if (mod.isHalfPortion()) {
                    halfPortionRow.data[dishColumnIndex].qty =
                      halfPortionRow.data[dishColumnIndex].qty + qty;
                    hasHalfPortion = true;
                  } else {
                    row.data[dishColumnIndex].qty =
                      row.data[dishColumnIndex].qty + qty;
                  }

                  totalRow.data[dishColumnIndex].qty =
                    totalRow.data[dishColumnIndex].qty + qty;

                  // row.data[dishColumnIndex].annotation =
                  //   mod.get("annotation") || "";
                });

                allRows.push(row);
                if (hasHalfPortion) allRows.push(halfPortionRow);
                if (hasAnnotation) allRows.push(annotationRow);
              });

              allRows.push(totalRow);
            });
            rankData.chunks[chunkIndex] = allRows;
          });

          turnData.ranks.push(rankData);
        });
        turns.push(turnData);
      });

      return turns;
    },
  },
});
