import Vue from "vue";
export default {
  methods: {
    setScrollTop: function () {
      const el = this.getScrollElement();
      if (!el) return;
      //@ts-ignore
      const key = `${this.$route.name}ScrollTop`;
      //@ts-ignore
      const scrollTop = this.$store.state.scrollPositions[key] || 0;
      //@ts-ignore
      this.$nextTick(() => {
        //@ts-ignore
        this.$nextTick(() => {
          //@ts-ignore
          this.$nextTick(() => {
            //@ts-ignore
            this.$nextTick(() => {
              el.scrollTop = scrollTop;
            });
          });
        });
      });
    },

    getScrollElement: function () {
      return document.scrollingElement;
      // const ua = navigator.userAgent.toLowerCase();

      // if (ua.indexOf("safari") != -1) {
      //   if (ua.indexOf("chrome") === -1) {
      //     //safari
      //     return document.body;
      //   }
      // }

      // return document.documentElement;
    },
  },
};
