<template>
  <div class="login-wrapper">
    <jcard class="login-card">
      <h2 class="text-center" slot="header">Inserisci la tua password</h2>
      <div class="body">
        <jinput
          ref="passwordinput"
          type="password"
          style="width: 80%"
          placeholder="Password"
          v-model="password"
          v-on:enterpressed="login"
        ></jinput>
        <jbutton
          :loading="loading"
          v-on:click.prevent.stop="login"
          class="login-button"
          >Entra</jbutton
        >
      </div>
    </jcard>
  </div>
</template>

<script>
import Parse from "parse";
export default {
  data: function () {
    return {
      password: "",
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.passwordinput.focus();
    });
  },
  computed: {
    email: function () {
      return this.$route.query.email;
    },
  },
  methods: {
    login: async function () {
      if (!this.password.length) {
        return this.$msg("Inserisci una password ^^");
      }

      this.loading = true;

      try {
        await this.$store.dispatch("users/logIn", {
          username: this.email,
          password: this.password,
        });

        await this.$store.dispatch("downSyncAll");

        this.loading = false;

        this.$router.push({
          name: "Hotels",
        });
      } catch (e) {
        this.loading = false;
        return this.$msg("Username o password errati ;(");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #f4443f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  font-size: 16px;
  width: 90%;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2em;
}

.login-button {
  margin-top: 1.5em;
}

@media only screen and (min-width: 768px) {
  .login-card {
    max-width: 560px;
  }
}
</style>
