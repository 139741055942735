import Login from "@/components/Auth/Login.vue";
import LoginPassword from "@/components/Auth/LoginPassword.vue";
import Register from "@/components/Auth/Register.vue";
import BookSummary from "@/components/Book/BookExtra.vue";
import BookGantt from "@/components/Book/BookGantt.vue";
import BookList from "@/components/Book/BookList.vue";
import BookPeople from "@/components/Book/BookPeople.vue";
import TutorialQrCode from "@/components/Book/TutorialQrCode.vue";
import EditMealBook from "@/components/Hotel/EditMealBook.vue";
import HotelCalendar from "@/components/Hotel/HotelCalendar.vue";
import HotelDishes from "@/components/Hotel/HotelDishes.vue";
import HotelEdit from "@/components/Hotel/HotelEdit.vue";
import HotelMealBookScreen from "@/components/Hotel/HotelMealBookScreen.vue";
import HotelMealBooks from "@/components/Hotel/HotelMealBooks.vue";
import Hotels from "@/components/Hotel/Hotels.vue";
import Turns from "@/components/Hotel/Turns.vue";

import NewHotel from "@/components/Hotel/NewHotel.vue";
import HotelRooms from "@/components/Hotel/Rooms.vue";
import TablesView from "@/components/Hotel/TablesView.vue";
import ExtraMealOrder from "@/components/ExtraMealOrder/ExtraMealOrder.vue";
import MealBookTurnScreen from "@/components/Hotel/MealBookTurnScreen.vue";
import SpottyLogin from "@/components/SpottyLogin/SpottyLogin.vue";
import SpottyWelcome from "@/components/SpottyWelcome/SpottyWelcome.vue";
import SubscriptionExpired from "@/components/Subscription/SubscriptionExpired.vue";
import MassNotifyScreen from "@/components/Hotel/MassNotifyBooks.vue";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import NProgress from "nprogress";
import Vue from "vue";
import Router from "vue-router";
import "../assets/css/nprogress.css";
import store from "../store/VuexStore";
import _ from "lodash";
NProgress.configure({ showSpinner: false });
Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Hotels",
      component: Hotels,
      meta: { requiresAuth: true },
      alias: "/hotels",
    },
    {
      path: "/spottywelcome",
      name: "SpottyWelcome",
      component: SpottyWelcome,
      meta: { requiresAuth: true, noBar: true },
    },
    {
      path: "/hotels/:hotelid/tutorial",
      name: "TutorialQrCode",
      component: TutorialQrCode,
      meta: { requiresAuth: true },
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { noBar: true },
    },
    {
      path: "/spotty-login",
      name: "SpottyLogin",
      component: SpottyLogin,
      meta: { noBar: true },
    },
    {
      path: "/loginPassword",
      name: "LoginPassword",
      component: LoginPassword,
      meta: { noBar: true },
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { noBar: true },
    },
    {
      path: "/newhotel",
      name: "NewHotel",
      component: NewHotel,
      meta: { requiresAuth: true, noBar: true },
    },
    {
      path: "/hotels/:hotelid/planning",
      name: "Planning",
      component: BookGantt,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/notify",
      name: "MassNotify",
      component: MassNotifyScreen,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/extramealorders",
      name: "ExtraMealOrder",
      component: ExtraMealOrder,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/summary/:bookid",
      name: "Summary",
      component: BookSummary,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/list",
      name: "BookList",
      component: BookList,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/rooms",
      name: "HotelRooms",
      component: HotelRooms,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/tables",
      name: "TablesView",
      component: TablesView,
      meta: { requiresAuth: true, premium: true },
    },

    {
      path: "/hotels/:hotelid/calendar",
      name: "HotelCalendar",
      component: HotelCalendar,
      meta: { requiresAuth: true, premium: true },
    },

    {
      path: "/hotels/:hotelid/books/:bookid/people",
      name: "BookPeople",
      component: BookPeople,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/dishes",
      name: "HotelDishes",
      component: HotelDishes,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/settings",
      name: "HotelEdit",
      component: HotelEdit,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/mealbooks",
      name: "HotelMealBooks",
      component: HotelMealBooks,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/mealbooks/:calendarmealid/turnselect",
      name: "MealBookTurnScreen",
      component: MealBookTurnScreen,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/mealbooks/:calendarmealid/turn/:turnid",
      name: "HotelMealBookScreenTurn",
      component: HotelMealBookScreen,
      meta: { requiresAuth: true, premium: true },
    },

    {
      path: "/hotels/:hotelid/mealbooks/:calendarmealid",
      name: "HotelMealBook",
      component: HotelMealBookScreen,
      meta: { requiresAuth: true, premium: true },
    },

    {
      path: "/hotels/:hotelid/mealbooks/:calendarmealid/edit/:bookid",
      name: "EditMealBook",
      component: EditMealBook,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/hotels/:hotelid/mealbooks/:calendarmealid/turn/:turnid/edit/:bookid",
      name: "EditMealBookTurn",
      component: EditMealBook,
      meta: { requiresAuth: true, premium: true },
    },
    {
      path: "/expired/:hotelid",
      name: "SubscriptionExpired",
      component: SubscriptionExpired,
      meta: { requiresAuth: true },
    },
  ],
});

function getScrollElement() {
  const ua = navigator.userAgent.toLowerCase();

  if (ua.indexOf("safari") != -1) {
    if (ua.indexOf("chrome") === -1) {
      //safari
      return document.body;
    }
  }

  return document.documentElement;
}

router.beforeEach(async (to, from, next) => {
  const query = {
    ...to.query,
  };
  if (to.name && to.name !== from.name) {
    store.commit("setMobileMenuOpened", false);
    // Start the route progress bar.

    NProgress.start();
  }

  // this route requires auth, check if logged in
  // if not, redirect to login page.

  const spottyToken = Cookies.get("jpasta-login");

  //@ts-ignore
  const user = store.state.users.current;

  // if (user && _.size(spottyToken) > 0) {
  //   try {
  //     await store.dispatch("users/logOut");
  //   } catch (e) {}
  // }
  if (_.size(spottyToken) > 0 && to.name != "SpottyLogin") {
    return next({
      name: "SpottyLogin",
      query: { redirect: to.fullPath },
    });
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    //@ts-ignore
    !store.state.users.current
  ) {
    return next({
      name: "Login",
      query: { redirect: to.fullPath },
    });
  }

  // if (
  //   to.params.hotelid &&
  //   to.name != "SubscriptionExpired" &&
  //   to.matched.some(record => record.meta.premium)
  // ) {
  //   //@ts-ignore
  //   var hotel = store.state.hotels.objects[to.params.hotelid];

  //   if (hotel) {
  //     var expireDate = hotel.get("expireDate") || moment().subtract(1, "days");
  //     var now = moment();
  //     if (now.isAfter(expireDate)) {
  //       //Abbonamento scaduto
  //       return next({
  //         name: "SubscriptionExpired",
  //         params: {
  //           hotelid: hotel.get("localId")
  //         },
  //         query: {
  //           ...(to.query || {})
  //         }
  //       });
  //     }
  //   }
  // }

  if (from.name === to.name) return next();
  const key = `${from.name}ScrollTop`;
  const el = getScrollElement();
  const pos = el.scrollTop;

  store.commit("setScrollPosition", {
    key: key,
    value: pos,
  });
  el.scrollTop = 0;
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
