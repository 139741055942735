
import {
  MealOrderDish,
  Jpasta,
  findJCourseByLocalId,
  JPASTA_COURSES,
  MealOrder,
  CalendarMeal,
  Dish,
  Hotel,
} from "@/jpasta-sdk";
import mixins from "vue-typed-mixins";
import { Course } from "@/jpasta-sdk";
import _, { Dictionary } from "lodash";
export default mixins().extend({
  props: {
    index: {
      type: Number as () => number,
    },
    order: {
      type: Object as () => MealOrder,
    },
    getOdishBy: {
      type: Function as () => any,
    },
    odishByOrderAndDishMap: {
      type: Object as () => any,
    },
    calendarMeal: {
      type: Object as () => CalendarMeal,
    },
  },
  data: function (): {
    qtys: { [dishId: string]: number };
  } {
    return {
      qtys: {},
    };
  },
  methods: {
    setOrderCompleted(value: boolean) {
      const order = this.order;
      order.set("completed", value);
      this.$store.dispatch("mealOrders/saveObj", order);
    },
    getMaxDishesOf(courseLocalId: string) {
      return this.hotel.getMaxDishesByCourseLocalId(courseLocalId);
    },
    getModeOfString: function (order): string {
      if (!order) return "";
      switch (this.getModeOf(order)) {
        case "delivery":
          return "Delivery";
        case "takeaway":
          return "Takeaway";
        default:
          return "";
      }
    },
    getModeOf: function (order: MealOrder): string {
      if (!order.get("delivery") && !order.get("takeAway")) return "normal";
      else if (order.get("delivery")) return "delivery";
      else return "takeaway";
    },
    changeOrderCompleted(order, val): void {
      order.set("completed", val);
      this.$store.dispatch("mealOrders/saveObj", order);
    },

    existsOrderDish: function (order, dish): boolean {
      return !!this.getOdishBy(order, dish);
    },
    getLocalizedName: function (name): string {
      return Jpasta.getLocalizedName(name, "it", "Nessun nome");
    },
    findJCourseByLocalId: function (courseLocalId): Course | undefined {
      return findJCourseByLocalId(courseLocalId);
    },

    setDishQuantity: function (
      orderLocalId: string,
      dishLocalId: string,
      value: number
    ) {
      const order = this.$store.state.mealOrders.objects[orderLocalId];

      let odish: MealOrderDish | undefined = _.find(
        this.$store.state.mealOrderDishes.objects as Dictionary<MealOrderDish>,
        (oDish) => {
          return (
            oDish &&
            oDish.get("dish") &&
            oDish.belongsTo(order, "order") &&
            oDish.get("dish").get("localId") === dishLocalId
          );
        }
      );

      if (value === undefined) return;

      if (!odish && !value) return;
      const dish = this.$store.state.dishes.objects[dishLocalId];
      if (!odish) {
        if (!dish) return;
        odish = new MealOrderDish();
        odish.set("order", order);

        odish.set("dish", dish);
      }
      if (odish.get("quantity") !== value) {
        odish.set("quantity", value);
        order.set("completed", true);
        this.$store.dispatch("mealOrderDishes/saveObj", odish);

        //this.$store.dispatch("mealOrders/saveObj", odish);
      }
    },

    openEditModal: function (orderLocalId): void {
      if (!orderLocalId) return;
      this.$emit("openEditModal", {
        orderLocalId,
      });
      //   this.editingOrderLocalId = orderLocalId;
      //   this.editingDishLocalId = dishLocalId;
      //   this.annotationModalVisible = true;
    },
    getDishQuantity: function (orderLocalId, courseIndex, dishLocalId): number {
      try {
        let order = this.$store.state.mealOrders.objects[orderLocalId];
        return (
          _.find(this.$store.state.mealOrderDishes.objects, (oDish) => {
            return (
              oDish &&
              oDish.get("dish") &&
              oDish.belongsTo(order, "order") &&
              oDish.get("dish").get("localId") === dishLocalId &&
              oDish.get("quantity") >= 1
            );
          }).get("quantity") || 0
        );
      } catch (e) {
        return 0;
      }
    },
    getDishAnnotation(order, dish): string | undefined {
      const odish = this.getOdishBy(order, dish);
      return odish && odish.get("annotation") && odish.get("annotation").length
        ? odish.get("annotation")
        : undefined;
    },
  },
  computed: {
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    courses: function (): Course[] {
      return JPASTA_COURSES;
    },
    coursesWithDishes: function (): Course[] {
      return _.filter(this.courses, (course) => {
        return !!this.getDishOfCourse(course.id).length;
      });
    },
    dishes: function (): Dish[] {
      if (!this.calendarMeal) return [];
      return _.filter(this.calendarMeal.get("dishes") as Dish[], (d) => !!d);
    },
    getDishOfCourse: function (): any {
      return _.memoize(
        (courseLocalId) => {
          return _.filter(this.dishes, (dish) => {
            return (
              dish &&
              dish.get("courseIndex") === courseLocalId &&
              dish.getType() === "dish" //TODO togliere quando le bevande sono implementate meglio
            );
          });
        },
        (courseLocalId) => {
          return `${courseLocalId}-${this.$store.state.dishes.version}`;
        }
      );
    },
  },
});
