<template>
  <button
    v-on:click="$emit('click', $event)"
    class="jbutton"
    style="line-height: 1.2"
    :class="{
      small: size == 'small',
      primary: !type || type == 'primary',
      secondary: type == 'secondary',
      text: type == 'text',
      'border-button':
        type == 'border' ||
        type == 'border-secondary' ||
        type == 'border-grey' ||
        type == 'border-primary',
      'border-button-secondary': type == 'border-secondary',
      'border-button-grey': type == 'border-grey',
      'border-button-primary': type == 'border-primary',
      link: type == 'link',
    }"
    :disabled="disabled"
  >
    <div
      class="el-wrapper"
      :class="{
        small: size == 'small',
        large: size == 'large',
      }"
    >
      <slot></slot>
      <jspinner
        class="spinnerJButton"
        :loading="loading"
        :type="type"
      ></jspinner>
    </div>
  </button>
</template>

<script type="text/javascript">
export default {
  props: {
    type: {},
    loading: {},
    disabled: {},
    size: {
      default: undefined,
    },
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/constants.scss";
.jbutton {
  &.border-button-primary:not(:disabled):hover {
    .spinnerJButton {
      color: white !important;
    }
  }
}
</style>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";

.loading-normal {
  width: 14px;
  height: 16px;
}

.loading-small {
  width: 10px;
  height: 12px;
}

.jbutton.primary {
  background-color: map-get($colors, "primary");
  box-shadow: 0 5px 15px 0 rgba(255, 72, 44, 0.2);
}

.jbutton.border-button {
  background-color: transparent;
  border-radius: $border-radius;
  border: 1px solid map-get($colors, "primary");
  color: map-get($colors, "primary");
  padding: 2px 20px;
  min-width: inherit;
  // text-transform: uppercase;
  font-weight: bold;
  margin-right: 0;
  .el-wrapper:not(.small) {
    min-height: 35px;
  }
}

.jbutton.border-button-secondary {
  border: 1px solid map-get($colors, "secondary");
  color: map-get($colors, "secondary");
}

.jbutton.border-button-grey {
  border: 1px solid map-get($colors, "darken-grey");
  background: white;
  color: map-get($colors, "darken-grey");
}
.jbutton.border-button-primary {
  border: 1px solid map-get($colors, "primary");
  background: white;
  color: map-get($colors, "primary");
}

.jbutton.primary:not([disabled="disabled"]):hover {
  background-color: map-get($colors, "primary-clicked");
}

.jbutton.secondary {
  background-color: map-get($colors, "secondary");
  box-shadow: 0 5px 15px 0 rgba(78, 90, 99, 0.2);
}

.jbutton.text {
  background-color: transparent;
  color: #2c3e50;

  cursor: pointer;
  font-size: 14px;
  //text-decoration: underline;
  margin-right: 10px;
  font-weight: normal;
  min-width: inherit;
  padding: 5px 5px;
  margin: 0;
  font-weight: bold;
  &:hover:not(:disabled) {
    color: map-get($colors, "primary");
  }
}

.jbutton[disabled="disabled"] {
  opacity: 0.5;
}

.jbutton {
  .el-wrapper.large {
    min-height: 35px;
  }

  min-height: 32px;
  min-width: 120px;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  outline: none;
  border: 0px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 12px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  padding: 5px 15px;
  text-align: center;

  .el-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  &.small {
    padding: 5px 5px !important;
    font-size: 12px !important;
  }
}

.jbutton.secondary:not([disabled="disabled"]):hover {
  background-color: darken(#4e5a63, 7%);
}

.jbutton.border-button:not([disabled="disabled"]):hover {
  background-color: map-get($colors, "primary");
  color: white;
}

.jbutton.border-button-secondary:not([disabled="disabled"]):hover {
  background-color: #2c3e50 !important;
  color: white;
}

.jbutton.border-button-grey:not([disabled="disabled"]):hover {
  background-color: lighten(map-get($colors, "darken-grey"), 20%);
  color: white;
}

.jbutton.link {
  font-weight: bold;
  background-color: transparent;
  border-width: 0px;
  // text-decoration: underline;
  text-transform: uppercase;
  color: map-get($colors, "secondary");
  padding: 0;
  min-width: auto;
  min-height: 20px;
  margin: 3px 0;
  &:not([disabled="disabled"]):hover {
    background-color: transparent;
    color: lighten(map-get($colors, "secondary"), 20%);
  }
}
.jbutton:disabled {
  cursor: not-allowed;
}
</style>

<style lang="scss">
@import "../../assets/scss/constants.scss";
.jbutton.border-button-primary {
  .spinnerJButton {
    //background: blue;
    .ispinner__blade {
      background-color: map-get($colors, "primary") !important;
    }
  }

  &:hover {
    .spinnerJButton {
      .ispinner__blade {
        background-color: white !important;
      }
    }
  }
}
</style>
