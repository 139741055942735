var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"jbutton",class:{
    small: _vm.size == 'small',
    primary: !_vm.type || _vm.type == 'primary',
    secondary: _vm.type == 'secondary',
    text: _vm.type == 'text',
    'border-button':
      _vm.type == 'border' ||
      _vm.type == 'border-secondary' ||
      _vm.type == 'border-grey' ||
      _vm.type == 'border-primary',
    'border-button-secondary': _vm.type == 'border-secondary',
    'border-button-grey': _vm.type == 'border-grey',
    'border-button-primary': _vm.type == 'border-primary',
    link: _vm.type == 'link',
  },staticStyle:{"line-height":"1.2"},attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('div',{staticClass:"el-wrapper",class:{
      small: _vm.size == 'small',
      large: _vm.size == 'large',
    }},[_vm._t("default"),_c('jspinner',{staticClass:"spinnerJButton",attrs:{"loading":_vm.loading,"type":_vm.type}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }