export default class JImageHelper {
  private pica: any;
  private imageFile: any;
  private image: any;
  private imageBase64: any;

  constructor(imageFile, pica) {
    this.imageFile = imageFile;
    this.pica = pica;
  }

  async squareCropAndResize(size) {
    if (!this.image) await this.getImage();

    const width = this.image.width;
    const height = this.image.height;

    if (width / height > height / width) {
      const img = await this.crop(height, height, false);

      return await this.processWithPica(size, size, img);
    } else {
      const img = await this.crop(width, width, false);

      return await this.processWithPica(size, size, img);
    }
  }

  getBase64() {
    return new Promise((resolve, reject) => {
      if (!this.imageFile)
        reject(
          new DOMException(
            "You must pass a valid input file to the constructor of JImageHelper before calling getImageBase64."
          )
        );

      const reader = new FileReader();
      reader.onload = (event) => {
        if (!event.target)
          return reject(new DOMException("Problem parsing input file."));
        this.imageBase64 = event.target.result;

        resolve(this.imageBase64);
      };

      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      reader.readAsDataURL(this.imageFile);
    });
  }

  getImageFromBase64() {
    return new Promise((resolve, reject) => {
      if (!this.imageBase64)
        return reject(
          new DOMException(
            "You must call getImageBase64 before calling getImageFromBase64."
          )
        );
      const imageObj = new Image();
      imageObj.onload = (image: any) => {
        this.image = image.path ? image.path[0] : image.target;
        resolve(this.image);
      };
      imageObj.onerror = () => {
        reject(new DOMException("Problem loading image."));
      };

      imageObj.src = this.imageBase64;
    });
  }

  async getImage() {
    await this.getBase64();
    return await this.getImageFromBase64();
  }

  async crop(cropWidth, cropHeight, getBase64 = true) {
    if (!this.image) await this.getImage();

    cropWidth = this.image.width < cropWidth ? this.image.width : cropWidth;
    cropHeight =
      this.image.height < cropHeight ? this.image.height : cropHeight;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) return null;
    context.canvas.width = cropWidth;
    context.canvas.height = cropHeight;

    const sourceX = (this.image.width - cropWidth) / 2;
    const sourceY = (this.image.height - cropHeight) / 2;
    const sourceWidth = cropWidth;
    const sourceHeight = cropHeight;
    const destWidth = cropWidth;
    const destHeight = cropHeight;
    const destX = 0;
    const destY = 0;

    context.drawImage(
      this.image,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      destX,
      destY,
      destWidth,
      destHeight
    );

    return getBase64 ? canvas.toDataURL() : canvas;
  }

  blob2base64(blob) {
    return new Promise((resolve, _reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        resolve(reader.result);
      };
    });
  }

  async processWithPica(width, height, image) {
    const canvas = document.createElement("canvas");

    canvas.width = width;
    canvas.height = height;
    try {
      const res = await this.pica.resize(image, canvas, {
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2,
      });

      const blob = await this.pica.toBlob(res, "image/jpeg", 0.9);

      const base64 = await this.blob2base64(blob);

      return base64;
    } catch (e) {
      throw "An error occured";
    }
  }

  async resizeWithMaxSize(maxSize) {
    if (!this.image) await this.getImage();

    const width = this.image.width;
    const height = this.image.height;
    let newHeight;
    let newWidth;
    if (width / height > height / width) {
      //Width maggiore di Height
      if (width > maxSize) {
        newWidth = maxSize;
        newHeight = (newWidth * height) / width;

        return this.processWithPica(newWidth, newHeight, this.image);
        //context.drawImage(this.image, 0, 0, width, height, 0, 0, newWidth, newHeight)
      } else {
        return this.processWithPica(width, height, this.image);
      }
    } else {
      //Height maggiore di width
      if (height > maxSize) {
        newHeight = maxSize;
        newWidth = (newHeight * width) / height;

        return this.processWithPica(newWidth, newHeight, this.image);
      } else {
        return this.processWithPica(width, height, this.image);
      }
    }
  }
}
