import Parse from "parse";
import Jpasta from "./Jpasta";

export default class MealOrderDish extends Jpasta {
  constructor() {
    super("MealOrderDish");
  }

  getDishLocalId() {
    return this.get("dish") ? this.get("dish").get("localId") : -1;
  }

  public setHalfPortion(val: boolean) {
    this.set("halfPortion", val);
  }

  public belongsToCourse(courseIndex?: string | number): boolean {
    const dish = this.get("dish");
    if (!dish) return false;

    let v = courseIndex;
    if (typeof v === "string") v = parseInt(courseIndex as string);

    return dish.get("courseIndex") === v;
  }

  public isHalfPortion(): boolean {
    return !!this.get("halfPortion");
  }

  is(dishLocalId, courseIndex) {
    try {
      return (
        (this.get("dish") === undefined &&
          dishLocalId == -1 &&
          this.get("courseIndex") == courseIndex) ||
        (this.get("dish").get("localId") == dishLocalId &&
          this.get("dish").get("courseIndex") == courseIndex)
      );
    } catch (e) {
      return false;
    }
  }
}

Parse.Object.registerSubclass("MealOrderDish", MealOrderDish);
