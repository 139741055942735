import Parse from "parse";
import Jpasta from "./Jpasta";

export default class Rank extends Jpasta {
  constructor() {
    super("Rank");
  }
}

Parse.Object.registerSubclass("Rank", Rank);
