import CSVParser from "./CSVParser";
import {
  BEB,
  FULL_BOARD,
  HALF_BOARD_WITH_DINNER,
  ONLY_BED,
} from "@/jpasta-sdk";
import _ from "lodash";
import moment from "moment-timezone";
const BOOK_TYPES_MAP = {
  BB: BEB,
  FB_ID: FULL_BOARD,
  FB_ST: FULL_BOARD,
  HB_ID: HALF_BOARD_WITH_DINNER,
  HB_ST: HALF_BOARD_WITH_DINNER,
  HBB_BS: HALF_BOARD_WITH_DINNER,
  HBB_ID: HALF_BOARD_WITH_DINNER,
  HBB_ST: HALF_BOARD_WITH_DINNER,
  RO: ONLY_BED,
  ROA: ONLY_BED,
};

enum CSVCOL {
  BOOKID,
  NAME,
  CHECKIN = 3,
  CHECKOUT,
  BOOKTYPE = 6,
  ROOM_NUMBER = 11,
  FIRST_PAX = 13,
  SECOND_PAX = 14,
  THIRD_PAX = 15,
  FOURTH_PAX = 16,
  LANG = 32,
  MOBILE = 37,
  EMAIL,
  CANC_DATE,
}

export default class HotelCubeParser extends CSVParser {
  protected getValidBookTypes() {
    return _.map(BOOK_TYPES_MAP, (v, k) => k);
  }

  protected parseBookType(type?: string): number | undefined {
    if (!type) return undefined;
    return BOOK_TYPES_MAP[type];
  }

  private pad(val: number) {
    return val < 10 ? `0${val}` : `${val}`;
  }

  private sanitizeDate(date?: string) {
    if (!date) return undefined;
    const [day, month, year] = date.split("/");
    const dayNumber = parseInt(day);
    const monthNumber = parseInt(month);

    return `${year}-${this.pad(monthNumber)}-${this.pad(dayNumber)}`;
  }

  private sumPax(
    first?: string,
    second?: string,
    third?: string,
    fourth?: string
  ) {
    return (
      parseInt(first || "0") +
      parseInt(second || "0") +
      parseInt(third || "0") +
      parseInt(fourth || "0")
    );
  }

  protected parseRow(row: string[], index: number) {
    const bookId = this.getRowData(row, CSVCOL.BOOKID);
    const name = this.getRowData(row, CSVCOL.NAME);
    const checkinRaw = this.getRowData(row, CSVCOL.CHECKIN);
    const checkoutRaw = this.getRowData(row, CSVCOL.CHECKOUT);
    const checkin = this.sanitizeDate(checkinRaw);
    const checkout = this.sanitizeDate(checkoutRaw);
    const rawBookType = this.getRowData(row, CSVCOL.BOOKTYPE);
    const bookType = this.parseBookType(rawBookType);
    const roomNumber = this.getRowData(row, CSVCOL.ROOM_NUMBER);
    const firstPax = this.getRowData(row, CSVCOL.FIRST_PAX);
    const secondPax = this.getRowData(row, CSVCOL.SECOND_PAX);
    const thirdPax = this.getRowData(row, CSVCOL.THIRD_PAX);
    const fourthPax = this.getRowData(row, CSVCOL.FOURTH_PAX);
    const mobile = this.getRowData(row, CSVCOL.MOBILE);
    const email = this.getRowData(row, CSVCOL.EMAIL);
    const deleted = _.size(this.getRowData(row, CSVCOL.CANC_DATE)) > 0;
    const langRaw = this.getRowData(row, CSVCOL.LANG);
    const lang = langRaw && _.size(langRaw) > 0 ? langRaw.toLowerCase() : "en";

    const newBook: BookPlain = {
      email,
      name,
      lang,
      deleted,
      bookId,
      bookType: bookType ? bookType : ONLY_BED,
      rawBookType,
      checkIn: checkin,
      checkOut: checkout,
      roomNumber,
      pax: this.sumPax(firstPax, secondPax, thirdPax, fourthPax),
      mobile,
      rowIndex: index,
    };

    return newBook;
  }
}
