
import Vue from "vue";
import mixins from "vue-typed-mixins";
export default mixins().extend({
  props: {
    progress: {
      type: Number as () => number,
    },
  },
  methods: {
    computeOrderProgressColor() {
      if (this.progress < 40) {
        return "rgba(244, 68, 63,0.7)";
      } else if (this.progress < 80) {
        return "rgba(222,207,0,0.7)";
      } else {
        return "rgba(79,196,0,0.7)";
      }
    },
  },
});
