<template>
  <div ref="room" class="room" :data-itemnumber="item">
    <div
      :data-book="book.book.get('localId')"
      v-on:dragstart="onDragStart"
      :ref="`book-${book.book.get('localId')}`"
      v-on:contextmenu.prevent
      v-on:touchstart="onTouchStart(book.book, $event)"
      v-on:mousedown.prevent="onMouseDownInternal(book.book, $event)"
      class="book-gantt"
      :style="{
        left: book.offset,
        width: book.width,
        'background-color': book.color,
      }"
      :class="{
        'display-hidden': dragging == book.book.get('localId'),
      }"
      v-for="book in booksByItem"
      :key="book.book.get('localId')"
      :title="
        book.book.get('refName')
          ? book.book.get('refName')
          : book.book.get('refEmail')
      "
    >
      <span class="name" style="margin-left: auto; margin-right: auto">{{
        book.book.get("refName")
          ? book.book.get("refName")
          : book.book.get("refEmail")
      }}</span>
    </div>
    <days
      v-on:startBookCreation="startBookCreation"
      style="width: 100%"
      :item="item"
      :dayWidth="dayWidth"
      :days="days"
    ></days>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import DayComponent from "./DayComponent";

export default {
  components: {
    days: DayComponent,
    // VueContext
  },
  props: {
    item: {},
    dayWidth: {},
    days: {},
    currentDate: {},
    booksByItem: {},
    onMouseDown: {},

    dragging: {},
    initLeftResize: {},
    initRightResize: {},
  },

  beforeDestroy() {
    if (this.draggingEl) document.body.removeChild(this.draggingEl);
  },
  data: function () {
    return {};
  },
  methods: {
    onTouchStart: function (book, e) {
      const touch = _.first(e.touches);
      if (!touch) return;

      return this.dragBook(book, touch.clientX, touch.clientY);
    },
    startBookCreation: function (day) {
      this.$emit("startBookCreation", day);
    },
    onDragStart: function () {
      return false;
    },
    startLeftResize: function (book, e) {
      const el = this.$refs["book-" + book.book.get("localId")][0];
      this.initLeftResize(this.$refs["book-" + book.book.get("localId")][0]);
    },
    startRightResize: function (book, e) {
      const el = this.$refs["book-" + book.book.get("localId")][0];
      this.initRightResize(this.$refs["book-" + book.book.get("localId")][0]);
    },
    checkinIsInCurrentMonth: function (book) {
      return this.currentDate.isSame(book.get("checkin"), "month");
    },
    checkoutIsInCurrentMonth: function (book) {
      return this.currentDate.isSame(book.get("checkout"), "month");
    },
    getDayBelow: function (el, x, y) {
      el.style["pointer-events"] = "none";
      const elemBelow = document.elementFromPoint(x, y);
      if (
        !elemBelow ||
        elemBelow.tagName === "HTML" ||
        elemBelow.tagName === "BODY"
      ) {
        el.style["pointer-events"] = "";
        return;
      }

      let day = elemBelow.className.includes("day")
        ? elemBelow
        : elemBelow.closest(".day");

      if (!day) day = this.getDayBelow(elemBelow, x, y);
      el.style["pointer-events"] = "";

      return day;
    },

    openMenu: function (book, e) {
      this.$emit("rightClicked", {
        book,
        event,
      });
    },

    onMouseDownInternal: function (book, e) {
      if (e.button === 2) return this.openMenu(book, e);
      return this.dragBook(book, e.clientX, e.clientY);
    },

    dragBook: function (book, x, y) {
      const bookingEl = this.$refs["book-" + book.get("localId")][0];
      const draggingEl = bookingEl.cloneNode(true);

      const rect = bookingEl.getBoundingClientRect();

      const elX = rect.left;
      const elY = rect.top;
      const draggingElementOffsetX = x - elX;
      const draggingElementOffsetY = y - elY;

      const el = this.$refs["book-" + book.get("localId")][0];

      const day = this.getDayBelow(el, x, y);

      draggingEl.style.position = "fixed";
      draggingEl.style.left = elX + "px";
      draggingEl.style.top = elY - 5 + "px";
      draggingEl.className = (draggingEl.className || "") + " dragging";
      draggingEl.style["z-index"] = 1;

      //   this.dragging = book.get("localId");
      this.onMouseDown(
        draggingEl,
        draggingElementOffsetX,
        draggingElementOffsetY,
        day.dataset.day
      );
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";

.delete-item {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  &:hover {
    font-weight: bold !important;
    color: white;
  }
}
.book-gantt:hover {
  .delete-item {
    display: block;
  }
}
.resize {
  &:hover {
    font-weight: bold;
  }
  &.left {
    cursor: w-resize;
  }
  &.right {
    cursor: e-resize;
  }
  display: none;
}
.room {
  display: flex;
  height: $book-height;
  position: relative;

  .room-number,
  .day {
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      border-right: 1px solid
        transparentize(darken($gantt-header-color, 10%), 0.6);
    }
  }
  // &:not(:last-child) {
  .day,
  .room-number {
    border-bottom: 1px solid
      transparentize(darken($gantt-header-color, 10%), 0.6);
  }

  .room-number {
    border-left: 1px solid transparentize(darken($gantt-header-color, 10%), 0.6);
  }

  // }
}

.dragging {
  .resize,
  .delete-item {
    display: none;
  }
}
</style>
