<template>
  <el-row style="width: 100%">
    <el-col
      :xs="getMainContainerSize('xs')"
      :sm="getMainContainerSize('sm')"
      :md="getMainContainerSize('md')"
      :lg="getMainContainerSize('lg')"
      :xl="getMainContainerSize('xl')"
    >
      <el-tabs
        :value="value"
        v-on:input="$emit('input', $event)"
        type="card"
        v-on:tab-click="$emit('tab-click', $event)"
        class="j-layout-tabs"
        :class="{
          tutorial: isTutorial,
          hideTabControls: hideTabControls,
        }"
      >
        <slot></slot>
      </el-tabs>
      <slot name="extraTabs"></slot>
    </el-col>
    <el-col
      class="no-print"
      v-if="!isTutorial"
      :xs="24 - xs"
      :sm="24 - sm"
      :md="24 - md"
      :lg="24 - lg"
      :xl="24 - xl"
    >
      <RightMenu v-if="$slots.rightMenu">
        <slot name="rightMenu"></slot>
        <div style="width: 100%" slot="footer">
          <slot name="rightMenuFooter"></slot>
        </div>
      </RightMenu>
    </el-col>
  </el-row>
</template>

<script>
import RightMenu from "./RightMenu";
export default {
  components: {
    RightMenu,
  },
  props: {
    value: {},
    hideTabControls: {},
  },
  data: function () {
    return {
      xs: 24,
      sm: 18,
      md: 18,
      lg: 18,
      xl: 20,
    };
  },
  methods: {
    getMainContainerSize(size) {
      if (!this.$slots.rightMenu || this.isTutorial) return 24;
      switch (size) {
        case "xs":
          return this.xs;
          break;
        case "sm":
          return this.sm;
          break;
        case "md":
          return this.md;
          break;
        case "lg":
          return this.lg;
          break;
        case "xl":
          return this.xl;
          break;
      }
    },
  },
  computed: {
    isTutorial: function () {
      return this.$route.query.tutorial == "1";
    },
  },
};
</script>

<style scoped lang="scss"></style>
<style lang="scss">
.j-layout-tabs {
  display: flex;
  flex-direction: column;
  flex: 1;
  // height: 100%;
  .el-tab-pane {
    // height: 100%;
  }
  &.tutorial {
    .el-tabs__nav-wrap {
      z-index: 0;
    }
  }
  .el-tabs__content {
    // height: 100%;
  }
}
</style>
