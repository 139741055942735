import { render, staticRenderFns } from "./JAllergen.vue?vue&type=template&id=7868587c&scoped=true&"
import script from "./JAllergen.vue?vue&type=script&lang=js&"
export * from "./JAllergen.vue?vue&type=script&lang=js&"
import style0 from "./JAllergen.vue?vue&type=style&index=0&id=7868587c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7868587c",
  null
  
)

export default component.exports