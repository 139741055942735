import Parse from "parse";
import Jpasta from "./Jpasta";
import _ from "lodash";

export default class Rate extends Jpasta {
  constructor() {
    super("Rate");
  }

  isAncestorOf(rate, rates) {
    if (!rate || !rate.get("parentRate")) return false;
    if (this.get("localId") === rate.get("parentRate").get("localId"))
      return true;
    else
      return this.isAncestorOf(
        rates[rate.get("parentRate").get("localId")],
        rates
      );
  }

  calcPriceFromParent(parentPrice) {
    if (parentPrice <= 0) return 0;
    const incType = this.get("incrementType");
    if (!incType) return 0;
    const amount = this.get("incrementAmount");
    if (amount === undefined) return 0;
    switch (incType) {
      case "percentage":
        const am = (parentPrice * amount) / 100;
        return parentPrice + am;

      case "nominal":
        return parentPrice + amount;
    }
  }

  isParent(rate) {
    if (!rate || !rate.get("parentRate")) return false;
    return this.get("localId") === rate.get("parentRate").get("localId");
  }

  getChildren(rates) {
    return _.filter(rates, (r) => {
      return this.isParent(r);
    });
  }
}

Parse.Object.registerSubclass("Rate", Rate);
