<template>
  <el-row
    ref="rowContainer"
    class="rowContainer m-0"
    style="flex-wrap: wrap"
    type="flex"
    :gutter="16"
    :class="{
      noSelect: !!this.draggingItemEl,
    }"
  >
    <div
      :class="{
        nullRow: row.id === null,
        target: targetRow === row.id,
        lastRow: index === rows.length - 1,
      }"
      :data-row="row.id"
      class="rowElementContainer"
      v-for="(row, index) in rows"
      :key="row.id"
    >
      <div
        :title="
          row.id === null && voidRowTitle
            ? voidRowTitle
            : row.name || 'Senza nome'
        "
        v-on:click="row.id === null ? undefined : editRow(row)"
        :class="{
          realRow: row.id !== null,
        }"
        class="rowElementHeader"
      >
        <span>{{ row.name || "-" }}</span>
      </div>
      <div class="rowItemsList noSelect">
        <div
          v-on:dragstart="onDragStart"
          :class="{
            hide: draggingItemEl && draggingItemEl.dataset.item === item.id,
            selected:
              searchItemString &&
              searchItemString.length &&
              (item.name || '')
                .toLowerCase()
                .includes(searchItemString.toLowerCase()),
          }"
          :ref="`item-${item.id}`"
          :data-item="item.id"
          v-on:mousedown="onMouseDown(item, $event)"
          v-on:touchstart="onMouseDown(item, $event, true)"
          class="itemCell"
          v-for="item in row.items"
          :key="item.id"
        >
          {{ item.name || "//" }}
          <span
            class="icon-cross delete-item"
            v-on:mousedown.stop
            v-on:click.prevent.stop="deleteItem(item)"
            style="
              font-size: 8px;
              margin-left: auto;
              font-weight: bold;
              cursor: pointer;
            "
          ></span>
        </div>
      </div>
      <span
        class="icon-cross deleteRowCross"
        v-on:click.prevent.stop="deleteRow(row)"
        style="
          font-size: 8px;
          margin-left: auto;
          font-weight: bold;
          cursor: pointer;
        "
      ></span>
    </div>
  </el-row>
</template>

<script>
import _ from "lodash";
import setScrollTopMixin from "@/mixins/setScrollTopMixin";
let clickTimeout;
let mouseGoUp = false;
let scrollTimeout;
const MAX_SPEED = 52;
const THRESHOLD = 50;

export default {
  mixins: [setScrollTopMixin],
  props: {
    voidRowTitle: {},
    rows: {},
    disabled: {},
    searchItemString: {},
  },
  mounted() {
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("touchmove", this.onTouchMove, { passive: false });
    window.addEventListener("mouseup", this.onMouseUp);
    window.addEventListener("touchend", this.onTouchEnd);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("touchmove", this.onTouchMove);
    window.removeEventListener("mouseup", this.onMouseUp);
    window.removeEventListener("touchend", this.onTouchEnd);
  },
  data: function () {
    return {
      editingTable: undefined,
      dialogVisible: false,
      editingRank: undefined,
      dialogVisibleRank: false,
      draggingItemEl: undefined,
      originalDraggingTableRect: undefined,
      offsetX: undefined,
      offsetY: undefined,
      rankContainerRect: undefined,
      targetRow: undefined,
      showTableDuplicateName: false,
      firstTouchX: undefined,
      firstTouchY: undefined,
      touchMovedAtX: undefined,
      touchMovedAtY: undefined,
    };
  },
  computed: {
    scrollElement: function () {
      return this.getScrollElement();
    },
  },
  methods: {
    computeScrollSpeed: function (x) {
      if (x < 0) return MAX_SPEED;
      const finalRes = (1 / x) * 100;
      return finalRes < Infinity ? finalRes : MAX_SPEED;
    },
    getRowBelow: function (el, x, y) {
      el.style["pointer-events"] = "none";
      const elemBelow = document.elementFromPoint(x, y);
      if (
        !elemBelow ||
        elemBelow.tagName === "HTML" ||
        elemBelow.tagName === "BODY"
      ) {
        el.style["pointer-events"] = "";
        return;
      }
      let rank = elemBelow.className.includes("rowElementContainer")
        ? elemBelow
        : elemBelow.closest(".rowElementContainer");

      if (!rank) rank = this.getRowBelow(elemBelow, x, y);
      el.style["pointer-events"] = "";

      return rank;
    },
    cursorUpAt(x, y) {
      mouseGoUp = true;
      if (!this.draggingItemEl) return;
      //const rowContainer = this.$refs.rowContainer.$el;
      const rowContainer = document.body;
      try {
        this.draggingItemEl.style["pointer-events"] = "none";

        const elemBelow = document.elementFromPoint(x, y);
        if (!elemBelow) throw new Error("");

        if (!elemBelow.closest(".rowContainer")) throw new Error("");

        const row = this.getRowBelow(this.draggingItemEl, x, y);

        if (!row) throw new Error("");

        const itemId = this.draggingItemEl.dataset.item;
        const rowId = row.dataset.row;

        this.$emit("dropped", {
          itemId: itemId,
          rowId: rowId,
        });
      } catch (e) {}

      rowContainer.removeChild(this.draggingItemEl);
      this.draggingItemEl = undefined;
      this.targetRow = undefined;
      this.originalDraggingTableRect = undefined;
    },
    onMouseUp: function (e) {
      this.cursorUpAt(e.clientX, e.clientY);
    },

    onTouchEnd: function (e) {
      const touch = _.first(e.changedTouches);

      if (!touch) return;
      this.cursorUpAt(touch.clientX, touch.clientY);
    },
    scroll(x, y) {
      if (!this.draggingItemEl) return;
      let scrolled = false;
      const scrollElRect = this.scrollElement.getBoundingClientRect();
      const scrollTop = this.scrollElement.scrollTop;

      if (y > window.innerHeight - THRESHOLD) {
        const SCROLL_AMOUNT = this.computeScrollSpeed(window.innerHeight - y);

        this.scrollElement.scrollTop += SCROLL_AMOUNT;
        scrolled = true;
      } else if (y <= THRESHOLD) {
        const SCROLL_AMOUNT = this.computeScrollSpeed(y);

        this.scrollElement.scrollTop -= SCROLL_AMOUNT;
        scrolled = true;
      }

      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
        scrollTimeout = undefined;
      }
      if (!scrolled) return;
      scrollTimeout = setTimeout(() => {
        this.scroll(x, y);
      }, 10);
    },
    cursorMovedAt(x, y) {
      if (!this.draggingItemEl) return;

      this.scroll(x, y);

      this.draggingItemEl.style["pointer-events"] = "none";

      const elemBelow = document.elementFromPoint(x, y);
      if (elemBelow) {
        const rowEl = elemBelow.closest(".rowElementContainer");
        if (rowEl) {
          this.targetRow = rowEl.dataset.row ? rowEl.dataset.row : null;
        } else {
          this.targetRow = undefined;
        }
      } else {
        this.targetRow = undefined;
      }

      this.draggingItemEl.style["pointer-events"] = "";
      this.draggingItemEl.style.left =
        x /* - this.rankContainerRect.left*/ - this.offsetX - 5 + "px";
      this.draggingItemEl.style.top =
        y /*- this.rankContainerRect.top*/ - this.offsetY + "px";
    },

    onTouchMove: function (e) {
      const touch = _.first(e.targetTouches);
      if (!touch) {
        return;
      }

      this.touchMovedAtX = touch.clientX;
      this.touchMovedAtY = touch.client;

      if (!this.draggingItemEl) return;
      e.preventDefault();
      e.stopPropagation();

      this.cursorMovedAt(touch.clientX, touch.clientY);
    },

    onMouseMove: function (e) {
      this.cursorMovedAt(e.clientX, e.clientY);
    },

    cursorDownAt: function (item, x, y) {
      //const rowContainer = this.$refs.rowContainer.$el;
      const rowContainer = document.body;

      const el = _.get(this.$refs, `item-${item.id}.0`);
      if (!el || !rowContainer) return;
      const rCRect = rowContainer.getBoundingClientRect();

      this.rankContainerRect = rCRect;
      const elRect = el.getBoundingClientRect();

      this.offsetX = x - elRect.left;
      this.offsetY = y - elRect.top;

      const newElement = el.cloneNode(true);
      // el.style.display = "none";
      newElement.style.position = "fixed";
      newElement.style["z-index"] = 3003;

      newElement.style.left = x /*elRect.left -rCRect.left -*/ - 5 + "px";
      newElement.style.top = y /*elRect.top - rCRect.top */ + "px";

      rowContainer.appendChild(newElement);

      this.draggingItemEl = newElement;
    },

    onMouseDown: function (item, e, isTouch) {
      if (e.button === 2) return;
      if (clickTimeout) clearTimeout(clickTimeout);

      if (isTouch) {
        var touch = _.first(e.targetTouches);
        if (!touch) return;
        this.firstTouchX = touch.clientX;
        this.firstTouchY = touch.clientY;
        this.touchMovedAtX = touch.clientX;
        this.touchMovedAtY = touch.clientY;
      }

      mouseGoUp = false;
      clickTimeout = setTimeout(() => {
        if (mouseGoUp) {
          this.editItem(item);
        } else {
          if (this.disabled) return;
          if (isTouch) {
            const x1 = this.firstTouchX;
            const y1 = this.firstTouchY;
            const x2 = this.touchMovedAtX;
            const y2 = this.touchMovedAtY;
            const distance = Math.sqrt(
              Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2)
            );

            if (distance <= 0) {
              e.preventDefault();
              this.cursorDownAt(item, touch.clientX, touch.clientY);
            }
          } else {
            this.cursorDownAt(item, e.clientX, e.clientY);
          }

          //this.startDragging(item, e);
        }
        clickTimeout = undefined;
      }, 130);
    },
    onDragStart: function () {
      return false;
    },
    editRow: function (row) {
      this.$emit("editRow", row.id);
    },
    editItem: function (item) {
      this.$emit("editItem", item.id);
    },
    deleteItem: function (item) {
      this.$emit("deleteItem", item.id);
    },
    deleteRow: function (row) {
      this.$emit("deleteRow", row.id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";
.itemCell {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  cursor: pointer;
  background-color: white;
  //background-color: darken(map-get($colors, "list-bg"), 15%);
  border: 1px solid rgba(0, 0, 0, 0.15);

  border-radius: 4px;
  padding: 8px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  &.hide {
    display: none;
  }

  margin-top: 2px;
  margin-bottom: 2px;

  &.selected {
    color: white;
    background-color: map-get($colors, "primary");
  }

  &.selected:hover {
    background-color: darken(map-get($colors, "primary"), 7%);
  }

  .icon-cross.delete-item {
    display: none !important;
    position: static !important;
    margin-left: 8px !important;
    width: 10px;
    height: 10px;
  }

  &:hover {
    .icon-cross.delete-item {
      display: flex !important;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(sm) {
        display: none !important;
      }
    }

    background-color: map-get($colors, "list-bg-hover");
  }
}
.rowContainer {
  $border: 1px solid darken(map-get($colors, "soft-grey"), 7%);
  position: relative;
  border: $border;

  .rowElementContainer {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    position: relative;

    @include media-breakpoint-up(md) {
      .icon-cross {
        position: absolute;
        right: 10px;
        display: none;
      }

      &:hover {
        .icon-cross {
          display: flex;
        }
      }
    }

    .rowItemsList {
      display: flex;
      align-items: center;
      padding: 5px 0;
      flex-wrap: wrap;
    }

    .rowElementHeader {
      flex-shrink: 0;
      height: 100%;
      overflow: hidden;
      span {
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        //flex-wrap: nowrap;
        //display: flex;
        white-space: nowrap;
        word-break: break-all;
        //width: 100%;
        //resize: horizontal;
        font-size: 9px;
      }
      width: auto;
      border-right: $border;
      padding: 10px;
      border-right: $border;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      //flex-wrap: nowrap;
      background-color: map-get($colors, "list-bg-hover");

      &.realRow:hover {
        background-color: darken(map-get($colors, "list-bg-hover"), 15%);
        cursor: pointer;
      }
    }

    &.target {
      background-color: map-get($colors, "list-bg-hover");
      //background-color: darken(map-get($colors, "list-bg-hover"), 13%);
    }

    width: 100%;

    &:not(.lastRow) {
      border-bottom: $border;
    }

    padding-right: 15px;
  }
  .newRankRow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.noSelect {
  user-select: none;
}
.table-container {
  background-color: darken(map-get($colors, "soft-grey"), 4%);
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: darken(map-get($colors, "soft-grey"), 7%);
  }
  .table-name {
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}

.tableWrapper {
  .icon-cross {
    display: none;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
  }
  &:hover {
    .icon-cross {
      display: flex;
    }
  }
}

.nullRow {
  .deleteRowCross {
    display: none !important;
  }
}
</style>

<style lang="scss"></style>
