import Parse from "parse";
import Jpasta from "./Jpasta";
import _ from "lodash";

export default class RoomType extends Jpasta {
  constructor() {
    super("RoomType");
  }

  getRates(rates, filterStrings) {
    return _.filter(rates, (r) => {
      const matchFilter =
        filterStrings === undefined || filterStrings.length <= 0
          ? true
          : !!_.find(filterStrings, (filterString) => {
              const matchFilter = (r.get("name") || "")
                .toLowerCase()
                .includes(filterString.toLowerCase());

              return matchFilter;
            });

      return (
        matchFilter &&
        _.find(r.get("types"), (t) => {
          return t && t.get("localId") === this.get("localId");
        })
      );
    });
  }
}

Parse.Object.registerSubclass("RoomType", RoomType);
