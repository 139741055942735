<template>
  <el-dialog
    :key="visible"
    :append-to-body="true"
    :fullscreen="$mq === 'xs' || isFullScreen"
    :before-close="beforeCloseInternal"
    :close-on-click-modal="true"
    :modal-append-to-body="true"
    :title="title"
    :visible="visible"
    :custom-class="customClass"
    width="80%"
  >
    <span slot="title">
      <slot name="title">
        {{ title }}

        <i
          title="Precedente"
          v-if="hasPrevious"
          style="margin-left: 10px"
          class="el-icon-arrow-up elCursor"
          v-on:click.prevent="$emit('previous')"
        ></i>
        <i
          title="Successivo"
          v-if="hasNext"
          class="el-icon-arrow-down elCursor"
          v-on:click.prevent="$emit('next')"
        ></i>
      </slot>
    </span>
    <slot></slot>
    <div v-if="$slots.footer" class="jModalFooter" slot="footer">
      <slot name="footer"></slot>
    </div>
  </el-dialog>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: {
    isFullScreen: {},
    beforeClose: {},
    visible: {},
    title: {},
    hasPrevious: {},
    hasNext: {},
    customClass: {},
  },

  mounted() {
    window.addEventListener("keydown", this.handleKeyDown, true);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  data: function () {
    return {
      keyDownTimeout: undefined,
    };
  },
  methods: {
    handleKeyDown: function (e) {
      if (!this.visible) return;
      if (this.keyDownTimeout) {
        clearTimeout(this.keyDownTimeout);
      }

      this.keyDownTimeout = setTimeout(() => {
        switch (e.keyCode) {
          case 40:
            if (this.hasNext) this.$emit("next");
            break;
          case 38:
            if (this.hasPrevious) this.$emit("previous");
            break;
        }
        this.keyDownTimeout = undefined;
      }, 100);
    },
    beforeCloseInternal: function (done) {
      if (this.beforeClose) this.beforeClose(done);
      this.$nextTick(() => {
        const scrollTop = this.$route.query[`${this.$route.name}ScrollTop`];
        if (scrollTop !== undefined) {
          this.getScrollElement().scrollTop = scrollTop;
        }
      });
    },
    getScrollElement: function () {
      const ua = navigator.userAgent.toLowerCase();

      if (ua.indexOf("safari") != -1) {
        if (ua.indexOf("chrome") === -1) {
          //safari
          return document.body;
        }
      }

      return document.documentElement;
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler: function (newVisible, oldVisible) {
        if (!oldVisible && newVisible) {
          const scrollTop = this.getScrollElement().scrollTop;

          this.$router
            .push({
              query: {
                ...this.$route.query,
                [`${this.$route.name}ScrollTop`]: scrollTop,
              },
            })
            .catch((e) => {});
        }
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.elCursor {
  cursor: pointer;
  opacity: 0.4;
  font-weight: bold;
}
</style>

<style lang="scss"></style>
