<template>
  <div class="main-container">
    <img :title="title" class="a-img" :src="icon" />
    <!-- <span class="a-title">{{title}}</span> -->
  </div>
</template>

<script>
export default {
  props: ["title", "icon"],
};
</script>

<style scoped lang="scss">
.main-container {
  margin: 0 5px;
  display: flex;
  flex-direction: column;

  .a-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
}
</style>

<style lang="scss"></style>
