<template>
  <div class="ganttRow" style="display: flex">
    <div
      :title="item !== undefined && item !== null ? item : '//'"
      class="room-number"
      :style="{
        flex: dayWidth,
      }"
    >
      <span>{{ item !== undefined && item !== null ? item : "//" }}</span>
    </div>

    <div
      v-on:mousedown="startBookCreation(day, $event)"
      class="day"
      :ref="`day-${day.date()}`"
      :style="{
        flex: dayWidth,
      }"
      v-for="(day, index) in days"
      :key="index"
      :data-day="day.toISOString()"
    ></div>
  </div>
</template>

<script>
let mouseGoUp = false;
export default {
  props: {
    item: {},
    days: {},
    dayWidth: {},
  },
  mounted() {
    window.addEventListener("mouseup", this.mouseUp);
  },
  beforeDestroy() {
    window.removeEventListener("mouseup", this.mouseUp);
  },
  methods: {
    mouseUp: function () {
      mouseGoUp = true;
    },
    startBookCreation: function (day, e) {
      if (e.button === 2) return;
      mouseGoUp = false;
      setTimeout(() => {
        if (mouseGoUp) return;
        this.$emit("startBookCreation", {
          day: day,
          x: e.clientX,
          y: e.clientY,
        });
      }, 200);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";
.ganttRow {
  &:hover {
    background-color: map-get($colors, "list-bg-hover");
  }
}
.room-number {
  word-break: break-word;
  overflow: hidden;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: 10px;
  }
}
</style>

<style></style>
