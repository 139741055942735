import Parse from "parse";
import Jpasta from "./Jpasta";
import { findJMealByLocalId } from "./constants";
import moment, { Moment } from "moment-timezone";
import { Language } from "./types";

export default class CalendarMeal extends Jpasta {
  constructor() {
    super("CalendarMeal");
  }

  public getMealName(lang: Language = "it"): string {
    const jMeal = findJMealByLocalId(this.get("mealIndex"));
    if (!jMeal) return "";
    return Jpasta.getLocalizedName(jMeal.name, lang, "");
  }

  public getEndDate(): Moment {
    return this.get("endDate")
      ? moment(this.get("endDate"))
      : moment(this.get("date"));
  }

  isInFuture(timezone) {
    return (
      moment()
        .tz(timezone)
        .subtract(2, "days")
        .isSameOrBefore(moment.tz(this.get("date"), timezone)) &&
      moment()
        .tz(timezone)
        .isAfter(moment.tz(this.get("fromDate"), timezone))
    );
  }

  canBeBooked(timezone) {
    return moment()
      .tz(timezone)
      .isBetween(
        moment(this.get("fromDate")).tz(timezone),
        moment(this.get("toDate")).tz(timezone)
      );
  }
}

Parse.Object.registerSubclass("CalendarMeal", CalendarMeal);
