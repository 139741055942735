<template>
  <div class="new-hotel-wrapper">
    <jcard v-if="step === 0" class="new-hotel-card">
      <h2 class="text-center" slot="header">Inizia addestramento</h2>

      <div class="body">
        <p class="training-copy">
          Inizia la tua esperienza su Jpasta esplorando le sue caratteristiche
          in 4 semplici passaggi!
        </p>
        <jbutton
          v-on:click.prevent.stop="
            $router
              .push({
                name: 'Hotels',
                query: {
                  tutorial: '1',
                },
              })
              .catch((e) => {})
          "
          class="new-hotel-button"
          >Inizia</jbutton
        >
        <p class="end-training">
          <span v-on:click="skipTutorial">Salta addestramento</span>
        </p>
      </div>
    </jcard>
  </div>
</template>

<script>
import Parse from "parse";
import provinces from "./Provinces";
import Vue from "vue";
import moment from "moment-timezone";
import { User, FREE_TRIAL_DAYS, Hotel } from "@/jpasta-sdk";

export default {
  data: function () {
    return {
      step: 0,
      hotelName: "",
      dishName: "",
      country: null,
      province: null,
      address: "",
      cap: "",
      city: "",
      countries: [
        { name: "Austria", ivaCode: 9, isoCode: "AT" },

        { name: "Belgio", ivaCode: 10, isoCode: "BE" },
        { name: "Bulgaria", ivaCode: 9, isoCode: "BG" },
        { name: "Cipro", ivaCode: 9, isoCode: "CY" },
        { name: "Croazia", ivaCode: 11, isoCode: "HR" },
        { name: "Danimarca", ivaCode: 8, isoCode: "DK" },
        { name: "Estonia", ivaCode: 9, isoCode: "EE" },
        { name: "Finlandia", ivaCode: 8, isoCode: "FI" },
        { name: "Francia", ivaCode: 11, isoCode: "FR" },
        { name: "Germania", ivaCode: 9, isoCode: "DE" },
        { name: "Grecia", ivaCode: 9, isoCode: "EL" },
        { name: "Irlanda", ivaCode: 8, isoCode: "IE" },
        { name: "Italia", ivaCode: 11, isoCode: "IT" },
        { name: "Austria", ivaCode: 9, isoCode: "AT" },

        { name: "Lettonia", ivaCode: 11, isoCode: "LV" },

        { name: "Lituania", ivaCode: 9, isoCode: "LT" },
        { name: "Lussemburgo", ivaCode: 8, isoCode: "LU" },
        { name: "Malta", ivaCode: 8, isoCode: "MT" },
        { name: "Olanda", ivaCode: 12, isoCode: "NL" },
        { name: "Polonia", ivaCode: 10, isoCode: "PL" },
        { name: "Portogallo", ivaCode: 9, isoCode: "PT" },
        { name: "Repubblica Ceca", ivaCode: 9, isoCode: "CZ" },
        { name: "Repubblica Slovacca", ivaCode: 10, isoCode: "SK" },
        { name: "Romania", ivaCode: 2, isoCode: "RO" },
        { name: "Slovenia", ivaCode: 8, isoCode: "SI" },
        { name: "Spagna", ivaCode: 9, isoCode: "ES" },
        { name: "Svezia", ivaCode: 12, isoCode: "SE" },
        { name: "Ungheria", ivaCode: 8, isoCode: "HU" },
      ],

      provinces: provinces,
    };
  },
  mounted() {
    // var step = this.$route.query.step;
    // if (step) {
    //   switch (parseInt(step, 10)) {
    //     case 0:
    //       this.step = 0;
    //       this.$nextTick(() => {
    //         this.$refs.hotelnameinput.focus();
    //       });
    //       break;
    //     case 1:
    //       if (!this.hotel) {
    //         this.step = 0;
    //         this.$nextTick(() => {
    //           this.$refs.hotelnameinput.focus();
    //         });
    //       } else {
    //         this.hotelName = this.$route.query.name;
    //         this.step = 1;
    //         this.$nextTick(() => {
    //           this.$refs.hoteladdressinput.focus();
    //         });
    //       }
    //       break;
    //   }
    // } else {
    //   this.step = 0;
    //   this.$nextTick(() => {
    //     this.$refs.hotelnameinput.focus();
    //   });
    // }
  },
  computed: {
    hotel: function () {
      return _.find(this.$store.state.hotels.objects, (hotel) => {
        return hotel.get("localId") === this.$route.query.hotel;
      });
    },
    fromReg: function () {
      return this.$route.query.fromReg == 1;
    },
  },
  methods: {
    skipTutorial: function () {
      const user = this.$store.state.users.current;

      user.set("tutorialCompleted", true);

      this.$store.dispatch("users/saveObj", user);

      this.$router
        .push({
          name: "Hotels",
        })
        .catch((e) => {});
    },
    toHome: function () {
      this.$router.push({
        name: "Hotels",
      });
    },

    toStep1: function () {
      if (!this.hotelName.length) {
        return this.$msg("Compila tutti i campi");
      }

      var hotel = new Hotel();
      hotel.set("name", this.hotelName);
      hotel.set("owner", User.current());
      hotel.set(
        "expireDate",
        moment().add(FREE_TRIAL_DAYS, "days").endOf("day").toDate()
      );
      hotel.set(
        "timezone",
        moment.tz.guess() ? moment.tz.guess() : "Europe/Rome"
      );

      this.$store.dispatch("hotels/saveObj", hotel).then((newHotel) => {
        this.$router.push({
          query: {
            hotel: newHotel.get("localId"),
            step: 1,
          },
        });

        this.step = 1;

        this.$nextTick(() => {
          this.$refs.hoteldishinput.focus();
        });
      });
    },
    toStep2: function () {
      // if (
      //   !this.address.length ||
      //   !this.province ||
      //   !this.cap.length ||
      //   !this.city.length
      // ) {
      //   return this.$msg("Compila i campi richiesti");
      // }
      // var province = _.find(this.provinces, p => {
      //   return p.id === this.province;
      // });
      // var hotel = new Hotel();
      // hotel.set("name", this.hotelName);
      // hotel.set("address", this.address);
      // hotel.set("cap", this.cap);
      // hotel.set("province", province);
      // hotel.set("city", this.city);
      // hotel.set("owner", User.current());
      // hotel.set(
      //   "expireDate",
      //   moment()
      //     .add(FREE_TRIAL_DAYS, "days")
      //     .endOf("day")
      //     .toDate()
      // );
      // hotel.set(
      //   "timezone",
      //   moment.tz.guess() ? moment.tz.guess() : "Europe/Rome"
      // );
      // this.$store.dispatch("hotels/saveObj", hotel);
      // this.$router.push({
      //   name: "Hotels"
      // });
    },
  },
  watch: {
    province: function () {},
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants";

.end-training {
  text-decoration: underline;
  opacity: 0.4;
  text-align: center;
  margin-top: 1.5em;
  font-size: 14px;
  span {
    cursor: pointer;
  }
}

.training-copy {
  text-align: center;
  font-size: 18px;
}

.new-hotel-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #f4443f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-hotel-card {
  font-size: 16px;
}

.main-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  .new-hotel-input {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    & {
      width: 70%;
    }
  }

  .new-hotel-input:not(:first-child) {
    margin-top: 0.5em;
  }
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2em;
}

.first-card-body {
  flex-direction: row;
}

.first-card-body .jbutton:nth-child(1) {
  margin-right: 0.3em;
}

.first-card-body .jbutton:nth-child(2) {
  margin-left: 0.3em;
}

.new-hotel-select {
  width: calc(90%);
  margin-top: 0.8em;
}

.new-hotel-card {
  width: 90%;
}
@media only screen and (min-width: 768px) {
  .new-hotel-card {
    max-width: 560px;
  }

  .new-hotel-input {
    width: 70%;
  }

  .new-hotel-select {
    width: calc(70%);
  }
}

.new-hotel-button {
  margin-top: 1em;
}
</style>

<style>
.new-hotel-select > .dropdown-toggle > input,
.new-hotel-select.v-select input[type="search"]:focus {
  /*font-size: 0.8em;*/
  font-weight: 100;
}
</style>
