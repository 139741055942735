<template>
  <div
    sticky-container
    class="rootContainer"
    :class="{
      fullWidth: fullWidth,
      hasRightMenu: (hasRightMenu || $slots.rightMenu) && !isTutorial,
    }"
  >
    <el-row style="width: 100%">
      <el-col
        :xs="getMainContainerSize('xs')"
        :sm="getMainContainerSize('sm')"
        :md="getMainContainerSize('md')"
        :lg="getMainContainerSize('lg')"
        :xl="getMainContainerSize('xl')"
      >
        <JModal
          v-if="$slots.modalContent"
          :before-close="beforeCloseModal ? beforeCloseModal : undefined"
          :title="modalTitle ? modalTitle : ''"
          :visible="dialogVisible"
        >
          <slot name="modalContent"></slot>
        </JModal>
        <jbutton
          v-if="backAction && backLabel"
          v-on:click="backAction"
          type="link"
          style="margin-bottom: 16px"
          >{{ backLabel }}</jbutton
        >
        <slot name="titleWrapper">
          <div
            v-if="$slots.title || $slots.mainCtaWrapper || $slots.maincta"
            :class="{
              stickedTitle: stickedTitle && !isTutorial,
            }"
            on-stick="onStick"
            v-sticky="
              $mq !== 'xs' &&
              $mq !== 'sm' &&
              sticky &&
              !isTutorial &&
              $slots.maincta
            "
            class="no-print"
            sticky-side="both"
            sticky-offset="stickyOffset"
          >
            <el-row class="titleWrapperRow">
              <el-col
                v-if="$slots.title"
                :xs="24"
                :sm="$slots.maincta || $slots.mainCtaWrapper ? 12 : 24"
                :md="$slots.maincta || $slots.mainCtaWrapper ? 12 : 24"
                :lg="$slots.maincta || $slots.mainCtaWrapper ? 12 : 24"
                :xl="$slots.maincta || $slots.mainCtaWrapper ? 12 : 24"
              >
                <h3 class="text-sm-left text-center w-100 layoutTitle">
                  <slot name="title"></slot>
                </h3>
              </el-col>
              <el-col
                v-if="$slots.maincta || $slots.mainCtaWrapper"
                :xs="24"
                :sm="$slots.title && !stickedTitle ? 12 : 24"
                :md="$slots.title && !stickedTitle ? 12 : 24"
                :lg="$slots.title && !stickedTitle ? 12 : 24"
                :xl="$slots.title && !stickedTitle ? 12 : 24"
                :class="{
                  //justifyCenter: stickedTitle
                }"
                class="mainCtaWrapper"
              >
                <slot name="mainCtaWrapper">
                  <Jadvice
                    placement="top"
                    :title="buttonAdviceTitle"
                    :visible="buttonAdviceVisible"
                  >
                    <jbutton
                      :loading="loadingCta"
                      slot="trigger"
                      ref="main-cta-ref"
                      v-on:click="$emit('click')"
                      :class="{
                        'tutorial-button': blinkingButton,
                      }"
                      class="mt-sm-0"
                    >
                      <slot name="maincta"></slot>
                    </jbutton>
                    <slot name="buttonAdviceContent"></slot>
                  </Jadvice>
                </slot>
              </el-col>
            </el-row>
          </div>

          <el-row
            v-if="$slots['below-title']"
            class="flex-wrap mt-1 no-print"
            type="flex"
          >
            <el-col
              class="text-right justify-content-center justify-content-sm-start d-flex align-items-center"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
            >
              <slot name="below-title"></slot>
            </el-col>
          </el-row>
          <el-row
            v-if="
              $slots.maincta ||
              $slots['below-title'] ||
              $slots.title ||
              $slots.mainCtaWrapper
            "
            class="mt-2 no-print no-shrink separatorLine"
            style="height: 1px"
          ></el-row>
        </slot>
        <el-row class="mainSlotWrapper justifyCenter">
          <el-col
            :class="{
              'mt-2': $slots.maincta || $slots['below-title'] || $slots.title,
            }"
            :style="{
              ...(loadingForSync
                ? {
                    'min-height': '300px',
                  }
                : {}),
              ...(mainSlotWrapperStyle || {}),
            }"
            class="mainSlotWrapper"
            v-jpasta-loading="loadingForSync"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <slot v-if="!loadingForSync"></slot>
          </el-col>
        </el-row>
      </el-col>
      <el-col
        class="no-print"
        v-if="!isTutorial"
        :xs="24 - xs"
        :sm="24 - sm"
        :md="24 - md"
        :lg="24 - lg"
        :xl="24 - xl"
      >
        <RightMenu v-if="$slots.rightMenu && !isTutorial">
          <slot name="rightMenu"></slot>
          <div style="width: 100%" slot="footer">
            <slot name="rightMenuFooter"></slot>
          </div>
        </RightMenu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import RightMenu from "./RightMenu";
import Jadvice from "@/components/ui/JAdvice.vue";
export default {
  components: {
    RightMenu,
    Jadvice,
  },
  props: {
    loadingCta: {},
    backLabel: {},
    backAction: {},
    buttonAdviceVisible: {},
    buttonAdviceTitle: {},
    loadingForSync: {},
    fullWidth: {},
    hasRightMenu: {},
    beforeCloseModal: {},
    mainSlotWrapperStyle: {},
    dialogVisible: {},
    blinkingButton: {},
  },
  data: function () {
    return {
      sticky: true,
      stickedTitle: false,
      xs: 24,
      sm: 18,
      md: 18,
      lg: 18,
      xl: 20,
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.visiblePopover = true;
    // }, 400);
    //this.rightMenus = document.getElementsByClassName("rightMenuWrapper");
  },
  beforeDestroy() {
    this.sticky = false;
  },
  methods: {
    getMainContainerSize(size) {
      if (!this.$slots.rightMenu || this.isTutorial) return 24;
      switch (size) {
        case "xs":
          return this.xs;
          break;
        case "sm":
          return this.sm;
          break;
        case "md":
          return this.md;
          break;
        case "lg":
          return this.lg;
          break;
        case "xl":
          return this.xl;
          break;
      }
    },
    onStick: function (data) {
      this.stickedTitle = data.sticked;

      this.$store.commit("setSticked", data.sticked);

      // const els = this.rightMenus;
      // if (data.sticked) {
      //   _.forEach(els, e => {
      //     if (!e.className.includes("menuSticked")) {
      //       e.className = e.className + " menuSticked";
      //     }
      //   });
      // } else {
      //   _.forEach(els, e => {
      //     e.className = e.className.replace("menuSticked", "");
      //   });
      // }
    },
  },
  computed: {
    stickyOffset: function () {
      return { top: 64, left: this.$store.state.leftBarWidth - 20 };
    },
    isTutorial: function () {
      return this.$route.query.tutorial == "1";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";

$rootContainerPadding: 20px;
$rootContainerPaddingDouble: $rootContainerPadding + $rootContainerPadding;
.separatorLine {
  background-color: rgba(0, 0, 0, 0.09);
  //box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}

.justifyCenter {
  justify-content: center !important;
}
.rightMenuWrapper {
  position: relative;
  .rightMenu {
    position: fixed;
    top: 64px;
    right: 0px;
    bottom: 0px;
    padding: 10px 10px;
    // width: 100%;
    background-color: blue;
  }
}

.rootContainer {
  box-sizing: border-box;
  padding: 0 $rootContainerPadding;
  padding-top: $rootContainerPadding;

  @include media-breakpoint-down(sm) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  width: 100%;
  @include media-breakpoint-up(sm) {
    height: 100%;
    // width: 70%;
    margin-left: auto;
    margin-right: auto;
    // &.hasRightMenu {
    //   width: 90%;
    // }
  }
  @include media-breakpoint-up(lg) {
    height: 100%;
    // width: 70%;
    margin-left: auto;
    margin-right: auto;
    // &.hasRightMenu {
    //   width: 90%;
    // }
  }
  @include media-breakpoint-up(xl) {
    height: 100%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    // &.hasRightMenu {
    //   width: 80%;
    // }
  }
  &.fullWidth {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
}
.mainCtaWrapper {
  display: flex;
  justify-content: center;
  @include media-breakpoint-up(sm) {
    justify-content: flex-end;
  }

  align-items: center;
}
.mainSlotWrapper {
  // height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.justifyCenter {
  align-items: center;
}

.layoutTitle {
  transition: font-size 200ms;
}

.stickedTitle {
  &:empty {
    visibility: hidden;
  }
  background: white;
  // left: 0 !important;
  // right: 0 !important;
  // width: 100% !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  .layoutTitle {
    font-size: 16px !important;
  }

  .titleWrapperRow {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(sm) {
      height: 100%;
      //width: calc(70% - #{$rootContainerPaddingDouble});
      //margin-left: auto;
      // margin-right: auto;
    }
    @include media-breakpoint-up(lg) {
      height: 100%;
      //width: calc(70% - #{$rootContainerPaddingDouble});
      //margin-left: auto;
      //margin-right: auto;
    }
    @include media-breakpoint-up(xl) {
      height: 100%;
      // width: calc(65% - #{$rootContainerPaddingDouble});
      //margin-left: auto;
      //margin-right: auto;
    }
  }
}

.tutorial-button {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@keyframes glowing {
  0% {
    background-color: map-get($colors, "primary");
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: darken(map-get($colors, "primary"), 10%);
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: darken(map-get($colors, "primary"), 20%);
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}
</style>

<style lang="scss"></style>
