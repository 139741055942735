import { useCallback, useState } from "react";
import Parse from "parse";
import Axios from "axios";

export function useMarkOrderAsIncomplete() {
  const [loading, setLoading] = useState(false);
  const markOrderAsIncomplete = useCallback(
    async (calendarMealId: string, bookId: string) => {
      try {
        setLoading(true);
        await Axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/custom/mark-as-incomplete`,
          {
            calendarMealId,
            bookId,
          },
          {
            headers: {
              "x-parse-session-token": Parse.User.current()?.getSessionToken(),
            },
          }
        );
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    loading,
    markOrderAsIncomplete,
  };
}
