
import _ from "lodash";
import moment from "moment-timezone";
import {
  Jpasta,
  findHotelRateByLocalId,
  CalendarMeal,
  MealOrder,
  Rank,
  Table,
  Book,
  Dish,
  MealOrderDish,
  Turn,
  Hotel,
} from "@/jpasta-sdk";
import mixins from "vue-typed-mixins";
let mouseGoUp = false;

type ReportDataDishItem = {
  dish: MealOrderDish;
  qty: number;
  annotations: any[];
};

type TurnItem = {
  type: "turn";
  turnName: string;
  turnLocalId: string;
  key: string;
  firstTurn: boolean;
};

type ReportDataItem = {
  book: Book;
  dishes: {
    [key: string]: ReportDataDishItem;
  };
  completed: boolean;
  completedMealOrdersLength: number;
  peopleLength: number;
  isIncluded: boolean;
  rateName: string;
  firstOfRank: boolean;
  type: "book" | "rank" | "group-block";
  key: string;
  pos: number;
  deliveryExplanations: string[];
};

export default mixins().extend({
  props: {
    books: {
      type: Array as () => Book[],
    },
    calendarMeal: {
      type: Object as () => CalendarMeal,
    },
    mealOrders: {
      type: Array as () => MealOrder[],
    },
    mealOrderDishesMap: {
      type: Object as () => any,
    },
    bookPeopleMap: {
      type: Object as () => any,
    },
    ranks: {
      type: Array as () => Rank[],
    },
    tables: {
      type: Array as () => Table[],
    },
    showNotIncludedOrders: {
      type: Boolean as () => any,
    },
    showUncompletedOrders: {
      type: Boolean as () => any,
    },
    showCompletedOrders: {
      type: Boolean as () => any,
    },
  },
  data: function (): {
    searchMealBook: any;
    searchMealBookType: string;
    dialogVisible: boolean;
    filterWaiterReportOptions: any[];
    searchMealBookString: string | undefined;
  } {
    return {
      // showCompletedOrders: true,
      // showUncompletedOrders: true,
      // showNotIncludedOrders: true,
      searchMealBook: undefined,
      searchMealBookType: "table",
      dialogVisible: false,
      filterWaiterReportOptions: [],
      searchMealBookString: undefined,
    };
  },
  mounted() {},
  methods: {
    getCompletedColor(ratio: number): string {
      if (ratio < 0.5) return "#f4443f";
      else return "#F2C100";
    },
    formatTurnName(name: string) {
      if (!name) return "";
      if (_.toLower(name).includes("turno")) return name;
      return `Turno ${name}`;
    },
    getTakeAwayOrders() {},
    getDeliveryOrders() {},
    changeSearchMealBookString: function (val): any {
      if (val === "all") {
        this.searchMealBookString = undefined;
        this.filterWaiterReportOptions = [];
      } else {
        this.searchMealBookString = val;
      }
    },
    doFilterWaiterReportOptions: function (queryString): any {
      if (!queryString || !queryString.length)
        return (this.filterWaiterReportOptions = []);
      this.filterWaiterReportOptions = [
        {
          value: "room-" + queryString,
          label: `Camera: ${queryString}`,
        },
        {
          value: "table-" + queryString,
          label: `Tavolo: ${queryString}`,
        },
        {
          value: "name-" + queryString,
          label: `Nome: ${queryString}`,
        },
        {
          value: "all",
          label: `Pulisci filtro`,
        },
      ];
    },
    openFiltersModal: function (): any {
      this.dialogVisible = true;
    },
    beforeCloseModal: function (): any {
      this.dialogVisible = false;
    },
    formatRankName: function (name) {
      return (name || "").toLowerCase().includes("rango")
        ? name
        : `Rango ${name}`;
    },
    mouseUp: function (): any {
      mouseGoUp = true;
    },
    mouseDown: function (book, e): any {
      if (e.button === 2) return;
      mouseGoUp = false;

      setTimeout(() => {
        if (mouseGoUp) this.editMealBookOf(book);
      }, 130);
    },
    editMealBookOf: function (book): any {
      //console.log("EditMealBookTurn", this.$route.params.turnid);
      if (this.$route.params.turnid) {
        this.$router
          .push({
            name: "EditMealBookTurn",
            params: {
              ...this.$route.params,
              bookid: book.get("localId"),
            },
          })
          .catch((e) => {});
      } else {
        this.$router
          .push({
            name: "EditMealBook",
            params: {
              ...this.$route.params,
              bookid: book.get("localId"),
            },
          })
          .catch((e) => {});
      }
    },
    getBookRateName: function (book): any {
      const rate =
        book.get("bookType") !== undefined
          ? findHotelRateByLocalId(book.get("bookType"))
          : null;

      return rate ? rate.name.it : "";
    },
    getBookTitle: function (book: Book): any {
      const tableName = book.getTableNumber();
      const roomName = book.getRoomNumber();
      const res: string[] = [];

      const bookRefName = book.get("refName");
      if (_.size(bookRefName) > 0) {
        res.push(bookRefName);
      }

      if (!this.isTableSameAsRoom) {
        if (tableName && tableName.length) {
          res.push(`Tavolo ${tableName}`);
        } else {
          res.push("<span style='color: #f4443f'>Senza tavolo</span>");
        }
      }

      if (roomName && roomName.length) {
        res.push(`Camera ${roomName}`);
      } else if (this.isTableSameAsRoom) {
        res.push(`<span style='color: #f4443f'>Senza tavolo</span>`);
      }

      const rate =
        book.get("bookType") !== undefined
          ? findHotelRateByLocalId(book.get("bookType"))
          : null;
      if (rate) {
        res.push(`${rate.name.it}`);
      }

      if (this.bookPeopleMap[book.get("localId")]) {
        res.push(`PAX ${this.bookPeopleMap[book.get("localId")].length}`);
      }

      return res.join(" - ");
    },
    getLocalizedName: function (name): any {
      return Jpasta.getLocalizedName(name, "it", "Nessun nome");
    },
    parseSearchMealBookType() {
      if (!this.searchMealBookString || !this.searchMealBookString.length)
        return null;
      const type = this.searchMealBookString.substring(
        0,
        this.searchMealBookString.indexOf("-")
      );
      const value = this.searchMealBookString.substring(
        this.searchMealBookString.indexOf("-") + 1
      );
      return {
        type,
        value,
      };
    },
    addItem(res, key, item, pos) {
      if (res[key]) return pos;
      item.pos = pos;
      item.key = key;
      res[key] = item;
      pos++;
      return pos;
    },
    filterBooks(books: Book[], searchMealBook: any): Book[] {
      let res: Book[] = [];
      if (!searchMealBook) res = books;
      else
        res = _.filter(books, (b) => {
          switch (searchMealBook.type) {
            case "name":
              const refName = b.get("refName");
              if (!refName) return false;
              return _.toLower(refName).includes(
                _.toLower(searchMealBook.value)
              );

            case "table":
              const tableName = b.getTableNumber();
              return !!(tableName || "")
                .toLowerCase()
                .includes(searchMealBook.value.toLowerCase());
            case "room":
              const roomName = b.getRoomNumber();
              return !!(roomName || "")
                .toLowerCase()
                .includes(searchMealBook.value.toLowerCase());
          }
          return false;
        });

      return _.sortBy(res, (b) => {
        if (b.isIncluded(this.calendarMeal)) return 0;
        return Infinity;
      });
    },

    addItemsByBooks: function (
      books: Book[],
      res: {
        [key: string]: ReportDataItem;
      },
      pos: number,
      mealOrderFilter?: (mo: MealOrder) => boolean,
      debug?: boolean
    ) {
      let firstOfGroup = true;
      _.forEach(books, (b) => {
        const mealOrders = _.filter(this.mealOrders, (mo) => {
          return mo.belongsTo(b, "book");
        });

        const people = this.bookPeopleMap[b.get("localId")] || [];

        const key = `${b.get("localId")}-${pos}`;

        const isIncluded = b.isIncluded(this.calendarMeal);

        if (this.showNotIncludedOrders === false && !isIncluded) return;

        pos = this.addItem(
          res,
          key,
          {
            book: b,
            dishes: {},
            completed: people.length > mealOrders.length ? false : true,
            completedMealOrdersLength: _.filter(mealOrders, (mo) => {
              return mo.get("completed");
            }).length,
            peopleLength: people.length,
            isIncluded: isIncluded,
            rateName: this.getBookRateName(b),
            firstOfRank: firstOfGroup,
            type: "book",
            deliveryExplanations: [],
          },
          pos
        );
        firstOfGroup = false;

        _.forEach(mealOrders, (mo) => {
          const dishes = this.mealOrderDishesMap[mo.get("localId")] || [];
          if (!mo.get("book")) return;
          const locationExpl = mo.getLocDeliveryExpl();
          if (mo.get("delivery") && locationExpl && locationExpl.length)
            res[key].deliveryExplanations.push(locationExpl);
          if (!mo.get("completed")) res[key].completed = false;

          _.forEach(dishes, (d) => {
            if (!d.get("dish")) return;
            const dishKey = d.get("dish").get("localId");
            const qty = d.get("quantity") || 0;
            if (qty <= 0) return;
            res[key].dishes[dishKey] = res[key].dishes[dishKey] || {
              dish: d,
              qty: 0,
              annotations: [],
            };

            res[key].dishes[dishKey].qty = res[key].dishes[dishKey].qty + qty;
            const annotation = d.get("annotation");
            if (annotation && annotation.length) {
              res[key].dishes[dishKey].annotations.push(annotation);
            }
          });
        });
      });

      return pos;
    },
    filterBooksByTurn: function (books: Book[], turn: Turn | undefined) {
      return _.filter(books, (b) => {
        const tableName = b.getTableNumber();

        if (tableName === undefined && turn === undefined) return true;
        const tableTurn = this.turnByTable[tableName as string];

        if (tableTurn === undefined && turn === undefined) return true;

        return !!(
          turn &&
          tableTurn &&
          turn.get("localId") === tableTurn.get("localId")
        );
      });
    },
  },
  computed: {
    tablesByName: function () {
      return _.keyBy(this.tables, (t) => {
        return t.get("name");
      });
    },
    isTableSameAsRoom: function (): boolean {
      return this.hotel.getTableIsSameAsRoom();
    },
    turnByTable: function (): {
      [key: string]: Turn | undefined;
    } {
      return _.reduce(
        this.tables,
        (res, t) => {
          res[t.get("name")] = t.get("turn");

          return res;
        },
        {}
      );
    },
    // turns: function(): Turn[] {
    //   return _.filter(this.$store.state.turns.objects, (o: Turn) => {
    //     return o.belongsTo(this.hotel, "hotel");
    //   });
    // },
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    getBooksByRankAndTurn: function (): any {
      return _.memoize(
        (rank: Rank, turn: Turn) => {
          let tables = _.filter(this.tables, (t) => {
            return !!(
              t.get("name") &&
              t.get("name").length &&
              (rank ? t.belongsTo(rank, "rank") : !t.get("rank")) &&
              (turn ? t.belongsTo(turn, "turn") : !t.get("turn"))
            );
          });

          const tablesMap = _.keyBy(tables, (t) => {
            return t.get("name");
          });

          const books = _.filter(this.books, (b) => {
            return !!(
              (b.getTableNumber() === undefined && !rank) ||
              tablesMap[b.getTableNumber() as string]
            );
          });

          return this.filterBooksByTurn(books, turn);
        },
        (rank, turn) => {
          return (
            (rank ? rank.get("localId") : "noRank") +
            "-" +
            (turn ? turn.get("localId") : "noTurn") +
            "-" +
            this.$store.state.tables.version +
            "-" +
            this.$store.state.ranks.version +
            "-" +
            this.$store.state.books.version +
            "-" +
            this.$store.state.turns.version
          );
        }
      );
    },
    getBooksOf: function (): any {
      return _.memoize(
        (rank) => {
          let tables = _.filter(this.tables, (t) => {
            return !!(
              t.get("name") &&
              t.get("name").length &&
              (rank ? t.belongsTo(rank, "rank") : !t.get("rank"))
            );
          });

          const tablesMap = _.keyBy(tables, (t) => {
            return t.get("name");
          });

          return _.filter(this.books, (b) => {
            if (!rank) {
              if (
                !b.getTableNumber() ||
                !this.tablesByName[b.getTableNumber() as string]
              )
                return true;

              if (tablesMap[b.getTableNumber() as string]) return true;
              //console.log("b.getTableNumber()", b.getTableNumber());
              return false;
            }

            return (
              (b.getTableNumber() === undefined && !rank) ||
              tablesMap[b.getTableNumber() as string]
            );
          });
        },
        (rank) => {
          return (
            (rank ? rank.get("localId") : "noRank") +
            "-" +
            this.$store.state.tables.version +
            "-" +
            this.$store.state.ranks.version +
            "-" +
            this.$store.state.books.version
          );
        }
      );
    },

    reportDataNew: function (): (ReportDataItem | TurnItem)[] {
      let pos = 0;

      const searchMealBook = this.parseSearchMealBookType();

      const ranks = [null, ...this.ranks];
      let reportDataWithTurn: (ReportDataItem | TurnItem)[] = [];
      let firstTurn = true;

      let firstRank = true;

      const reportData: {
        [key: string]: ReportDataItem;
      } = _.reduce(
        ranks,
        (res, r) => {
          const books = this.filterBooks(this.getBooksOf(r), searchMealBook);

          if (searchMealBook) {
            if (!books.length) return res;
          }

          let firstOfRank = true;
          const rankId = r ? r.get("localId") : "noRank";
          const rankKey = `rank-${rankId}`;
          if (r || books.length) {
            pos = this.addItem(
              res,
              rankKey,
              {
                firstRank: firstRank,
                type: "rank",
                rank: r ? r.get("name") || "Senza nome" : "Senza rango",
              },
              pos
            );
            firstRank = false;
          }

          if (!books.length) {
            const key = `missing-${rankKey}`;
            if (r) {
              pos = this.addItem(
                res,
                key,
                {
                  type: "missing",
                  rank: r.get("name") || "Senza nome",
                },
                pos
              );
            }
          }

          pos = this.addItemsByBooks(books, res, pos);
          return res;
        },
        {}
      );

      const finalReportData = _.sortBy(reportData, (r) => {
        return r.pos;
      });

      if (this.showUncompletedOrders === false) {
        _.remove(finalReportData, (r) => {
          return r.completed === false;
        });
      }

      if (this.showCompletedOrders === false) {
        _.remove(finalReportData, (r) => {
          return r.completed === true;
        });
      }

      reportDataWithTurn = reportDataWithTurn.concat(finalReportData);
      // });

      return reportDataWithTurn;
    },
  },
});
