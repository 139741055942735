<template>
  <el-row
    ref="main-wrapper"
    type="flex"
    justify="center"
    class="main rootContainer w-100"
  >
    <el-col
      v-jpasta-loading="loadingForSync"
      class="left-wrapper"
      :xs="showMenu ? 12 : 23"
      :sm="showMenu ? 12 : 23"
      :md="showMenu ? 12 : 23"
      :lg="showMenu ? 14 : 20"
      :xl="showMenu ? 16 : 18"
    >
      <slot></slot>
    </el-col>
    <el-col
      :style="{
        ...menuStyle,
        top:
          isTutorial && ($mq === 'xs' || $mq === 'sm' || $mq === 'md')
            ? '84px'
            : '64px',
        bottom:
          isTutorial && ($mq === 'xs' || $mq === 'sm' || $mq === 'md')
            ? '-2px'
            : '',
      }"
      v-if="showMenu"
      class="menu-wrapper no-print"
      :xs="12"
      :sm="12"
      :md="12"
      :lg="10"
      :xl="8"
    >
      <div class="menu">
        <div class="menu-header">
          <slot name="menu-header"></slot>
          <slot name="menu-header-control">
            <span
              v-on:click.prevent.stop="autoclose ? close : $emit('close')"
              class="icon"
              >&#10005;</span
            >
          </slot>
        </div>
        <div class="menu-body" ref="menu-body">
          <slot name="menu-body"></slot>
        </div>
        <!-- v-on:touchstart.prevent
          v-on:touchmove.prevent -->
        <div :style="footerStyle" class="menu-footer">
          <slot name="menu-footer"></slot>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import disableBodyScroll from "./disableBodyScroll";

export default {
  props: {
    menuStyle: {},
    loadingForSync: {},

    footerStyle: {},
    autoclose: {
      default: true,
    },
  },
  mounted() {},
  beforeDestroy() {
    this.clearFixMobileStyle();
  },
  data: function () {
    return {
      showMenu: false,
    };
  },

  methods: {
    preventDefault: function (e) {
      if (
        !this.$refs["menu-body"].contains(e.target) ||
        !this.$refs["menu-body"].contains(
          document.elementFromPoint(e.clientX, e.clientY)
        )

        /*||
        e.target != this.$refs["menu-body"]*/
      )
        e.preventDefault();
    },
    open: function () {
      this.showMenu = true;

      this.setFixMobileStyle();
    },

    clearFixMobileStyle: function () {
      document.querySelector("html").style["overflow-y"] = "";
      document.querySelector("#app-main-wrapper").style["overflow-y"] = "";
      document.querySelector("body").style.position = "";
      document.querySelector("html").style.position = "";
    },

    setFixMobileStyle: function () {
      if (this.$mq === "xs" || this.$mq === "sm" || this.$mq === "md") {
        document.querySelector("html").style["overflow-y"] = "hidden";
        document.querySelector("body").style.position = "fixed";
        document.querySelector("body").style["z-index"] = 0;
        document.querySelector("html").style["z-index"] = 0;
        // document.querySelector("html").style.position = "fixed";

        // document.querySelector("#app-main-wrapper").style["overflow-y"] =
        //   "hidden";
      }
    },

    close: function () {
      this.showMenu = false;
      this.clearFixMobileStyle();
      // document.body.removeEventListener("touchmove", this.preventDefault);
    },
    isOpened: function () {
      return this.showMenu;
    },
  },
  computed: {
    isTutorial: function () {
      return this.$route.query.tutorial == "1";
    },
  },
  watch: {
    $mq: {
      immediate: true,
      handler: function (newMq) {
        if (!this.showMenu) {
          return this.clearFixMobileStyle();
        }

        if (newMq !== "xs" && newMq !== "sm" && newMq !== "md") {
          this.clearFixMobileStyle();
        } else {
          this.setFixMobileStyle();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";
$menu-border-radius: 4px;
.rootContainer {
  min-height: 300px;
}
.main {
  padding: 1em;
  display: flex;
  max-height: 100%;

  @include media-breakpoint-down(sm) {
    padding: 0.1em;
  }

  .menu-wrapper {
    max-height: none !important;
    @include media-breakpoint-down(md) {
      position: fixed;
      top: 64px;
      left: 20.8333333%;
      right: 0;
      bottom: 0;
      width: auto;
    }
    @include media-breakpoint-down(sm) {
      position: fixed;
      top: 64px;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }

    // @include media-breakpoint-down(sm) {
    //   bottom: 50px;
    // }
    .menu {
      border: 1px solid darken(rgb(251, 251, 251), 10%);

      @include media-breakpoint-down(md) {
        border-color: transparent;
      }

      min-height: 10px;
      border-radius: $menu-border-radius;
      height: 100%;
      display: flex;
      flex-direction: column;

      .menu-header {
        border-top-right-radius: $menu-border-radius;
        border-top-left-radius: $menu-border-radius;
        display: flex;
        align-items: center;

        .slot-wrapper {
          flex: 1;
        }
        .icon {
          margin-left: auto;
          cursor: pointer;
        }
      }

      .menu-footer {
        border-bottom-right-radius: $menu-border-radius;
        border-bottom-left-radius: $menu-border-radius;
      }

      .menu-body,
      .menu-header,
      .menu-footer {
        padding: 10px;
      }

      .menu-header,
      .menu-footer {
        background-color: darken(rgb(251, 251, 251), 3%);
      }

      .menu-body {
        background-color: white;
        border-top: 1px solid darken(rgb(251, 251, 251), 10%);
        border-bottom: 1px solid darken(rgb(251, 251, 251), 10%);
        flex: 1;
        overflow-y: auto;
        //-webkit-overflow-scrolling: touch;
      }
    }
  }

  .menu-wrapper,
  .left-wrapper {
    padding: 10px;
  }

  .menu-wrapper {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }
}
</style>
