<template>
  <div class="calendar">
    <div class="header">
      <div class="navigator">
        <span
          v-on:click.prevent.stop="prevMonth"
          class="icon-left-arrow arrow"
        ></span>

        <span class="month">{{ getCurrMonthString() }}</span>

        <span
          v-on:click.prevent.stop="nextMonth"
          class="icon-right-arrow arrow"
        ></span>
      </div>
      <div class="days-of-week">
        <span>Lun</span>
        <span>Mar</span>
        <span>Mer</span>
        <span>Gio</span>
        <span>Ven</span>
        <span>Sab</span>
        <span>Dom</span>
      </div>
    </div>
    <div class="body">
      <Jadvice
        :title="adviceTitle"
        :visible="isAdviceVisible(day)"
        :key="index"
        class="advice-calendar"
        v-for="(day, index) in days"
      >
        <div
          slot="trigger"
          class="calendar-cell"
          v-on:mouseenter="mouseEnter(day, $event)"
          v-on:mousedown.stop.prevent="mouseDown(day, $event)"
          v-on:touchstart="mouseDown(day, $event, true)"
          :data-day="day.utcDate.toISOString()"
          :style="{
            'background-color': isAdviceVisible(day) ? 'white' : '',
          }"
          :class="{
            today: isToday(day),

            'current-month': day.utcDate.isSame(currDate, 'month'),
            'no-border-right': (index + 1) % 7 == 0,
            'no-border-bottom': index >= days.length - 7,
            selected: isSelected(day),
            'big-border-top': bigBorderTop(index),
            'big-border-left': bigBorderLeft(index),
            'big-border-bottom': bigBorderBottom(index),
            'big-border-right': bigBorderRight(index),
          }"
        >
          <!-- day.utcDate.tz('Europe/Rome').isSame(nowDate.tz(), 'day'), -->
          <div>
            <span
              :style="{
                color: isAdviceVisible(day) ? 'red' : '',
              }"
              class="day"
              >{{ getLocal(day.utcDate).date() }}</span
            >
            <slot name="daycell" :day="day.utcDate"></slot>
          </div>
        </div>
        <slot name="adviceContent"></slot>
      </Jadvice>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Jadvice from "@/components/ui/JAdvice.vue";
export default {
  components: {
    Jadvice,
  },
  props: {
    adviceActiveOnDay: {},
    selectingOnDisabled: {},
    adviceTitle: {},
    disabled: {},
  },
  data: function () {
    return {
      selecting: false,
      currDate: moment().utc().startOf("month"),
      first: null,
      last: null,
      selectingDayDate: undefined,
      firstTouchX: undefined,
      firstTouchY: undefined,
      touchMovedAtX: undefined,
      touchMovedAtY: undefined,
      isMounted: false,
    };
  },

  computed: {
    nowDate: function () {
      return moment();
    },
    days: function () {
      const days = [];

      for (
        let day = moment(this.currDate).startOf("isoWeek");
        day.isBefore(moment(this.currDate).startOf("month"));
        day = day.add(1, "days")
      ) {
        days.push({
          utcDate: moment(day).utc(),
        });
      }

      for (
        let day = moment(this.currDate).startOf("month");
        day.isBefore(moment(this.currDate).endOf("month"));
        day = day.add(1, "days")
      ) {
        days.push({
          utcDate: moment(day).utc(),
        });
      }

      for (
        let day = moment(days[days.length - 1].utcDate).add(1, "days");
        days.length % 7 != 0;
        day = day.add(1, "days")
      ) {
        days.push({
          utcDate: moment(day).utc(),
        });
      }

      return days;
    },
  },
  mounted() {
    window.addEventListener("mouseup", this.mouseUp);
    window.addEventListener("touchmove", this.onTouchMove, { passive: false });
    window.addEventListener("touchend", this.onTouchEnd);
    window.addEventListener("keyup", this.handleArrow);

    try {
      if (this.$route.query.firstDay && this.$route.query.lastDay) {
        this.first = moment(parseInt(this.$route.query.firstDay, 10));

        this.last = moment(parseInt(this.$route.query.lastDay, 10));

        this.notifyDaysSelected();

        //this.selectDayRange(this.lastDayOfSelection);

        // this.$nextTick(() => {
        //   this.openCalendarDetail();
        // });
      }
    } catch (e) {}

    this.isMounted = true;
  },
  beforeDestroy() {
    window.removeEventListener("mouseup", this.mouseUp);
    window.removeEventListener("touchmove", this.onTouchMove);
    window.removeEventListener("touchend", this.onTouchEnd);
    window.removeEventListener("keyup", this.handleArrow);
  },
  methods: {
    isToday: function (day) {
      return moment(day.utcDate)
        .tz("Europe/Rome")
        .isSame(moment(this.nowDate).tz("Europe/Rome"), "day");
    },
    isAdviceVisible: function (day) {
      return (
        this.isMounted &&
        this.adviceActiveOnDay &&
        day.utcDate.isSame(this.adviceActiveOnDay, "day")
      );
    },
    onTouchEnd: function (e) {
      if (this.selecting) {
        this.notifyDaysSelected();
      }
      this.selecting = false;
    },
    getCalendarCellBelow: function (el, x, y) {
      if (el) el.style["pointer-events"] = "none";
      const elemBelow = document.elementFromPoint(x, y);
      if (
        !elemBelow ||
        elemBelow.tagName === "HTML" ||
        elemBelow.tagName === "BODY"
      ) {
        if (el) el.style["pointer-events"] = "";
        return;
      }
      let cell = elemBelow.className.includes("calendar-cell")
        ? elemBelow
        : elemBelow.closest(".calendar-cell");

      if (el) el.style["pointer-events"] = "";

      return cell;
    },
    onTouchMove: function (e) {
      const touch = _.first(e.targetTouches);
      if (!touch) {
        return;
      }

      this.touchMovedAtX = touch.clientX;
      this.touchMovedAtY = touch.clientY;
      if (!this.selecting) return;
      e.preventDefault();
      e.stopPropagation();
      const calendarCellBelow = this.getCalendarCellBelow(
        undefined,
        touch.clientX,
        touch.clientY
      );

      if (!calendarCellBelow) return;
      if (
        this.selectingDayDate &&
        moment(calendarCellBelow.dataset.day).isSame(this.selectingDayDate)
      )
        return;
      this.selectingDayDate = moment(calendarCellBelow.dataset.day);
      this.last = this.selectingDayDate;
    },
    clear: function () {
      this.first = null;
      this.last = null;
    },
    handleArrow: function (e) {
      switch (e.keyCode) {
        case 39: //Freccia destra
          this.nextMonth();
          break;
        case 37: //Freccia sinistra
          this.prevMonth();
          break;
      }
    },
    pad: function (number) {
      number = typeof number == "number" ? number.toString() : number;

      return number < 10 ? "0" + number : number;
    },
    getLocal: function (utcDate) {
      return moment(utcDate).local();
    },
    bigBorderRight: function (index) {
      //Check if no calendar cell is selected on the right of the calendar cell indentified by @index
      if ((index + 1) % 7 == 0) return true;

      var dayAfter = this.days[index + 1];
      if (!this.isSelected(dayAfter)) {
        return true;
      } else {
        return false;
      }
    },
    bigBorderBottom: function (index) {
      //Check if no calendar cell is selected below of the calendar cell indentified by @index

      try {
        var dayBelow = this.days[index + 7];
        if (!this.isSelected(dayBelow)) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return true;
      }
    },
    bigBorderLeft: function (index) {
      //Check if no calendar cell is selected on the left of the calendar cell indentified by @index
      if (index % 7 == 0) return true;

      var dayBefore = this.days[index - 1];
      if (!this.isSelected(dayBefore)) {
        return true;
      } else {
        return false;
      }
    },
    bigBorderTop: function (index) {
      //Check if no calendar cell is selected above of the calendar cell indentified by @index

      if (index >= 0 && index <= 6) return true;

      var dayAbove = this.days[index - 7];
      if (!this.isSelected(dayAbove)) {
        return true;
      } else {
        return false;
      }
    },

    handOnDisabledSelection: function (res) {
      if (res === true) return;
      else if (res.day) {
        this.first = res.day;
        this.last = res.day;
      } else if (res.first && res.last) {
        this.first = res.first;
        this.last = res.last;
      }
    },

    notifyDaysSelected: function () {
      if (!this.first || !this.last) return;
      if (this.first.isSame(this.last, "day")) {
        if (this.disabled)
          if (this.selectingOnDisabled) {
            return (async () => {
              const res = await this.selectingOnDisabled({
                type: "single",
                day: this.first,
              });

              this.handOnDisabledSelection(res);

              return this.handOnDisabledSelection(res);
            })();
          }

        // this.$emit("selectingOnDisabled", {
        //   type: "single",
        //   day: this.first
        // });
        this.$emit("select-single-day", this.first);
      } else {
        const first = this.first.isBefore(this.last) ? this.first : this.last;
        const last = this.first.isBefore(this.last) ? this.last : this.first;
        if (this.disabled)
          if (this.selectingOnDisabled) {
            return (async () => {
              const res = await this.selectingOnDisabled({
                type: "range",
                first: first,
                last: last,
              });

              return this.handOnDisabledSelection(res);
            })();
          }

        this.$emit("select-range-day", {
          first: first,
          last: last,
        });
      }
    },
    mouseUp: function () {
      if (this.selecting) {
        this.notifyDaysSelected();
      }
      this.selecting = false;
    },
    isSelected: function (day) {
      if (!this.first) return false;

      return (
        (day.utcDate.isSameOrAfter(this.first) &&
          day.utcDate.isSameOrBefore(this.last)) ||
        (day.utcDate.isSameOrAfter(this.last) &&
          day.utcDate.isSameOrBefore(this.first))
      );
    },
    startSelection(day) {
      this.selecting = true;
      this.first = day.utcDate;
      this.last = day.utcDate;
    },
    mouseDown: function (day, e, isTouch) {
      // if (this.disabled) return this.$emit("selectingOnDisabled");

      if (isTouch) {
        const touch = _.first(e.targetTouches);
        if (!touch) {
          return;
        }
        this.firstTouchX = touch.clientX;
        this.firstTouchY = touch.clientY;
        this.touchMovedAtX = touch.clientX;
        this.touchMovedAtY = touch.clientY;
        setTimeout(() => {
          const x1 = this.firstTouchX;
          const y1 = this.firstTouchY;
          const x2 = this.touchMovedAtX;
          const y2 = this.touchMovedAtY;
          const distance = Math.sqrt(
            Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2)
          );

          if (distance <= 0) {
            this.startSelection(day);
          }
        }, 130);
        return;
      }

      this.startSelection(day);
    },
    mouseEnter: function (day, e) {
      if (!this.selecting) return;
      if (e.buttons != 1 || e.ctrlKey) return;
      this.last = day.utcDate;
    },
    getCurrMonthString: function () {
      return moment(this.currDate).format("MMMM YYYY");
    },
    nextMonth: function () {
      const currDate = moment(this.currDate);

      currDate.add(1, "months");

      this.currDate = currDate;
    },
    prevMonth: function () {
      var currDate = moment(this.currDate);

      currDate.subtract(1, "months");

      this.currDate = currDate;
    },
  },
  watch: {
    first: function () {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            firstDay: this.first ? this.first.valueOf() : undefined,
          },
        })
        .catch((e) => {});
    },
    last: function () {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            lastDay: this.last ? this.last.valueOf() : undefined,
          },
        })
        .catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";
.calendar {
  border-radius: 4px;
  width: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  border: 1px solid map-get($colors, "grey");
  //margin-top: 2em;

  .header,
  .body {
    width: 100%;
  }

  .header {
    .navigator {
      display: flex;
      width: 100%;
      padding: 10px 10px;
      align-items: center;

      .arrow {
        flex: 0.1;
        font-size: 16px;
        color: map-get($colors, "primary");
        cursor: pointer;
      }

      & .month {
        flex: 1;
        text-align: center;
        font-size: 2em;
      }
    }

    .days-of-week {
      display: flex;
      min-height: 30px;
      align-items: center;
      width: 100%;

      span {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 14.28%;
        max-width: 14.28%;
      }
    }
  }

  .advice-calendar {
    // min-height: 100px;
    // max-height: 120px;
    flex-basis: 14.2857143%;
    min-width: 14.2857143%;
    max-width: 14.2857143%;
    // padding: 5px 5px;
  }

  .body {
    border-top: 1px solid map-get($colors, "grey");
    display: flex;
    flex-wrap: wrap;
    .calendar-cell {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-touch-callout: none;
      min-height: 100px;
      max-height: 100px;
      overflow-y: hidden;
      &.today {
        .day {
          color: map-get($map: $colors, $key: "primary");
          font-weight: bold;
        }
      }
      @include media-breakpoint-down(xs) {
        // min-height: 60px;
      }
      box-sizing: border-box;
      border: 1px solid map-get($colors, "grey");
      border-left-color: transparent;
      border-top-color: transparent;
      //flex-shrink: 0;
      // border-right: 1px solid map-get($colors, "grey");
      // border-bottom: 1px solid map-get($colors, "grey");
      //flex: 1;
      // flex-basis: 14.2857143%;
      // min-width: 14.2857143%;
      // max-width: 14.2857143%;
      padding: 5px 5px;
      cursor: pointer;

      &.no-border-right {
        border-right-color: transparent;
      }

      &.no-border-bottom {
        border-bottom-color: transparent;
      }

      &:not(.current-month) {
        opacity: 0.5;
      }
      $cellBgColor: transparentize(map-get($colors, "primary"), 0.75);
      &:hover {
        opacity: 1;
        overflow-y: auto;
        background-color: $cellBgColor;
      }

      &.selected {
        background-color: $cellBgColor;
        border-right-color: transparentize(map-get($colors, "primary"), 0.8);
        border-bottom-color: transparentize(map-get($colors, "primary"), 0.8);
      }

      &.selected.big-border-top {
        border-top: 1px solid map-get($colors, "primary");
      }

      &.selected.big-border-left {
        border-left: 1px solid map-get($colors, "primary");
      }

      &.selected.big-border-right {
        border-right: 1px solid map-get($colors, "primary");
      }

      &.selected.big-border-bottom {
        border-bottom: 1px solid map-get($colors, "primary");
      }
    }
  }
}
</style>

<style></style>
