import Parse from "parse";
import { Dish, JImageHelper, Label, Jpasta } from "@/jpasta-sdk";
import generalActions from "./generalActions";
import generalMutations from "./generalMutations";

const Course = Parse.Object.extend("Course");

export default {
  namespaced: true,
  state: {
    objects: {},
    className: "Template",
    uploadingImage: {},
    loadingConfigTextIndex: 0,
    loadingConfigTexts: ["Configuro Template", "Aggiungo i piatti predefiniti"],
  },

  mutations: {
    ...generalMutations,
    setLoadingConfigTextIndex(state, index) {
      state.loadingConfigTextIndex = index;
    },
    startUploadingImage(state, template) {
      state.uploadingImage = {
        ...state.uploadingImage,
        [template.get("localId")]: true,
      };
    },
    stopUploadingImage(state, template) {
      state.uploadingImage = {
        ...state.uploadingImage,
        [template.get("localId")]: false,
      };
    },
  },
  actions: {
    ...generalActions,
    attachImage: async function (
      { commit, dispatch, rootState, rootGetters },
      { file, template }
    ) {
      if (file.type != "image/jpeg" && file.type != "image/png")
        return Promise.reject("L' immagine deve essere PNG o JPEG");

      var imgHelper = new JImageHelper(file);
      var image = await imgHelper.getImage();
      if (image.width != 1024 || image.height != 500)
        return Promise.reject(
          "Le dimensioni dell' immagine non devono essere inferiori a 1024 x 500"
        );

      commit("startUploadingImage", template);

      var name = Jpasta.randomLocalUUID();
      var parseImage = new Parse.File(name, { base64: image.src });

      try {
        var uploadedImage = await parseImage.save();
      } catch (e) {
        commit("stopUploadingImage", template);
        return dispatch("handleParseError", e, { root: true });
      }

      template.set("image", uploadedImage);
      dispatch("saveObj", template);
      commit("stopUploadingImage", template);
    },
    addTemplateToHotel: async function (
      { commit, dispatch, rootState, rootGetters },
      { template, hotel }
    ) {
      var templateDishes =
        rootGetters["dishes/getFilteredDishesOfHotelOrTemplate"](template);

      var templateLabels = rootGetters["labels/labels"](template);

      var hotelLabelsFromTemplate = _.filter(
        rootState.labels.objects,
        (label) => {
          try {
            return (
              label.belongsTo(hotel) &&
              label.belongsTo(template, "fromTemplate") &&
              label.get("fromTemplateLabel").get("localId")
            );
          } catch (e) {
            return false;
          }
        }
      );

      hotelLabelsFromTemplate = _.keyBy(hotelLabelsFromTemplate, (label) => {
        return label.get("fromTemplateLabel").get("localId");
      });

      for (var templateLabel of templateLabels) {
        if (hotelLabelsFromTemplate[templateLabel.get("localId")]) continue;

        var label = new Label();
        label.set("name", templateLabel.get("name"));
        label.set("colorIndex", templateLabel.get("colorIndex"));
        label.set("iconIndex", templateLabel.get("iconIndex"));
        label.set("hotel", hotel);
        label.set("fromTemplateLabel", templateLabel);
        label.set("fromTemplate", template);

        await dispatch("labels/saveObj", label, { root: true });
      }

      for (var templateDish of templateDishes) {
        var hotelDish = new Dish();
        var dishLabels = _.filter(rootState.labels.objects, (label) => {
          return (
            label.belongsTo(hotel) &&
            _.find(templateDish.get("labels"), (templateLabel) => {
              return (
                label.get("fromTemplateLabel") &&
                label.belongsTo(templateLabel, "fromTemplateLabel")
              );
            })
          );
        });
        hotelDish.set("name", templateDish.get("name"));
        hotelDish.set("images", templateDish.get("images"));
        hotelDish.set("courseIndex", templateDish.get("courseIndex"));
        hotelDish.set("hotel", hotel);
        hotelDish.set("labels", dishLabels);
        hotelDish.set("fromTemplate", template);
        hotelDish.set("fromTemplateDish", templateDish);
        await dispatch("dishes/saveObj", hotelDish, { root: true });
      }

      hotel.set("addedTemplates", [
        ...(hotel.get("addedTemplates") ? hotel.get("addedTemplates") : []),
        template,
      ]);

      await dispatch("hotels/saveObj", hotel, { root: true });
    },
    removeTemplateFromHotel: async function (
      { commit, dispatch, rootState, rootGetters },
      { template, hotel }
    ) {
      var hotelDishes =
        rootGetters["dishes/getFilteredDishesOfHotelOrTemplate"](hotel);

      var dishesToRemove = _.filter(hotelDishes, (hotelDish) => {
        return hotelDish.belongsTo(template, "fromTemplate");
      });

      for (var dishToRemove of dishesToRemove) {
        dishToRemove.set("deleted", true);
        await dispatch("dishes/saveObj", dishToRemove, { root: true });
      }

      var addedTemplates = hotel.get("addedTemplates");

      var addedTemplates = _.filter(addedTemplates, (hotelTemplate) => {
        return !(
          (hotelTemplate.id && hotelTemplate.id === template.id) ||
          hotelTemplate.get("localId") === template.get("localId")
        );
      });

      hotel.set("addedTemplates", addedTemplates);

      await dispatch("hotels/saveObj", hotel, { root: true });
    },
  },
  getters: {
    getPredefined: (state) => {
      return _.filter(state.objects, (template) => {
        return template.get("predefined") && template.get("isPublic");
      });
    },
  },
};
