import generalMutations from "./generalMutations";
import generalActions from "./generalActions";
import moment from "moment-timezone";
import Parse from "parse";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    objects: {},
    className: "CalendarMeal",
    hotelDateMap: {},
    version: 0,
  },

  mutations: {
    ...generalMutations,
    upsertObject(state, obj) {
      if (obj.get("date") && !state[obj.get("localId")]) {
        let key =
          obj.get("hotel").get("localId") +
          "-" +
          moment(obj.get("date")).utc().startOf("day").valueOf();
        state.hotelDateMap[key] = state.hotelDateMap[key] || [];
        state.hotelDateMap[key].push(obj.get("localId"));
      }
      generalMutations.upsertObject(state, obj);
    },
  },
  actions: {
    ...generalActions,
  },
  getters: {
    getByDayAndHotelAndMealIndex: (state) => (date, hotel, mealIndex) => {
      return state.objects[
        _.find(
          state.hotelDateMap[
            hotel.get("localId") +
              "-" +
              moment(date).utc().startOf("day").valueOf()
          ],
          (cMealLocalId) => {
            let cMeal = state.objects[cMealLocalId];
            return cMeal && cMeal.get("mealIndex") === mealIndex.toString();
          }
        )
      ];
    },
    getByDayAndHotel: (state) =>
      _.memoize(
        (date, hotel) => {
          var alreadyAdded = {};

          return _.reduce(
            state.hotelDateMap[
              hotel.get("localId") +
                "-" +
                moment(date).utc().startOf("day").valueOf()
            ],
            (cMeals, cMealLocalId) => {
              if (state.objects[cMealLocalId] && !alreadyAdded[cMealLocalId]) {
                alreadyAdded[cMealLocalId] = true;
                cMeals.push(state.objects[cMealLocalId]);
              }

              return cMeals;
            },
            []
          );
        },
        (date, hotel) => {
          return `${moment(date).valueOf()}-${hotel.get("localId")}-${
            state.version
          }`;
        }
      ),
  },
};
