var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.searchBar)?_c('jinput',{ref:"search-input",staticClass:"hotel-input meal-name-input detail-text meal-entity-input",attrs:{"inputStyle":{
      'background-color': 'transparent',
    },"value":_vm.inputValue,"search":true,"noBorder":true,"type":"text","placeholder":_vm.placeholder},on:{"enterpressed":_vm.selectFirstItem,"input":_vm.onInput}}):_vm._e(),(_vm.searchBar)?_c('div',{staticClass:"search-divider"}):_vm._e(),(_vm.itemsToRender.length)?_c('ul',{staticClass:"items-list"},_vm._l((_vm.itemsToRender),function(item,index){return _c('li',{key:index,staticClass:"list-item",class:{
        selected: _vm.isSelected(item),
      },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.itemClicked(item)}}},[_vm._t("default",function(){return [_c('div',{staticClass:"list-item-wrapper"},[_c('div',{staticClass:"title-sub-wrapper"},[_c('span',[_vm._v(_vm._s(_vm.label ? item[_vm.label] : item.label))]),(_vm.subTitle)?_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.getSubTitle(item)))]):_vm._e()]),_c('span',{staticClass:"icon-checked"})])]})],2)}),0):_c('div',{staticClass:"no-result",class:{
      clickable: _vm.noResultAction,
    }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.noResultText ? _vm.noResultText : 'Nessun risultato trovato')}}),(_vm.noResultAction)?_c('jbutton',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('noresclicked')}}},[_vm._v(_vm._s(_vm.noResultButtonText ? _vm.noResultButtonText : "Aggiungi"))]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }