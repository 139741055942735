
import Vue from "vue";
import mixins from "vue-typed-mixins";
import { Turn, Meal, JPASTA_MEALS, Jpasta } from "@/jpasta-sdk";
export default Vue.extend({
  data: function (): {
    meals: Meal[];
  } {
    return {
      meals: JPASTA_MEALS,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.turnNameInput && this.$mq !== "xs") {
        const el: HTMLInputElement = this.$refs
          .turnNameInput as HTMLInputElement;
        el.focus();
      }
    });
  },
  props: {
    onClose: {
      type: Function as () => any,
    },
    newTurn: {
      type: Function as () => any,
    },
    turnLocalId: {
      type: String as () => string,
    },
  },
  computed: {
    turn: function (): Turn {
      return this.$store.state.turns.objects[this.turnLocalId];
    },
  },
  methods: {
    setToTime(turn: Turn, time: string, mealIndex: number) {
      turn.setToTime(time, mealIndex);
      this.$store.dispatch("turns/saveObj", turn);
    },
    setFromTime(turn: Turn, time: string, mealIndex: number) {
      turn.setFromTime(time, mealIndex);
      this.$store.dispatch("turns/saveObj", turn);
    },
    getLocalizedName(name): string {
      return Jpasta.getLocalizedName(name, "it", "");
    },
    setTurnProperty(propertyName: string, value: any): void {
      const turn = this.turn;
      if (!turn) return;
      if (value === undefined || value === null) {
        turn.unset(propertyName);
      } else {
        turn.set(propertyName, value);
      }

      this.$store.dispatch("turns/saveObj", turn);
    },
  },
});
