import React, { useEffect, useState } from "react";
import { Book, CalendarMeal, Hotel, Jpasta } from "@/jpasta-sdk";
import {
  CompletedOrdersInfoDTO,
  GetEditableMealOrderDishDTO,
  GetEditableMealOrderDTO,
  useGetEditableMealBooks,
} from "@/components/Hotel/MealBook/useGetEditableMealBooks";
import { Cog6ToothIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Modal } from "@/ui/Modal";
import { PrimaryButton } from "@/react/UI/PrimaryButton";
import {
  DishInputDTO,
  useCreateOrder,
} from "@/components/Hotel/MealBook/useCreateOrder";
import { PrimaryLoadingSpinner } from "@/ui/PrimaryLoadingSpinner";
import { nanoid } from "nanoid";
import _ from "lodash";
import { PlusAndMinus } from "@/react/UI/PlusAndMinus";
import { useMarkOrderAsIncomplete } from "./useMarkOrderAsIncomplete";

type Props = {
  hotel: Hotel;
  calendarMeal: CalendarMeal;
  book: Book;
};
type EditingBag = {
  dishId: string;
  dishName: string;
};

type AnnotationDTO = {
  qty: number;
  annotation: string;
  id: string;
};

type AnnotationDataBag = {
  annotationId?: string;
  annotation: string;
};
class DishInfo {
  private noVariationQty: number = 0;
  private annotations: Record<string, AnnotationDTO> = {};
  constructor(private readonly dishId: string) {}
  public increaseQty(qty: number, annotation?: AnnotationDataBag) {
    if (!annotation) {
      this.noVariationQty += qty;
      return;
    }
    const a = this.getAnnotation(annotation);
    a.qty += qty;
    this.annotations[a.id] = a;
  }
  public decreaseQty(qty: number, annotation?: AnnotationDataBag) {
    if (!annotation) {
      this.noVariationQty -= qty;
      return;
    }
    const a = this.getAnnotation(annotation);
    a.qty -= qty;
    this.annotations[a.id] = a;
  }

  private getAnnotation(annotation: AnnotationDataBag): AnnotationDTO {
    const aId = annotation.annotationId ? annotation.annotationId : nanoid();
    return (
      this.annotations[aId] ?? {
        qty: 0,
        annotation: annotation.annotation,
        id: aId,
      }
    );
  }

  public getQty(annotation?: AnnotationDataBag) {
    if (!annotation) {
      return this.noVariationQty;
    }
    const a = this.getAnnotation(annotation);
    return a.qty;
  }

  public clone() {
    return _.cloneDeep(this);
  }

  getDishInput(): DishInputDTO[] {
    const dishInput: DishInputDTO[] = [];
    if (this.noVariationQty > 0) {
      dishInput.push({
        dishId: this.dishId,
        quantity: this.noVariationQty,
      });
    }
    Object.values(this.annotations).forEach((a) => {
      if (a.qty > 0) {
        dishInput.push({
          dishId: this.dishId,
          quantity: a.qty,
          annotation: a.annotation,
        });
      }
    });
    return dishInput;
  }

  public getAnnotations(): AnnotationDTO[] {
    return Object.values(this.annotations);
  }

  setAnnotation(id: string, value: string) {
    const a = this.getAnnotation({
      annotation: value,
      annotationId: id,
    });

    a.annotation = value;
    this.annotations[a.id] = a;
  }

  removeAnnotation(id: string) {
    delete this.annotations[id];
  }
}

function EditMealBookInner({
  calendarMeal,
  book,
  mealOrders,
  dishes,
  completedOrdersInfo,
  refresh,
}: Props & {
  mealOrders: GetEditableMealOrderDTO[];
  dishes: GetEditableMealOrderDishDTO[];
  completedOrdersInfo: CompletedOrdersInfoDTO;
  refresh: () => void;
}) {
  const [editingBag, setEditingBag] = useState<EditingBag>();

  const [isDirty, setIsDirty] = useState(false);
  const { createOrder } = useCreateOrder();
  const [creating, setCreating] = useState(false);
  const [markingAsCompleted, setMarkingAsCompleted] = useState(false);
  const [markingAsIncomplete, setMarkingAsIncomplete] = useState(false);
  const { markOrderAsIncomplete } = useMarkOrderAsIncomplete();
  function buildCurrentDishValueMapMap() {
    const map = new Map<string, DishInfo>();
    mealOrders.forEach((mo) => {
      mo.dishes.forEach((d) => {
        const dish = map.get(d.dish.objectId) ?? new DishInfo(d.dish.objectId);
        dish.increaseQty(
          d.quantity,
          d.annotation
            ? {
                annotation: d.annotation,
              }
            : undefined
        );
        map.set(d.dish.objectId, dish);
      });
    });
    return map;
  }
  const [map, setMap] = useState<Map<string, DishInfo>>(new Map());
  useEffect(() => {
    setMap(buildCurrentDishValueMapMap());
  }, [dishes]);
  function incrementDishQty(dishId: string, annotation?: AnnotationDataBag) {
    setIsDirty(true);
    setMap((prev) => {
      const currentVal = prev.get(dishId) ?? new DishInfo(dishId);
      currentVal.increaseQty(1, annotation);
      prev.set(dishId, currentVal.clone());
      return new Map(prev);
    });
  }

  function decrementDishQty(dishId: string, annotation?: AnnotationDataBag) {
    setIsDirty(true);
    setMap((prev) => {
      const currentVal = prev.get(dishId) ?? new DishInfo(dishId);
      if (currentVal.getQty(annotation) - 1 < 0) return prev;
      currentVal.decreaseQty(1, annotation);
      prev.set(dishId, currentVal.clone());
      return new Map(prev);
    });
  }

  async function wrappedCreateOrder(
    map: Map<string, DishInfo>,
    allowEmptyOrder = false
  ) {
    const dishes = Array.from(map.entries()).reduce((res, [, dishesInfo]) => {
      const dishes = dishesInfo.getDishInput();
      res.push(...dishes);
      return res;
    }, [] as DishInputDTO[]);
    await createOrder({
      calendarMealId: calendarMeal.id,
      bookId: book.id,
      dishes: dishes,
      allowEmptyOrder,
    });
  }

  async function save() {
    if (!isDirty) return;
    try {
      setCreating(true);
      await wrappedCreateOrder(map, true);
      await refresh();
    } finally {
      setCreating(false);
    }
    setIsDirty(false);
  }
  function closeModal() {
    setEditingBag(undefined);
  }

  function edit(d: GetEditableMealOrderDishDTO) {
    setEditingBag({
      dishId: d.objectId,
      dishName: Jpasta.getLocalizedName(d.name, "it", "No Name"),
    });
  }

  function addAnnotation(dishId: string) {
    setIsDirty(true);
    setMap((prev) => {
      const currentVal = prev.get(dishId) ?? new DishInfo(dishId);
      currentVal.setAnnotation(nanoid(), "");
      prev.set(dishId, currentVal.clone());
      return new Map(prev);
    });
  }

  async function markAsCompleted() {
    if (
      isDirty &&
      !window.confirm(
        "Hai effettuato delle modifiche senza salvare. Procedendo verranno perse. Vuoi procedere?"
      )
    )
      return;
    try {
      setMarkingAsCompleted(true);
      await wrappedCreateOrder(buildCurrentDishValueMapMap(), true);
      await refresh();
    } finally {
      setIsDirty(false);
      setMarkingAsCompleted(false);
    }
  }

  async function markAsIncomplete() {
    try {
      setMarkingAsIncomplete(true);
      await markOrderAsIncomplete(calendarMeal.id, book.id);
      await refresh();
    } finally {
      setMarkingAsIncomplete(false);
    }
  }

  function increaseAnnotationQty(dishId: string, annotation: AnnotationDTO) {
    setIsDirty(true);
    setMap((prev) => {
      const currentVal = prev.get(dishId) ?? new DishInfo(dishId);
      currentVal.increaseQty(1, {
        annotation: annotation.annotation,
        annotationId: annotation.id,
      });
      prev.set(dishId, currentVal.clone());
      return new Map(prev);
    });
  }

  function decreaseAnnotationQty(dishId: string, annotation: AnnotationDTO) {
    setIsDirty(true);
    setMap((prev) => {
      const currentVal = prev.get(dishId) ?? new DishInfo(dishId);
      currentVal.decreaseQty(1, {
        annotation: annotation.annotation,
        annotationId: annotation.id,
      });
      prev.set(dishId, currentVal.clone());
      return new Map(prev);
    });
  }

  function removeAnnotation(dishId: string, annotation: AnnotationDTO) {
    setIsDirty(true);
    setMap((prev) => {
      const currentVal = prev.get(dishId) ?? new DishInfo(dishId);
      currentVal.removeAnnotation(annotation.id);
      prev.set(dishId, currentVal.clone());
      return new Map(prev);
    });
  }

  return (
    <>
      <Modal
        isOpen={!!editingBag}
        onClose={closeModal}
        title={`Modifica ${editingBag?.dishName ?? ""}`}
      >
        {editingBag ? (
          <div className={"w-full"}>
            {map
              .get(editingBag.dishId)
              ?.getAnnotations()
              .map((a, index) => {
                return (
                  <div
                    className={[
                      index <= 0 ? "" : "border-t border-slate-200",
                      "py-[1rem]",
                    ].join(" ")}
                    key={a.id}
                  >
                    <div className={"pb-[1rem] flex items-center space-x-2"}>
                      {/* @ts-ignore*/}
                      <XCircleIcon
                        onClick={() => removeAnnotation(editingBag.dishId, a)}
                        className={"h-7 w-7 text-red-400 cursor-pointer"}
                      />
                      <label>Annotazione #{index + 1}</label>
                    </div>
                    <div className={"flex items-center space-x-2"}>
                      <textarea
                        onChange={(e) => {
                          setIsDirty(true);
                          setMap((prev) => {
                            const currentVal =
                              prev.get(editingBag.dishId) ??
                              new DishInfo(editingBag.dishId);
                            currentVal.setAnnotation(a.id, e.target.value);
                            prev.set(editingBag.dishId, currentVal);
                            return new Map(prev);
                          });
                        }}
                        name={"annotation"}
                        className={
                          "w-full border border-slate-200 rounded-md p-1"
                        }
                        value={a.annotation ?? ""}
                      />
                      <PlusAndMinus
                        onPlus={() =>
                          increaseAnnotationQty(editingBag.dishId, a)
                        }
                        onMinus={() =>
                          decreaseAnnotationQty(editingBag.dishId, a)
                        }
                        qty={a.qty}
                      />
                    </div>
                  </div>
                );
              })}
            <div
              onClick={() => addAnnotation(editingBag.dishId)}
              className={
                "border-dashed mt-[0.5rem] p-[0.5rem] hover:bg-slate-200 border border-2 rounded"
              }
            >
              Aggiungi annotazione
            </div>
            <div className={"mt-2 flex justify-end"}>
              <PrimaryButton onClick={closeModal} label={"Chiudi"} />
            </div>
          </div>
        ) : null}
      </Modal>
      <div className={"p-[0.5em] md:py-4 md:px-8"}>
        <h2 className={"font-bold text-xl"}>
          Modifica ordine per prenotazione di{" "}
          {book.getRefName() ?? book.getRefEmail() ?? ""}
        </h2>
        <div
          className={
            "sticky space-y-4 md:space-y-0 md:space-x-4 top-[64px] bg-white py-4 flex flex-col md:flex-row justify-end"
          }
        >
          <PrimaryButton
            loading={markingAsIncomplete}
            label={`Segna ordine come incompleto`}
            onClick={markAsIncomplete}
            disabled={
              completedOrdersInfo.totalCompletedOrders <= 0 ||
              markingAsIncomplete
            }
          />
          <PrimaryButton
            loading={markingAsCompleted}
            label={`Segna ordine come completato (${completedOrdersInfo.totalCompletedOrders}/${completedOrdersInfo.totalPax})`}
            onClick={markAsCompleted}
            disabled={
              creating ||
              markingAsCompleted ||
              completedOrdersInfo.totalCompletedOrders >=
                completedOrdersInfo.totalPax
            }
          />
          <PrimaryButton
            loading={creating}
            label={"Salva"}
            disabled={!isDirty || creating || markingAsCompleted}
            onClick={save}
          />
        </div>
        <div
          className={
            "border font-bold border-slate-300 rounded p-[0.5rem] bg-slate-100"
          }
        >
          Pax con ordinazione completata:{" "}
          {completedOrdersInfo.totalCompletedOrders} /{" "}
          {completedOrdersInfo.totalPax}
        </div>
        {dishes.map((d, index) => {
          return (
            <div
              className={[
                "py-5",
                index > 0 ? "border-t border-slate-200" : "",
              ].join(" ")}
              key={d.objectId}
            >
              <div
                className={[
                  "flex flex-col md:flex-row space-y-2 md:space-y-0 md:items-center md:justify-between",
                ].join(" ")}
              >
                <div className={""}>
                  <h4 className={"font-bold"}>
                    {Jpasta.getLocalizedName(d.name, "it", "No Name")}
                  </h4>
                </div>
                <div className={"flex items-center space-x-2"}>
                  <PlusAndMinus
                    onPlus={() => incrementDishQty(d.objectId)}
                    onMinus={() => decrementDishQty(d.objectId)}
                    qty={map.get(d.objectId)?.getQty() ?? 0}
                  />

                  <div>
                    {/*@ts-ignore*/}
                    <Cog6ToothIcon
                      onClick={() => edit(d)}
                      className={"w-5 h-5 cursor-pointer"}
                    />
                  </div>
                </div>
              </div>
              <div>
                {map
                  .get(d.objectId)
                  ?.getAnnotations()
                  .filter((a) => a.annotation?.length > 0)
                  .map((annotation) => {
                    return (
                      <div className={"flex justify-between space-x-2"}>
                        <span>{annotation.annotation}</span>
                        <span>x{annotation.qty}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export function EditMealBook(props: Props) {
  const { calendarMeal, book } = props;
  const { mealOrders, dishes, completedOrdersInfo, revalidate, isLoading } =
    useGetEditableMealBooks(calendarMeal, book);
  if (isLoading)
    return (
      <div className={"min-h-screen flex items-center justify-center"}>
        <PrimaryLoadingSpinner />
      </div>
    );
  return (
    <EditMealBookInner
      {...props}
      completedOrdersInfo={completedOrdersInfo}
      mealOrders={mealOrders}
      dishes={dishes}
      refresh={revalidate}
    />
  );
}
