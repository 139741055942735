
import Vue from "vue";
import mixins from "vue-typed-mixins";
import _ from "lodash";
import { Hotel, User } from "@/jpasta-sdk";
export default mixins().extend({
  methods: {
    skipTutorial: function () {
      const user = this.$store.state.users.current;

      user.set("tutorialCompleted", true);

      this.$store.dispatch("users/saveObj", user);

      this.$router
        .push({
          name: "Hotels",
        })
        .catch((e) => {});
    },
    startTutorial: function () {
      const firstHotel: Hotel | undefined = _.first(
        _.values(this.$store.state.hotels.objects)
      );

      if (!firstHotel) return;
      this.$router
        .push({
          name: "HotelDishes",
          params: {
            hotelid: firstHotel.get("localId"),
          },
          query: {
            tutorial: "1",
          },
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
  },
});
