import Parse from "parse";
import Jpasta from "./Jpasta";

export default class Template extends Jpasta {
  constructor() {
    super("Template");
  }
}

Parse.Object.registerSubclass("Template", Template);
