import Vue from "vue";
import _ from "lodash";
export default Vue.extend({
  data: function () {
    return {
      mainWrapperHeight: window.innerHeight as number,
      timeout: undefined as any,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.computeMainWrapperHeight();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    computeMainWrapperHeight: function () {
      const topBar = _.first(document.getElementsByClassName("top-bar"));
      const topBarHeight = topBar ? topBar.clientHeight : 0;

      this.mainWrapperHeight = window.innerHeight - topBarHeight;
    },
    handleResize() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.computeMainWrapperHeight();
        this.timeout = undefined;
      }, 100);
    },
  },
  computed: {},
});
