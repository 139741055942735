
import Vue, { PropType } from "vue";
import {
  Jpasta,
  findJMealByLocalId,
  CalendarMeal,
  MealOrder,
  Table,
  Rank,
  Hotel,
  Book,
  MealOrderDish,
  Turn,
  Dish,
  findJCourseByLocalId,
  Course,
} from "@/jpasta-sdk";
import _, { Dictionary } from "lodash";
import moment from "moment-timezone";
import TableBuilder from "@/classes/TableBuilder";
import { IPopulateReportCellValueStrategy } from "@/components/Hotel/IPopulateReportCellValueStrategy";
import { DefaultPopulateCellValueStrategy } from "@/components/Hotel/DefaultPopulateCellValueStrategy";
import { AnnotationNearQuantityPopulateCellValueStrategy } from "@/components/Hotel/AnnotationNearQuantityPopulateCellValueStrategy";
const DISH_CHUNK_SIZE = 18;

export default Vue.extend({
  props: {
    hotel: {
      type: Object as () => Hotel,
    },
    tables: {
      type: Array as PropType<Table[]>,
    },
    ranks: {
      type: Array as () => Rank[],
    },
    title: {
      type: String as () => string,
    },
    books: {
      type: Array as () => Book[],
    },
    mealOrderDishesMap: {
      type: Object as () => { [key: string]: MealOrderDish[] },
    },
    mealOrders: {
      type: Array as () => MealOrder[],
    },
    isDeliveryOrTakeaway: {
      type: Boolean as () => boolean,
    },
    turn: {
      type: Object as () => Turn | undefined,
    },
    calendarMeal: {
      type: Object as () => CalendarMeal,
    },
  } as const,
  data: function (): {
    isMounted: boolean;
  } {
    return {
      isMounted: false,
    };
  },
  mounted() {
    this.isMounted = true;
  },

  methods: {
    getBooksOf: function (rank: Rank | null): Book[] {
      const tables = _.filter(this.tables, (t) => {
        return !!(
          _.size(t.get("name")) > 0 &&
          (rank ? t.belongsTo(rank, "rank") : !t.get("rank"))
        );
      });

      const tablesMap = _.keyBy(tables, (t) => {
        return t.get("name");
      });

      const books = _.filter(this.books, (b) => {
        if (!rank) {
          if (
            !b.getTableNumber() ||
            !this.tablesByName[b.getTableNumber() as string]
          )
            return true;

          if (tablesMap[b.getTableNumber() as string]) return true;
          //console.log("b.getTableNumber()", b.getTableNumber());
          return false;
        }

        return !!tablesMap[b.getTableNumber() as string];
      });

      return _.sortBy(books, (b) => {
        const tableName = b.getTableNumber() as string;
        const sanitizedName = (tableName || "").replace(/\D/g, "");
        const v = parseInt(sanitizedName || "0") || Infinity;

        return v;
      });
    },
    formatTableName({ name }: { name: string }) {
      return name;
    },
    formatRoomName({ roomName }: { roomName: string }) {
      return roomName;
    },
    formatPersonName({ personName }: { personName: string }) {
      return personName ?? "";
    },

    formatRankName: function (name: string = ""): string {
      if (name === "Delivery" || name === "TakeAway") return name;
      return (name ?? "").toLowerCase().includes("rango")
        ? name
        : `Rango ${name}`;
    },
    formatTurnName: function (): string {
      if (!this.turn) return "Senza turno";
      return _.size(this.turn.get("name"))
        ? this.turn.get("name")
        : "Turno senza nome";
    },
    getMealName: function (): string {
      const mealIndex = this.calendarMeal.get("mealIndex");
      if (mealIndex === undefined) return "";
      const meal = findJMealByLocalId(mealIndex);
      if (!meal) return "";
      return meal.name.it;
    },
    formatDate(date): string {
      return moment(date).format("ddd D MMMM YYYY [ore] HH:mm");
    },

    chunkString: function (str: string) {
      const MAX_LEN = 25;
      const chunks: string[] = [];
      let currString = "";
      for (let i = 0; i < str.length; i++) {
        currString = currString + str[i];
        if (currString.length >= MAX_LEN) {
          chunks.push(currString);
          currString = "";
        }
      }

      if (currString.length > 0) chunks.push(currString);

      return chunks;
    },
    truncateDishName: function (name: string) {
      const chunks = this.chunkString(name);

      return _.truncate(chunks.join("<br/>"), {
        length: 50,
      });
    },
    getLocalizedName: function (name): any {
      return Jpasta.getLocalizedName(name, "it", "Nessun nome");
    },
  },

  computed: {
    printNamesInWaiterReport: function (): boolean {
      return this.hotel.printNamesInWaiterReport();
    },
    populateCellValueStrategy: function (): IPopulateReportCellValueStrategy {
      return new AnnotationNearQuantityPopulateCellValueStrategy();
      // if (this.hotel.get("annotationNearQuantity")) {
      //   return new AnnotationNearQuantityPopulateCellValueStrategy();
      // } else {
      //   return new DefaultPopulateCellValueStrategy();
      // }
    },
    tablesByName: function () {
      return _.keyBy(this.tables, (t) => {
        return t.get("name");
      });
    },
    mealOrdersByBook: function (): Dictionary<MealOrder[]> {
      return _.groupBy(this.mealOrders, (mo) => {
        const book = mo.get("book") as Book;
        if (!book) return undefined;
        return book.get("localId");
      });
    },
    reportDataExplodedByOrder: function () {
      const report: {
        name: string;
        tables: any;
      }[] = [];

      const ranks = [null, ...this.ranks];
      _.forEach(ranks, (rank) => {
        const books = this.getBooksOf(rank);
        if (books.length <= 0) return;
        const builder = new TableBuilder<
          {
            name: string;
            type: "book" | "deliveryNotes";
            deliveryNotes?: string;
          },
          Dish,
          {
            qty: number;
            annotations: string[];
            halfPortions: number;
          } /*| {deliveryNotes: string, colSpan:number }*/
        >(DISH_CHUNK_SIZE);

        _.forEach(this.orderableDishes, (d) => {
          builder.addColumn({
            id: d.get("localId"),
            value: d,
          });
        });

        let hasAtLeastOneOrder = false;

        _.forEach(books, (b) => {
          const tableName = b.getTableNumber();
          const mealOrders = this.mealOrdersByBook[b.get("localId")];
          _.forEach(mealOrders, (mo) => {
            const dishes = this.mealOrderDishesMap[mo.get("localId")];
            const rowId = `${mo.get("localId")}`;

            _.forEach(this.orderableDishes, (dish) => {
              const columnId = dish.get("localId");
              builder.setCellValue(
                columnId,
                rowId,
                {
                  qty: 0,
                  annotations: [],
                  halfPortions: 0,
                },
                {
                  name: tableName ? tableName : "//",
                  type: "book",
                }
              );
            });

            _.forEach(dishes, (odish) => {
              const dish = odish.get("dish") as Dish;
              if (!dish) return;
              const columnId = dish.get("localId");
              const currCellValue = builder.getCellValue(columnId, rowId);
              if (!currCellValue) return;
              const qty = odish.get("quantity") || 0;
              if (qty > 0) hasAtLeastOneOrder = true;
              if (odish.isHalfPortion()) {
                currCellValue.halfPortions += qty;
              } else {
                currCellValue.qty += qty;
              }

              if (_.size(odish.get("annotation")) > 0) {
                currCellValue.annotations.push(`x1 ${odish.get("annotation")}`);
              }

              builder.setCellValue(columnId, rowId, currCellValue);
            });

            if (mo.isDelivery()) {
              builder.addRowAfter(rowId, `${rowId}-delivery`, undefined, {
                type: "deliveryNotes",
                name: tableName ? `D ${tableName}` : "//",
                deliveryNotes: mo.getLocDeliveryExpl(),
              });
            }
          });
        });
        if (hasAtLeastOneOrder)
          report.push({
            name: rank ? rank.get("name") : "Senza rango",
            tables: builder.getTables(),
          });
      });

      return report;
    },

    reportData: function (): {
      name: string;
      tables: any;
    }[] {
      if (this.isDeliveryOrTakeaway) {
        return this.reportDataExplodedByOrder;
      }
      const report: {
        name: string;
        tables: any;
      }[] = [];

      _.forEach([null, ...this.ranks], (rank) => {
        const books = this.getBooksOf(rank);
        if (books.length <= 0) return;
        const builder = new TableBuilder<
          {
            name: string;
            type: "book" | "deliveryNotes";
            roomName?: string;
            personName?: string;
          },
          Dish,
          {
            qty: number;
            annotations: string[];
            halfPortions: number;
          }
        >(DISH_CHUNK_SIZE);
        _.forEach(this.orderableDishes, (d) => {
          builder.addColumn({
            id: d.get("localId"),
            value: d,
          });
        });

        _.forEach(books, (b) => {
          const tableName = b.getTableNumber();
          const roomName = b.getRoomNumber();
          const rowId = b.get("localId");
          _.forEach(this.orderableDishes, (d) => {
            const columnId = d.get("localId");
            builder.setCellValue(
              columnId,
              rowId,
              {
                qty: 0,
                annotations: [],
                halfPortions: 0,
              },
              {
                type: "book",
                name: tableName ? tableName : "//",
                roomName,
                personName: b.getRefName(),
              }
            );
          });
        });

        _.forEach(this.mealOrders, (mo) => {
          const dishes = this.mealOrderDishesMap[mo.get("localId")];
          const book = mo.get("book") as Book;
          if (!book) return;
          const rowId = book.get("localId");
          _.forEach(dishes, (odish) => {
            const dish = odish.get("dish") as Dish;
            if (!dish) return;
            const columnId = dish.get("localId");
            const currValue = builder.getCellValue(columnId, rowId);

            if (!currValue) return;
            const newVal = this.populateCellValueStrategy.populateCellValue(
              currValue,
              odish
            );

            const setted = builder.setCellValue(columnId, rowId, newVal);
          });
        });

        // console.log("TABLES", builder.getTables());

        report.push({
          name: rank ? rank.get("name") : "Senza rango",
          tables: builder.getTables(),
        });
      });

      return report;
    },

    isTableSameAsRoom: function (): boolean {
      return this.hotel.getTableIsSameAsRoom();
    },

    orderableDishes: function (): Dish[] {
      const dishes = _.keyBy(this.calendarMeal.get("dishes") as Dish[], (d) => {
        return d.get("localId") as string;
      });

      _.forEach(this.mealOrderDishesMap, (odishes) => {
        _.forEach(odishes, (odish) => {
          const dish = odish.get("dish") as Dish;
          if (!dish) return;
          dishes[dish.get("localId")] = dish;
        });
      });

      const filteredDishes = _.filter(dishes, (d) => {
        const courseIndex = d.get("courseIndex");
        if (courseIndex === undefined) return false;
        const jCourse = findJCourseByLocalId(courseIndex);
        if (!jCourse) return false;

        return true;
      });

      return _.sortBy(filteredDishes, (d) => {
        const courseIndex = d.get("courseIndex");
        const jCourse = findJCourseByLocalId(courseIndex) as Course;
        return jCourse.pos;
      });
    },
  },
});
