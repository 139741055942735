var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"navigator"},[_c('span',{staticClass:"icon-left-arrow arrow",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.prevMonth.apply(null, arguments)}}}),_c('span',{staticClass:"month"},[_vm._v(_vm._s(_vm.getCurrMonthString()))]),_c('span',{staticClass:"icon-right-arrow arrow",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.nextMonth.apply(null, arguments)}}})]),_vm._m(0)]),_c('div',{staticClass:"body"},_vm._l((_vm.days),function(day,index){return _c('Jadvice',{key:index,staticClass:"advice-calendar",attrs:{"title":_vm.adviceTitle,"visible":_vm.isAdviceVisible(day)}},[_c('div',{staticClass:"calendar-cell",class:{
          today: _vm.isToday(day),

          'current-month': day.utcDate.isSame(_vm.currDate, 'month'),
          'no-border-right': (index + 1) % 7 == 0,
          'no-border-bottom': index >= _vm.days.length - 7,
          selected: _vm.isSelected(day),
          'big-border-top': _vm.bigBorderTop(index),
          'big-border-left': _vm.bigBorderLeft(index),
          'big-border-bottom': _vm.bigBorderBottom(index),
          'big-border-right': _vm.bigBorderRight(index),
        },style:({
          'background-color': _vm.isAdviceVisible(day) ? 'white' : '',
        }),attrs:{"slot":"trigger","data-day":day.utcDate.toISOString()},on:{"mouseenter":function($event){return _vm.mouseEnter(day, $event)},"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.mouseDown(day, $event)},"touchstart":function($event){return _vm.mouseDown(day, $event, true)}},slot:"trigger"},[_c('div',[_c('span',{staticClass:"day",style:({
              color: _vm.isAdviceVisible(day) ? 'red' : '',
            })},[_vm._v(_vm._s(_vm.getLocal(day.utcDate).date()))]),_vm._t("daycell",null,{"day":day.utcDate})],2)]),_vm._t("adviceContent")],2)}),1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"days-of-week"},[_c('span',[_vm._v("Lun")]),_c('span',[_vm._v("Mar")]),_c('span',[_vm._v("Mer")]),_c('span',[_vm._v("Gio")]),_c('span',[_vm._v("Ven")]),_c('span',[_vm._v("Sab")]),_c('span',[_vm._v("Dom")])])
}]

export { render, staticRenderFns }