// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
"use strict";
import ReactWrapper from "@/react/ReactWrapper.vue";

require("intersection-observer");
import * as Jpasta from "@/jpasta-sdk";
import "element-ui/lib/theme-chalk/index.css";

import "./assets/scss/main.scss";
import "vue-resize/dist/vue-resize.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Parse from "parse";
import JButton from "@/components/ui/JButton.vue";
import JLayoutTabs from "@/components/ui/JLayoutTabs.vue";
import JInput from "@/components/ui/JInput.vue";
import JCard from "@/components/ui/JCard.vue";
import BookExtra from "@/components/Book/BookExtra.vue";
import JBreadcrumbs from "@/components/ui/JBreadcrumbs.vue";
import JSpinner from "@/components/ui/JSpinner.vue";
import JTooltip from "@/components/ui/JTooltip.vue";
import JSearchableSelect from "@/components/ui/JSearchableSelect.vue";
import JFromTime from "@/components/ui/JFromTime.vue";
import JLayout from "@/components/ui/JLayout.vue";
import JAvatar from "@/components/ui/JAvatar.vue";
import JLayoutNew from "@/components/ui/JLayoutNew.vue";
import JAllergen from "@/components/ui/JAllergen.vue";
import JLayoutClosableMenu from "@/components/ui/JLayoutClosableMenu.vue";
import JGantt from "@/components/Book/JGantt.vue";
import Msg from "vue-message";
import vStars from "vue-stars";

// import Vuex from "vuex";
import store from "./store/VuexStore.js";
import TopBar from "@/components/Bar/TopBar.vue";
import BottomBar from "@/components/Bar/BottomBar.vue";
import ElementUI from "element-ui";

import Raven from "raven-js";
import RavenVue from "raven-js/plugins/vue";
import draggable from "vuedraggable";

import VuejsDialog from "vuejs-dialog";

import JModal from "@/components/ui/JModal.vue";
import JSearchModal from "@/components/ui/JSearchModal.vue";
import JCheckBox from "@/components/ui/JCheckBox.vue";
import JRadio from "@/components/ui/JRadio.vue";
import JColorBadge from "@/components/ui/JColorBadge.vue";
import BookEditComponent from "@/components/Book/BookEditComponent.vue";

import JLabels from "@/components/ui/JLabels.vue";
import JpastaModal from "@/components/ui/Modal.vue";
import AdviceText from "@/components/ui/AdviceText.vue";

import JNewLabel from "@/components/ui/JNewLabel.vue";
import DishList from "@/components/Hotel/DishList.vue";
import HotelEdit from "@/components/Hotel/HotelEdit.vue";

import locale from "element-ui/lib/locale/lang/it";

import VueTagsInput from "@johmun/vue-tags-input";
import InfiniteLoading from "vue-infinite-loading";
import VTooltip from "v-tooltip";

import VueVirtualScroller from "vue-virtual-scroller";

import VueMq from "vue-mq";
import {
  DynamicScroller,
  DynamicScrollerItem,
  RecycleScroller,
} from "vue-virtual-scroller";
import { ResizeObserver } from "vue-resize";

import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import Sticky from "vue-sticky-directive";
import JWarnMessage from "./components/ui/JWarnMessage.vue";
import Cookies from "js-cookie";
import "./directives/Jloading";
Cookies.set("test", "1234");
setTimeout(() => {
  console.log("TRIGGERED");
  Cookies.remove("test");
}, 10000);
Vue.use(Sticky);
//Vue.use(JloadingDirective);
Vue.component("resize-observer", ResizeObserver);
Vue.use(VueVirtualScroller);

Vue.component("j-gantt", JGantt);

Vue.component("jlayout-tabs", JLayoutTabs);
Vue.component("react-wrapper", ReactWrapper);
Vue.component("DynamicScroller", DynamicScroller);
Vue.component("AdviceText", AdviceText);
Vue.component("DynamicScrollerItem", DynamicScrollerItem);
Vue.component("RecycleScroller", RecycleScroller);
Vue.component("HotelEdit", HotelEdit);
Vue.component("book-edit", BookEditComponent);
//

Vue.component("JModal", JpastaModal);

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    xs: 490,
    sm: 768,
    md: 992,
    lg: 1200,
    mxl: 1920,
    xl: Infinity,
  },
  defaultBreakpoint: "sm", // customize this for SSR
});

Vue.use(VTooltip);
if (process.env.NODE_ENV != "development") {
  Raven.config("https://bb6740d89a8a48548595c41d02dc5077@sentry.io/1250094")
    .addPlugin(RavenVue, Vue)
    .install();
}
Vue.use(VuejsDialog);

Vue.use(ElementUI, { locale });
Vue.use(Msg, {
  text: "",
  duration: 3000,
  background: "rgba(7,17,27,0.6)",
});
Vue.config.productionTip = false;

Parse.initialize("8c68df8618564920aa6509a7568cdfed");
Parse.serverURL = process.env.VUE_APP_PARSE_SERVER_URL
  ? process.env.VUE_APP_PARSE_SERVER_URL
  : "http://127.0.0.1:1337/parse";

Vue.component("book-extra", BookExtra);
Vue.component("j-searchable-select", JSearchableSelect);
Vue.component("jfromtime", JFromTime);
Vue.component("jlayout", JLayout);
Vue.component("JLayout", JLayoutNew);
Vue.component("jlayoutclosable", JLayoutClosableMenu);
Vue.component("draggable", draggable);
Vue.component("dish-list", DishList);
Vue.component("v-stars", vStars);
Vue.component("jallergen", JAllergen);
Vue.component("jcolorbadge", JColorBadge);
Vue.component("vue-tags-input", VueTagsInput);

Vue.component("jwarn", JWarnMessage);

Vue.component("jmodal", JModal);

Vue.component("jbutton", JButton);
Vue.component("javatar", JAvatar);
Vue.component("jinput", JInput);
Vue.component("jspinner", JSpinner);
Vue.component("jtooltip", JTooltip);

Vue.component("jnewlabel", JNewLabel);
Vue.component("jlabels", JLabels);

Vue.component("jcard", JCard);
Vue.component("top-bar", TopBar);
Vue.component("bottom-bar", BottomBar);
Vue.component("jbreadcrumbs", JBreadcrumbs);
Vue.component("jsearch-modal", JSearchModal);
Vue.component("infinite-loading", InfiniteLoading);
Vue.component("jcheckbox", JCheckBox);
Vue.component("jradio", JRadio);

if (Jpasta.User.current()) {
  store.commit("users/setCurrent", Jpasta.User.current());
}

new Vue({
  el: "#app",
  store,
  router,
  components: { App },
  template: "<App/>",
});
