<template>
  <Tables></Tables>
</template>

<script>
import Tables from "./Tables";
export default {
  components: {
    Tables,
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss"></style>
