
import Vue from "vue";
import mixins from "vue-typed-mixins";
import moment from "moment-timezone";
import _ from "lodash";
import {
  MealOrder,
  CalendarMeal,
  findJMealByLocalId,
  Jpasta,
  Book,
  Turn,
} from "@/jpasta-sdk";
export default mixins().extend({
  data: function (): {} {
    return {};
  },
  methods: {
    getCalendarMeal(cMealLocalId): CalendarMeal {
      return this.$store.state.calendarMeals.objects[cMealLocalId];
    },
    formatDate(date): string {
      if (!date) return "";
      return moment(date).format("DD MMM YYYY HH:mm");
    },
    getCmealName(cMeal: CalendarMeal) {
      const meal = findJMealByLocalId(cMeal.get("mealIndex"));
      if (!meal) return "";
      return `${Jpasta.getLocalizedName(
        meal.name,
        "it",
        ""
      )} di ${this.formatDate(cMeal.get("date"))}`;
    },
    getTurnName(turn: Turn) {
      if (!turn) return "Senza turno";
      return `Turno ${turn.get("name") || "Senza nome"}`;
    },
    getBookName() {
      if (!this.book) return;
      const refName = this.book.get("refName");
      if (_.size(refName) > 0) return refName;

      const email = this.book.get("refEmail");
      if (_.size(email) > 0) return email;
      //       const tableName =  this.book.getTableNumber()

      //  const roomName = this.book.getRoomNumber()
      return "";
    },
  },
  computed: {
    book: function (): Book {
      return this.$store.state.books.objects[this.$route.params.bookid];
    },
    turn: function (): Turn {
      return this.$store.state.turns.objects[this.$route.params.turnid];
    },
    paths: function (): { label: string; to?: any }[] {
      const tableName = this.book ? this.book.getTableNumber() : undefined;
      const roomName = this.book ? this.book.getRoomNumber() : undefined;
      const refName = this.book ? this.book.get("refName") : undefined;
      switch (this.$route.name) {
        case "HotelMealBooks":
          return [
            {
              label: "Ordini pasti",
              // to: {
              //   name: "HotelMealBooks",
              //   params: {
              //     hotelid: this.$route.params.hotelid
              //   }
              // }
            },
          ];
        case "BookPeople":
          return [
            {
              label: `Prenotazione ${this.getBookName()}`,
              to: this.$route.query.fromGantt
                ? {
                    name: "Planning",
                    params: {
                      hotelid: this.$route.params.hotelid,
                    },
                    query: {
                      selectedBook: this.book.get("localId"),
                    },
                  }
                : {
                    name: "BookList",
                    params: {
                      hotelid: this.$route.params.hotelid,
                    },
                    query: {
                      selectedBook: this.book.get("localId"),
                    },
                  },
            },
            {
              label: "Persone",
            },
          ];
        case "EditMealBookTurn":
          return [
            {
              label: "Ordini pasti",
              to: {
                name: "HotelMealBooks",
                params: {
                  hotelid: this.$route.params.hotelid,
                },
              },
            },

            {
              label: this.getTurnName(this.turn),
              to: {
                name: "MealBookTurnScreen",
                params: {
                  hotelid: this.$route.params.hotelid,
                  calendarmealid: this.$route.params.calendarmealid,
                },
              },
            },
            {
              label: this.getCmealName(
                this.getCalendarMeal(this.$route.params.calendarmealid)
              ),
              to: {
                name: "HotelMealBookScreenTurn",
                params: {
                  hotelid: this.$route.params.hotelid,
                  calendarmealid: this.$route.params.calendarmealid,
                  turnid: this.$route.params.turnid,
                },
              },
            },
            {
              label: `Ordini ${
                tableName ? `Tav ${tableName}` : "Tavolo senza nome"
              }`,
            },
          ];
        case "HotelMealBookScreenTurn":
          return [
            {
              label: "Ordini pasti",
              to: {
                name: "HotelMealBooks",
                params: {
                  hotelid: this.$route.params.hotelid,
                },
              },
            },

            {
              label: this.getTurnName(this.turn),
              to: {
                name: "MealBookTurnScreen",
                params: {
                  hotelid: this.$route.params.hotelid,
                  calendarmealid: this.$route.params.calendarmealid,
                },
              },
            },
            {
              label: this.getCmealName(
                this.getCalendarMeal(this.$route.params.calendarmealid)
              ),
            },
          ];
        case "MealBookTurnScreen":
          return [
            {
              label: "Ordini pasti",
              to: {
                name: "HotelMealBooks",
                params: {
                  hotelid: this.$route.params.hotelid,
                },
              },
            },
            {
              label: "Seleziona turno",
              // to: {
              //   name: "HotelMealBooks",
              //   params: {
              //     hotelid: this.$route.params.hotelid
              //   }
              // }
            },
          ];

        case "HotelMealBook":
          return [
            {
              label: "Ordini pasti",
              to: {
                name: "HotelMealBooks",
                params: {
                  hotelid: this.$route.params.hotelid,
                },
              },
            },
            {
              label: "Senza turno",
              to: {
                name: "MealBookTurnScreen",
                params: {
                  hotelid: this.$route.params.hotelid,
                  calendarmealid: this.$route.params.calendarmealid,
                },
              },
            },
            {
              label: this.getCmealName(
                this.getCalendarMeal(this.$route.params.calendarmealid)
              ),
            },
          ];

        case "EditMealBook":
          return [
            {
              label: "Ordini pasti",
              to: {
                name: "HotelMealBooks",
                params: {
                  hotelid: this.$route.params.hotelid,
                },
              },
            },
            {
              label: "Senza turno",
              to: {
                name: "MealBookTurnScreen",
                params: {
                  hotelid: this.$route.params.hotelid,
                  calendarmealid: this.$route.params.calendarmealid,
                },
              },
            },
            {
              label: this.getCmealName(
                this.getCalendarMeal(this.$route.params.calendarmealid)
              ),
              to: {
                name: "HotelMealBook",
                params: {
                  hotelid: this.$route.params.hotelid,
                  calendarmealid: this.$route.params.calendarmealid,
                },
              },
            },
            {
              label: `Ordini ${
                tableName ? `Tav ${tableName}` : "Tavolo senza nome"
              }`,
            },
          ];

        default:
          return [];
      }
    },
  },
});
