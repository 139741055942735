var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"menuWrapper",staticClass:"menuWrapper"},[_c('div',{staticClass:"logoWrapper",on:{"click":function($event){return _vm.toAllHotels(false)}}},[_c('img',{staticStyle:{"cursor":"pointer","max-height":"100px","max-width":"120px"},attrs:{"src":require("../../assets/LogoWhiteAndAppIcon.svg")}})]),_c('div',{staticClass:"link",class:{
      selected: _vm.$route.name == 'HotelCalendar',
    },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toHotelCalendar.apply(null, arguments)}}},[_vm._v(" Calendario Pasti ")]),_c('div',{staticClass:"link",class:{
      selected: _vm.$route.name == 'HotelMealBooks',
    },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toHotelMealBook.apply(null, arguments)}}},[_vm._v(" Ordini pasti ")]),_c('div',{staticClass:"link",class:{
      selected: _vm.$route.name == 'ExtraMealOrder',
    },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toExtraMealOrder.apply(null, arguments)}}},[_c('el-badge',{staticClass:"item",attrs:{"hidden":_vm.notSeenExtraMealOrders.length <= 0,"value":_vm.notSeenExtraMealOrders.length}},[_vm._v(" Ordini extra pasto ")])],1),_c('div',{staticClass:"link",class:{
      selected: _vm.$route.name == 'BookList',
    },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toBookList.apply(null, arguments)}}},[_vm._v(" Lista prenotazioni ")]),(_vm.$mq !== 'xs' && _vm.$mq !== 'sm')?_c('div',{staticClass:"link",class:{
      selected: _vm.$route.name == 'Planning',
    },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toHotelPlanning.apply(null, arguments)}}},[_vm._v(" Disposizione sala ")]):_vm._e(),_c('div',{staticClass:"link",class:{
      selected: _vm.$route.name == 'TablesView',
    },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toTablesView.apply(null, arguments)}}},[_vm._v(" Tavoli ")]),_c('div',{staticClass:"link",class:{
      selected: _vm.$route.name == 'HotelRooms',
    },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toRoomsView.apply(null, arguments)}}},[_vm._v(" Camere ")]),_c('div',{staticClass:"link",class:{
      selected: _vm.$route.name == 'HotelDishes',
    },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toDishesList.apply(null, arguments)}}},[_vm._v(" Raccolta piatti ")]),_c('div',{staticClass:"link",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openQrCodeModal.apply(null, arguments)}}},[_vm._v(" Qr Code visualizzazione pasti ")]),_c('div',{staticClass:"link",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toMassNotifyView.apply(null, arguments)}}},[_vm._v(" Invia notifica ")]),_c('div',{staticClass:"link",class:{
      selected: _vm.$route.name == 'HotelEdit',
    },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toStructureEdit.apply(null, arguments)}}},[_vm._v(" Impostazioni ")]),(_vm.$mq === 'xs')?_c('div',{staticClass:"link",class:{
      selected: _vm.$route.name == 'Hotels',
    },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toAllHotels(true)}}},[_vm._v(" Lista alberghi ")]):_vm._e(),_vm._m(0),(_vm.hotel && _vm.hotel.id)?_c('ViewDishesQrCode',{attrs:{"hotel":_vm.hotel,"showModal":_vm.showQrCodeModal},on:{"close":function($event){_vm.showQrCodeModal = false}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"updatesWrapper"},[_c('span',{staticClass:"updatesText"},[_vm._v("Novità!")])])
}]

export { render, staticRenderFns }