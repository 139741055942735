
import mixins from "vue-typed-mixins";
export default mixins().extend({
  props: {
    value: {},
  },
  data: function (): {
    filterBookOptions: { value: string; label: string }[];
  } & any {
    return {
      filterBookOptions: [],
    };
  },
  methods: {
    parseValue: function () {
      const value = this.value;
      if (!value) return undefined;
      return `${value.type}-${value.value}`;
    },
    doFilterBookOptions: function (queryString) {
      if (!queryString || !queryString.length)
        return (this.filterBookOptions = []);
      this.filterBookOptions = [
        {
          value: "room-" + queryString,
          label: `Camera: ${queryString}`,
        },
        {
          value: "table-" + queryString,
          label: `Tavolo: ${queryString}`,
        },
        {
          value: "email-" + queryString,
          label: `Email contiene ${queryString}`,
        },
        {
          value: "name-" + queryString,
          label: `Nome contiene ${queryString}`,
        },
        {
          value: "all",
          label: `Pulisci filtro`,
        },
      ];
    },
    parseSearchBook(searchString) {
      if (!searchString || !searchString.length) return null;
      const type = searchString.substring(0, searchString.indexOf("-"));
      const value = searchString.substring(searchString.indexOf("-") + 1);
      return {
        type,
        value,
      };
    },
    chagneSearchBookString: function (val) {
      if (val === "all") {
        this.$emit("input", undefined);
        this.filterBookOptions = [];
      } else {
        this.$emit("input", this.parseSearchBook(val));

        //this.searchBookString = val;
      }
    },
  },
});
