import Parse from "parse";
import Jpasta from "./Jpasta";
import _ from "lodash";

export default class Turn extends Jpasta {
  constructor() {
    super("Turn");
  }

  public setFromTime(value: string, mealIndex: number) {
    if (mealIndex === undefined) {
      return;
    }
    const fromTime = this.get("fromTime") || {};
    fromTime[mealIndex] = value;
    this.set("fromTime", _.cloneDeep(fromTime));
  }
  public setToTime(value: string, mealIndex: number) {
    if (mealIndex === undefined) {
      return;
    }

    const toTime = this.get("toTime") || {};
    toTime[mealIndex] = value;
    console.log("toTime", toTime);
    this.set("toTime", _.cloneDeep(toTime));
  }

  public getFromTime(mealIndex: number): string | undefined {
    if (mealIndex === undefined) {
      return;
    }

    const fromTime = this.get("fromTime") || {};

    return fromTime[mealIndex];
  }

  public getToTime(mealIndex: number): string | undefined {
    if (mealIndex === undefined) {
      return;
    }

    const toTime = this.get("toTime") || {};

    return toTime[mealIndex];
  }
}

Parse.Object.registerSubclass("Turn", Turn);
