var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('JLayout',{attrs:{"blinkingButton":_vm.isTutorial && _vm.tutorialStep === 0,"buttonAdviceTitle":"Crea il tuo albergo","buttonAdviceVisible":_vm.isTutorial && _vm.tutorialStep === 0},on:{"click":_vm.newHotel}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("I miei alberghi")]),_c('span',{attrs:{"slot":"maincta"},slot:"maincta"},[_vm._v("Aggiungi Albergo")]),(!_vm.hasTutorialInQuery)?_c('div',{staticClass:"card-wrapper"},_vm._l((_vm.hotels),function(hotel){return _c('div',{key:hotel.get('localId'),staticClass:"hotel-card-wrapper"},[_c('jcard',{staticClass:"hotel-card",staticStyle:{"cursor":"pointer"},attrs:{"clickable":true},nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.$router.push({
            name: 'HotelCalendar',
            params: {
              hotelid: hotel.get('localId'),
            },
          })}}},[_c('div',{staticClass:"d-flex",attrs:{"slot":"header"},slot:"header"},[_c('h2',[_vm._v(_vm._s(hotel.get("name") ? hotel.get("name") : "Senza nome"))]),_c('div',{staticStyle:{"margin-left":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":function($event){return _vm.handleHotelDropdownCommand(hotel, $event)}}},[_c('span',{staticClass:"icon-more dropDownToggleIcon"}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"edit"}},[_vm._v("Modifica")]),_c('el-dropdown-item',{attrs:{"command":"delete"}},[_vm._v("Elimina")])],1)],1)],1)]),_c('div',{staticClass:"card-hotel-body"},[(_vm.addressString(hotel) && _vm.addressString(hotel).length)?_c('p',{staticClass:"hotel-address"},[_vm._v(" "+_vm._s(_vm.addressString(hotel) ? _vm.addressString(hotel) : "Nessuna informazione sull'indirizzo")+" ")]):_vm._e(),_c('jwarn',{staticStyle:{"margin-top":"10px"},attrs:{"show":_vm.hotelCardAdvices[hotel.get('localId')] &&
              _vm.hotelCardAdvices[hotel.get('localId')].opened,"message":_vm.hotelCardAdvices[hotel.get('localId')]
                ? _vm.hotelCardAdvices[hotel.get('localId')].content
                : ''}})],1)])],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }