export default {
  book: {
    link: `Condividi questo link con i tuoi clienti per aggiungerli a questa prenotazione`,
    bookId: `Un ID che identifica la prenotazione. Questa informazione non viene utilizzata da Jpasta,
                ma può esserti utile se utilizzi un sistema di gestione alberghiera`,
    refEmail: `Jpasta utilizza questa email per invitare i tuoi clienti a scaricare l'applicazione. 
                Per garantire una migliore esperienza da parte del cliente, il sistema invierà una sola email di notifica per prenotazione. Se vuoi fare in modo che il sistema invii nuovamente l'email, clicca su "Reinvia Email"`,
    refLocale: `Jpasta utilizza questa informazione per inviare l'email di invito nella lingua corretta`,
    people: `La lista dei prenotati. Jpasta richiederà, per qeusta prenotazione, tanti ordini quante sono le persone in questa lista.
                Se non inserisci i nomi, l'applicazione li chiderà automaticamente ai clienti al momento del primo ordine`,
    personDates: `Puoi utilizzare questi campi per gestire i casi in cui un prenotato arrivi o parta in una data diversa dagli altri`,
    bookHolder: `I responsabili sono le persone che hanno effettuato la prenotazione. Generalmente avrai solo un responsabile per prenotazione`,
    bookType: `Il tipo di prenotazione. In base a questa opzione Jpasta conteggerà gli ordini fuori tariffa che verranno mostrati negli extra.`,
    extraSyncWithPMS: `Ti consigliamo di eseguire questa operazione al momento del saldo finale da parte del cliente, in modo da avere tutti i conteggi sul tuo PMS`,
  },
  dish: {
    price: `Dai un prezzo a questo piatto per permettere a Jpasta di calcolare gli extra per gli ordini fuori tariffa. Un ordine fuori tariffa avviene ad esempio quando un cliente con tariffa Bed & Breakfast ordina un pranzo`,
  },
};
