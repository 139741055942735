import Vue from "vue";
import uniqid from "uniqid";
const activeLoader = {};
const activeLoaderIntervals = {};

function getRandomInteger(start, end) {
  const val = Math.random() * (end - start) + start;
  return Math.round(val);
}

const options = [
  "Sto cuocendo la torta di mele",
  "Sto stendendo la tovaglia",
  "Sto dando da mangiare agli Umpa Lumpa",
  "Sto contattando Gordon Ramsay",
  "Sto spolverando il calendario",
  "Sto acquistando le materie prime",
];

function endLoading(el) {
  const id = el.dataset.jpastaLoadingId;
  if (!id) return;
  const wrapper = activeLoader[id];
  if (!wrapper) return;

  el.removeChild(wrapper);
  activeLoader[id] = undefined;
  el.dataset.jpastaLoadingId = undefined;
  if (activeLoaderIntervals[id]) {
    clearInterval(activeLoaderIntervals[id]);
    activeLoaderIntervals[id] = undefined;
  }
}

function setTip(id) {
  const wrapper = activeLoader[id];
  if (!wrapper) {
    return;
  }
  const currentText = document.getElementById("textLoading" + id);
  if (!currentText) return;
  const index = getRandomInteger(0, options.length - 1);

  if (currentText.innerText === options[index]) {
    var text = options[(index + 1) % options.length];
  } else {
    var text = options[index];
  }
  currentText.innerText = text;
}

function startLoading(el, modifiers) {
  if (el.dataset.jpastaLoadingId) {
    const wrapper = activeLoader[el.dataset.jpastaLoadingId];
    if (wrapper) return;
  }
  const wrapper = document.createElement("div");
  const id = uniqid();
  wrapper.className = "logoSpinnerWrapper";
  wrapper.id = "jpastaLoadingSpinner" + id;
  el.dataset.jpastaLoadingId = id;

  wrapper.style.position = "absolute";

  wrapper.innerHTML = `
      <div class="rotatingLogoWrapper">
          <img
            class="rotatingLogo"
            style="width: 50px; height: 50px;"
            src="/JpastaIconMask.svg"
          />
          <span id="textLoading${id}">Caricamento</span>
        </div>
      
      `;

  el.style.position = "relative";

  el.appendChild(wrapper);
  activeLoader[id] = wrapper;

  if (!modifiers.showRandomTip) return;
  setTip(id);
  activeLoaderIntervals[id] = setInterval(() => {
    setTip(id);
    //   if (index === this.currentIndex) {
    //     this.currentIndex = (this.currentIndex + 1) % this.options.length;
    //   } else {
    //     this.currentIndex = index;
    //   }
  }, 2000);
}

Vue.directive("jpasta-loading", {
  update: function (el, { value, modifiers }) {
    if (value) {
      startLoading(el, modifiers);
    } else {
      endLoading(el);
    }
  },
  // When the bound element is inserted into the DOM...
  inserted: function (el, { value, modifiers }) {
    if (value) {
      startLoading(el, modifiers);
    } else {
      endLoading(el);
    }
  },
});
