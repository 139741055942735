<template>
  <span
    :class="{
      'column-layout': columnLayout,
    }"
  >
    <span
      :style="{
        'background-color': colors[label.get('colorIndex')]
          ? colors[label.get('colorIndex')].hex
          : colors[0],
      }"
      class="label-item"
      :class="{
        last: index === labels.length - 1,
      }"
      v-for="(label, index) in labels"
      :key="index"
      ><span class="name">{{ label.get("name").it }}</span>

      <img
        v-if="canEdit && !label.get('predefined')"
        v-on:click.prevent.stop="onEdit(label)"
        src="../../assets/edit-white.svg"
        :style="{
          'margin-left': 'auto',
        }"
        style="cursor: pointer; width: 10px; margin-left: auto"
      />

      <span
        :style="{
          'margin-left': canEdit ? '0.2em' : 'auto',
        }"
        v-if="canDelete && !label.get('predefined')"
        v-on:click.prevent.stop="onDelete(label)"
        class="remove-label-wrapper"
      >
        <span class="cross">&#10005;</span>
      </span>
    </span>

    <span v-if="canAddLabel" v-on:click="$emit('addlabel')" class="add-label"
      >Aggiungi etichetta</span
    >
  </span>
</template>

<script>
import { JPASTA_LABEL_COLORS } from "@/jpasta-sdk";
export default {
  props: ["labels", "canDelete", "columnLayout", "canEdit", "canAddLabel"],
  data: function () {
    return {
      colors: JPASTA_LABEL_COLORS,
    };
  },
  methods: {
    onDelete: function (labelIndex) {
      this.$emit("delete", labelIndex);
    },
    onEdit: function (labelIndex) {
      this.$emit("edit", labelIndex);
    },
  },
};
</script>

<style scoped lang="scss">
.column-layout {
  display: flex;
  flex-direction: column;
  .label-item {
    margin: 0.2em 0;
  }
}

.add-label {
  padding: 2px 4px;
  border: 1px dashed darken(#cccccc, 60%);
  border-radius: 4px;
  opacity: 0.6;
  font-size: 0.9em;
  cursor: pointer;
}

.label-item.last {
  margin-right: 0;
}

.label-item {
  margin-top: 0.1em;
  margin-bottom: 0.1em;

  margin-right: 0.2em;
  padding: 2px 4px;
  color: white;
  border-radius: 4px;
  font-size: 0.9em;
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;

  .name {
    margin-right: 0.2em;
  }

  .remove-label-wrapper {
    visibility: visible;
    cursor: pointer;
    opacity: 1;
  }
}
</style>
