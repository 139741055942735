<template>
  <div class="login-wrapper">
    <jcard class="login-card">
      <h2 class="text-center" slot="header">
        {{
          $route.query.reg == "1" ? "Crea il tuo account" : "Entra o Registrati"
        }}
      </h2>
      <div class="body">
        <jinput
          ref="emailinput"
          type="email"
          style="width: 80%"
          placeholder="Email"
          v-model="email"
          v-on:enterpressed="login"
        ></jinput>
        <jbutton
          :loading="loading"
          v-on:click.prevent.stop="login"
          class="login-button"
          >Entra</jbutton
        >
      </div>
    </jcard>
  </div>
</template>

<script>
import Parse from "parse";
export default {
  props: [""],
  data: function () {
    return {
      email: "",
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.emailinput.focus();
    });
  },
  methods: {
    login: async function (e) {
      if (!this.email.length) return this.$msg("Inserisci una email ^^");
      this.loading = true;

      try {
        var response = await this.$store.dispatch(
          "users/verifyUserExists",
          this.email
        );
        if (response.exists) {
          this.$router
            .push({
              name: "LoginPassword",
              query: {
                email: this.email,
              },
            })
            .catch((e) => {});
        } else {
          this.$router
            .push({
              name: "Register",
              query: {
                email: this.email,
              },
            })
            .catch((e) => {});
        }
      } catch (e) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.login-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #f4443f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2em;
}

.login-card {
  font-size: 16px;
  width: 90%;
}

.login-button {
  margin-top: 1.5em;
}

@media only screen and (min-width: 768px) {
  .login-card {
    max-width: 560px;
  }
}
</style>
