var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rootContainer",class:{
    first: _vm.index === 0,
  }},[_c('div',{staticClass:"person-wrapper"},[_c('div',{staticClass:"d-flex align-items-center person-header"},[_c('span',{staticClass:"person-number"},[_vm._v("#"+_vm._s(_vm.index + 1))]),_c('span',{staticStyle:{"width":"15px","height":"15px","margin-left":"auto","cursor":"pointer","opacity":"0.4"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteBookPerson(_vm.person)}}},[_vm._v("✕")])]),_c('div',{staticClass:"person-body"},[_c('el-row',{attrs:{"gutter":16}},[_c('el-col',{attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('div',{staticClass:"input-field"},[_c('span',{staticClass:"label"},[_vm._v("Nome")]),_c('jinput',{staticClass:"hotel-input",attrs:{"value":_vm.person.get('name'),"type":"text","placeholder":""},on:{"change":function($event){return _vm.setPersonProperty(_vm.person, 'name', $event)}}})],1)]),_c('el-col',{attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('div',{staticClass:"input-field"},[_c('span',{staticClass:"label"},[_vm._v("Cognome")]),_c('jinput',{staticClass:"hotel-input",attrs:{"value":_vm.person.get('surname'),"type":"text","placeholder":""},on:{"change":function($event){return _vm.setPersonProperty(_vm.person, 'surname', $event)}}})],1)]),_c('el-col',{staticStyle:{"margin-top":"10px"},attrs:{"span":24}},[_c('span',{staticClass:"label"},[_vm._v(" Check-in Check-out: "),_c('jtooltip',{staticStyle:{"margin-left":"3px","margin-right":"3px"},attrs:{"trigger":"hover","title":"Check-in e Check-out per questa persona","autoHide":true,"content":_vm.advices.book.personDates}},[_c('img',{staticStyle:{"opacity":"0.6","cursor":"pointer","width":"13px","height":"13px"},attrs:{"slot":"target","src":require("../../assets/info.svg")},slot:"target"})])],1)]),_c('el-col',{attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('el-date-picker',{ref:`person-checkin-${_vm.person.get('localId')}`,staticClass:"book-datetime-picker w-100",attrs:{"value":_vm.peopleCheckInValues[_vm.person.get('localId')] ||
              _vm.person.get('checkin') ||
              _vm.book.get('checkin'),"picker-options":{
              firstDayOfWeek: 1,
              disabledDate: (date) => {
                return _vm.personCheckInDisabled(date, _vm.person);
              },
            },"placeholder":"Check In","popper-class":"book-datetime-popper","type":"datetime","default-time":_vm.defaultCheckinTime,"format":_vm.dateFormat},on:{"input":function($event){_vm.setPersonCheckIn(_vm.person.get('localId'), $event)},"change":function($event){return _vm.setPersonProperty(_vm.person, 'checkin', $event)}}})],1),_c('el-col',{staticClass:"fix-datepicker-mobile",attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('el-date-picker',{ref:`person-checkout-${_vm.person.get('localId')}`,staticClass:"book-datetime-picker w-100 checkoutInput",attrs:{"picker-options":{
              firstDayOfWeek: 1,
              disabledDate: (date) => {
                return _vm.personCheckOutDisabled(date, _vm.person);
              },
            },"value":_vm.peopleCheckOutValues[_vm.person.get('localId')] ||
              _vm.person.get('checkout') ||
              _vm.book.get('checkout'),"placeholder":"Check Out","popper-class":"book-datetime-popper","type":"datetime","default-time":_vm.defaultCheckoutTime || undefined,"format":_vm.dateFormat},on:{"input":function($event){_vm.setPersonCheckOut(_vm.person.get('localId'), $event)},"change":function($event){return _vm.setPersonProperty(_vm.person, 'checkout', $event)}}})],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }