
import Vue from "vue";
export default Vue.extend({
  props: {
    actionTitle: {},
    inline: {},
    actionTitleDisabled: {},
    placement: {
      default: "top",
    },
    title: {},
    width: { default: "auto" },
    visible: {},
  },
  methods: {
    actionClicked: function () {
      if (this.actionTitleDisabled) return;
      this.$emit("actionclicked");
    },
  },
});
