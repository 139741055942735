import { Notification, Message } from "element-ui";
import _ from "lodash";

export default class SaveManager {
  static self?: SaveManager = undefined;
  static savingMap = {};
  static savingMapTimeout = {};
  private notificationTimout?: any = undefined;

  static instance = () => {
    if (SaveManager.self === undefined) {
      SaveManager.self = new SaveManager();
    }

    return SaveManager.self;
  };

  notify = (commit?) => {
    this.endSaving(commit);
    // Message({
    //   message: "Jpasta ha salvato in automatico le tue modifiche",
    //   type: "success",
    //   duration: 4000,
    //   customClass: "bottomNotification",
    //   showClose: true
    // });
    // Notification({
    //   title: "Salvataggio completato",
    //   message: "Le tue modifiche sono state salvate con successo",
    //   position: "bottom-right",
    //   type: "success",
    //   duration: 2000
    // });
  };

  public startSaving(commit?) {
    if (commit)
      commit("startSaving", undefined, {
        root: true,
      });
    window.saving = true;
  }

  public endSaving(commit?) {
    if (commit)
      commit("stopSaving", undefined, {
        root: true,
      });
    window.saving = false;
  }

  public saved(commit?) {
    if (window.location.search.indexOf("tutorial") !== -1) return;

    if (this.notificationTimout) {
      clearTimeout(this.notificationTimout);
    }

    this.notificationTimout = setTimeout(() => {
      this.notify(commit);
    }, 1500);
  }

  public batchSave({ key, objectKey, object, action }) {
    if (SaveManager.savingMapTimeout[key])
      clearTimeout(SaveManager.savingMapTimeout[key]);
    _.set(SaveManager.savingMap, `${key}.${objectKey}`, object);
    SaveManager.savingMapTimeout[key] = setTimeout(async () => {
      const obj2Save = _.cloneDeep(SaveManager.savingMap[key]);
      SaveManager.savingMap[key] = {};
      SaveManager.savingMapTimeout[key] = undefined;
      await action(_.values(obj2Save));
    }, 1100);
  }
}
