import Parse from "parse";
import _ from "lodash";
import { Language } from "./types";

Parse.User.allowCustomUserClass(true);
const VALID_LOCALES = ["it", "en", "de"];
export default class User extends Parse.User {
  constructor() {
    super(Parse.User);
  }

  private sanitizeLocale(): string | undefined {
    const locale = this.get("locale") as string | undefined;
    if (!locale) return undefined;
    if (locale.indexOf("-") === -1) return locale;
    return locale.substr(0, locale.indexOf("-"));
  }

  public getLocale(): Language {
    const locale = this.sanitizeLocale();
    if (!locale || !VALID_LOCALES.includes(locale)) return "en";

    return locale as Language;
  }

  hasRoleLocal(roleName, roles) {
    //Passo db locale contenente i ruoli

    return !!_.find(roles, (role) => {
      return role.get("name") === roleName;
    });
  }

  isSync(roleName, usersRelationData) {
    /* Passo il database locale che contiene la relazione nomeRuolo -> utente (chiamata sincrona) */

    const users = usersRelationData[roleName];
    if (!users) return false;

    return !!_.find(users, (user) => {
      return this.id === user.id;
    });
  }

  async is(_roleName) {
    /* TODO Eseguo una query per vedere se l'utente appartiene al ruolo specificato da roleName (chiamata asincrona ritorna una promessa) */
  }
}

Parse.Object.registerSubclass("_User", User);
