import moment from "moment-timezone";
import _, { add } from "lodash";
import validator from "email-validator";
import Papa from "papaparse";

export default abstract class CSVParser {
  protected abstract parseRow(row: string[], index: number): BookPlain;
  protected file: File;
  protected errors: {
    [rowIndex: number]: {
      [fieldKey: string]: string[];
    };
  } = {};

  protected books: BookPlain[] = [];

  protected startRow: number = 0;
  public setStartRow(val: number) {
    this.startRow = val;
  }
  //   protected shouldSkipFirstRow: boolean = true;

  //   public setShouldSkipFirstRow(val: boolean) {
  //     this.shouldSkipFirstRow = true;
  //   }
  constructor(file: File) {
    this.file = file;
  }

  protected abstract getValidBookTypes(): string[];

  protected groupRows(rows: string[][]): string[][] {
    return rows;
  }

  protected isVoidRow(row: string[]): boolean {
    let isVoid = true;

    _.forEach(row, (s) => {
      if (s && s.trim().length > 0) {
        isVoid = false;
      }
    });

    return isVoid;
  }
  protected abstract parseBookType(type: string);
  public getErrors() {
    return this.errors;
  }

  protected addError(rowIndex: number, fieldKey: string, error: string) {
    _.set(this.errors, `${rowIndex}.${fieldKey}`, []);
    this.errors[rowIndex][fieldKey].push(error);
  }
  protected validateBook(book: BookPlain, addToError: boolean = true) {
    let hasError = false;

    if (!book.checkIn || !moment(book.checkIn).isValid()) {
      hasError = true;
      if (addToError)
        this.addError(
          book.rowIndex,
          "checkIn",
          "Checkin non valido. Le date devono essere nel formato: AAAA-MM-GG oppure AAAA-MM-GG HH:mm"
        );
    }
    if (!book.checkOut || !moment(book.checkOut).isValid()) {
      hasError = true;
      if (addToError)
        this.addError(
          book.rowIndex,
          "checkOut",
          "Checkout non valido. Le date devono essere nel formato: AAAA-MM-GG oppure AAAA-MM-GG HH:mm"
        );
    }
    if (
      book.checkOut &&
      book.checkIn &&
      moment(book.checkOut).isBefore(book.checkIn)
    ) {
      hasError = true;
      if (addToError) {
        this.addError(book.rowIndex, "checkOut", "Checkout prima del checkin");
        this.addError(book.rowIndex, "checkIn", "Checkout dopo il checkin");
      }
    }
    if (
      book.checkOut &&
      book.checkIn &&
      moment(book.checkOut).isBefore(book.checkIn)
    ) {
      hasError = true;
      if (addToError) {
        this.addError(book.rowIndex, "checkOut", "Checkout prima del checkin");
        this.addError(book.rowIndex, "checkIn", "Checkout dopo il checkin");
      }
    }
    if (this.parseBookType(book.rawBookType as string) === undefined) {
      hasError = true;
      if (addToError)
        this.addError(
          book.rowIndex,
          "bookType",
          `Trattamento non riconosciuto. Valori validi sono: ${this.getValidBookTypes().join(
            ", "
          )}`
        );
    }
    if (book.email && !validator.validate(book.email)) {
      if (addToError) this.addError(book.rowIndex, "email", "Email non valida");
    }

    if (book.pax === undefined) {
      hasError = true;
      if (addToError)
        this.addError(
          book.rowIndex,
          "pax",
          "Il numero pax deve essere un numero positivo"
        );
    }

    return hasError;
  }

  protected getRowData(row: string[], index: number): string | undefined {
    if (row.length <= index) return undefined;
    return row[index].trim();
  }
  protected setRowData(
    row: string[],
    index: number,
    val: string
  ): string | undefined {
    if (row.length <= index) return;
    return (row[index] = val);
  }

  public getBooks(): BookPlain[] {
    return _.cloneDeep(this.books);
  }

  protected filterRows(rows: string[][]): string[][] {
    return _.filter(rows, () => true);
  }

  protected rowHasError(row: string[] | undefined, index: number) {
    if (!row) return true;
    const book = this.parseRow(row, index);

    return this.validateBook(book, false);
  }

  protected parseRows(rows: string[][]) {
    this.errors = {};
    const books: BookPlain[] = [];
    const groupedRows = this.groupRows(rows);

    const notVoids = _.filter(groupedRows, (row) => !this.isVoidRow(row));
    const filteredRows = this.filterRows(notVoids);

    _.forEach(filteredRows, (row, index) => {
      if (row.length <= 0) return;
      if (this.isVoidRow(row)) return;
      if (this.startRow - 1 >= index) return;

      const book = this.parseRow(row, index);
      this.validateBook(book);

      books.push(book);
    });

    this.books = books;
    //this.showBookRecapModal = true;
  }
  protected guessSeparator(row: string) {
    const numberOfSemicolon = (row.match(/;/g) || []).length;
    const numberOfColon = (row.match(/,/g) || []).length;
    if (numberOfSemicolon > numberOfColon) return ";";
    else return ",";
  }

  protected parseFile(): Promise<Papa.ParseResult<string[]>> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        if (!event.target) return reject();

        try {
          const res: Papa.ParseResult<string[]> = Papa.parse(
            event.target.result as string
          );
          return resolve(res);
        } catch (e) {
          return reject();
        }
      };
      reader.readAsText(this.file);
    });
  }

  public async guessFirstRow() {
    const fileContent = await this.parseFile();
    const rows: string[][] = fileContent.data;
    const firstRowHasError = this.rowHasError(_.first(rows), 0);
    if (!firstRowHasError) this.startRow = 0;
    else this.startRow = 1;
    return this.startRow;
  }

  public async parse() {
    try {
      const fileContent = await this.parseFile();
      const rows: string[][] = fileContent.data;
      this.parseRows(rows);
    } catch (e) {
      return;
    }

    // return new Promise((resolve, reject) => {
    //   const reader = new FileReader();
    //   reader.onload = (event: any) => {
    //     if (!event.target) return;

    //     try {
    //       const res: Papa.ParseResult<string[]> = Papa.parse(
    //         event.target.result as string
    //       );

    //       const rows: string[][] = res.data;
    //       this.parseRows(rows);

    //       //     console.log(csv)
    //       //   const rows = (event.target.result as string).split("\n");
    //       //   const sanitizeRows =
    //       //
    //       resolve();
    //     } catch (e) {
    //       console.log("E", e);
    //       reject();
    //     }
    //   };
    //   reader.readAsText(this.file);
    // });
  }
  // public parse() {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       if (!event.target) return;
  //       try {
  //         const rows = (event.target.result as string).split("\n");
  //         this.parseRows(_.map(rows, r => r.split(this.guessSeparator(r))));
  //         resolve();
  //       } catch (e) {
  //         reject();
  //       }
  //     };
  //     reader.readAsText(this.file);
  //   });
  // }
}
