import _ from "lodash";
import Parse from "parse";
import SaveManager from "./SaveManager";
import { Jpasta } from "@/jpasta-sdk";
export default {
  saveObj({ commit, dispatch, state }, obj) {
    const genByProgram = obj.genByProgram;
    let objReal = obj.obj ? obj.obj : obj;

    // if (!obj.className) {
    //   var info = obj;
    //   obj = obj.obj;
    // }
    objReal = _.cloneDeep(objReal);
    if (!genByProgram) SaveManager.instance().startSaving(commit);

    commit("upsertObject", objReal);

    SaveManager.instance().batchSave({
      key: state.className,
      objectKey: objReal.get("localId"),
      object: objReal,
      action: (objs) => {
        return Parse.Object.saveAll(objs)
          .then((res) => {
            _.forEach(res, (obj) => {
              commit("upsertObject", obj);
            });
            if (!genByProgram) SaveManager.instance().saved(commit);
          })
          .catch((e) => {
            dispatch("handleParseError", e, { root: true });
            SaveManager.instance().endSaving(commit);
          })
          .finally(() => {});
      },
    });

    return Promise.resolve(obj);
  },
  saveObjSync({ commit, dispatch, state }, obj) {
    if (!obj.className) {
      var info = obj;
      obj = obj.obj;
    }
    obj = _.cloneDeep(obj);

    commit("upsertObject", obj);
    if (!info || !info.genByProgram) SaveManager.instance().startSaving(commit);
    return obj
      .save()
      .then((savedObj) => {
        if (!info || !info.genByProgram) SaveManager.instance().saved(commit);
        commit("upsertObject", savedObj);

        return Promise.resolve(savedObj);
      })
      .catch((e) => {
        SaveManager.instance().endSaving(commit);
        dispatch("handleParseError", e, { root: true });
      });
    // .finally(() => {
    //   //
    // });
  },
  saveObjBatch({ commit, dispatch, state }, objs) {
    const genByProgram = objs.genByProgram;
    const objsReal = objs.objs ? objs.objs : objs;
    // if (!obj.className) {
    //   var info = obj;
    //   obj = obj.obj;
    // }
    _.forEach(objsReal, (obj) => {
      obj = _.cloneDeep(obj);

      commit("upsertObject", obj);
    });

    if (!genByProgram) SaveManager.instance().startSaving(commit);

    return Parse.Object.saveAll(objsReal)
      .then((res) => {
        _.forEach(res, (obj) => {
          commit("upsertObject", obj);
        });
        if (!genByProgram) SaveManager.instance().saved(commit);
      })
      .catch((e) => {
        SaveManager.instance().endSaving(commit);
        dispatch("handleParseError", e, { root: true });
      });
  },
  async getObjects(
    { commit, dispatch, state },
    { params, includes, query, clear }
  ) {
    if (clear) {
      commit("emptyObjects");
    }

    if (!query) {
      query = new Parse.Query(state.className);
      if (params) {
        _.forEach(params, (param) => {
          query[param.method](param.key, param.value);
        });
      }

      if (includes) {
        _.forEach(includes, (include) => {
          query.include(include);
        });
      }
    }
    try {
      const objects = await Jpasta.execRecursiveFind({
        query: query,
      });

      for (var i = 0; i < objects.length; i++) {
        commit("upsertObject", objects[i]);
      }

      return objects;
    } catch (e) {
      dispatch("handleParseError", e, { root: true });
    }
  },
  deleteObj({ commit, state, dispatch }, obj) {
    commit("deleteObj", obj);
    SaveManager.instance().startSaving(commit);
    return obj
      .destroy()
      .then(() => {
        SaveManager.instance().saved(commit);
      })
      .catch((e) => {
        SaveManager.instance().endSaving(commit);
        dispatch("handleParseError", e, { root: true });
        //TODO: notificare errore e reinserire oggetto
      });
  },
  deleteObjBatch({ commit, state, dispatch }, objs) {
    const genByProgram = objs.genByProgram;
    const objsReal = objs.objs ? objs.objs : objs;

    _.forEach(objsReal, (obj) => {
      commit("deleteObj", obj);
    });
    if (!genByProgram) SaveManager.instance().startSaving(commit);

    return Parse.Object.destroyAll(objsReal)
      .then(() => {
        if (!genByProgram) SaveManager.instance().saved(commit);
      })
      .catch((e) => {
        SaveManager.instance().endSaving(commit);
        dispatch("handleParseError", e, { root: true });
        //TODO: notificare errore e reinserire oggetto
      });
  },
};
