import Parse from "parse";
import Jpasta from "./Jpasta";
import { Dish, Hotel, CalendarMeal, Book } from ".";
import _ from "lodash";

export default class MealOrder extends Jpasta {
  constructor() {
    super("MealOrder");
  }

  public getPersonName(): string | undefined {
    const person = this.get("person");
    if (!person) return;

    return person.get("name");
  }

  private getMealPrice(hotel: Hotel, cMeal: CalendarMeal, book: Book) {
    if (!hotel || !cMeal || !book) return 0;
    if (book.isIncluded(cMeal)) return 0;
    if (hotel.getMealPaymentType() === "per_dish") return 0;
    const price = hotel.getMealPrice(cMeal.get("mealIndex"));
    return price || 0;
  }

  public isDelivery(): boolean {
    return !!this.get("delivery");
  }

  public isTakeAway(): boolean {
    return !!this.get("takeAway");
  }

  public getLocDeliveryExpl() {
    const sameLocAs = this.get("sameLocationAs");
    if (sameLocAs && sameLocAs.get("delivery"))
      return sameLocAs.get("locationExpl");

    return this.get("locationExpl");
  }

  public getDeliveryCost(hotel: Hotel) {
    if (!this.get("delivery")) return 0;
    const sameLocAs = this.get("sameLocationAs");
    if (sameLocAs && sameLocAs.get("delivery")) return 0;
    return hotel.get("deliveryCost") || 0;
  }

  getTotalPrice(
    dishes: {
      qty: number;
      dish: Dish;
    }[],
    hotel: Hotel,
    cMeal: CalendarMeal,
    book: Book
    // allBooksOrders: MealOrder[]
  ) {
    // const otherOrders = _.filter(allBooksOrders, (o) => {
    //   return !!(this.get("localId") !== o.get("localId"));
    // });

    const mealPrice = this.getMealPrice(hotel, cMeal, book);

    const deliveryCost = this.getDeliveryCost(hotel);

    const totalPrice = _.reduce(
      dishes,
      (total, sd) => {
        const price = sd.dish.getDishPrice(book, cMeal);

        //   const totalDishPrice = _.reduce(
        //     sd.data,
        //     (sum, dish) => {
        //       const price = dish.dishObject.getDishPrice(props.book, props.cMeal);

        //       return sum + price * dish.quantity;
        //     },
        //     0
        //   );

        return total + price * sd.qty;
      },
      mealPrice + deliveryCost
    );

    return totalPrice;
  }
}

Parse.Object.registerSubclass("MealOrder", MealOrder);
