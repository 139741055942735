import Vue from "vue";
export default Vue.extend({
  data: function () {
    return {
      tutorialStep: 0,
      bootForTutorial: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.bootForTutorial = true;
    }, 300);
  },
  methods: {
    nextTutorialStep: function () {
      if (!this.isTutorial) return;
      this.tutorialStep = this.tutorialStep + 1;
    },
    prevTutorialStep: function () {
      if (!this.isTutorial) return;
      this.tutorialStep = this.tutorialStep - 1;
    },
  },
  computed: {
    hasTutorialInQuery: function (): boolean {
      return this.$route.query.tutorial == "1";
    },
    isTutorial: function (): boolean {
      return this.bootForTutorial && this.hasTutorialInQuery;
    },
  },
});
