import CSVParser from "./CSVParser";
import {
  BEB,
  FULL_BOARD,
  HALF_BOARD_WITH_DINNER,
  ONLY_BED,
  HALF_BOARD_WITH_DINNER_AND_DRINK,
  Hotel,
} from "@/jpasta-sdk";
import _ from "lodash";
import moment from "moment-timezone";

enum CSVCOL {
  ROOM = 2,
  NAME = 5,
  CHECKIN,
  CHECKOUT,
  PAX1 = 9,
  PAX2 = 11,
  PAX3 = 13,
  BOOKTYPE = 15,
  BOOKID,
  EMAIL,
}

export default class Ericsoft4Parser extends CSVParser {
  private hotel: Hotel;
  constructor(file: File, hotel: Hotel) {
    super(file);
    this.hotel = hotel;
  }

  protected getValidBookTypes() {
    const authPMS = this.hotel.get("authPMS");
    const bbRates: string[] = _.get(authPMS, "ericsoft.bbRates", []);
    const hbRates: string[] = _.get(authPMS, "ericsoft.hbRates", []);
    const fbRates: string[] = _.get(authPMS, "ericsoft.fbRates", []);
    const validRates = [...bbRates, ...hbRates, ...fbRates];

    return validRates;
  }

  private mapRates(
    rates: string[],
    rateName: "bbRates" | "hbRates" | "fbRates" | "skipRates"
  ) {
    return _.map(rates, (r) => ({
      label: r,
      rateName: rateName,
    }));
  }

  protected parseBookType(type?: string): number | undefined {
    const authPMS = this.hotel.get("authPMS");
    const bbRates = _.get(authPMS, "ericsoft.bbRates", []);
    const hbRates = _.get(authPMS, "ericsoft.hbRates", []);
    const fbRates = _.get(authPMS, "ericsoft.fbRates", []);
    const rates = {
      ..._.keyBy(this.mapRates(bbRates, "bbRates"), (r) => r.label),
      ..._.keyBy(this.mapRates(hbRates, "hbRates"), (r) => r.label),
      ..._.keyBy(this.mapRates(fbRates, "fbRates"), (r) => r.label),
    };

    const rate = rates[type as string];

    if (!rate) return undefined;
    switch (rate.rateName) {
      case "bbRates":
        return BEB;
      case "hbRates":
        return HALF_BOARD_WITH_DINNER;
      case "fbRates":
        return FULL_BOARD;
      default:
        return undefined;
    }
  }

  protected filterRows(rows: string[][]) {
    return _.filter(rows, (row) => {
      const bookid = this.getRowData(row, CSVCOL.BOOKID);
      const email = this.getRowData(row, CSVCOL.EMAIL);
      return (
        email !== undefined &&
        _.size(email.trim()) > 0 &&
        bookid !== undefined &&
        _.size(bookid.trim()) > 0
      );
    });
  }

  private parseInt(v?: string) {
    if (!v) return 0;
    return parseInt(v || "0") || 0;
  }

  protected parseRow(row: string[], index: number) {
    const room = this.getRowData(row, CSVCOL.ROOM);
    const checkinRaw = this.getRowData(row, CSVCOL.CHECKIN);
    const checkin = moment(checkinRaw, "DD/MM/YYYY");
    const checkoutRaw = this.getRowData(row, CSVCOL.CHECKOUT);
    const checkout = moment(checkoutRaw, "DD/MM/YYYY");
    const name = this.getRowData(row, CSVCOL.NAME);
    const pax1 = this.parseInt(this.getRowData(row, CSVCOL.PAX1));
    const pax2 = this.parseInt(this.getRowData(row, CSVCOL.PAX2));
    const pax3 = this.parseInt(this.getRowData(row, CSVCOL.PAX3));
    const email = this.getRowData(row, CSVCOL.EMAIL);
    const bookid = this.getRowData(row, CSVCOL.BOOKID);
    const rawBookType = this.getRowData(row, CSVCOL.BOOKTYPE);
    const bookType = this.parseBookType(rawBookType);
    const lang = "it";
    const newBook: BookPlain = {
      bookId: bookid,
      email,
      pax: pax1 + pax2 + pax3,
      name,
      checkIn: checkin.toISOString(),
      checkOut: checkout.toISOString(),
      roomNumber: room,
      deleted: false,
      bookType: bookType !== undefined ? bookType : ONLY_BED,
      rawBookType: rawBookType,
      rowIndex: index,
      lang,
    };
    return newBook;
  }
}
