var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-row',[_c('el-col',{staticClass:"book-wrapper",attrs:{"span":24}},[_c('div',{staticClass:"book-row main-book-person d-flex flex-column justify-content-center",on:{"mousedown":function($event){return _vm.goToBook(_vm.book, $event)},"mouseup":_vm.mouseUp}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.getBookTitle(_vm.book)))]),_c('div',{staticClass:"dropdownBookWrapper",staticStyle:{"margin-left":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('el-dropdown',{staticClass:"menu-header-control no-print",attrs:{"slot":"menu-header-control","trigger":"click"},on:{"command":function($event){return _vm.handleAction(_vm.book, $event)}},slot:"menu-header-control"},[_c('span',{staticClass:"icon-more clickable no-outline",staticStyle:{"font-size":"15px"}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"delete"}},[_c('div',{staticClass:"detail-action"},[_vm._v("Elimina")])]),_c('el-dropdown-item',{attrs:{"command":"toSummary"}},[_c('div',{staticClass:"detail-action"},[_vm._v("Ordini effettuati")])])],1)],1)],1)]),(_vm.book.get('refEmail') && _vm.book.get('refEmail').length)?_c('div',{staticClass:"d-flex",staticStyle:{"margin-top":"0.7em","color":"rgba(0, 0, 0, 0.6)","font-size":"0.8em"}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.book.get("refEmail")))])]):_vm._e(),_c('div',{staticClass:"d-flex",staticStyle:{"margin-top":"0.7em","color":"rgba(0, 0, 0, 0.6)","font-size":"0.8em"}},[_c('span',{staticStyle:{"margin-right":"0.8em"}},[_vm._v(_vm._s(_vm.book.get("checkin") && _vm.book.get("checkout") ? "Dal " + _vm.formatDate(_vm.book.get("checkin")) + " al " + _vm.formatDate(_vm.book.get("checkout")) : "Informazioni di checkin - checkout mancanti"))])]),(
          _vm.book.get('checkin') &&
          _vm.book.get('checkout') &&
          _vm.book.get('branchIoUrl')
        )?_c('div',{staticStyle:{"margin-top":"0.6em"}},[_c('span',{staticClass:"linkWrapper",staticStyle:{"font-size":"0.8em","color":"rgba(0, 0, 0, 0.6)","word-break":"break-all","display":"flex","align-items":"center"}},[_c('AdviceText',{staticStyle:{"margin-right":"3px"},attrs:{"tooltipTitle":"Link Prenotazione","normalLineHeight":true,"tooltipText":_vm.advices.book.link}},[_c('span',{staticClass:"adviceText"},[_vm._v("Link prenotazione:")])]),_vm._v(" "+_vm._s(_vm.book.get("branchIoUrl"))),_c('i',{staticClass:"el-icon-copy-document",staticStyle:{"font-size":"16px","margin-left":"5px"},attrs:{"title":"Copia link negli appunti"},on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();},"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.copy(_vm.book.get('branchIoUrl'))}}})],1)]):_vm._e(),(
          _vm.book.get('bookingReference') && _vm.book.get('bookingReference').length
        )?_c('div',{staticStyle:{"margin-top":"0.6em"}},[_c('span',{staticClass:"linkWrapper",staticStyle:{"font-size":"0.8em","color":"rgba(0, 0, 0, 0.6)","word-break":"break-all","display":"flex","align-items":"center"}},[_c('span',{staticClass:"adviceText"},[_vm._v("ID:")]),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(` ${_vm.book.get("bookingReference")}`))]),_c('i',{staticClass:"el-icon-copy-document",staticStyle:{"font-size":"16px","margin-left":"5px"},attrs:{"title":"Copia ID negli appunti"},on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();},"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.copy(_vm.book.get('bookingReference'))}}})])]):_vm._e(),(!_vm.book.get('checkin') || !_vm.book.get('checkout'))?_c('div',{staticStyle:{"margin-top":"0.6em"}},[_c('span',{staticStyle:{"opacity":"0.8","font-size":"0.8em","color":"#f4443f","word-break":"break-all"}},[_vm._v("Questa prenotazione non ha una data di Check-in o di Check-out. Non sarà pertanto possibile invitare i clienti")])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }