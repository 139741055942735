var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.visible)?_c('div',{staticClass:"tutorial-mask",staticStyle:{"z-index":"3000"}}):_vm._e(),_c('el-popover',{style:({
      display: _vm.inline ? 'inline' : '',
    }),attrs:{"popper-class":"jpAdvicePopover","placement":"bottom","title":_vm.title,"width":_vm.width,"trigger":"manual","value":_vm.visible}},[_c('div',{style:({
        display: _vm.inline ? 'inline' : '',
        position: 'relative',
        'z-index': _vm.visible ? 3001 : 0,
      }),attrs:{"slot":"reference"},slot:"reference"},[_vm._t("trigger")],2),_c('div',{staticStyle:{"word-break":"break-word","text-align":"start"}},[_vm._t("default"),(_vm.actionTitle && _vm.actionTitle.length)?_c('div',{staticClass:"action",class:{
          disabled: _vm.actionTitleDisabled,
        },on:{"click":_vm.actionClicked}},[_vm._v(" "+_vm._s(_vm.actionTitle)+" ")]):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }