
import * as Parse from "parse";
import Jadvice from "@/components/ui/JAdvice.vue";

import _ from "lodash";
import axios from "axios";
import TableSelect from "./TableSelect.vue";
import RoomSelect from "./RoomSelect.vue";
import {
  BookPerson,
  HOTEL_RATES,
  Jpasta,
  MealOrder,
  MealOrderDish,
  Hotel,
  Table,
  Book,
  Rate,
  Room,
} from "@/jpasta-sdk";
import Vue from "vue";
import moment from "moment-timezone";
import scrollToElement from "scroll-to-element";
import SaveManager from "../../store/SaveManager";
import advices from "../advices";
import copy from "copy-to-clipboard";
import AdviceMixin from "@/mixins/AdviceMixin";
import mixins from "vue-typed-mixins";
export default mixins(AdviceMixin).extend({
  components: {
    TableSelect,
    RoomSelect,
    Jadvice,
  },
  props: {
    hotelid: {
      type: String as () => string,
    },

    bookid: {
      type: String as () => string,
    },
    hideButtons: {
      type: Boolean as () => boolean,
    },
    isNew: {
      type: Boolean as () => boolean,
    },
  },
  data: function (): {
    canEditPmsId: boolean;
    bookDateValue?: string[];
    disableSyncPeople: boolean;
    bookPeopleNumberValue?: number;
    refreshCodeLoading: boolean;
    languages: { key: string; label: string }[];
    advices: any;
    dateFormat: string;
    hotelRates: Rate[];
    loadingForSync: boolean;
    syncingPeople: boolean;
  } {
    return {
      canEditPmsId: false,
      bookDateValue: undefined,
      disableSyncPeople: false as boolean,
      bookPeopleNumberValue: undefined as number | undefined,

      refreshCodeLoading: false as boolean,

      languages: [
        { key: "en", label: "Inglese" },
        { key: "it", label: "Italiano" },
        { key: "de", label: "Tedesco" },
      ] as { key: string; label: string }[],
      advices: advices as any,
      dateFormat: "dd MMMM yyyy HH:mm" as string,
      hotelRates: HOTEL_RATES as any,
      loadingForSync: true as boolean,
      syncingPeople: false as boolean,
    };
  },

  mounted() {
    window.addEventListener("beforeunload", this.beforeUnload);
    if (this.isTutorial) {
      this.loadingForSync = false;
    } else if (this.book.id) {
      _.debounce(async () => {
        await this.fetchData();
        if (this.isNew) {
          this.focusDateInput();
        }
      }, 400)();
    } else {
      this.loadingForSync = false;
    }
  },
  beforeDestroy: function () {
    window.removeEventListener("beforeunload", this.beforeUnload);
  },

  methods: {
    setRoomNumber(r: Room) {
      const book = this.book;
      book.setRoomNumber(r ? r.get("name") : undefined);
      this.$store.dispatch("books/saveObj", book);
    },
    setTableNumber(t: Table) {
      const book = this.book;
      book.setTableNumber(t ? t.get("name") : undefined);
      this.$store.dispatch("books/saveObj", book);
    },
    formatSentAtDate(date: string) {
      return moment(date).format("DD MMM YYYY HH:mm");
    },
    enableReferenceEditing() {
      this.canEditPmsId = true;
      this.$nextTick(() => {
        const ref = this.$refs[
          "ref-bookingReference-input-ref"
        ] as HTMLInputElement;
        if (ref) ref.focus();
      });
    },
    goToBookPeople: function () {
      this.checkIfIsSyncingPeople()
        .then(() => {
          this.$emit("go-to-book-people");
        })
        .catch(() => {});
    },
    setBookPeopleNumberVal: function (val) {
      this.$emit("startSyncingPeople");
      this.syncingPeople = true;
      this.bookPeopleNumberVal = val;
    },
    beforeUnload: function (e) {
      if (this.syncingPeople) {
        const confirmationMessage =
          "Il numero di persone è in aggiornamento, ricaricando perderai le modifiche apportate";
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage;
      }
    },
    stepAfterPeopleNumber: function (): any {
      this.checkIfIsSyncingPeople()
        .then(() => {
          this.$router
            .push({
              name: "TutorialQrCode",
              params: {
                hotelid: this.$route.params.hotelid,
              },
              query: {
                tutorial: "1",
              },
            })
            .catch((e) => {});
        })
        .catch(() => {});
    },
    stepAfterBookType: function (): any {
      this.nextTutorialStep();
      //@ts-ignore
      this.$refs.peopleNumberInput.focus();
    },
    stepAfterLanguage: function (): any {
      this.nextTutorialStep();
    },
    stepAfterTableNumber: function (): any {
      this.nextTutorialStep();
      //@ts-ignore
      this.$refs["ref-email-input-ref"].focus();
    },
    stepAfterEmail: function (): any {
      this.nextTutorialStep();
    },

    copyLink: function (): any {
      copy(this.book.get("branchIoUrl"));
      this.$msg("Copiato negli appunti");
    },
    copyCode: function (): any {
      copy(this.book.get("code"));
      this.$msg("Copiato negli appunti");
    },
    focusDateInput: function (): any {
      if (this.$refs.datesInput) {
        //@ts-ignore
        this.$refs.datesInput.focus();
      }
    },
    setBookCheckinCheckout: function (dates): any {
      const book = this.book;
      //const currCheckin = book.get("checkin") ? moment(book.get("checkin")) : undefined

      if (!dates) {
        this.setBookProperty("checkin", undefined);
        this.setBookProperty("checkout", undefined);
        return;
      }

      const checkin = dates[0];
      const checkout = dates[1];
      this.setBookProperty("checkin", checkin);
      this.setBookProperty("checkout", checkout);
    },

    fetchData: async function (): Promise<any> {
      this.loadingForSync = true;
      const bookPeopleQuery = new Parse.Query(BookPerson);
      bookPeopleQuery.equalTo("book", this.book);

      // const mealOrdersQuery = new Parse.Query(MealOrder);
      // mealOrdersQuery.equalTo("book", this.book);

      const batch: Promise<any>[] = [];

      batch.push(
        this.$store.dispatch("bookPersons/getObjects", {
          query: bookPeopleQuery,
          //clear: true
        })
      );

      // const mealOrderDishesQuery = new Parse.Query(MealOrderDish);
      // mealOrderDishesQuery.matchesKeyInQuery(
      //   "order",
      //   "objectId",
      //   mealOrdersQuery
      // );

      // batch.push(
      //   this.$store.dispatch("mealOrderDishes/getObjects", {
      //     query: mealOrderDishesQuery,
      //     clear: true
      //   })
      // );

      await Promise.all(batch);

      this.loadingForSync = false;
    },

    getLocalizedName: function (name, locale, noNamePlaceholder): any {
      return Jpasta.getLocalizedName(name, locale, noNamePlaceholder);
    },

    checkIfIsSyncingPeople: function () {
      if (!this.syncingPeople) return Promise.resolve();
      return this.$dialog
        .confirm(
          "La modifica al numero di persone non è ancora stata salvata, procedento perderai le modifiche",
          {
            okText: "Continua",
            cancelText: "Annulla",
          }
        )
        .then(function () {
          return Promise.resolve();
        })
        .catch(function () {
          return Promise.reject();
        });
    },

    checkIfNeedConfirmation: function (count): Promise<any> {
      if (count > this.bookPersons.length) return Promise.resolve();

      const personWithName = _.find(this.bookPersons, (bp) => {
        return (
          (bp.get("name") && bp.get("name").length) ||
          (bp.get("surname") && bp.get("surname"))
        );
      });

      if (!personWithName) return Promise.resolve();

      return this.$dialog
        .confirm(
          "Stai diminuendo il numero di persone per questa prenotazione, ma alcuni di loro hanno un nome impostato. Se procedi Jpasta rimuoverà i nomi di tutti i prenotati e glieli richiederà tramite app al momento della prossima comanda. Se non vuoi perdere i nomi dei prenotati, puoi eliminare singolarmente le persone in questione cliccando sulla x corrispondente nella lista dei prenotati",
          {
            okText: "Continua",
            cancelText: "Annulla",
          }
        )
        .then(function () {
          return Promise.resolve();
        })
        .catch(function () {
          return Promise.reject();
        });
    },
    syncPeople: async function (count): Promise<any> {
      count = parseInt(count);
      if (count <= 0)
        return (this.bookPeopleNumberValue = this.bookPersons.length);
      if (count === this.bookPersons.length) return;
      if (this.disableSyncPeople) return;
      this.checkIfNeedConfirmation(count)
        .then(async () => {
          this.syncingPeople = true;

          try {
            const people = await this.book.syncPeople(count);
            await this.$store.dispatch("bookPersons/replacePeople", {
              book: this.book,
              people: people,
              count,
            });
            SaveManager.instance().saved();
          } catch (e) {}
          this.syncingPeople = false;
          this.$emit("stopSyncingPeople");
        })
        .catch(() => {});
    },

    resetSent: function (): any {
      var book = this.book;
      book.unset("sent");
      this.$store.dispatch("books/saveObj", book);
    },
    refreshCode: function (): any {
      this.refreshCodeLoading = true;

      var book = this.book;
      //@ts-ignore
      Parse.Cloud.run("refresh-book-code", { bookId: book.id })
        .then((response) => {
          book.set("code", response.data.code);
          book.set("branchIoUrl", response.data.branchUrl);

          this.$store.dispatch("books/saveObj", book);

          this.refreshCodeLoading = false;
        })
        .catch(() => {
          this.refreshCodeLoading = false;
        });
    },

    setBookProperty: function (propertyName, value): any {
      var book = this.book;

      if (propertyName === "refEmail") book.unset("sent");
      if (value !== undefined) {
        book.set(propertyName, value);
      } else {
        book.unset(propertyName);
      }

      this.$store.dispatch("books/saveObj", book).then(() => {
        if (
          this.isTutorial &&
          ((propertyName === "checkin" && this.tutorialStep === 0) ||
            (propertyName === "checkout" && this.tutorialStep === 1) ||
            (propertyName === "refLocale" && this.tutorialStep === 4))
        ) {
          this.nextTutorialStep();
        }
      });
    },
  },
  computed: {
    tableIsSameAsRoom: function (): boolean {
      if (!this.hotel) return false;
      return this.hotel.getTableIsSameAsRoom();
    },
    options: function () {
      return {
        onPick: (data) => {},
      };
    },
    stage: function (): string | undefined {
      return process.env.NODE_ENV;
    },
    tables: function (): Table[] {
      return _.filter(this.$store.state.tables.objects, (t) => {
        return (
          t.belongsTo(this.hotel, "hotel") &&
          t.get("name") &&
          t.get("name").length
        );
      });
    },
    rooms: function (): any {
      return _.filter(this.$store.state.rooms.objects, (r) => {
        return (
          r.belongsTo(this.hotel, "hotel") &&
          r.get("name") &&
          r.get("name").length
        );
      });
    },
    bookPeopleNumberVal: {
      get: function (): number {
        return this.bookPeopleNumberValue !== undefined
          ? this.bookPeopleNumberValue
          : this.bookPersons.length;
      },
      set: function (val): void {
        this.bookPeopleNumberValue = val;
      },
    } as any,
    bookDates: {
      get: function (): any {
        if (!this.book) return [];

        const checkin = this.book.get("checkin");
        const checkout = this.book.get("checkout");

        return this.bookDateValue || [checkin, checkout];
      },
      set: function (val): any {
        this.bookDateValue = val;
      },
    } as any,

    defaultCheckinTime: function (): any {
      return this.hotel.getDefaultCheckinTime();
    },
    defaultCheckoutTime: function (): any {
      return this.hotel.getDefaultCheckoutTime();
    },

    hotel: function (): Hotel {
      const hotel = this.$store.state.hotels.objects[this.hotelid];

      return hotel;
    },
    book: function (): Book {
      return this.$store.state.books.objects[this.bookid];
    },
    bookPersons: function (): any {
      return _.filter(this.$store.state.bookPersons.objects, (bookPerson) => {
        return bookPerson.belongsTo(this.book);
      });
    },
  },
  watch: {
    bookid: function () {
      this.canEditPmsId = false;
    },
  },
});
