
import _ from "lodash";
import moment from "moment-timezone";
import { User, FREE_TRIAL_DAYS, Hotel } from "@/jpasta-sdk";
import AdviceMixin from "@/mixins/AdviceMixin";
import mixins from "vue-typed-mixins";
export default mixins(AdviceMixin).extend({
  data: function () {
    return {
      hotelCardAdvices: {},
    };
  },
  methods: {
    getBooksOf: function (hotel) {
      return _.filter(this.$store.state.books.objects, (book) => {
        return book.belongsTo(hotel, "hotel");
      });
    },

    getDishesOf: function (hotel) {
      return _.filter(this.$store.state.dishes.objects, (dish) => {
        return dish.belongsTo(hotel, "hotel");
      });
    },

    getCalendarMealsOf: function (hotel) {
      return _.filter(this.$store.state.calendarMeals.objects, (cMeal) => {
        return cMeal.belongsTo(hotel, "hotel");
      });
    },

    handleHotelCardAdviceMessage: function () {
      _.forEach(this.hotels, (hotel) => {
        var books = this.getBooksOf(hotel);

        this.hotelCardAdvices[hotel.get("localId")] =
          this.hotelCardAdvices[hotel.get("localId")] || {};

        if (!hotel.get("name") || !hotel.get("name").length) {
          this.hotelCardAdvices[hotel.get("localId")].content =
            "<div >Questo albergo non ha un nome.</div>";
          this.hotelCardAdvices[hotel.get("localId")].opened = true;
          this.hotelCardAdvices = _.cloneDeep(this.hotelCardAdvices);
          return;
        }

        if (!_.size(books)) {
          this.hotelCardAdvices[hotel.get("localId")].content =
            "<div >Questo albergo non ha prenotazioni!<br/>Per iniziare ad automatizzare la gestione dei tuoi ordini, inserisci le prenotazioni del tuo albergo</div>";
          this.hotelCardAdvices[hotel.get("localId")].opened = true;
          this.hotelCardAdvices = _.cloneDeep(this.hotelCardAdvices);
          return;
        }

        if (!_.size(this.getDishesOf(hotel))) {
          this.hotelCardAdvices[hotel.get("localId")].content =
            "<div >Non ci sono piatti in questo albergo.<br/>Inserisci i piatti tipici della tua cucina per permettere ai tuoi clienti di ordinarli</div>";
          this.hotelCardAdvices[hotel.get("localId")].opened = true;
          this.hotelCardAdvices = _.cloneDeep(this.hotelCardAdvices);

          return;
        }

        if (!_.size(this.getCalendarMealsOf(hotel))) {
          this.hotelCardAdvices[hotel.get("localId")].content =
            "<div >Devi inserire il menù sul calendario di questo albergo per ricevere ordini ^^</div>";
          this.hotelCardAdvices[hotel.get("localId")].opened = true;
          this.hotelCardAdvices = _.cloneDeep(this.hotelCardAdvices);

          return;
        }
      });
    },

    newHotel: function () {
      var hotel = new Hotel();

      //@ts-ignore
      hotel.set("owner", User.current());
      //@ts-ignore
      hotel.set(
        "expireDate",
        moment().add(FREE_TRIAL_DAYS, "days").endOf("day").toDate()
      );
      //@ts-ignore
      hotel.set(
        "timezone",
        moment.tz.guess() ? moment.tz.guess() : "Europe/Rome"
      );

      this.$store.dispatch("hotels/saveObj", hotel).then((newHotel) => {
        this.$router
          .push({
            name: "HotelEdit",
            params: {
              hotelid: newHotel.get("localId"),
            },
            query: {
              ...{ fromHotels: "1" },
              ...(this.isTutorial
                ? {
                    tutorial: "1",
                  }
                : {}),
            },
          })
          .catch((e) => {});
      });
    },
    handleHotelDropdownCommand: function (hotel, command) {
      switch (command) {
        case "edit":
          this.$router
            .push({
              name: "HotelEdit",
              params: {
                hotelid: hotel.get("localId"),
              },
              query: {
                ...{ fromHotels: "1" },
              },
            })
            .catch((e) => {});
          break;
        case "delete":
          return this.$dialog
            .confirm(
              "Vuoi davvero eliminare " +
                (hotel.get("name") && hotel.get("name").length
                  ? hotel.get("name")
                  : "Senza nome") +
                "? La rimozione comporta la perdita di tutti i dati associati all'hotel come piatti e prenotazioni",
              {
                okText: "Continua",
                cancelText: "Annulla",
              }
            )
            .then(() => {
              return this.$store.dispatch("hotels/deleteObj", hotel);
            })
            .catch(() => {});

          break;
      }
    },
    addressString: function (hotel) {
      return (
        (hotel.get("address") ? hotel.get("address") + ", " : "") +
        (hotel.get("city") ? hotel.get("city") + ", " : "") +
        (hotel.get("province") ? hotel.get("province").nome + " " : "") +
        (parseInt(hotel.get("cap"), 10) ? hotel.get("cap") : "")
      );
    },
  },
  mounted() {
    for (var hotel of this.hotels) {
      if (!hotel.get("timezone")) {
        hotel.set(
          "timezone",
          moment.tz.guess() ? moment.tz.guess() : "Europe/Rome"
        );
        this.$store.dispatch("hotels/saveObj", hotel);
      }
    }

    this.$store.dispatch("hotels/getObjects", [
      //@ts-ignore
      { method: "equalTo", key: "owner", value: User.current() },
    ]);

    this.handleHotelCardAdviceMessage();
  },
  computed: {
    hotels: function (): Hotel[] {
      return _.filter<Hotel>(this.$store.state.hotels.objects, (hotel) => {
        const currUser = User.current();
        const hotelOwnerId = hotel.get("owner")?.id;
        if (!currUser || !hotelOwnerId) return false;
        return hotelOwnerId === currUser.id;
      });
    },
  },
});
