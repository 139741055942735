
import Vue from "vue";
import moment from "moment-timezone";
import Parse from "parse";
import localeIT from "./locale_it_moment.js";
import { Notification } from "element-ui";
import LeftBar from "@/components/Bar/LeftBar.vue";
import Cookies from "js-cookie";
import {
  getLanguagesByCode,
  User,
  Role,
  Dish,
  findJCourseByLocalId,
  ExtraMealOrder,
  Hotel,
} from "@/jpasta-sdk";
import _, { Dictionary } from "lodash";
import setScrollTopMixin from "@/mixins/setScrollTopMixin";
import AdviceMixin from "@/mixins/AdviceMixin";
import firebase from "./firebase";
import mixins from "vue-typed-mixins";
const options = [
  "Sto cuocendo la torta di mele",
  "Sto stendendo la tovaglia",
  "Sto dando da mangiare agli Umpa Lumpa",
  "Sto contattando Gordon Ramsay",
  "Sto spolverando il calendario",
  "Sto acquistando le materie prime",
];

function getRandomInteger(start, end) {
  const val = Math.random() * (end - start) + start;
  return Math.round(val);
}
let interval = undefined;
let setScrollPositionTimeout;
let alreadyHandledSpottyLogin = false;
export default mixins(setScrollTopMixin, AdviceMixin).extend({
  components: {
    LeftBar,
  },
  mixins: [setScrollTopMixin, AdviceMixin],
  name: "App",

  data: function (): {
    fbRef: firebase.database.Reference | undefined;
    loaded: boolean;
    currentIndex: number;
    options: any;
    selectedHotelCookie: string | undefined;
  } {
    return {
      fbRef: undefined,
      loaded: false,
      currentIndex: getRandomInteger(0, options.length - 1),
      options: options,
      selectedHotelCookie: Cookies.get("selectedHotelLocalId"),
    };
  },
  beforeDestroy(): any {
    window.removeEventListener("dragover", this.onDragOver);
    window.removeEventListener("drop", this.onDrop);
    window.removeEventListener("beforeunload", this.beforeUnload);
    if (!this.fbRef) return;
    if (this.fbRef) this.fbRef.off("value", this.onLastOrderDateChanged);

    // window.removeEventListener("scroll", this.bodyScrolled);
  },
  methods: {
    onLastOrderDateChanged: async function (
      snap: firebase.database.DataSnapshot
    ): Promise<void> {
      if (!this.hotel) return;
      const extraMealOrderQuery = new Parse.Query(ExtraMealOrder);
      extraMealOrderQuery.equalTo("hotel", this.hotel);

      extraMealOrderQuery.greaterThanOrEqualTo(
        "dueDate",
        moment().subtract(3, "hours").toDate()
      );

      const objs = await this.$store.dispatch("extraMealOrders/getObjects", {
        query: extraMealOrderQuery,
      });

      if (this.notSeenExtraMealOrders.length > 0)
        this.$notify({
          type: "info",
          title: "Nuovo ordine extra pasto",
          message: `Vai nella sezione "Ordini extra pasto" per vedere il nuovo ordine`,
        });
    },
    beforeUnload(e): any {
      if (window.saving) {
        const confirmationMessage =
          "Le modifiche apportate non sono ancora state salvate, vuoi procedere?";
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage;
      }
    },
    getMainWrapperColSpan(size):
      | {
          span: number;
          offset: number;
        }
      | undefined {
      if (this.isTutorial)
        return {
          span: 24,
          offset: 0,
        };
      if (!this.hotel)
        return {
          span: 24,
          offset: 0,
        };
      switch (size) {
        case "xs":
          return {
            span: 24,
            offset: 0,
          };
        case "sm":
          return {
            span: 19,
            offset: 5,
          };
        case "md":
          return {
            span: 20,
            offset: 4,
          };
        case "lg":
          return {
            span: 21,
            offset: 3,
          };
        case "xl":
          return {
            span: 22,
            offset: 2,
          };
      }
    },
    getLeftColSize(size):
      | {
          span: number;
          offset: number;
        }
      | undefined {
      if (!this.hotel)
        return {
          span: 0,
          offset: 0,
        };
      switch (size) {
        case "xs":
          return {
            span: 1,
            offset: 0,
          };
        case "sm":
          return {
            span: 5,
            offset: 0,
          };
        case "md":
          return {
            span: 4,
            offset: 0,
          };
        case "lg":
          return {
            span: 3,
            offset: 0,
          };
        case "xl":
          return {
            span: 2,
            offset: 0,
          };
      }
    },

    bodyScrolled: function (): any {
      if (setScrollPositionTimeout) clearTimeout(setScrollPositionTimeout);

      setScrollPositionTimeout = setTimeout(() => {
        this.$router.replace({
          query: {
            ...(this.$route.query || {}),
            //@ts-ignore
            [`${this.$route.name}ScrollTop`]: this.getScrollElement().scrollTop,
          },
        });
      }, 200);
    },
    onDragOver: function (e): any {
      e = e || event;
      e.preventDefault();
    },
    onDrop: function (e): any {
      e = e || event;
      e.preventDefault();
    },
    startListeningOnFirebase(): any {
      if (!this.hotel || !this.hotel.id) return;
      if (this.fbRef) this.fbRef.off("value", this.onLastOrderDateChanged);
      this.fbRef = firebase
        .database()
        .ref(`extraMealOrders/${this.hotel.id}/changeDates/lastOrderDate`);
      this.fbRef.on("value", this.onLastOrderDateChanged);
    },
  },
  mounted: async function (): Promise<any> {
    //window.alert("CIOA")
    // window.addEventListener("scroll", this.bodyScrolled);
    window.addEventListener("dragover", this.onDragOver, false);
    window.addEventListener("drop", this.onDrop, false);
    window.addEventListener("beforeunload", this.beforeUnload);

    moment.locale("it", localeIT);

    // window.onbeforeunload = () => {
    //   if (!this.saved) {
    //     return "Hai modificato il menù, ma non hai salvato. Continuando perderai le modifiche, vuoi procedere?";
    //   }
    // };

    if (!this.$store.state.users.current) {
      return (this.loaded = true);
    }

    const user = this.$store.state.users.current as User;
    const token = user.getSessionToken();
    console.log("token", token);
    const startTime = moment();

    await this.$store.dispatch("downSyncAll");
    if (this.$route.params.hotelid) {
      const hotel =
        this.$store.state.hotels.objects[this.$route.params.hotelid];

      if (hotel) {
        Cookies.set("selectedHotelLocalId", this.$route.params.hotelid);

        const expireDate =
          hotel.get("expireDate") || moment().subtract(1, "days");
        const now = moment();
      }
    } else if (
      this.selectedHotelCookie &&
      this.$route.name !== "HotelCalendar" &&
      this.$route.name !== "SpottyWelcome"
    ) {
      const hotel = this.$store.state.hotels.objects[this.selectedHotelCookie];
      if (hotel && !this.isTutorial)
        this.$router
          .push({
            name: "HotelCalendar",
            params: {
              hotelid: this.selectedHotelCookie,
            },
          })
          .catch((e) => {});
    }
    this.startListeningOnFirebase();

    // const spottyToken = Cookies.get("jpasta-login");
    // if (_.size(spottyToken) > 0) {
    //   this.$store.dispatch("users/logOut").then(() => {
    //     this.$router
    //       .push({
    //         name: "Login"
    //       })
    //       .catch(e => {});
    //   });
    // }

    if (moment().diff(startTime, "seconds") < 3) {
      setTimeout(() => {
        this.loaded = true;
      }, 2000);
    } else {
      this.loaded = true;
    }
  },

  // mounted() {

  // },

  // beforeDestroy() {

  // },
  computed: {
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    notSeenExtraMealOrders: function (): ExtraMealOrder[] {
      if (!this.hotel) return [];
      return _.filter(
        this.$store.state.extraMealOrders.objects as Dictionary<ExtraMealOrder>,
        (mo) => {
          const hotel = this.hotel;
          const lastSeenDate = hotel.get("lastSeenExtraMealOrders");
          //console.log("lastSeenDate", lastSeenDate);
          return (
            mo.belongsTo(hotel, "hotel") &&
            (!lastSeenDate ||
              moment(lastSeenDate).isBefore(mo.get("createdAt")))
          );
        }
      );
    },
  },
  watch: {
    hotel: function (newHotel: Hotel, oldHotel: Hotel) {
      if (newHotel && !oldHotel) this.startListeningOnFirebase();
      if (
        newHotel &&
        oldHotel &&
        newHotel.get("localId") !== oldHotel.get("localId")
      )
        this.startListeningOnFirebase();
    },
    $route: function (to, from) {
      if (!to.params.hotelid) return;
      const hotel = this.$store.state.hotels.objects[to.params.hotelid];

      if (hotel) {
        Cookies.set("selectedHotelLocalId", to.params.hotelid);
      }
    },
    isTutorial: {
      immediate: true,
      handler: function (newVal) {
        let html = document.querySelector("html") as HTMLElement;
        if (newVal) {
          html.classList.add("isTutorial");
        } else {
          html.classList.remove("isTutorial");
        }
      },
    },

    $mq: {
      immediate: true,
      handler: function (newVal) {
        let html = document.querySelector("html") as HTMLElement;
        if (newVal == "sm" || newVal == "xs") {
          html.classList.add("infinite-wrapper");
        } else {
          html.classList.remove("infinite-wrapper");
        }
      },
    },
    // handler: function(newVal) {
    //   (this.$refs.drawer as any).toggle(newVal);
    // }
    // immediate: true
  },
});
