import generalMutations from "./generalMutations";
import generalActions from "./generalActions";
import Parse from "parse";
import _ from "lodash";
export default {
  namespaced: true,
  state: {
    objects: {},
    className: "DishImage",
    version: 0,
  },

  mutations: {
    ...generalMutations,
  },
  actions: {
    ...generalActions,
  },
  getters: {
    getImagesOf: (state) => {
      return _.memoize(
        (dish) => {
          return _.reduce(
            dish.get("images"),
            (images, image) => {
              if (
                state.objects[image.get("localId")] &&
                state.objects[image.get("localId")].get &&
                state.objects[image.get("localId")].get("thumb")
              )
                images.push(state.objects[image.get("localId")]);

              return images;
            },
            []
          );
        },
        (dish) => {
          return `${dish.get("localId")}-${state.version}`;
        }
      );
    },
  },
};
