var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isTutorial)?_c('div',{staticClass:"top-bar-tutorial"},[_c('el-steps',{attrs:{"active":_vm.activeTutorialStep,"align-center":""}},[_c('el-step',{attrs:{"title":_vm.$mq === 'xs' || _vm.$mq === 'sm' || _vm.$mq === 'md'
            ? ''
            : 'Crea il tuo albergo'}}),_c('el-step',{attrs:{"title":_vm.$mq === 'xs' || _vm.$mq === 'sm' || _vm.$mq === 'md'
            ? ''
            : 'Aggiungi un piatto alla raccolta'}}),_c('el-step',{attrs:{"title":_vm.$mq === 'xs' || _vm.$mq === 'sm' || _vm.$mq === 'md'
            ? ''
            : 'Disponi un menu sul calendario'}}),_c('el-step',{attrs:{"title":_vm.$mq === 'xs' || _vm.$mq === 'sm' || _vm.$mq === 'md'
            ? ''
            : 'Aggiungi una prenotazione'}})],1),(_vm.activeTutorialStep !== 5)?_c('p',{staticClass:"end-training"},[_c('span',{on:{"click":_vm.skipTutorial}},[_vm._v("Salta addestramento")])]):_vm._e()],1):_vm._e(),(!_vm.$route.meta.noBar && !_vm.isTutorial)?_c('div',{staticClass:"outer-wrapper no-print"},[_c('div',{staticClass:"top-bar d-flex align-items-center",class:{
        //offset2: $mq === 'xl' && hotel,
        offset2: _vm.$mq === 'mxl' && _vm.hotel,
        offset5: _vm.$mq === 'md' && _vm.hotel,
        offset4: _vm.$mq === 'lg' /*|| $mq === 'mxl'*/ && _vm.hotel,
        mobileTopBar: _vm.$mq === 'xs' || _vm.$mq === 'sm',
        mobileOpened: _vm.$store.state.mobileMenuOpened,
      }},[(_vm.hotel)?_c('mq-layout',{attrs:{"mq":['xs', 'sm']}},[_c('div',{staticClass:"burger-wrapper"},[_c('span',{staticClass:"icon-burger-menu",class:{
              mobileOpened: _vm.$store.state.mobileMenuOpened,
            },on:{"click":_vm.toggleMenu}})])]):_vm._e(),(!_vm.hotel)?_c('div',{staticClass:"hotel-link-wrapper"},[_c('img',{staticStyle:{"width":"50px","height":"50px","margin-right":"20px","cursor":"pointer"},attrs:{"src":require("../../assets/JpastaIconMask.svg")},on:{"click":_vm.toHotels}})]):_vm._e(),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-left":"10px","margin-right":"10px"}},[(_vm.hotel)?_c('h2',{staticClass:"hotelName"},[_vm._v(_vm._s(_vm.hotel.get("name")))]):_vm._e(),_c('BreadcrumbsBar',{staticStyle:{"margin-top":"4px"}})],1),(_vm.$route.meta.cpanel)?_c('div',{staticClass:"hotel-link-wrapper"},[_c('span',{staticClass:"hotel-link",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toLabelsCfg.apply(null, arguments)}}},[_vm._v("Etichette predefinite")])]):_vm._e(),(_vm.getLoggedUser())?_c('el-dropdown',{staticClass:"profileDropdown",attrs:{"trigger":"click"},on:{"command":function($event){return _vm.handleProfileCommand($event)}}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","cursor":"pointer"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-left":"auto"}},[_c('p',{staticStyle:{"margin-left":"auto","margin-right":"0.5em","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.getLoggedUser().get("name") || _vm.getLoggedUser().get("surname") ? _vm.getLoggedUser().get("name") + " " + _vm.getLoggedUser().get("surname") : _vm.getLoggedUser().get("username"))+" ")]),_c('p',{staticStyle:{"margin-right":"0.5em","margin-left":"auto","opacity":"0.7"}},[_vm._v(" "+_vm._s(_vm.getLoggedUser().get("username"))+" ")])]),_c('javatar',{staticStyle:{"margin-right":"1.3em"},attrs:{"user":_vm.getLoggedUser()}})],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"logout"}},[_vm._v("Esci")])],1)],1):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }