
import Vue from "vue";
import mixins from "vue-typed-mixins";
import { Hotel } from "@/jpasta-sdk";
export default mixins().extend({
  data: function (): {
    takeAwayOptions: { label: string; value: number }[];
  } {
    return {
      takeAwayOptions: [
        {
          label: "Sì",
          value: 1,
        },
        {
          label: "No",
          value: 0,
        },
      ],
    };
  },
  methods: {
    toggleEnableKitchenNotes: function (value) {
      const hotel = this.hotel;
      hotel.setEnableKitchenNotes(!!value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    setDeliveryCost: function (value) {
      const hotel = this.hotel;
      if (value === hotel.get("deliveryCost")) return;

      hotel.set("deliveryCost", value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    toggleEnableDelivery: function (value) {
      const hotel = this.hotel;
      hotel.set("enableDelivery", !!value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    setOnlyVisualization(val) {
      this.hotel.setOnlyVisualization(!!val);
      this.$store.dispatch("hotels/saveObj", this.hotel);
    },

    getOnlyVisualization() {
      return this.hotel.onlyVisualization();
    },
    setImageDisplayType: function (type) {
      const hotel = this.hotel;
      hotel.setImageDisplayType(type);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    toggleEnableTakeAway: function (value) {
      const hotel = this.hotel;
      hotel.set("enableTakeAway", !!value);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
  },
  computed: {
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
  },
});
