import generalMutations from "./generalMutations";
import generalActions from "./generalActions";
import Parse from "parse";

export default {
  namespaced: true,
  state: {
    objects: {},
    className: "Label",
  },

  mutations: {
    ...generalMutations,
  },
  actions: {
    ...generalActions,
  },
  getters: {
    getLabelsOfDish: (state) => (dish) => {
      var labels = [];
      _.forEach(dish.get("labels"), (label) => {
        if (state.objects[label.get("localId")]) {
          labels.push(state.objects[label.get("localId")]);
        }
      });
      return labels;
    },
    labels: (state) => (obj) => {
      return _.filter(state.objects, (label) => {
        return (
          label.belongsTo(obj) ||
          (label.get("predefined") && !label.get("deleted"))
        );
      });
    },
  },
};
