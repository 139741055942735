
import { Room, Hotel, Table } from "@/jpasta-sdk";
import _ from "lodash";
import mixins from "vue-typed-mixins";

type RoomOption = {
  id: string;
  name: string;
  value?: string;
};

export default mixins().extend({
  props: {
    value: {
      type: String as () => any,
    },
    rooms: {
      type: Array as () => Room[],
    },
    hotel: {
      type: Object as () => Hotel,
    },
  },
  data: function (): { options: RoomOption[]; creatingRoom: boolean } {
    return {
      options: [],
      creatingRoom: false,
    };
  },

  mounted() {
    this.initRooms();
  },
  methods: {
    initRooms() {
      const rooms = this.filterRooms("");
      this.options = rooms;
    },
    search(query: string) {
      const rooms = this.filterRooms(query);
      this.options = rooms;
    },
    filterRooms(input: string): RoomOption[] {
      const rooms = _.filter(this.roomOptions, (r) => {
        return _.toLower(r.name).includes(_.toLower(input));
      });

      if (!rooms.length && input.length) {
        return [
          {
            id: "new",
            name: "Crea: " + input,
            value: input,
          },
        ];
      } else {
        return _.sortBy(rooms, (r) => {
          return r.name ? parseInt(r.name) : Infinity;
        });
      }
    },
    getRoom: async function (room: RoomOption): Promise<Room> {
      let r;
      if (room.id === "new") {
        r = new Room();
        r.set("hotel", this.hotel);
        r.set("name", room.value);
        this.creatingRoom = true;
        try {
          r = await this.$store.dispatch("rooms/saveObjSync", r);
        } catch (e) {
        } finally {
          this.creatingRoom = false;
        }
        if (this.hotel.getTableIsSameAsRoom()) {
          const table = new Table();
          table.set("room", r);
          table.set("hotel", this.hotel);
          await this.$store.dispatch("tables/saveObj", table);
        }
      } else {
        r = _.find(this.rooms, (r) => {
          return r.get("name") === room.id;
        });
      }
      return r;
    },

    async onRoomChange(room): Promise<any> {
      if (!room) return;
      const r = await this.getRoom(room);
      this.initRooms();
      return this.$emit("selected", r);
    },
  },
  computed: {
    validRooms: function (): Room[] {
      const rooms = _.filter(this.rooms, (r) => {
        return !!(r.get("name") && r.get("name").length);
      });
      return rooms;
    },
    roomOptions: function (): RoomOption[] {
      return _.map(this.validRooms, (r) => {
        return {
          id: r.get("name"),
          name: r.get("name"),
        };
      });
    },
  },
});
