
import mixins from "vue-typed-mixins";
import { Turn, Hotel } from "@/jpasta-sdk";
import _ from "lodash";
type TurnOption = {
  id: string;
  name: string;
  value?: string;
};

export default mixins().extend({
  props: {
    value: { type: Object as () => Turn | undefined },
    hotel: { type: Object as () => Hotel },
  },
  data: function (): {
    options: TurnOption[];
  } {
    return {
      options: [],
    };
  },
  mounted() {
    this.initTurns();
  },
  methods: {
    initTurns(): void {
      const turns = this.filterTurns("");
      this.options = turns;
    },
    onTurnChange: async function (turn): Promise<void> {
      if (!turn) return;
      const t = await this.getTurn(turn);
      this.initTurns();
      this.$emit("selected", t);
    },
    search(query: string): void {
      const turns = this.filterTurns(query);

      this.options = turns;
    },
    filterTurns(input: string): TurnOption[] {
      const turns = _.filter(this.turnOptions, (r) => {
        return _.toLower(r.name).includes(_.toLower(input));
      });

      if (!turns.length && input.length) {
        return [
          {
            id: "new",
            name: "Crea: " + input,
            value: input,
          },
        ];
      } else {
        return turns;
      }
    },
    getTurn: async function (turn: TurnOption): Promise<Turn | undefined> {
      let t: Turn | undefined;
      if (turn.id === "new") {
        t = new Turn();
        t.set("hotel", this.hotel);
        t.set("name", turn.value);

        t = await this.$store.dispatch("turns/saveObj", t);
      } else {
        t = _.find(this.turns, (t) => {
          return !!(t.get("localId") === turn.id);
        });
      }
      return t;
    },
  },
  computed: {
    selectValue: function (): string | undefined {
      if (!this.value) return undefined;
      return this.value.get("name");
    },
    turns: function (): Turn[] {
      return _.filter(this.$store.state.turns.objects, (t: Turn) => {
        return t.belongsTo(this.hotel, "hotel");
      });
    },
    validTurns: function (): Turn[] {
      const turns = _.filter(this.turns, (t) => {
        return !!(t.get("name") && t.get("name").length);
      });

      return turns;
    },
    turnOptions: function (): TurnOption[] {
      return _.map(this.validTurns, (t) => {
        return {
          id: t.get("localId"),
          name: t.get("name"),
        };
      });
    },
  },
});
