<template>
  <jtooltip
    :popoverClass="['advicePopover']"
    class="advicePopup"
    :trigger="manual ? 'manual' : 'hover'"
    :title="tooltipTitle"
    :content="tooltipText"
  >
    <span
      class="adviceText"
      :class="{
        manual: manual,
        normalLineHeight: normalLineHeight,
      }"
      slot="target"
    >
      <slot></slot>
    </span>
  </jtooltip>
</template>

<script>
export default {
  props: {
    tooltipText: {},
    tooltipTitle: {},
    normalLineHeight: {},
    manual: {},
  },
};
</script>

<style lang="scss" scoped>
.adviceText {
  line-height: 40px;
  &:not(.manual) {
    text-decoration: underline;
    text-decoration-style: dotted;
    cursor: help;
  }

  &.normalLineHeight {
    line-height: initial;
  }
}
</style>

<style lang="scss"></style>
