import React from "react";

export function WhiteLoadingSpinner() {
  return (
    <div
      className={
        "border-t-white flex-shrink-0 animate-spin rounded-full border-slate-300 border-4 h-5 w-5"
      }
    />
  );
}
