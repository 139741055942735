
import Vue from "vue";
import _ from "lodash";
import ColorHash from "color-hash";
import { JPASTA_ALLERGENS, Dish } from "@/jpasta-sdk";
import Color from "color";

import mixins from "vue-typed-mixins";
const chash = new ColorHash({ lightness: 0.5, saturation: 0.4 });
export default mixins().extend({
  props: {
    canSetCourse: {},
    dishes: {},
  },
  data: function (): {
    noTitlePlaceholder: string;
  } {
    return {
      noTitlePlaceholder: "Senza titolo",
    };
  },

  methods: {
    deleteDish: function (dish): any {
      return this.$dialog
        .confirm("Vuoi davvero eliminare questo piatto?", {
          okText: "Continua",
          cancelText: "Annulla",
        })
        .then(() => {
          return this.$emit("delete", dish.id);
        })
        .catch(() => {});
    },
    getColor: function (courseName) {
      const res = Color(chash.hex(courseName)).alpha(1).string();
      return res;
    },
    getDishName: function (name): any {
      const n = _.get(name, "it", this.noTitlePlaceholder);
      return n && n.trim().length ? n : this.noTitlePlaceholder;
    },
    getBgImage: function (image) {
      if (!image) return {};
      const img = {
        "background-image": `url('${image.get("thumb").url()}')`,
      };

      return img;
    },
    isUploading: function (dishLcoalId: string): boolean {
      return this.$store.state.dishes.uploadingImages[dishLcoalId];
    },
  },
  computed: {
    getAllergenTitle: function (): any {
      return _.memoize((allergen) => {
        let all = _.find(JPASTA_ALLERGENS, (a) => {
          return a.id === allergen;
        });

        if (all) return all.name.it;
      });
    },
    getAllergenIcon: function (): any {
      return _.memoize((allergen) => {
        let all = _.find(JPASTA_ALLERGENS, (a) => {
          return a.id === allergen;
        });

        if (all) return all.icon;
      });
    },
    isTutorial: function (): any {
      return this.$route.query.tutorial == "1";
    },
  },
});
