import CSVParser from "./CSVParser";
import {
  FULL_BOARD,
  HALF_BOARD_WITH_DINNER,
  BEB,
  ONLY_BED,
  FULL_BOARD_WITH_DRINK,
  HALF_BOARD_WITH_DINNER_AND_DRINK,
} from "@/jpasta-sdk";
import _ from "lodash";
import moment from "moment-timezone";
const BOOK_TYPES_MAP = {
  FB: FULL_BOARD,
  HB: HALF_BOARD_WITH_DINNER,
  BB: BEB,
  NT: ONLY_BED,
  M: ONLY_BED,
};

enum CSVCOL {
  ROOM,
  SURNAME,
  NAME,
  CHECKIN,
  CHECKOUT,
  BOOK_TYPE,
  FULL_PAX,
  DISCOUNTED_PAX,
  FORFAIT_PAX,
  EMAIL,
}

const ROOM = 0;
const SURNAME = 1;
const NAME = 2;
const CHECKIN = 3;
const CHECKOUT = 4;
const BOOK_TYPE = 5;
const FULL_PAX = 6;
const DISCOUNTED_PAX = 7;
const FORFAIT_PAX = 8;
const EMAIL = 9;

export default class H2000Parser extends CSVParser {
  getValidBookTypes() {
    return _.map(BOOK_TYPES_MAP, (v, k) => k);
  }

  parseBookType(type?: string) {
    if (!type) return undefined;
    return BOOK_TYPES_MAP[type];
  }

  parseRow(row: string[], index) {
    const email = this.getRowData(row, EMAIL);
    const checkinRaw = this.getRowData(row, CHECKIN);
    const checkoutRaw = this.getRowData(row, CHECKOUT);
    const checkin = moment(checkinRaw, "DD/MM/YYYY").format("YYYY-MM-DD");
    const checkout = moment(checkoutRaw, "DD/MM/YYYY").format("YYYY-MM-DD");
    const deleted = false;
    const bookId = undefined;
    const roomNumber = this.getRowData(row, ROOM);
    const bookTypeRaw = this.getRowData(row, BOOK_TYPE);
    const bookType = this.parseBookType(bookTypeRaw);
    const lang = "en";
    const fullPax = parseInt(this.getRowData(row, FULL_PAX) || "0") || 0;
    const discountedPax =
      parseInt(this.getRowData(row, DISCOUNTED_PAX) || "0") || 0;
    const forfaitPax = parseInt(this.getRowData(row, FORFAIT_PAX) || "0") || 0;
    const name = this.getRowData(row, NAME);
    const surname = this.getRowData(row, SURNAME);

    const book: BookPlain = {
      email,
      checkIn: checkin,
      checkOut: checkout,
      name: `${name} ${surname}`,
      pax: fullPax + discountedPax + forfaitPax,
      lang,
      bookType,
      rawBookType: bookTypeRaw,
      roomNumber,
      deleted,
      bookId,
      rowIndex: index,
    };

    return book;
  }
}
