<template>
  <jlayout>
    <div class="mainWrapper">
      <p class="gratsCopy">
        Complimenti, hai completato il tuo addestramento!
        <br />
        <i class="em-svg em-birthday"></i>
      </p>
      <p class="mt-2 explCopy">
        Ora non ti resta che provare come i tuoi clienti ordineranno i piatti
        con Jpasta.
        <br />
      </p>

      <p class="explCopy2" v-if="isMobile">
        Ti basta cliccare
        <a :href="firstBook.get('branchIoUrl')">qui</a> e scaricare
        l'applicazione
        <br />
      </p>

      <p class="explCopy2" v-else>
        Inquadra il qrcode col tuo smartphone per scaricare l'applicazione
        <br />(o attendi l'email su <i>{{ firstBook.get("refEmail") }}</i
        >)
        <br />
      </p>
      <p class="qrcode" v-if="!isMobile">
        <canvas ref="qrcode"></canvas>
      </p>
      <div class="endWrapper">
        <jbutton class="refresh-link" v-on:click.prevent.stop="end"
          >Finito</jbutton
        >
      </div>
    </div>
  </jlayout>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import QRCode from "qrcode";
import confetti from "canvas-confetti";
export default {
  data: function () {
    return {
      isMobile: isMobile,
    };
  },
  mounted: async function () {
    if (!this.isMobile) {
      let canvas = this.$refs.qrcode;

      QRCode.toCanvas(
        canvas,
        this.firstBook.get("branchIoUrl"),
        function (error) {
          if (error) console.error(error);
        }
      );
    }

    const user = this.$store.state.users.current;
    user.set("tutorialCompleted", true);

    await user.save();
  },
  methods: {
    end: function () {
      var end = Date.now() + 3 * 1000;

      var interval = setInterval(function () {
        if (Date.now() > end) {
          return clearInterval(interval);
        }

        confetti({
          startVelocity: 30,
          spread: 360,
          ticks: 60,
          origin: {
            x: Math.random(),
            // since they fall down, start a bit higher than random
            y: Math.random() - 0.2,
          },
        });
      }, 200);

      setTimeout(() => {
        this.$router.push({
          name: "HotelCalendar",
          params: {
            hotelid: this.hotel.get("localId"),
          },
        });
      }, 3500);
    },
  },
  computed: {
    hotel: function () {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    firstBook: function () {
      let first = _.filter(this.$store.state.books.objects, (b) => {
        return b.belongsTo(this.hotel, "hotel") && !b.get("stafferBook");
      })[0];

      return first;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";
.mainWrapper {
  .endWrapper {
    margin-top: 2em;
    display: flex;
    justify-content: center;
  }

  .gratsCopy {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }

  .em-birthday {
    font-size: 80px;
    margin-top: 20px;
  }

  .explCopy,
  .explCopy2 {
    font-size: 20px;
    text-align: center;
  }

  .qrcode {
    display: flex;
    justify-content: center;
  }

  .explCopy2 {
    a {
      color: map-get($colors, "primary");
    }
  }
}
</style>
