var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{staticStyle:{"flex":"1","height":"100%","width":"100vw"},attrs:{"type":"flex","justify":"center"}},[_c('el-col',{staticClass:"no-shrink sx-col",class:{
      'infinite-wrapper': _vm.$mq !== 'xs' && _vm.$mq !== 'sm',
    },style:({
      'overflow-y': _vm.$mq === 'xs' || _vm.$mq === 'sm' ? 'visible' : 'auto',
    }),attrs:{"xs":_vm.getMainColSizes('xs'),"sm":_vm.getMainColSizes('sm'),"md":_vm.getMainColSizes('md'),"lg":_vm.getMainColSizes('lg'),"xl":_vm.getMainColSizes('xl')}},[_c('el-col',{staticClass:"main-container",class:{
        'increase-margin-top': _vm.isTutorial,
        'full-width': _vm.fullWidth,
      },style:(_vm.mainContainerStyle || {}),attrs:{"xs":_vm.fullWidth ? 24 : 23,"sm":_vm.fullWidth ? 24 : 18,"md":_vm.fullWidth ? 24 : 16,"lg":_vm.fullWidth ? 24 : 16,"xl":_vm.fullWidth ? 24 : 14}},[(_vm.$slots['maincta'] || _vm.$slots['title'])?_c('el-row',{staticClass:"flex-wrap mt-1 no-print",attrs:{"type":"flex"}},[(_vm.$slots.title)?_c('el-col',{staticClass:"text-right justify-content-center justify-content-sm-start d-flex align-items-center",attrs:{"xs":24,"sm":_vm.$slots.maincta ? 12 : 24,"md":_vm.$slots.maincta ? 12 : 24,"lg":_vm.$slots.maincta ? 12 : 24,"xl":_vm.$slots.maincta ? 12 : 24}},[_c('h1',{staticClass:"text-sm-left text-center w-100"},[_vm._t("title")],2)]):_vm._e(),(_vm.$slots.maincta)?_c('el-col',{staticClass:"text-right justify-content-center justify-content-sm-end d-flex align-items-center",staticStyle:{"z-index":"2"},style:({
            'z-index': _vm.blinkingButton ? '3000' : '2',
          }),attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":12}},[_c('jtooltip',{ref:"calendar-advice",staticStyle:{"margin-left":"3px","margin-right":"3px","display":"inline"},attrs:{"trigger":"manual","show":_vm.blinkingButton && _vm.tooltipTitle && _vm.tooltipTitle.length,"title":_vm.tooltipTitle,"content":_vm.tooltipText,"hideClose":true}},[_c('jbutton',{ref:"main-cta-ref",staticClass:"mt-2 mt-sm-0",class:{
                'tutorial-button': _vm.blinkingButton,
              },attrs:{"slot":"target"},on:{"click":function($event){return _vm.$emit('click')}},slot:"target"},[_vm._t("maincta")],2)],1)],1):_vm._e()],1):_vm._e(),(_vm.$slots['below-title'])?_c('el-row',{staticClass:"flex-wrap mt-1 no-print",attrs:{"type":"flex"}},[_c('el-col',{staticClass:"text-right justify-content-center justify-content-sm-start d-flex align-items-center",attrs:{"xs":24,"sm":24,"md":24,"lg":24,"xl":24}},[_vm._t("below-title")],2)],1):_vm._e(),(_vm.$slots.maincta || _vm.$slots['below-title'] || _vm.$slots.title)?_c('el-row',{staticClass:"mt-2 no-print",staticStyle:{"height":"1px","background-color":"#cccccc"}}):_vm._e(),_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingForSync),expression:"loadingForSync"}],staticClass:"mainSlotWrapper",class:{
          'mt-2': _vm.$slots.maincta || _vm.$slots['below-title'] || _vm.$slots.title,
        },style:({
          ...(_vm.loadingForSync
            ? {
                'min-height': '300px',
              }
            : {}),
          ...(_vm.mainSlotWrapperStyle || {}),
        })},[(!_vm.loadingForSync)?_vm._t("default"):_vm._e()],2)],1)],1),(_vm.showRightMenu)?_c('el-col',{staticClass:"side-menu-wrapper no-print",staticStyle:{"padding":"10px 10px","padding-bottom":"20px"},style:({
      'overflow-y': _vm.$mq === 'xs' || _vm.$mq === 'sm' ? 'auto' : 'auto',
    }),attrs:{"xs":0,"sm":8,"md":8,"lg":6,"xl":4}},[(_vm.closable)?_c('div',{staticClass:"sideMenuHeader"},[_c('span',{staticClass:"icon-cross",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.closeMenu.apply(null, arguments)}}})]):_vm._e(),_vm._t("rightmenu")],2):_vm._e(),_c('mq-layout',{attrs:{"mq":"md+"}},[(!_vm.opened && _vm.trigger === undefined)?_c('div',{staticClass:"openMenu",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openMenu.apply(null, arguments)}}},[_c('span',{staticClass:"icon-burger-menu"})]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }