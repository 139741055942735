<template>
  <div>
    <div
      v-on:click="handleOnClick"
      v-if="show"
      :class="{
        neutral: theme === 'neutral',
        clickable: !!onClick,
      }"
      class="warning-box"
    >
      <span class="icon-warning"></span>
      <span v-html="message"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {},
    onClick: {},
    show: {},
    theme: {
      default: "red",
    },
  },
  data: function () {
    return {};
  },
  methods: {
    handleOnClick: function () {
      if (this.onClick) this.onClick();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";

.warning-box {
  border-radius: 8px;
  background-color: map-get($colors, "primary");
  padding: 1em;
  display: flex;
  align-items: center;
  & > * {
    color: white;
  }

  .icon-warning {
    font-size: 30px;
    margin-right: 8px;
  }

  &.clickable:hover {
    background-color: map-get($colors, "primary-clicked");
  }
}

.warning-box.neutral {
  background-color: darken(rgb(251, 251, 251), 6%);

  & > * {
    color: #2c3e50;
  }
  //color: ;
}
</style>
