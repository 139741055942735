
import {
  Room,
  ROOM_TYPES,
  findJRoomTypeByLocalId,
  RoomType,
  Hotel,
  Table,
} from "@/jpasta-sdk";
import _ from "lodash";
import ColorHash from "color-hash";

import Color from "color";
import DragList from "./DragList.vue";
import EditRoomComponent from "./EditRoomComponent.vue";
import mixins from "vue-typed-mixins";
let keyDownPressed = false;
let keyDownPressedTimeout;
const chash = new ColorHash({ lightness: 0.5, saturation: 0.4 });
export default mixins().extend({
  components: {
    DragList,
    EditRoomComponent,
  },
  data: function (): {
    searchRoomString: string | undefined;
    editingRoomId: string | undefined;
    editingRoomType: RoomType | undefined;
    dialogVisible: boolean;
    allowCreate: boolean;
    dialogVisibleRoomType: boolean;
    showRoomDuplicateName: boolean;
    creatingRoom: boolean;
  } {
    return {
      searchRoomString: undefined,
      editingRoomId: undefined,
      dialogVisible: false,
      allowCreate: false,
      dialogVisibleRoomType: false,
      editingRoomType: undefined,
      showRoomDuplicateName: false,
      creatingRoom: false,

      // roomTypes: ROOM_TYPES
    };
  },
  updated: function () {},
  mounted() {
    // window.addEventListener("keyup", this.handleKeyUp);
    this.parseQueryData();
    window.addEventListener("keydown", this.handleKeyDown);
  },

  beforeDestroy() {
    // window.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    parseQueryData(): void {
      if (this.$route.query.editingRoom) {
        const room =
          this.$store.state.rooms.objects[
            this.$route.query.editingRoom as string
          ];
        if (room) {
          this.editRoom(room);
        }
      }
    },
    resetTimeout(): void {
      if (keyDownPressedTimeout) clearTimeout(keyDownPressedTimeout);
      keyDownPressedTimeout = setTimeout(() => {
        keyDownPressed = false;
        keyDownPressedTimeout = undefined;
      }, 500);
    },
    keyDownDebounce(): boolean {
      if (keyDownPressed) {
        this.resetTimeout();
        return true;
      }

      keyDownPressed = true;

      this.resetTimeout();

      return false;
    },
    handleKeyDown: function (e): void {
      if (e.keyCode === 13 && e.shiftKey) {
        e.preventDefault();
        if (this.keyDownDebounce()) return;

        this.newRoom();
      }
    },
    setRoomName: function (name): any {
      this.showRoomDuplicateName = false;

      if (
        _.find(this.rooms, (r) => {
          return r.get("name") === name;
        })
      ) {
        return (this.showRoomDuplicateName = true);
      }
      this.setRoomProperty("name", name);
    },
    newRoomType: function () {
      const roomType = new RoomType();
      roomType.set("hotel", this.hotel);
      return this.$store.dispatch("roomTypes/saveObj", roomType).then((rt) => {
        this.editRoomType(rt);
      });
    },
    changeRoomType: function ({ itemId, rowId }) {
      const room = this.$store.state.rooms.objects[itemId];

      if (rowId) {
        const roomType = this.$store.state.roomTypes.objects[rowId];

        room.set("type", roomType);
      } else {
        room.unset("type");
      }

      this.$store.dispatch("rooms/saveObj", room);
    },
    getRoomsOf: function (roomType) {
      const res = _.filter(this.rooms, (r) => {
        return roomType ? r.belongsTo(roomType, "type") : !r.get("type");
      });
      return _.sortBy(res, (r) => {
        return parseInt(r.get("name") || Infinity);
      });
    },
    buildRows: function () {
      return [
        {
          name: "Cam",
          id: null,
          items: _.map(this.rooms, (r) => {
            return {
              name: r.get("name"),
              id: r.get("localId"),
            };
          }),
        },
        // ..._.map(this.roomTypes, rt => {
        //   return {
        //     name: rt.get("name"),
        //     id: rt.get("localId"),
        //     items: _.map(this.getRoomsOf(rt), r => {
        //       return {
        //         name: r.get("name"),
        //         id: r.get("localId")
        //       };
        //     })
        //   };
        // })
      ];
    },

    deleteRoom: function (room): any {
      return this.$dialog
        .confirm("Vuoi davvero eliminare questa camera?", {
          okText: "Continua",
          cancelText: "Annulla",
        })
        .then(() => {
          return this.$store.dispatch("rooms/deleteObj", room);
        })
        .catch(() => {});
    },
    // deleteRoomType: function(roomType): any {
    //   return this.$dialog
    //     .confirm(
    //       "Vuoi davvero eliminare questo tipo di camera? Tutte le camere e le tariffe appartenenti ad essa verranno disassociate",
    //       {
    //         okText: "Continua",
    //         cancelText: "Annulla"
    //       }
    //     )
    //     .then(() => {
    //       const rates2save = [];

    //       _.forEach(this.rates, r => {
    //         const newRTypes = r.get("types");
    //         const removed = _.remove(newRTypes, rt => {
    //           return rt.get("localId") === roomType.get("localId");
    //         });
    //         if (removed.length) {
    //           r.set("types", newRTypes);
    //           rates2save.push(r);
    //         }
    //       });

    //       const rooms2save = [];
    //       _.forEach(this.rooms, r => {
    //         if (
    //           r.get("type") &&
    //           r.get("type").get("localId") === roomType.get("localId")
    //         ) {
    //           r.unset("type");
    //           rooms2save.push(r);
    //         }
    //       });

    //       Promise.all([
    //         this.$store.dispatch("rooms/saveObjBatch", rooms2save),
    //         this.$store.dispatch("rates/saveObjBatch", rates2save),
    //         this.$store.dispatch("roomTypes/deleteObj", roomType)
    //       ]);
    //     })
    //     .catch(() => {});
    // },
    filterTypeOptions(s): any {
      if (s && s.length) this.allowCreate = true;
      else this.allowCreate = false;
      return _.filter(this.roomTypeOptions, (rt) => {
        return (rt.name || "").toLowerCase().includes((s || "").toLowerCase());
      });
    },
    sanitizeRoomType(room) {
      const type = room.get("type");
      if (!type) return null;
      return {
        id: type.get("localId"),
        name: type.get("name"),
      };
    },
    async onTypeChange(type) {
      // let rt;
      // if (typeof type === "string") {
      //   rt = new RoomType();
      //   rt.set("hotel", this.hotel);
      //   rt.set("name", type);
      //   this.$store.dispatch("roomTypes/saveObjSync", rt);
      // } else {
      //   rt = _.find(this.roomTypes, rt => {
      //     return rt.get("localId") === type.id;
      //   });
      // }
      // if (!rt) return;
      const room = this.editingRoom;
      if (!room) return;
      room.set("type", type);

      this.$store.dispatch("rooms/saveObj", room);
    },
    setRoomProperty(propertyName, value) {
      const room = this.editingRoom;
      if (!room) return;
      room.set(propertyName, value);

      this.$store.dispatch("rooms/saveObj", room);
    },
    setRoomTypeProperty(propertyName, value) {
      const roomType = this.editingRoomType;
      if (!roomType) return;
      roomType.set(propertyName, value);

      this.$store.dispatch("roomTypes/saveObj", roomType);
    },
    getRoomColor(room) {
      const res = Color(chash.hex(this.getRoomType(room)))
        .alpha(1)
        .string();
      return res;
    },
    getRoomType(room) {
      try {
        return room.get("type").get("name");
      } catch (e) {
        return "Nessuna tiplogia";
      }

      // const _room = findJRoomTypeByLocalId(room.get("type"));
      // if (!_room) return "Nessuna tiplogia";
      // return _room.name.it;
    },

    beforeCloseEditModal: function () {
      this.clearEditRoom();
    },
    beforeCloseEditModalRoomType: function () {
      this.clearEditRoomType();
    },
    newRoom: function () {
      const room = new Room();
      room.set("hotel", this.hotel);
      this.creatingRoom = true;
      this.$store
        .dispatch("rooms/saveObjSync", room)
        .then((room) => {
          if (this.hotel.getTableIsSameAsRoom()) {
            const table = new Table();
            table.set("room", room);
            table.set("hotel", this.hotel);
            this.$store.dispatch("tables/saveObj", table);
          }
          this.editRoom(room);
          this.creatingRoom = false;
        })
        .catch((e) => {
          this.creatingRoom = false;
        });
    },
    getRoomName: function (room) {
      const name = room.get("name");
      return name && name.length ? name : "Senza nome";
    },
    editRoom: function (room) {
      this.editingRoomId = room.get("localId");
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (this.$refs.roomNameInput)
          (this.$refs.roomNameInput as HTMLInputElement).focus();
      });
    },
    editRoomType: function (roomType) {
      this.editingRoomType = roomType;
      this.dialogVisibleRoomType = true;
      this.$nextTick(() => {
        if (this.$refs.roomTypeNameInput)
          (this.$refs.roomTypeNameInput as HTMLInputElement).focus();
      });
    },
    clearEditRoom: function () {
      this.editingRoomId = undefined;
      this.dialogVisible = false;
    },
    clearEditRoomType: function () {
      this.editingRoomType = undefined;
      this.dialogVisibleRoomType = false;
    },
  },
  computed: {
    editingRoom: function (): Room | undefined {
      if (!this.editingRoomId) return undefined;

      return this.$store.state.rooms.objects[this.editingRoomId];
    },

    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    rooms: function (): Room[] {
      return _.filter(this.$store.state.rooms.objects, (r) => {
        return (
          r.belongsTo(this.hotel, "hotel") &&
          (!r.get("type") || r.get("type").get("localId"))
        );
      });
    },

    roomTypes: function (): RoomType[] {
      return _.filter(this.$store.state.roomTypes.objects, (r) => {
        return r.belongsTo(this.hotel, "hotel");
      });
    },

    roomTypeOptions: function (): { id: string; name: string }[] {
      return _.map(this.roomTypes, (rt) => {
        return {
          id: rt.get("localId"),
          name: rt.get("name"),
        };
      });
    },
  },
});
