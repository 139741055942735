var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"jpasta-loading",rawName:"v-jpasta-loading.showRandomTip",value:(!_vm.loaded),expression:"!loaded",modifiers:{"showRandomTip":true}}],class:{
    tutorial: _vm.isTutorial,
  },attrs:{"id":"app"}},[(_vm.$store.state.saving)?_c('div',{staticClass:"saveBox"},[_c('span',{staticClass:"saving"},[_vm._v("Salvataggio")]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"savingSpinner"})]):_vm._e(),(_vm.loaded && !_vm.$store.state.showErrorScreen)?_c('top-bar'):_vm._e(),(!_vm.$store.state.showErrorScreen)?_c('div',{staticClass:"main-wrapper",class:{
      'no-bar': _vm.$route.meta.noBar || _vm.isTutorial,
    },staticStyle:{"display":"flex"},attrs:{"id":"app-main-wrapper"}},[_c('el-row',{staticStyle:{"width":"100%"}},[(!!_vm.hotel && !_vm.isTutorial)?_c('el-col',{staticClass:"no-print",class:{
          mobileOpened: _vm.$store.state.mobileMenuOpened,
          mobileLeftBar: _vm.$mq === 'xs' || _vm.$mq === 'sm',
        },staticStyle:{"position":"relative"},attrs:{"xs":_vm.getLeftColSize('xs'),"sm":_vm.getLeftColSize('sm'),"md":_vm.getLeftColSize('md'),"lg":_vm.getLeftColSize('lg'),"xl":_vm.getLeftColSize('xl')}},[_c('LeftBar',{staticClass:"leftBar"})],1):_vm._e(),_c('el-col',{staticClass:"mainWrapper",class:{
          mobileOpened: _vm.$store.state.mobileMenuOpened,
          mobileMainWrapper: _vm.$mq === 'xs' || _vm.$mq === 'sm',
        },attrs:{"xs":_vm.getMainWrapperColSpan('xs'),"sm":_vm.getMainWrapperColSpan('sm'),"md":_vm.getMainWrapperColSpan('md'),"lg":_vm.getMainWrapperColSpan('lg'),"xl":_vm.getMainWrapperColSpan('xl')}},[(_vm.loaded)?_c('router-view'):_vm._e()],1)],1)],1):_vm._e(),(_vm.$store.state.showErrorScreen)?_c('div',{staticClass:"error-screen"},[_c('h1',[_vm._v("Credo che si sia rotto qualcosa")]),_c('p',{staticClass:"error-copy"},[_vm._v(" Non preoccuparti, ben 500 litri di caffè sono già stati consumati per far fronte al problema. Ti chiediamo di riprovare più tardi ")]),_c('i',{staticClass:"em-svg em-thinking_face"})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }