
import Vue from "vue";
import moment, { Moment } from "moment-timezone";
import _ from "lodash";
import ColorHash from "color-hash";
import Color from "color";
// import VueResizable from "vue-resizable";
import BookComponentGantt from "./BookComponentGantt.vue";
import {
  HOTEL_RATES,
  Jpasta,
  Book,
  BookPerson,
  Room,
  Table,
  Hotel,
  Turn,
} from "@/jpasta-sdk";
import { mixin as clickaway } from "vue-clickaway";
import FilterBook from "./FilterBook.vue";
import mixins from "vue-typed-mixins";
import { Dictionary } from "vue-router/types/router";
import BooksUtilsMixin from "@/mixins/BooksUtilsMixin";
import BookQrCode from "./BookQrCode.vue";
const chash = new ColorHash({ lightness: 0.3, saturation: 0.8 });
let timeout;
const THRESHOLD = 50;
const MAX_SPEED = 52;
let resizeTimeout;
let heightResizeTimeout;
let keyDownPressed = false;
let keyDownPressedTimeout;

type BooksOf = { book: Book; offset: string; width: string; color: string };

export default mixins(BooksUtilsMixin).extend({
  mixins: [clickaway],
  components: {
    // "vue-resizable": VueResizable,
    BookComponentGantt,
    FilterBook,
    BookQrCode,
  },
  data: function (): {
    selectedTurnLocalIdModel: undefined | string;
    searchBook: { value: string; type: string } | undefined;
    selectedCheckin: Moment | undefined;
    selectedCheckout: Moment | undefined;
    loadingForSync: boolean;
    creatingBook: boolean;
    showQrCodeModal: boolean;
    disableSyncPeople: boolean;
    mouseGoUp: boolean;
    syncingPeople: boolean;
    dialogVisible: boolean;
    rightMenuOpened: boolean;
    cantSelect: boolean;
    isNew: boolean;
    draggingNoTable: boolean;
    rendering: boolean;
    dragging: string | undefined;
    vListRect: ClientRect | undefined;
    ganttWrapperRect: ClientRect | undefined;
    rightClickMenuTop: number | undefined;
    draggingElementOffsetY: number | undefined;
    draggingElementOffsetX: number | undefined;
    rightClickMenuLeft: number | undefined;
    rightClickedBook: Book | undefined;
    languages: { key: string; label: string }[];
    dayWidthPx: number | undefined;
    selectedRowMode: string | undefined;
    ganttSize: number;
    realGanttSize: number;
    currentDate: Moment;
    offsetX: number | undefined;
    offsetY: number | undefined;
    timeoutChangeSize: any;
    selectedBookId: string | null;
    resizingLeftEl: HTMLElement | undefined;
    resizingRightEl: HTMLElement | undefined;
    creatingElement: HTMLElement | undefined;
    vListEl: HTMLElement | undefined;
    draggingEl: HTMLElement | undefined;
    creatingRoom: HTMLElement | undefined;
    draggingNoTableEl: HTMLElement | undefined;
    originalResizingElementRect: ClientRect | undefined;
    draggingElementRect: ClientRect | undefined;
    version: number;
    originalCreatingX: number | undefined;
    draggingNoTableOffsetX: number | undefined;
    draggingNoTableOffsetY: number | undefined;
    startDragDay: Moment | undefined;
    originalCreatingDay: Moment | undefined;
  } {
    return {
      searchBook: undefined,
      selectedCheckin: undefined,
      selectedCheckout: undefined,
      draggingElementOffsetY: undefined,
      draggingElementOffsetX: undefined,
      loadingForSync: true,
      creatingBook: false,
      showQrCodeModal: false,
      disableSyncPeople: false,

      syncingPeople: false,
      dialogVisible: false,
      vListRect: undefined,
      rightClickMenuTop: undefined,
      rightClickMenuLeft: undefined,
      ganttWrapperRect: undefined,
      rightClickedBook: undefined,
      rightMenuOpened: false,
      languages: [
        { key: "en", label: "Inglese" },
        { key: "it", label: "Italiano" },
        { key: "de", label: "Tedesco" },
      ],

      dayWidthPx: undefined,
      selectedRowMode: undefined,
      ganttSize: 100,
      realGanttSize: 100,
      currentDate: moment(),
      dragging: undefined,
      offsetX: undefined,
      offsetY: undefined,
      timeoutChangeSize: undefined,
      selectedBookId: null,
      resizingLeftEl: undefined,
      originalResizingElementRect: undefined,
      resizingRightEl: undefined,
      mouseGoUp: false,
      draggingEl: undefined,
      version: 0,
      startDragDay: undefined,
      cantSelect: false,
      isNew: false,
      draggingElementRect: undefined,
      selectedTurnLocalIdModel: undefined,
      vListEl: undefined,
      rendering: false,
      creatingElement: undefined,
      originalCreatingX: undefined,
      originalCreatingDay: undefined,
      creatingRoom: undefined,

      draggingNoTable: false,
      draggingNoTableEl: undefined,
      draggingNoTableOffsetX: undefined,
      draggingNoTableOffsetY: undefined,
    };
  },
  mounted() {
    window.addEventListener("mousemove", this.mouseMove);
    window.addEventListener("touchend", this.touchEnd);

    window.addEventListener("touchmove", this.touchMove);
    window.addEventListener("mouseup", this.mouseUp);
    window.addEventListener("keyup", this.handleArrow);
    window.addEventListener("resize", this.handleHeightRezie);
    window.addEventListener("keydown", this.handleKeyDown);
    setTimeout(() => {
      this.loadingForSync = false;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.parseQueryData();
          });
        });

        this.updateDayWidthPx();
        this.fixJGanttHeight();

        if (this.$refs["recycler-scroller"]) {
          this.vListRect = (
            this.$refs["recycler-scroller"] as any
          ).$el.getBoundingClientRect();
          this.vListEl = (this.$refs["recycler-scroller"] as any).$el;
        }

        if (this.$refs["gantt-wrapper"])
          this.ganttWrapperRect = (
            this.$refs["gantt-wrapper"] as HTMLElement
          ).getBoundingClientRect();
      });
    }, 800);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.mouseMove);
    window.removeEventListener("touchend", this.touchEnd);
    window.removeEventListener("touchmove", this.touchMove);
    window.removeEventListener("mouseup", this.mouseUp);
    window.removeEventListener("keyup", this.handleArrow);
    window.removeEventListener("resize", this.handleHeightRezie);
    window.removeEventListener("keydown", this.handleKeyDown);
    if (this.draggingEl) document.body.removeChild(this.draggingEl);
    if (this.draggingNoTableEl)
      document.body.removeChild(this.draggingNoTableEl);
  },
  methods: {
    goToSummary(): void {
      if (!this.selectedBook) return;
      this.$router
        .push({
          query: this.getQueryData(),
        })
        .catch((e) => {});
      this.$router.push({
        name: "Summary",
        params: {
          bookid: this.selectedBook.get("localId"),
          hotelid: this.hotel.get("localId"),
        },
      });
    },
    onTouchStartNoTable: function (book, e): any {
      const touch: any = _.first(e.touches);
      if (!touch) return;
      const x = touch.clientX;
      const y = touch.clientY;
      return this.startDraggingNoTableBook(book, x, y);
    },
    onDragStart: function (): boolean {
      return false;
    },

    mouseDownNoTable(book, e): void {
      this.mouseGoUp = false;
      this.cantSelect = true;
      setTimeout(() => {
        if (this.mouseGoUp) {
          this.editBook(book);
        } else {
          const x = e.clientX;
          const y = e.clientY;

          return this.startDraggingNoTableBook(book, e.clientX, e.clientY);
        }
      }, 130);
    },

    startDraggingNoTableBook(book, x, y): any {
      this.cantSelect = true;
      const el: HTMLElement = _.first(
        this.$refs[`noTable-${book.get("localId")}`] as HTMLElement[]
      ) as HTMLElement;

      const rect = el.getBoundingClientRect();

      if (!book || !book.get("checkin") || !book.get("checkout"))
        return this.clearSelectedDates();
      else {
        this.selectedCheckin = moment(book.get("checkin"));
        this.selectedCheckout = moment(book.get("checkout"));
      }

      this.draggingNoTable = true;
      this.draggingNoTableOffsetX = x - rect.left;
      this.draggingNoTableOffsetY = y - rect.top;
      //@ts-ignore
      const draggingEl: HTMLElement = el.cloneNode(true);
      draggingEl.style.position = "fixed";
      draggingEl.style.left = rect.left + "px";
      draggingEl.style.top = rect.top + "px";
      draggingEl.style.width = rect.width + "px";
      draggingEl.style["user-select"] = "none";

      draggingEl.style["z-index"] = 1;

      this.draggingNoTableEl = draggingEl;
      document.body.appendChild(draggingEl);
    },
    formatDate: function (date): string {
      if (!date) return "";
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    async deleteBookFromModal(): Promise<void> {
      if (await this.deleteBook(this.selectedBook)) {
        this.clearSelectedBook();
      }
    },
    resetTimeout(): void {
      if (keyDownPressedTimeout) clearTimeout(keyDownPressedTimeout);
      keyDownPressedTimeout = setTimeout(() => {
        keyDownPressed = false;
        keyDownPressedTimeout = undefined;
      }, 500);
    },
    keyDownDebounce(): boolean {
      if (keyDownPressed) {
        this.resetTimeout();
        return true;
      }

      keyDownPressed = true;

      this.resetTimeout();

      return false;
    },
    handleKeyDown: function (e): void {
      if (e.keyCode === 13 && e.shiftKey) {
        e.preventDefault();
        if (this.keyDownDebounce()) return;
        this.newBook(true);
      }
    },
    closeRightClickMenu: function (): void {
      this.rightClickMenuTop = undefined;
      this.rightClickedBook = undefined;
      this.rightClickMenuLeft = undefined;
      this.rightMenuOpened = false;
    },
    onRightClick: function ({ book, event }): void {
      if (!this.$refs["gantt-body"]) return;
      const ganttBodyRect = (
        this.$refs["gantt-body"] as HTMLElement
      ).getBoundingClientRect();
      this.rightClickMenuTop = event.clientY - ganttBodyRect.top;
      this.rightClickMenuLeft = event.clientX - ganttBodyRect.left;
      this.rightMenuOpened = true;
      this.rightClickedBook = book;
    },
    handleHeightRezie: function (): void {
      if (heightResizeTimeout) {
        clearTimeout(heightResizeTimeout);
      }

      heightResizeTimeout = setTimeout(() => {
        this.fixJGanttHeight();

        this.ganttWrapperRect = (
          this.$refs["gantt-wrapper"] as HTMLElement
        ).getBoundingClientRect();

        heightResizeTimeout = undefined;
      }, 300);
    },
    fixJGanttHeight: function (): void {
      if (!this.$refs["recycler-scroller"]) return;
      const rect = (
        this.$refs["recycler-scroller"] as Vue
      ).$el.getBoundingClientRect();

      const height = window.innerHeight - rect.top - 24 - 15;
      (
        (this.$refs["recycler-scroller"] as Vue).$el as HTMLElement
      ).style.height = height + "px";
      (this.$refs["recycler-scroller"] as Vue).$forceUpdate();
    },

    deleteBook: async function (
      book,
      force: boolean = false
    ): Promise<boolean> {
      if (force) return this.$store.dispatch("books/deleteObj", book);
      this.closeRightClickMenu();
      try {
        await this.$dialog.confirm(
          "Vuoi davvero eliminare questa prenotazione?",
          {
            okText: "Continua",
            cancelText: "Annulla",
          }
        );
        this.deleteBook(book, true);
        return true;
      } catch (e) {
        return false;
      }
    },
    startBookCreation: function (obj, itemNumber): any {
      const row: Vue = this.$refs[`row-${itemNumber}`] as Vue;
      if (!row) return;
      const room = row.$refs.room as HTMLElement;
      if (!room) return;
      const rect = room.getBoundingClientRect();
      const newElement = document.createElement("div");
      newElement.className = "book-gantt";
      newElement.style["background-color"] = Color(chash.hex("no email"))
        .alpha(0.75)
        .string();

      newElement.style.position = "absolute";
      newElement.style.left = obj.x - rect.left + "px";
      newElement.style.width = "10px";
      this.creatingElement = newElement;
      this.originalCreatingX = obj.x;
      this.originalCreatingDay = obj.day;
      this.selectedCheckin = this.originalCreatingDay;
      this.creatingRoom = room;
      room.appendChild(newElement);
    },
    beforeCloseDialog: function (done, force): any {
      if (
        !this.selectedBook ||
        !this.selectedBook.get("checkin") ||
        !this.selectedBook.get("checkout")
      ) {
        return this.$dialog
          .confirm(
            "Per completare la creazione della prenotazione è necessario specificare una data di checkin e una di checkout. Se continui, questa prenotazione verrà eliminata.",
            {
              okText: "Elimina",
              cancelText: "Annulla",
            }
          )
          .then(() => {
            const book = _.cloneDeep(this.selectedBook);
            this.clearSelectedBook();

            this.deleteBook(book, true);

            done();
          })
          .catch(function () {
            return Promise.reject();
          });
      }
      this.clearSelectedBook();
      done();
    },
    newBook: function (sync = false): void {
      const book = new Book();
      book.set("hotel", this.hotel);
      book.set("pax", 1);
      let promise;
      if (sync) {
        this.creatingBook = true;
        promise = this.$store.dispatch("books/saveObjSync", book);
      } else {
        promise = this.$store.dispatch("books/saveObj", book);
      }
      promise.then((newBook) => {
        if (sync) this.creatingBook = false;
        const bookPerson = new BookPerson();
        bookPerson.set("book", newBook);
        this.$store.dispatch("bookPersons/saveObj", bookPerson);
        this.editBook(newBook, true);
      });
    },
    parseQueryData: function (): void {
      const query = this.$route.query;
      if (!this.vListEl) return;
      if (query.currentDate) {
        this.currentDate = moment.unix(
          parseInt((query.currentDate as string) || "0")
        );
      }

      if (query.gScrollPosition !== undefined) {
        this.$nextTick(() => {
          if (!this.vListEl) return;
          this.vListEl.scrollTop = parseInt(
            (query.gScrollPosition as string) || "0"
          );
        });
      }

      if (query.selectedBook) {
        const book =
          this.$store.state.books.objects[query.selectedBook as string];
        if (book) this.editBook(book);
      }
    },
    goToBookPeople: function (): void {
      if (!this.selectedBook) return;
      this.$router.push({
        query: this.getQueryData(),
      });

      this.$router
        .push({
          name: "BookPeople",
          params: {
            bookid: this.selectedBook.get("localId"),
            hotelid: this.hotel.get("localId"),
          },
          query: {
            fromGantt: "1",
          },
        })
        .catch((e) => {});
    },
    getQueryData: function ():
      | {
          fromGantt: string;
          gScrollPosition: string;
          selectedBook: string;
          currentDate: string;
        }
      | undefined {
      if (!this.vListEl || !this.selectedBook) return undefined;
      return {
        fromGantt: "1",
        gScrollPosition: this.vListEl.scrollTop.toString(),
        selectedBook: this.selectedBook.get("localId"),
        currentDate: this.currentDate.unix().toString(),
      };
    },

    handleArrow: function (e): void {
      switch (e.keyCode) {
        case 39: //Freccia destra
          this.nextMonth();
          break;
        case 37: //Freccia sinistra
          this.prevMonth();
          break;
      }
    },
    clearSelectedBook: function (): void {
      this.dialogVisible = false;
      this.selectedBookId = null;
      this.isNew = false;
    },
    editBook: function (book, isNew = false): void {
      this.dialogVisible = true;
      this.selectedBookId = book.get("localId");
      this.isNew = isNew;
      // if (!focus) return;
      // this.$nextTick(() => {
      //   _.debounce(() => {
      //     if (this.$refs.editBookComponent) {
      //       this.$refs.editBookComponent.focusDateInput();
      //     }
      //   }, 200)();
      // });
    },
    nextMonth: function (): void {
      this.currentDate = moment(this.currentDate).add(1, "month");
    },
    prevMonth: function (): void {
      this.currentDate = moment(this.currentDate).subtract(1, "month");
    },
    updateDayWidthPx: function (): void {
      if (!this.$refs["first-top-cell"]) return;
      const rect = (
        this.$refs["first-top-cell"] as HTMLElement
      ).getBoundingClientRect();

      this.dayWidthPx = rect.width;
    },
    handleResize: function (): void {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }

      resizeTimeout = setTimeout(() => {
        this.version++;
        this.updateDayWidthPx();

        resizeTimeout = undefined;
      }, 300);

      //this.$refs["virtual-list"].forceRender();
    },

    computeScrollSpeed: function (x): number {
      if (x < 0) return MAX_SPEED;
      const finalRes = (1 / x) * 100;
      return finalRes < Infinity ? finalRes : MAX_SPEED;
    },
    scrollVList: function (mouseX, mouseY): void {
      if (!this.dragging && !this.draggingNoTable) return;
      if (!this.$refs["recycler-scroller"]) return;

      const vListRect = (
        this.$refs["recycler-scroller"] as Vue
      ).$el.getBoundingClientRect();

      let scrolled = false;
      if (mouseY >= vListRect.bottom - THRESHOLD) {
        const SCROLL_AMOUNT = this.computeScrollSpeed(
          vListRect.bottom - mouseY
        );
        if (this.vListEl) this.vListEl.scrollTop += SCROLL_AMOUNT;
        scrolled = true;
      } else if (mouseY <= vListRect.top + THRESHOLD) {
        const SCROLL_AMOUNT = this.computeScrollSpeed(mouseY - vListRect.top);
        if (this.vListEl) this.vListEl.scrollTop -= SCROLL_AMOUNT;
        scrolled = true;
      }

      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }
      if (!scrolled) return;
      timeout = setTimeout(() => {
        this.scrollVList(mouseX, mouseY);
      }, 10);
    },
    setSelectedCheckin: function (): void {
      try {
        if (!this.resizingLeftEl) return;

        const rect = this.resizingLeftEl.getBoundingClientRect();

        const day = this.getDayBelow(this.resizingLeftEl, rect.left, rect.top);
        if (!day) throw new Error("");

        this.selectedCheckin = moment(day.dataset.day);
      } catch (e) {
        this.selectedCheckin = undefined;
      }
    },
    setSelectedCheckout: function (): void {
      try {
        if (!this.resizingRightEl) return;
        const rect = this.resizingRightEl.getBoundingClientRect();

        const day = this.getDayBelow(
          this.resizingRightEl,
          rect.right,
          rect.top
        );
        if (!day) throw new Error("");

        this.selectedCheckout = moment(day.dataset.day);
      } catch (e) {
        this.selectedCheckout = undefined;
      }
    },

    clearSelectedDates: function (): void {
      this.selectedCheckin = undefined;
      this.selectedCheckout = undefined;
    },

    setSelectedDayFixed: function (draggingEl, bookid, x, y): any {
      const day = this.getDayBelow(draggingEl, x, y);
      if (!day) return this.clearSelectedDates();

      const book = this.$store.state.books.objects[bookid];

      if (!book || !book.get("checkin") || !book.get("checkout"))
        return this.clearSelectedDates();

      this.selectedCheckin = moment(book.get("checkin"));
      this.selectedCheckout = moment(book.get("checkout"));
    },
    setSelectedDay: function (x, y): any {
      //this.draggingEl.style["pointer-events"] = "none";

      try {
        if (!this.draggingEl) return;
        const day = this.getDayBelow(this.draggingEl, x, y);

        if (!day) throw new Error("");

        const offset = moment(day.dataset.day).diff(
          this.startDragDay,
          "second"
        );

        const book =
          this.$store.state.books.objects[
            this.draggingEl.dataset.book as string
          ];

        this.selectedCheckin = moment(book.get("checkin")).add(
          offset,
          "second"
        );
        this.selectedCheckout = moment(book.get("checkout")).add(
          offset,
          "second"
        );
      } catch (e) {
        this.selectedCheckin = undefined;
        this.selectedCheckout = undefined;
      }

      //this.draggingEl.style["pointer-events"] = "";
    },
    selectDayForCreation(x, y): any {
      const day = this.getDayBelow(this.creatingElement, x, y);

      if (!day) return (this.selectedCheckout = undefined);

      this.selectedCheckout = moment(day.dataset.day);
    },
    mouseMoveNoTable(x, y): any {
      if (!this.draggingNoTableEl) return;
      this.draggingNoTableEl.style.left =
        x - (this.draggingNoTableOffsetX || 0) + "px";
      this.draggingNoTableEl.style.top =
        y - (this.draggingNoTableOffsetY || 0) + "px";

      this.scrollVList(x, y);
    },

    createElement(x, y): any {
      if (
        !this.creatingElement ||
        this.originalCreatingX === undefined ||
        !this.ganttWrapperRect
      )
        return;
      this.selectDayForCreation(x, y);
      if (x < this.originalCreatingX) {
        this.creatingElement.style.left = x - this.ganttWrapperRect.left + "px";
        this.creatingElement.style.width = this.originalCreatingX - x + "px";
      } else {
        this.creatingElement.style.width = x - this.originalCreatingX + "px";
      }
    },

    draggingBooking(x, y): any {
      if (!this.draggingEl) return;

      this.draggingEl.style.top = y - (this.draggingElementOffsetY || 0) + "px";
      this.draggingEl.style.left =
        x - (this.draggingElementOffsetX || 0) + "px";

      this.scrollVList(x, y);
    },

    resizeLeft(x, y): any {
      if (
        !this.originalResizingElementRect ||
        !this.resizingLeftEl ||
        !this.ganttWrapperRect
      )
        return;
      this.setSelectedCheckin();

      if (this.originalResizingElementRect.right <= x) {
        return;
      }
      this.resizingLeftEl.style.left = x - this.ganttWrapperRect.left + "px";
      this.resizingLeftEl.style.width =
        this.originalResizingElementRect.right - x + "px";
    },

    resizeRight(x, y): any {
      if (!this.originalResizingElementRect || !this.resizingRightEl) return;
      this.setSelectedCheckout();
      if (this.originalResizingElementRect.left >= x) {
        return;
      }
      this.resizingRightEl.style.width =
        x - this.originalResizingElementRect.left + "px";
    },

    touchEnd: function (e): any {
      const touch:
        | {
            clientX: number;
            clientY: number;
          }
        | undefined = _.first(e.changedTouches);

      if (!touch) return this.clearDraggingNoTable();
      const x = touch.clientX;
      const y = touch.clientY;
      if (this.draggingNoTable) {
        return this.endDragActionNoTable(x, y);
      } else if (this.creatingElement) {
        return this.endDragActionCreateElement(x, y);
      } else if (this.dragging) {
        return this.endDragActionDragging(x, y);
      } else if (this.resizingLeftEl) {
        return this.endDragActionResizingLeft(x, y);
      } else if (this.resizingRightEl) {
        return this.endDragActionResizingRight(x, y);
      }
    },

    touchMove: function (e): any {
      const touch:
        | {
            clientX: number;
            clientY: number;
          }
        | undefined = _.first(e.touches);

      if (!touch) return;
      const x = touch.clientX;
      const y = touch.clientY;
      if (this.draggingNoTable) {
        return this.mouseMoveNoTable(x, y);
      } else if (this.creatingElement) {
        return this.createElement(x, y);
      } else if (this.dragging) {
        return this.draggingBooking(x, y);
      } else if (this.resizingLeftEl) {
        return this.resizeLeft(x, y);
      } else if (this.resizingRightEl) {
        return this.resizeRight(x, y);
      }
    },

    mouseMove: function (e): any {
      if (this.draggingNoTable) {
        return this.mouseMoveNoTable(e.clientX, e.clientY);
      } else if (this.creatingElement) {
        return this.createElement(e.clientX, e.clientY);
      } else if (this.dragging) {
        return this.draggingBooking(e.clientX, e.clientY);
      } else if (this.resizingLeftEl) {
        return this.resizeLeft(e.clientX, e.clientY);
      } else if (this.resizingRightEl) {
        return this.resizeRight(e.clientX, e.clientY);
      }
    },
    onMouseDown: function (
      draggingEl,
      draggingElementOffsetX,
      draggingElementOffsetY,
      startDragDay
    ): any {
      this.mouseGoUp = false;
      this.cantSelect = true;
      setTimeout(() => {
        if (this.mouseGoUp) {
          this.editBook(
            this.$store.state.books.objects[draggingEl.dataset.book]
          );
        } else {
          document.body.appendChild(draggingEl);
          this.startDragDay = startDragDay;

          this.draggingElementOffsetX = draggingElementOffsetX;
          this.draggingElementOffsetY = draggingElementOffsetY;
          this.draggingEl = draggingEl;
          if (!this.draggingEl) return;
          const book =
            this.$store.state.books.objects[
              this.draggingEl.dataset.book as string
            ];

          if (!book || !book.get("checkin") || !book.get("checkout"))
            return this.clearSelectedDates();
          else {
            this.selectedCheckin = moment(book.get("checkin"));
            this.selectedCheckout = moment(book.get("checkout"));
          }
          this.draggingElementRect = this.draggingEl.getBoundingClientRect();
          this.dragging = this.draggingEl.dataset.book;
        }
      }, 130);
    },
    resetResizingEl(el): void {
      if (!this.originalResizingElementRect || !this.ganttWrapperRect) return;
      el.style.left =
        this.originalResizingElementRect.left -
        this.ganttWrapperRect.left +
        "px";
      el.style.width = this.originalResizingElementRect.width + "px";
    },
    getDayBelow: function (el, x, y, rec = 0): any {
      el.style["pointer-events"] = "none";
      const elemBelow = document.elementFromPoint(x, y);

      if (rec === 0) {
        if (!elemBelow) return;
        const ganttWrapper = elemBelow.closest(
          ".vue-recycle-scroller__item-wrapper"
        );
        if (!ganttWrapper) return;
        if (elemBelow.closest(".room-number")) return;
      }

      if (
        !elemBelow ||
        elemBelow.tagName === "HTML" ||
        elemBelow.tagName === "BODY"
      ) {
        el.style["pointer-events"] = "";
        return;
      }

      let day = elemBelow.className.includes("day")
        ? elemBelow
        : elemBelow.closest(".day");

      if (!day) day = this.getDayBelow(elemBelow, x, y, rec + 1);
      el.style["pointer-events"] = "";

      return day;
    },

    getNoTableBelow: function (el, x, y, rec = 0): any {
      el.style["pointer-events"] = "none";

      const elemBelow = document.elementFromPoint(x, y);
      if (
        !elemBelow ||
        elemBelow.tagName === "HTML" ||
        elemBelow.tagName === "BODY"
      ) {
        el.style["pointer-events"] = "";
        return;
      }

      let noTableWrapper = elemBelow.className.includes("bookNoTableMenu")
        ? elemBelow
        : elemBelow.closest(".bookNoTableMenu");

      if (!noTableWrapper)
        noTableWrapper = this.getNoTableBelow(elemBelow, x, y, rec + 1);
      el.style["pointer-events"] = "";

      return noTableWrapper;
    },
    getRoomBelow: function (el, x, y, rec = 0): any {
      el.style["pointer-events"] = "none";

      const elemBelow = document.elementFromPoint(x, y);
      if (
        !elemBelow ||
        elemBelow.tagName === "HTML" ||
        elemBelow.tagName === "BODY"
      ) {
        el.style["pointer-events"] = "";
        return;
      }

      let room = elemBelow.className.includes("room")
        ? elemBelow
        : elemBelow.closest(".room");

      if (!room) room = this.getRoomBelow(elemBelow, x, y, rec + 1);
      el.style["pointer-events"] = "";

      return room;
    },
    clearCreatingData(): any {
      if (this.creatingRoom && this.creatingElement)
        this.creatingRoom.removeChild(this.creatingElement);
      this.originalCreatingX = undefined;
      this.originalCreatingDay = undefined;
      this.creatingElement = undefined;
      this.creatingRoom = undefined;
    },
    clearDraggingNoTable(): any {
      if (this.draggingNoTableEl)
        document.body.removeChild(this.draggingNoTableEl);

      this.draggingNoTableEl = undefined;

      this.draggingNoTable = false;
      this.draggingNoTableOffsetX = undefined;

      this.draggingNoTableOffsetY = undefined;
      this.clearSelectedDates();
    },
    endDragActionNoTable(x, y): any {
      const row = this.getRoomBelow(this.draggingNoTableEl, x, y);
      if (!row) {
        return this.clearDraggingNoTable();
      }

      if (!this.draggingNoTableEl) return;

      const itemNumber = row.dataset.itemnumber;

      const book =
        this.$store.state.books.objects[
          this.draggingNoTableEl.dataset.id as string
        ];

      if (!book) return this.clearDraggingNoTable();

      switch (this.rowMode) {
        case "room":
          book.setRoomNumber(itemNumber);

          break;

        case "table":
          book.setTableNumber(itemNumber);

          break;
      }

      this.$store.dispatch("books/saveObj", book);

      this.clearDraggingNoTable();
    },
    endDragActionDragging(x, y): any {
      try {
        if (!this.draggingEl) return;
        const book =
          this.$store.state.books.objects[
            this.draggingEl.dataset.book as string
          ];
        const noTableEl = this.getNoTableBelow(this.draggingEl, x, y);
        if (noTableEl) {
          book.setTableNumber(undefined);
          this.$store.dispatch("books/saveObj", book);
        } else {
          const room = this.getRoomBelow(this.draggingEl, x, y);

          if (!room) throw new Error("");

          const itemNumber = room.dataset.itemnumber;

          switch (this.rowMode) {
            case "room":
              if (itemNumber === undefined || itemNumber === null) {
                book.setRoomNumber(undefined);
              } else {
                book.setRoomNumber(itemNumber.toString());
              }

              break;
            case "table":
              if (itemNumber === undefined || itemNumber === null) {
                book.setTableNumber(undefined);
              } else {
                book.setTableNumber(itemNumber.toString());
              }

              break;
          }

          this.$store.dispatch("books/saveObj", book);
        }
      } catch (e) {
        console.log("ERROR", e);
      }
      if (this.draggingEl) document.body.removeChild(this.draggingEl);
      this.draggingEl = undefined;
      this.draggingElementRect = undefined;
      this.dragging = undefined;
    },
    endDragActionCreateElement(x, y): any {
      const day = this.getDayBelow(this.creatingElement, x, y);
      if (!day || !this.creatingRoom) {
        this.clearCreatingData();
        return;
      }

      const book = new Book();
      book.set("hotel", this.hotel);
      const itemNumber = this.creatingRoom.dataset.itemnumber;
      if (this.rowMode === "room" && itemNumber) {
        book.setRoomNumber(itemNumber);
      } else if (itemNumber) {
        book.setTableNumber(itemNumber);
      }
      const dayDate = moment(day.dataset.day);
      if (dayDate.isBefore(this.originalCreatingDay)) {
        book.set("checkin", dayDate.toDate());
        if (this.originalCreatingDay)
          book.set("checkout", this.originalCreatingDay.toDate());
      } else {
        if (this.originalCreatingDay)
          book.set("checkin", this.originalCreatingDay.toDate());
        book.set("checkout", dayDate.toDate());
        book.set("pax", 1);
      }

      this.$store.dispatch("books/saveObj", book).then((newBook) => {
        const bookPerson = new BookPerson();
        bookPerson.set("book", newBook);
        this.$store.dispatch("bookPersons/saveObj", bookPerson);
        this.editBook(book);
      });

      this.clearCreatingData();
    },
    endDragActionResizingRight(x, y): any {
      try {
        if (!this.resizingRightEl) return;
        const rect = this.resizingRightEl.getBoundingClientRect();

        const day = this.getDayBelow(
          this.resizingRightEl,
          rect.right,
          rect.top
        );
        if (!day) throw new Error("");
        const book =
          this.$store.state.books.objects[
            this.resizingRightEl.dataset.book as string
          ];

        book.set("checkout", moment(day.dataset.day).toDate());

        this.$store.dispatch("books/saveObj", book);
      } catch (e) {
        this.resetResizingEl(this.resizingRightEl);
      }

      this.resizingRightEl = undefined;
      this.originalResizingElementRect = undefined;
    },
    endDragActionResizingLeft(x, y): any {
      try {
        if (!this.resizingLeftEl) return;
        const rect = this.resizingLeftEl.getBoundingClientRect();

        const day = this.getDayBelow(this.resizingLeftEl, rect.left, rect.top);
        if (!day) throw new Error("");
        const book =
          this.$store.state.books.objects[
            this.resizingLeftEl.dataset.book as string
          ];

        book.set("checkin", moment(day.dataset.day).toDate());

        this.$store.dispatch("books/saveObj", book);
      } catch (e) {
        this.resetResizingEl(this.resizingLeftEl);
      }

      this.resizingLeftEl = undefined;
      this.originalResizingElementRect = undefined;
    },
    mouseUp: function (e): any {
      this.cantSelect = false;
      this.mouseGoUp = true;
      this.selectedCheckin = undefined;
      this.selectedCheckout = undefined;
      if (this.draggingNoTable) {
        return this.endDragActionNoTable(e.clientX, e.clientY);
      } else if (this.creatingElement) {
        this.endDragActionCreateElement(e.clientX, e.clientY);
      } else if (this.dragging) {
        return this.endDragActionDragging(e.clientX, e.clientY);
      } else if (this.resizingLeftEl) {
        return this.endDragActionResizingLeft(e.clientX, e.clientY);
      } else if (this.resizingRightEl) {
        return this.endDragActionResizingRight(e.clientX, e.clientY);
      }
    },
    initLeftResize: function (el): any {
      this.resizingLeftEl = el;
      if (!this.resizingLeftEl) return;
      this.originalResizingElementRect =
        this.resizingLeftEl.getBoundingClientRect();
    },
    initRightResize: function (el): any {
      this.resizingRightEl = el;
      if (!this.resizingRightEl) return;
      this.originalResizingElementRect =
        this.resizingRightEl.getBoundingClientRect();
    },
    changeCurrDate: function (date): any {
      this.currentDate = moment(date);
    },

    changeGanttSize: function (v): any {
      this.realGanttSize = v;
      if (this.timeoutChangeSize) {
        clearTimeout(this.timeoutChangeSize);
      }

      this.timeoutChangeSize = setTimeout(() => {
        this.ganttSize = v;
      }, 300);
    },

    filterBook: function (book): boolean {
      if (!this.searchBook) return true;

      const value = (this.searchBook.value || "").toLowerCase();

      switch (this.searchBook.type) {
        case "room":
          return (book.getRoomNumber() || "").toLowerCase().includes(value);
          break;
        case "table":
          return (book.getTableNumber() || "").toLowerCase().includes(value);
          break;
        case "email":
          return (book.get("refEmail") || "").toLowerCase().includes(value);
          break;
        case "name":
          return (book.get("refName") || "").toLowerCase().includes(value);
          break;
      }

      return false;
    },
    getBooksOf: function (itemNumber, sort = false): BooksOf[] {
      if (!this.dayWidthPx || this.rendering) return [];

      let books = _.filter(this.$store.state.books.objects, (b: Book) => {
        if (!this.filterBook(b)) return false;

        if (itemNumber && !this.belongsToTurn(b, this.selectedTurn))
          return false;

        let matchItem;
        switch (this.rowMode) {
          case "room":
            if (itemNumber === null) {
              matchItem =
                b.getRoomNumber() === undefined ||
                b.getRoomNumber() === null ||
                b.getRoomNumber() === "";
            } else {
              matchItem = b.getRoomNumber() == itemNumber;
            }
            break;

          case "table":
            if (itemNumber === null) {
              matchItem =
                b.getTableNumber() === undefined ||
                b.getTableNumber() === null ||
                b.getTableNumber() === "";
            } else {
              matchItem = b.getTableNumber() == itemNumber;
            }

            break;
        }

        return (
          matchItem &&
          b.get("checkin") &&
          b.get("checkout") &&
          moment(b.get("checkin")).isSameOrBefore(moment(b.get("checkout"))) &&
          b.belongsTo(this.hotel, "hotel") &&
          (moment(this.days[0]).isBetween(
            moment(b.get("checkin")).startOf("day"),
            moment(b.get("checkout")).endOf("day"),
            null,
            "[]"
          ) ||
            moment(this.days[this.days.length - 1]).isBetween(
              moment(b.get("checkin")).startOf("day"),
              moment(b.get("checkout")).endOf("day"),
              null,
              "[]"
            ) ||
            moment(b.get("checkin")).isBetween(
              moment(this.days[0]).startOf("day"),
              moment(this.days[this.days.length - 1]).endOf("day"),
              null,
              "[]"
            ))
        );
      });

      if (sort) {
        books = _.sortBy(books, (b) => {
          return b.get("checkin")
            ? moment(b.get("checkin")).valueOf()
            : Infinity;
        });
      }

      const roomRect = (
        this.$refs["gantt-body"] as HTMLElement
      ).getBoundingClientRect();
      return _.reduce(
        books,
        (res: BooksOf[], b) => {
          const checkin = moment(b.get("checkin")).isSame(
            this.currentDate,
            "month"
          )
            ? b.get("checkin")
            : this.days[0];

          const checkInDay = (
            this.$refs[`day-${moment(checkin).date()}`] as HTMLDivElement
          )[0];
          const rectCheckin = checkInDay.getBoundingClientRect();

          let offset = rectCheckin.left - roomRect.left;

          const checkout = moment(b.get("checkout")).isSame(
            this.currentDate,
            "month"
          )
            ? b.get("checkout")
            : this.days[this.days.length - 1];

          const checkOutDay = (
            this.$refs[`day-${moment(checkout).date()}`] as HTMLDivElement
          )[0];
          const rectCheckout = checkOutDay.getBoundingClientRect();

          let width = rectCheckout.right - rectCheckin.left;

          width = width - 20;

          offset = offset + 10;
          res.push({
            book: b,
            offset: offset + "px",
            width: width + "px",
            color: Color(chash.hex(b.get("refEmail") || "no email")).alpha(
              0.75
            ),
          });
          return res;
        },
        []
      );
    },
    tableIsInSelectedTurn: function (t: Table): boolean {
      const tableTurn = this.turnByTable[t.get("name")];
      const tableTurnLocalId = tableTurn ? tableTurn.get("localId") : undefined;

      const selectedTurnLocalId = this.selectedTurn
        ? this.selectedTurn.get("localId")
        : undefined;
      return selectedTurnLocalId === tableTurnLocalId;
    },
    removeNotDigitChars: function (str?: string) {
      if (str === undefined) return str;
      return str.replace(new RegExp(/[^0-9.]/, "g"), "");
    },
  },
  computed: {
    selectedTurn: function (): Turn | undefined {
      if (!this.selectedTurnLocalId) return undefined;
      return this.$store.state.turns.objects[
        this.selectedTurnLocalId as string
      ];
    },
    turns: function (): Turn[] {
      return _.filter(
        this.$store.state.turns.objects as Dictionary<Turn>,
        (t) => t.belongsTo(this.hotel, "hotel")
      );
    },
    noTableOpened: function (): boolean {
      return true;
      //return !!this.getBooksOf(null).length;
    },
    selectedBook: function (): Book | undefined {
      return this.selectedBookId
        ? this.$store.state.books.objects[this.selectedBookId]
        : null;
    },
    selectedTurnLocalId: {
      set: function (val): void {
        this.selectedTurnLocalIdModel = val;
      },
      get: function (): string | undefined {
        if (this.selectedTurnLocalIdModel === null)
          return this.selectedTurnLocalIdModel;
        if (this.selectedTurnLocalIdModel) return this.selectedTurnLocalIdModel;
        if (this.turns.length > 0)
          return (_.first(this.turns) as Turn).get("localId");
        return undefined;
      },
    } as any,
    rowMode: {
      set: function (val): void {
        this.selectedRowMode = val;
      },
      get: function (): string {
        return this.selectedRowMode
          ? this.selectedRowMode
          : this.rowModes[0].value;
      },
    } as any,
    rowModes: function (): { label: string; value: "table" | "room" }[] {
      if (this.hotel.get("enablePMS")) {
        return [
          {
            label: "Per Camera",
            value: "room",
          },
          {
            label: "Per Tavolo",
            value: "table",
          },
        ];
      } else {
        return [
          {
            label: "Per Tavolo",
            value: "table",
          },
        ];
      }
    },

    items2render: function (): any {
      const res: { key: number; itemNumber: string; books: BooksOf[] }[] = [];
      this.version;

      if (this.rowMode === "room") {
        _.forEach(this.rooms, (r, index) => {
          if (!r.get("name") || !r.get("name").length) return;
          res.push({
            key: index,
            itemNumber: r.get("name"),
            books: this.getBooksOf(r.get("name")),
          });
        });
      } else {
        _.forEach(this.tables, (t, index) => {
          if (!t.get("name") || !t.get("name").length) return;

          res.push({
            key: index,
            itemNumber: t.get("name"),
            books: this.getBooksOf(t.get("name")),
          });
        });
      }

      return res;
    },

    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    isTutorial: function (): boolean {
      return this.$route.query.tutorial == "1";
    },
    dayWidth: function (): number {
      return 100 / this.days.length;
    },

    rooms: function (): Room[] {
      const rooms = _.filter(this.$store.state.rooms.objects, (r) => {
        return r.belongsTo(this.hotel, "hotel");
      });

      return _.sortBy(rooms, (r) => {
        return parseInt(r.get("name") || 0) || 0;
      });
    },
    tables: function (): Table[] {
      const tables = _.filter(this.$store.state.tables.objects, (t) => {
        return (
          t.belongsTo(this.hotel, "hotel") && this.tableIsInSelectedTurn(t)
        );
      });
      return _.sortBy(tables, (t) => {
        const name = this.removeNotDigitChars(t.get("name"));
        return parseInt(name || "0") || 0;
      });

      //return _.orderBy(tables, t => _.toLower(t.get("name")));
    },
    days: function (): Moment[] {
      const start = moment(this.currentDate).startOf("month");
      const end = moment(this.currentDate).endOf("month");

      const days: Moment[] = [];
      for (
        let it = moment(start);
        moment(it).isSameOrBefore(end);
        it = moment(it).add(1, "day")
      ) {
        days.push(it);
      }
      return days;
    },
  },
  watch: {
    currentDate: function () {
      this.rendering = true;
      this.$nextTick(() => {
        this.rendering = false;
      });
    },
  },
});
