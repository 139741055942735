import { useCallback, useRef, useState } from "react";
import { nanoid } from "nanoid";
import Axios from "axios";
import Parse from "parse";
export type DishInputDTO = {
  dishId: string;
  quantity: number;
  annotation?: string;
};
export type OrderInputDTO = {
  calendarMealId: string;
  dishes: DishInputDTO[];
  bookId: string;
  allowEmptyOrder?: boolean;
};
export function useCreateOrder() {
  const [nonce, setNonce] = useState<string>(nanoid());
  const [loading, setIsLoading] = useState(false);
  const inFlightRef = useRef(false);

  const createOrder = useCallback(
    async (input: OrderInputDTO) => {
      if (inFlightRef.current) return;
      inFlightRef.current = true;
      setIsLoading(true);
      try {
        await Axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/custom/orders`,
          {
            ...input,
            nonce,
          },
          {
            headers: {
              "x-parse-session-token": Parse.User.current()?.getSessionToken(),
            },
          }
        );
        setNonce(nanoid());
      } finally {
        inFlightRef.current = false;
        setIsLoading(false);
      }
    },
    [nonce]
  );
  return {
    loading,
    createOrder,
  };
}
