
import Vue from "vue";
import mixins from "vue-typed-mixins";
import { Hotel, OutOfMealSlot } from "@/jpasta-sdk";
export default mixins().extend({
  methods: {
    getCutoff() {
      return this.hotel.getExtraMealOrdersCutoff();
    },
    setCutoff(v: number) {
      const hotel = this.hotel;
      hotel.setExtraMealOrdersCutoff(v);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    getEndDateStart(slot: OutOfMealSlot) {
      if (!slot.startTime) return "00:00";
      return slot.startTime;
    },
    getStartDateEnd(slot: OutOfMealSlot) {
      if (!slot.endTime) return "23:59";
      return slot.endTime;
    },
    deleteSlot(slot: OutOfMealSlot) {
      if (!slot.id) return;
      this.hotel.removeOutOfMealSlot(slot.id);

      this.$store.dispatch("hotels/saveObj", this.hotel);
    },
    newSlot() {
      const newSlot = {};

      this.hotel.addOutOfMealSlots(newSlot);
      this.$store.dispatch("hotels/saveObj", this.hotel);
    },
    setSlotStartTime(slot: OutOfMealSlot, time: string) {
      slot.startTime = time;
      this.hotel.editOutOfMealSlot(slot);
      this.$store.dispatch("hotels/saveObj", this.hotel);
    },
    setSlotEndTime(slot: OutOfMealSlot, time: string) {
      slot.endTime = time;
      this.hotel.editOutOfMealSlot(slot);
      this.$store.dispatch("hotels/saveObj", this.hotel);
    },
    toggleCanOrderOutOfMeal(val: 1 | 0) {
      this.hotel.setOutOfMealOrders(!!val);
      this.$store.dispatch("hotels/saveObj", this.hotel);
    },
  },
  computed: {
    slots: function (): OutOfMealSlot[] {
      return this.hotel.canOrderOutOfMeal()
        ? this.hotel.getOutOfMealSlots()
        : [];
    },
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
  },
});
