import Parse from "parse";
import Jpasta from "./Jpasta";

export default class DishImage extends Jpasta {
  constructor() {
    super("DishImage");
  }
}

Parse.Object.registerSubclass("DishImage", DishImage);
