<template>
  <JLayout>
    <dish-list
      :unlockFilter="unlockDishFilter"
      :hasRightMenu="true"
      :courseFilters="courseFilters"
      :filterByTypes="['dish']"
    ></dish-list>
    <div slot="rightMenu">
      <el-form ref="form" label-position="top" label-width="120px">
        <el-form-item>
          <h4 slot="label">Filtra per portata</h4>
          <el-row :gutter="16">
            <el-col
              v-for="course in JPASTA_COURSES"
              :key="course.id"
              :span="24"
            >
              <el-checkbox
                :value="courseFilters[course.id]"
                v-on:input="setCourseFilter(course.id, $event)"
                >{{ course.name.it }}</el-checkbox
              >
            </el-col>
            <el-col :span="24">
              <el-checkbox
                :value="courseFilters['noCourse']"
                v-on:input="setCourseFilter('noCourse', $event)"
                >Piatti senza portata</el-checkbox
              >
            </el-col>
            <el-col :span="24">
              <el-checkbox
                :value="getAllCourseFilterValue()"
                v-on:input="setAllCourseFilterValue"
                >Tutte</el-checkbox
              >
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>
  </JLayout>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import Parse from "parse";
import advices from "../advices";
import {
  getLanguagesByCode,
  JPASTA_LANGUAGES,
  JPASTA_MEALS,
  JPASTA_COURSES,
  JPASTA_ALLERGENS,
  Dish,
  Label,
  User,
  CELERY_ALLERGEN,
  CRUSTACEANS_ALLERGEN,
  DAIRY_PRODUCTS_ALLERGEN,
  EGG_ALLERGEN,
  GLUTEN_ALLERGEN,
  LUPINS_ALLERGEN,
  MOLLUSKS_ALLERGEN,
  MUSTARD_ALLERGEN,
  NUTS_ALLERGEN,
  PEANUTS_ALLERGEN,
  SESAME_GRAINS_ALLERGEN,
  findJCourseByLocalId,
  findJMealByLocalId,
  HOTEL_RATES,
  DRINK,
} from "@/jpasta-sdk";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: [],
  data: function () {
    return {
      courseFilters: _.reduce(
        [
          ...JPASTA_COURSES,
          {
            id: "noCourse",
          },
        ],
        (res, c) => {
          res[c.id] = true;
          return res;
        },
        {}
      ),
      drinkCourseIndex: DRINK,
      activeName: "first",
      searchableSelect: {},
      advices: advices,
      JPASTA_COURSES: JPASTA_COURSES,
      sideMenuOpened: false,
      searchDishString: "",
      selected: null,
      section: "dishes",
      maxDishes: 5,
      languages: JPASTA_LANGUAGES,
      filters: {
        showDishImage: true,
        labelFilter: {
          noLabels: true,
        },
        courseFilter: {
          noCourses: true,
        },
      },
      rates: HOTEL_RATES,
      jmeals: JPASTA_MEALS,
    };
  },
  mounted() {},

  beforeDestroy: function () {},
  methods: {
    setCourseFilter: function (courseId, val) {
      this.courseFilters = {
        ...this.courseFilters,
        [courseId]: val,
      };
    },
    getAllCourseFilterValue: function () {
      return _.reduce(
        this.courseFilters,
        (res, cf) => {
          if (!cf) res = false;
          return res;
        },
        true
      );
    },
    setAllCourseFilterValue: function (val) {
      _.forEach(this.courseFilters, (cf, cfIndex) => {
        this.courseFilters[cfIndex] = val;
      });

      this.courseFilters = {
        ...this.courseFilters,
      };
    },
    unlockDishFilter: function (filter) {
      this.setAllCourseFilterValue(true);
    },
    setMealIncludedOptionForDish: function (dish, rateIndex, mealIndex, val) {
      let dishIncludedOptions = dish.get("bookIncludedOptions") || {};
      dishIncludedOptions[rateIndex] = dishIncludedOptions[rateIndex] || {};

      dishIncludedOptions[rateIndex][mealIndex] = val;

      dish.set("bookIncludedOptions", dishIncludedOptions);

      this.$store.dispatch("dishes/saveObj", dish);
    },
    getMealIncludedOptionForDish: function (dish, rateIndex, mealIndex) {
      return dish.isIncluded(rateIndex, mealIndex);
    },
    getAllergenTitle: function (allergen) {
      let all = _.find(JPASTA_ALLERGENS, (a) => {
        return a.id === allergen;
      });

      if (all) return all.name.it;
    },
    getAllergenIcon: function (allergen) {
      let all = _.find(JPASTA_ALLERGENS, (a) => {
        return a.id === allergen;
      });

      if (all) return all.icon;
    },
    closeUnfocusedPopover: function (event) {
      try {
        var refs = [
          {
            popover: "course-search-popover",
            trigger: "course-badge",
            controlKey: "dish-search",
          },
          {
            popover: "allergen-search-popover",
            trigger: "allergen-edit",
            controlKey: "allergen-search",
          },
        ];
        _.forEach(refs, (ref) => {
          if (Array.isArray(this.$refs[ref.popover])) {
            //Siamo in un v-for
            _.forEach(this.$refs[ref.popover], (popover, index) => {
              var trigger = this.$refs[ref.trigger][index];

              if (
                !popover.$refs.popper.contains(event.target) &&
                !trigger.contains(event.target)
              )
                this.searchableSelect[`${ref.controlKey}-${index}`] = false;
            });
          } else {
            var popover = this.$refs[ref.popover];
            var trigger = this.$refs[ref.trigger];
            if (
              !popover.$refs.popper.contains(event.target) &&
              !trigger.contains(event.target)
            )
              this.searchableSelect[ref.controlKey] = false;
          }
        });
      } catch (e) {
        console.log(e);
      }
    },

    openAllergenPopover: function (dishIndex) {
      this.searchableSelect["allergen-search-" + dishIndex] = true;
    },

    openCoursePopover: function (dishIndex) {
      this.searchableSelect["dish-search-" + dishIndex] = true;
    },
    /* getMissingLabelsOf ritorna la lista delle etichette che non appartengono a dish */
    getMissingLabelsOf: function (dish) {
      return dish
        ? _.filter(
            _.map(this.hotelLabels, (label, index) => {
              return {
                label: label.get("name") ? label.get("name").it : "",
                index: label.get("localId"),
              };
            }),
            (label) => {
              return !_.find(dish.get("labels"), (dishLabel) => {
                return label.index === dishLabel.get("localId");
              });
            }
          )
        : [];
    },
    canAddLabel: function (dish) {
      return (
        this.hotelLabels.length >
        _.filter(dish.get("labels"), (label) => {
          return this.$store.state.labels.objects[label.get("localId")];
        }).length
      );
    },
    getLabelsOf: function (dish) {
      return this.$store.getters["labels/getLabelsOfDish"](dish);
    },
    openEditLabelModal: function (label) {
      this.$refs.newLabelModal.open({
        type: "edit",
        labelIndex: label.get("localId"),
      });
    },
    deleteLabel: function (label) {
      this.$store.dispatch("labels/deleteObj", label);
    },
    saveLabel: function (label, data) {
      _.forEach(data, (value, key) => {
        label.set(key, value);
      });
      return this.$store.dispatch("labels/saveObjSync", label);
    },
    newLabel: async function (data) {
      var label = new Label();
      _.forEach(data, (value, key) => {
        label.set(key, value);
      });
      label.set("hotel", this.hotel);

      await this.$store.dispatch("labels/saveObjSync", label);
    },
    closeNewLabelModal: function () {
      this.$refs.newLabelModal.close();
    },
    openNewLabelModal: function () {
      this.$refs.newLabelModal.open({
        type: "new",
      });
    },
    deleteDishLabel: function (dish, labelIndex) {
      var label = this.$store.state.labels.objects[labelIndex];
      dish.remove("labels", label);
      this.$store.dispatch("dishes/saveObj", dish);
    },

    labelSelected: function (label) {
      var labelIndex = label.index;

      var label = this.$store.state.labels.objects[labelIndex];
      var dish =
        this.$store.state.dishes.objects[this.$route.query.dishForLabel] ||
        null;

      if (!dish || !label) return;
      dish.add("labels", label);

      if (!this.filters.labelFilter[labelIndex])
        this.toggleFilter("labelFilter", labelIndex);

      this.$store.dispatch("dishes/saveObj", dish);

      this.$router
        .push({
          ...this.$route,
          query: {},
        })
        .catch((e) => {});
    },
    toAddLabel: function (dish) {
      this.$router
        .push({
          ...this.$route,
          query: {
            dishForLabel: dish.get("localId"),
          },
        })
        .catch((e) => {});

      this.$refs.selectLabel.open();
    },
    toggleFilter: function (filterName, objIndex) {
      if (objIndex === undefined) {
        this.filters[filterName] = !this.filters[filterName];
      } else {
        this.filters[filterName][objIndex] =
          !this.filters[filterName][objIndex];
      }

      this.filters = _.cloneDeep(this.filters);
    },
    getDishOfTemplate: function (template) {
      return this.$store.getters["dishes/getFilteredDishesOfHotelOrTemplate"](
        template
      );
    },
    checkToOpenModal: function (route) {
      if (route.query.dish) {
        this.$refs.dishModal.open();
      } else {
        this.$refs.dishModal.close();
      }
    },
    removeTemplate: function (template) {
      this.$store.dispatch("startConfigTemplate");

      setTimeout(async () => {
        await this.$store.dispatch("templates/removeTemplateFromHotel", {
          template: template,
          hotel: this.hotel,
        });

        this.$store.dispatch("stopConfigTemplate");
      }, 1500);
    },
    isAlreadyAdded: function (template) {
      return _.find(this.hotel.get("addedTemplates"), (hotelTemplate) => {
        return (
          (template.id && hotelTemplate.id === template.id) ||
          template.get("localId") === hotelTemplate.get("localId")
        );
      });
    },
    getCourseColorForBadge: function (dish) {
      var course =
        dish.get("courseIndex") !== undefined
          ? findJCourseByLocalId(dish.get("courseIndex"))
          : null;
      return course ? course.color.hex : null;
    },
    getCourseNameForBadge: function (dish) {
      var course =
        dish.get("courseIndex") !== undefined
          ? findJCourseByLocalId(dish.get("courseIndex"))
          : null;
      return course ? course.name.it : "Nessuna portata";
    },

    addTemplate: function (template) {
      this.$store.dispatch("startConfigTemplate");
      this.$store.commit("templates/setLoadingConfigTextIndex", 0);

      setTimeout(async () => {
        await this.$store.dispatch("templates/addTemplateToHotel", {
          template: template,
          hotel: this.hotel,
        });

        this.$store.dispatch("stopConfigTemplate");
      }, 1500);
    },

    toTemplateView: function (template) {
      this.$router.push({
        name: "TemplateView",
        params: {
          hotelid: this.hotel.get("localId"),
          templateid: template.get("localId"),
        },
      });
    },

    onCheckInputCourseFilter: function (courseLocalId, checked) {
      if (checked) {
        this.addInCourseFilter(courseLocalId);
      } else {
        this.removeFromCourseFilter(courseLocalId);
      }
    },

    addInCourseFilter: function (courseIndex) {
      Vue.set(this.courseFilter, courseIndex, true);
    },

    removeFromCourseFilter: function (courseIndex) {
      Vue.set(this.courseFilter, courseIndex, false);
    },

    findInCourseFilter: function (courseIndex) {
      if (courseIndex === undefined) return false;
      return this.courseFilter[courseIndex];
    },
    openDishesSection: function () {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          section: "dishes",
        },
      });

      this.section = "dishes";
    },

    dishInfiniteHandler: function ($state) {
      this.maxDishes = this.maxDishes + 10;
      $state.loaded();
    },
    selectDishBelow: function (dishIndex) {
      if (dishIndex < this.dishes.length - 1) {
        this.focusInput(
          "dish-name-input-" + this.dishesToRender[dishIndex + 1].get("localId")
        );
      }
    },

    selectDishAbove: function (dishIndex) {
      if (dishIndex > 0) {
        this.focusInput(
          "dish-name-input-" + this.dishesToRender[dishIndex - 1].get("localId")
        );
      }
    },

    focusInput: function (input) {
      this.$refs[input][0].focus();
    },

    deleteDishImage: function (image) {
      this.$store.dispatch("dishImages/deleteObj", image);
    },

    allergenSelected: function (allergen, dishLocalId, dishIndex) {
      if (!dishLocalId) return;

      let dish = this.$store.state.dishes.objects[dishLocalId];

      let allergens = dish.get("allergens") || [];

      let allergenIndex = _.findIndex(allergens, (allergenId) => {
        return allergen.localId == allergenId;
      });

      if (allergenIndex !== -1) {
        allergens.splice(allergenIndex, 1);
      } else {
        allergens.push(allergen.localId);
      }
      dish.set("allergens", [...allergens]);
      this.$store.dispatch("dishes/saveObj", dish);
    },

    courseSelected: function (course, dishLocalId, dishIndex) {
      if (!dishLocalId) return;

      var dish = this.$store.state.dishes.objects[dishLocalId];

      if (!this.filters.courseFilter[course.localId])
        this.toggleFilter("courseFilter", course.localId);

      dish.set("courseIndex", course.localId);

      this.$store.dispatch("dishes/saveObj", dish);

      this.searchableSelect[`dish-search-${dishIndex}`] = false;
    },

    editDishCourse: function (dish) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          currDish: dish.get("localId"),
        },
      });

      this.$refs.selectCourseModal.open();
    },

    handleDropdownCommand: function (dish, command) {
      switch (command) {
        case "delete":
          this.deleteDish(dish);
          break;
        case "editIncludedBooks":
          this.$router.push({
            name: this.$route.name,
            query: {
              dish: dish.get("localId"),
              editBookType: 1,
            },
          });
          break;
        case "editLanguages":
          this.$router.push({
            name: this.$route.name,
            query: {
              dish: dish.get("localId"),
              editLang: 1,
            },
          });
          break;
        // case "editDishCourse":
        //   this.editDishCourse(dish);

        //   break;
      }
    },

    changeDishLanguage: function (dish, languageObject) {
      dish.set("name", {
        ...dish.get("name"),
        ...languageObject,
      });
      this.$store.dispatch("dishes/saveObj", dish);
    },
    setDishProperty: function (propertyName, dish, value) {
      dish.set(propertyName, value);
      this.$store.dispatch("dishes/saveObj", dish);
    },
    addFileToDish: function (dish, file) {
      this.$store
        .dispatch("dishes/attachImage", {
          dish: dish,
          file: file,
        })
        .catch((msg) => {
          return this.$msg(msg);
        });
    },

    imageDropped: function (dish) {
      if (!event.dataTransfer.files.length) return;

      this.addFileToDish(dish, event.dataTransfer.files[0]);
    },
    browseImage: function (dish, dishIndex) {
      var fileInput = this.$refs["dish-input-" + dishIndex][0];
      fileInput.onchange = (e) => {
        if (fileInput.files.length) {
          this.addFileToDish(dish, fileInput.files[0]);
          fileInput.value = null;
        }
      };
      fileInput.click();
    },
    newDish: function () {
      this.searchDishString = "";
      var dish = new Dish();
      dish.set("hotel", this.hotel);
      this.filters.courseFilter["noCourses"] = true;
      this.$store.dispatch("dishes/saveObj", dish).then((dish) => {
        this.$nextTick(() => {
          if (
            this.$refs["dish-name-input-" + dish.get("localId")] &&
            this.$refs["dish-name-input-" + dish.get("localId")].length
          )
            this.$refs["dish-name-input-" + dish.get("localId")][0].focus();
        });
      });
    },
    deleteDish: function (dish) {
      dish.set("deleted", true);
      this.$store.dispatch("dishes/saveObj", dish);
    },
  },
  computed: {
    isTutorial: function () {
      return this.$route.query.tutorial == "1";
    },
    dishesImages: function () {
      return _.reduce(
        this.dishesToRender,
        (images, dish) => {
          let time1 = moment();

          images[dish.get("localId")] =
            this.$store.getters["dishImages/getImagesOf"](dish);
          return images;
        },
        {}
      );
    },
    hotelLabels: function () {
      return this.$store.getters["labels/labels"](this.hotel);
    },

    templates: function () {
      return _.filter(this.$store.state.templates.objects, (template) => {
        return template.get("isPublic") || this.isAlreadyAdded(template);
      });
    },
    dishesToRender: function () {
      return this.dishes.length > this.maxDishes
        ? this.dishes.slice(0, this.maxDishes - 1)
        : this.dishes;
    },
    selectedDish: function () {
      return this.$store.state.dishes.objects[this.$route.query.dish];
    },
    hotel: function () {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },

    /* Contiene la lista delle portate tra cui scegliere quella da assegnare a un piatto */
    coursesList: function () {
      return _.map(JPASTA_COURSES, (course) => {
        return {
          label: course.name.it,
          localId: course.id,
        };
      });
    },

    allergensList: function () {
      return _.map(JPASTA_ALLERGENS, (allergen) => {
        return {
          label: allergen.name.it,
          localId: allergen.id,
        };
      });
    },

    dishes: function () {
      return this.$store.getters["dishes/getFilteredDishesOfHotelOrTemplate"](
        this.hotel,
        this.filters,
        this.searchDishString
      );
    },
  },
  watch: {
    // filters: {
    //   deep: true,
    //   handler: function(newFilter) {
    //     var user = this.$store.state.users.current;
    //     user.set("viewFilter", newFilter);
    //     this.$store.dispatch("users/saveObj", user);
    //   }
    // },
    // $route: function(newRoute) {
    //   this.checkToOpenModal(newRoute);
    // }
  },
};
</script>

<style lang="scss">
.j-dish-tabs {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .el-tabs__content {
    display: flex;
  }
}

.dish-header-name {
  .title {
    .jinput {
      height: auto;
    }
  }
}

.hotel-input.price-input {
  .jinput {
    height: auto;
    // padding-top: 8px;
    // padding-bottom: 8px;
    // margin-left: 5px;
    font-weight: normal;
  }
}

.price-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  .symbol {
    flex-shrink: 0;
    opacity: 0.7;
    flex-basis: 30%;
    .price-label {
      display: flex;
      width: 100%;
      align-items: center;
      min-height: 35px;
      background-color: #cccccc;
      padding: 0 10px;
    }
    display: flex;
    font-size: 0.9em;
    align-items: center;
    .icon-info {
      margin-left: 3px;
      font-size: 0.88em;
    }
  }
}
</style>

<style lang="scss" scoped>
.rate-wrapper {
  .rate-title {
    font-weight: bold;
  }

  .rate-meal-container {
    margin-top: 5px;
    padding: 0 10px;
  }
}

.allrgen-wrapper {
  display: flex;
  width: 100%;
  padding: 0 20px;
}

.dish-labels {
  display: flex;
  align-items: center;
}
.filter-wrapper {
  margin-top: 1em;

  .filter-item {
    font-size: 0.9em;
    display: flex;
    align-items: center;
  }
}

.template-menu-header {
  display: flex;
  align-items: center;
}
.template-wrapper {
  border: 1px solid lighten(#cccccc, 4%);
  overflow: hidden;
  margin-bottom: 0.5em;

  border-radius: 4px;

  .template-header {
    padding: 8px;
    background-color: rgba(240, 241, 241, 1);
  }

  .template-body {
    padding: 7px;
    .template-image {
      height: 150px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 4px;
    }
  }

  .template-button {
    padding: 8px;
    min-width: auto;
    min-height: auto;
    border-radius: 4px;
  }

  .template-footer {
    padding: 8px;
    background-color: rgba(240, 241, 241, 1);

    display: flex;
    align-items: center;
  }
}

.side-menu-title {
  font-size: 0.95em;
  font-weight: normal;
}

.entity-title {
  padding: 0 10px;
}

.entity-container {
  overflow: auto;
  padding: 0 10px;
  border-top: 1px solid lighten(#cccccc, 13%);
  margin-top: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.side-menu-wrapper {
  background-color: darken(rgba(252, 253, 253, 1), 0%);
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.15);

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid lighten(#ccc, 15%);
  }

  .settings-input {
    display: flex;
    align-items: center;

    .label {
      font-size: 0.95em;
      opacity: 0.8;
    }

    .jcheckbox {
      margin-left: auto;
    }
  }

  .side-menu-body {
    .side-menu-course-filter {
      display: flex;
      flex-direction: column;

      .filter-title {
        width: 100%;
        text-align: center;
      }
    }

    margin-top: 0.5em;
  }
  .side-menu-header {
    display: flex;
    align-items: center;
  }
}

.outer-wrapper {
  flex-direction: column;
  display: flex;

  height: calc(100vh - 96px);
}

@media only screen and (max-width: 768px) {
  .entity-container {
    overflow: visible;
  }

  .side-menu-wrapper {
    position: fixed;
  }
  .side-menu-hidden {
    display: none !important;
  }

  .outer-wrapper {
    height: 100%;
  }
}

.name-col {
  display: flex;
  align-items: center;

  span {
    margin: 0 0.5em;
  }
}

.top-buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  padding: 0 10px;

  .select-section-button:first-child {
    margin-left: 0;
  }

  .select-section-button:last-child {
    margin-right: 0;
  }
  .select-section-button {
    cursor: pointer;
    /* min-height: 42px; */
    border: 2px solid #f4443f;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 0 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    span {
      color: #f4443f;
    }
  }

  .select-section-button.selected,
  .select-section-button:hover {
    /* background-color: #f4443f; */
    /* background-color: darken(white, 2%); */
    border: 2px solid darken(#f4443f, 20%);
    span {
      /* color: white; */
      color: darken(#f4443f, 20%);
    }
  }
}

.add-button-big {
  display: flex;
  border: 2px dotted #cccccc;
  padding: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  border-radius: 4px;
  cursor: pointer;

  span {
    opacity: 0.6;
  }
}

.add-button-big:hover {
  border: 2px dotted darken(#cccccc, 40%);
  span {
    opacity: 1;
  }
}

.modal-body {
  padding: 10px;
  width: 100%;
  .lang-input:not(:first-child) {
    margin-top: 0.6em;
  }
}

.input-field {
  display: flex;
  flex-direction: column;
  .label {
    font-size: 0.7em;
    padding-left: 10px;
    margin-bottom: 0.2em;
  }
}

.entity-wrapper.selected {
  border: 1px solid darken(#cccccc, 5%); /*#f4443f;*/
  /* background-color: rgba(244,68,63,0.2); */
  background-color: darken(white, 5%);

  .header {
    background-color: darken(rgb(250, 251, 251), 5%);
    /* background-color: rgba(244,68,63,0.3); */
  }
}

.entity-wrapper {
  border-radius: 4px;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  .header {
    border-bottom: 1px solid #cccccc;
    background-color: rgba(250, 251, 251, 1);
    padding: 3px 10px;
    padding-right: 20px;
    border-radius: 4px;
    width: 100%;
    flex-direction: column;

    .dish-course {
      font-size: 0.9em;
      margin-left: 10px;
      margin-bottom: 7px;
      padding: 2px 4px;
      margin-right: auto;
      border-radius: 4px;
      color: white;
      background-color: #4da0ff;
      cursor: pointer;
      .dish-course-name {
        text-decoration: underline;
      }
    }

    .dish-course.no-course {
      background-color: #f4443f !important;
      color: white !important;
    }

    .dish-header-name {
      display: flex;
      width: 100%;
      align-items: center;
    }

    .title {
      font-weight: bold;
      width: 100%;
    }
    .icon {
      cursor: pointer;
      width: 12px;
      height: 12px;
    }

    .icon:first-of-type {
      margin-left: auto;
    }
  }
}

.entity-wrapper:not(:first-child) {
  margin-top: 1em;
}

.uploading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border: 1px solid lighten(#cccccc, 10%);
  border-radius: 4px;
}

.img-wrapper {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .img-container {
    position: relative;

    margin-right: 0.5em;
    .cancel-wrapper {
      position: absolute;

      padding: 0px 2px;
      background-color: rgba(255, 255, 255, 0.8);
      border-bottom-left-radius: 8px;
      border-top-right-radius: 4px;
      right: 0;
      font-size: 1.1em;
      cursor: pointer;

      .cross {
        opacity: 0.6;

        z-index: 100;
      }
    }
  }

  .dish-image.no-image {
    padding: 0 10px;

    font-size: 0.9em;
    height: 100px;
    width: 100px;
    border: 2px dashed #cccccc;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.3);
    text-align: center;
  }

  .dish-image.image {
    height: 100px;
    width: 100px;
    cursor: pointer;
    border-radius: 6px;
  }
}
</style>
