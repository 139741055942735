
import Vue from "vue";
import { User, Hotel } from "@/jpasta-sdk";
import mixins from "vue-typed-mixins";
import VisibleAreaHeightMixin from "@/mixins/VisibleAreaHeightMixin";
import Cookies from "js-cookie";
import Parse from "parse";
import _ from "lodash";
export default mixins(VisibleAreaHeightMixin).extend({
  mounted: async function () {
    await this.$store.dispatch("users/spottyLogin");
    await this.$store.dispatch("downSyncAll");
    const user: User = this.$store.state.users.current;

    if (!user) {
      return;
    }

    const firstHotel: Hotel | undefined = _.first(
      _.values(this.$store.state.hotels.objects)
    );

    if (!firstHotel || user.get("tutorialCompleted")) {
      this.$router
        .push({
          name: "Hotels",
        })
        .catch((e) => {
          console.log("e", e);
        });
    } else {
      this.$router
        .push({
          name: "SpottyWelcome",
        })
        .catch((e) => {
          console.log("e", e);
        });
    }
  },
});
