<template>
  <JGantt></JGantt>
</template>

<script>
import JGantt from "./JGantt";
export default {
  components: {
    JGantt,
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss"></style>
