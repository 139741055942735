<template>
  <!-- <div class="rightMenuWrapper" :class="{
      menuSticked: $store.state.sticked
  }">-->
  <div ref="menuWrapper" class="rightMenu">
    <div
      class="rightMenuBody"
      :class="{
        withFooter: $slots.footer,
        menuSticked: $store.state.sticked,
      }"
    >
      <slot></slot>
    </div>
    <div class="rightMenuFooter">
      <slot name="footer"></slot>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import _ from "lodash";
export default {
  mounted() {
    window.addEventListener("resize", this.resize);
    this.adjustWidth();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    adjustWidth() {
      if (!this.$refs.menuWrapper) return;
      this.$refs.menuWrapper.style.width =
        this.$refs.menuWrapper.parentElement.clientWidth + "px";
    },
    resize() {
      _.debounce(() => {
        this.adjustWidth();
      }, 500)();
    },
  },
};
</script>

<style lang="scss" scoped>
$footerHeight: 64px;

.rightMenu {
  background-color: white;
  transition: top 0.15s ease 0s;
  position: fixed;
  top: 64px;

  right: 0px;
  bottom: 0px;
  width: inherit;
  padding: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  .rightMenuBody {
    height: 100%;
    &.withFooter {
      height: calc(100% - 64px);
    }
  }

  .rightMenuFooter {
    height: 64px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 10px;
  }

  &.menuSticked {
    .rightMenu {
      top: 116px;
    }
  }
}
</style>

<style lang="scss"></style>
