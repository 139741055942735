import generalMutations from "./generalMutations";
import generalActions from "./generalActions";
import Parse from "parse";
import _ from "lodash";
import { Jpasta } from "@/jpasta-sdk";
export default {
  namespaced: true,
  state: {
    objects: {},
    usersRelation: {},
    className: "_Role",
  },

  mutations: {
    ...generalMutations,
    addUserRelation(state, { roleName, users }) {
      state.usersRelation[roleName] = users;
    },
  },
  actions: {
    ...generalActions,
    async getRoles({ commit, dispatch, state }, { params, includes }) {
      await dispatch("getObjects", {
        params,
        includes,
      });

      for (var roleName in state.objects) {
        var role = state.objects[roleName];
        var users = await Jpasta.execRecursiveFind({
          query: role.get("users").query(),
        });
        commit("addUserRelation", {
          roleName,
          users,
        });
      }
    },
  },
};
