<template>
  <div>
    <jinput
      :inputStyle="{
        'background-color': 'transparent',
      }"
      v-if="searchBar"
      ref="search-input"
      v-on:enterpressed="selectFirstItem"
      :value="inputValue"
      :search="true"
      :noBorder="true"
      v-on:input="onInput"
      type="text"
      class="hotel-input meal-name-input detail-text meal-entity-input"
      :placeholder="placeholder"
    ></jinput>
    <div v-if="searchBar" class="search-divider"></div>
    <ul v-if="itemsToRender.length" class="items-list">
      <li
        class="list-item"
        :class="{
          selected: isSelected(item),
        }"
        v-on:click.prevent.stop="itemClicked(item)"
        :key="index"
        v-for="(item, index) in itemsToRender"
      >
        <slot>
          <div class="list-item-wrapper">
            <div class="title-sub-wrapper">
              <span>{{ label ? item[label] : item.label }}</span>
              <span class="sub-title" v-if="subTitle">{{
                getSubTitle(item)
              }}</span>
            </div>
            <span class="icon-checked"></span>
          </div>
        </slot>
      </li>
    </ul>
    <div
      :class="{
        clickable: noResultAction,
      }"
      v-else
      class="no-result"
    >
      <span
        v-html="noResultText ? noResultText : 'Nessun risultato trovato'"
      ></span>
      <jbutton
        v-if="noResultAction"
        v-on:click.prevent.stop="$emit('noresclicked')"
        >{{ noResultButtonText ? noResultButtonText : "Aggiungi" }}</jbutton
      >
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    multiple: {},
    items: {},
    filterBy: {},
    subTitle: {},
    label: {},
    searchBar: {
      default: true,
    },
    placeholder: {},
    noResultAction: {},
    noResultText: {},
    noResultButtonText: {},
    selected: {},
    idKey: {
      default: "localId",
    },
  },
  data: function () {
    return {
      inputValue: "",
    };
  },

  mounted() {},

  methods: {
    isSelected: function (item) {
      if (this.multiple) {
        return (
          _.find(this.selected, (sel) => {
            return item.localId == sel;
          }) !== undefined
        );
      } else {
        return this.selected && item[this.idKey] === this.selected[this.idKey];
      }
    },
    focus: function () {
      if (this.$mq !== "xs")
        this.$nextTick(() => {
          if (this.$refs["search-input"]) this.$refs["search-input"].focus();
        });
    },
    selectFirstItem: function () {
      if (this.itemsToRender.length) this.itemClicked(this.itemsToRender[0]);
    },

    itemClicked: function (item) {
      this.$emit("itemclicked", item);
    },
    onInput: function (filterText) {
      this.inputValue = filterText;
      this.$emit("input", filterText);
    },
    getSubTitle: function (item) {
      try {
        var keys = this.subTitle.split(".");
        var res = item;
        for (var key of keys) {
          res = res[key];
        }
        return res;
      } catch (e) {
        return "";
      }
    },
  },
  computed: {
    itemsToRender: function () {
      return _.filter(this.items, (item) => {
        var filter = this.filterBy ? this.filterBy : "label";

        if (this.subTitle) {
          var subTitle = this.getSubTitle(item);

          var subTitleMatch =
            subTitle.length &&
            subTitle.toLowerCase().indexOf(this.inputValue.toLowerCase()) != -1;
        } else {
          var subTitleMatch = false;
        }

        return (
          item[filter] &&
          item[filter].length &&
          ((item[filter] &&
            item[filter].length &&
            item[filter].toLowerCase().indexOf(this.inputValue.toLowerCase()) !=
              -1) ||
            subTitleMatch)
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";

.list-item {
  .list-item-wrapper {
    display: flex;
    align-items: center;

    .icon-checked {
      margin-left: auto;
      font-size: 12px;
      opacity: 1;
      color: map-get($colors, "primary");
    }
  }

  &:not(.selected) {
    .list-item-wrapper {
      .icon-checked {
        display: none;
      }
    }
  }

  .sub-title {
    font-size: 0.9em;
    opacity: 0.7;
  }

  .title-sub-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.search-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid lighten(map-get($colors, "grey"), 15%);
  width: 100%;
  margin-bottom: 1em;
}

ul.items-list {
  list-style-type: none;
  padding: 0 0px;
  width: 100%;
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
  li {
    padding: 8px 10px;
    cursor: pointer;
  }

  li:hover {
    border-radius: 4px;
    background-color: map-get($colors, "primary");
    color: white;

    .list-item-wrapper {
      .icon-checked {
        color: white;
      }
    }
  }
}

.no-result {
  padding: 10px;
  width: 100%;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  & > button {
    margin-top: 5px;
  }
}
</style>
