import { Dictionary } from "lodash";
import _ from "lodash";
import tables from "@/store/tables";
type Column<COLV> = {
  id: string;
  value: COLV;
};

type InternalColumn<COLV> = {
  id: string;
  value: COLV;
  index: number;
};

type RowCell<CELLV> = {
  id: string;
  columnId: string;
  value?: CELLV;
};

type Row<ROWV, CELLV> = {
  value?: ROWV;
  id: string;
  cells: RowCell<CELLV>[];
};

type Table<ROWV, COLV, CELLV> = {
  tableIndex: number;
  columns: InternalColumn<COLV>[];
  rows: Row<ROWV, CELLV>[];
};

export default class TableBuilder<ROWV, COLV, CELLV> {
  private maxColumns: number;
  private currentTable = -1;
  private columnTableMap: Dictionary<number> = {};
  private rowTableMap: Dictionary<Dictionary<number>> = {};
  private columnIndexMap: Dictionary<number> = {};
  constructor(maxColumns: number) {
    this.maxColumns = maxColumns;
    this.pushNewTable();
  }

  private tables: Table<ROWV, COLV, CELLV>[] = [];

  /** PRIVATE METHODS **/

  private newRow(
    rowId: string,
    table: Table<ROWV, COLV, CELLV>,
    value?: ROWV,
    initCells?: boolean
  ) {
    const row: Row<ROWV, CELLV> = {
      id: rowId,
      cells: [],
      value: value,
    };
    if (!initCells) return row;
    _.forEach(table.columns, (c) => {
      row.cells.push({
        id: this.getCellId(c.id, rowId),
        value: undefined,
        columnId: c.id,
      });
    });

    return row;
  }

  private addRow(rowId: string, table: Table<ROWV, COLV, CELLV>, value?: ROWV) {
    const row = this.newRow(rowId, table, value, true);

    table.rows.push(row);
    return row;
  }

  private getTableByColumnId(columnId?: string) {
    if (!columnId) return undefined;
    const tableIndex = this.columnTableMap[columnId];
    return this.tables[tableIndex] || undefined;
  }

  private getColumn(columnId: string) {
    const tableIndex = this.columnTableMap[columnId];
    if (tableIndex === undefined) return undefined;
    const table = this.tables[tableIndex];
    const columnIndex = this.columnIndexMap[columnId];
    if (columnIndex === undefined) return undefined;
    return table.columns[columnIndex];
    //return _.find(table.columns, c => c.id === columnId);
  }

  private saveRowIndex(tableIndex: number, rowId: string, rowIndex: number) {
    this.rowTableMap[tableIndex] = this.rowTableMap[tableIndex] || {};
    this.rowTableMap[tableIndex][rowId] = rowIndex;
  }

  private getRowIndex(tableIndex: number, rowId: string): number | undefined {
    if (!this.rowTableMap[tableIndex]) return undefined;

    return this.rowTableMap[tableIndex][rowId];
  }

  private getRow(tableIndex: number, rowId: string) {
    const rowIndex = this.getRowIndex(tableIndex, rowId);
    if (rowIndex === undefined) return undefined;
    const table = this.tables[tableIndex];
    if (!table) return undefined;
    return table.rows[rowIndex];
  }

  private getCurrentTable() {
    return this.tables[this.currentTable];
  }

  private pushNewTable() {
    this.tables.push({
      columns: [],
      rows: [],
      tableIndex: this.currentTable + 1,
    });
    this.currentTable = this.tables.length - 1;
    return this.getCurrentTable();
  }

  private getCellId(columnId: string, rowId: string) {
    return `${columnId}-${rowId}`;
  }

  public addColumn(column: Column<COLV>) {
    let table = this.getCurrentTable();
    if (table.columns.length >= this.maxColumns) {
      table = this.pushNewTable();
    }
    this.columnTableMap[column.id] = this.currentTable;

    table.columns.push({
      value: column.value,
      id: column.id,
      index: table.columns.length,
    });

    this.columnIndexMap[column.id] = table.columns.length - 1;

    _.forEach(table.rows, (row) => {
      row.cells.push({
        id: this.getCellId(column.id, row.id),
        value: undefined,
        columnId: column.id,
      });
    });
  }

  /** PUBLIC METHODS **/

  public getTables() {
    return _.cloneDeep(this.tables);
  }

  public getCellValue(columnId: string, rowId: string): CELLV | undefined {
    const tableIndex = this.columnTableMap[columnId];
    const column = this.getColumn(columnId);
    if (!column) return undefined;
    //const table = this.tables[tableIndex];
    const row = this.getRow(tableIndex, rowId);
    // const row = _.find(table.rows, r => r.id === rowId);
    if (!row) return undefined;
    const rowCell = row.cells[column.index];
    if (!rowCell) return undefined;
    return rowCell.value;
  }

  public addRowAfter(
    existingRowId: string,
    newRowId: string,
    columnId?: string,
    newRowVal?: ROWV,
    initCells?: boolean
  ) {
    const table = this.getTableByColumnId(columnId);
    if (!table && columnId) return;
    const tables = table ? [table] : this.tables;
    _.forEach(tables, (t) => {
      const rowIndex = this.getRowIndex(t.tableIndex, existingRowId);
      if (rowIndex === undefined) return;
      const newRow = this.newRow(newRowId, t, newRowVal, initCells);
      t.rows.splice(rowIndex + 1, 0, newRow);
    });
  }

  public setCellValue(
    columnId: string,
    rowId: string,
    value: CELLV,
    rowValue?: ROWV
  ) {
    const column = this.getColumn(columnId);
    if (!column) return false;
    const tableIndex = this.columnTableMap[columnId];
    const table = this.tables[tableIndex];
    //const rowIndex = this.getRowIndex(tableIndex, rowId);
    let row = this.getRow(tableIndex, rowId);
    //let row = rowIndex !== undefined ? table.rows[rowIndex] : undefined;
    // let row = _.find(table.rows, r => r.id === rowId);
    if (!row) {
      row = this.addRow(rowId, table, rowValue);
      this.saveRowIndex(tableIndex, rowId, table.rows.length - 1);
    }

    const cell = row.cells[column.index];
    if (!cell) return false;
    cell.value = value;
    return true;
  }
}
