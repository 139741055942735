<template>
  <div>
    <!-- Modal traduzioni -->
    <jmodal :zindex="5002" :autoClose="true" ref="translationModal">
      <h4 slot="header" style="margin-right: 0.4em">Traduzioni</h4>
      <div class="modal-body">
        <div
          class="input-field lang-input"
          :key="index"
          v-for="(lang, index) in languages"
        >
          <span class="label">{{ lang.label }}</span>
          <jinput
            :inputStyle="{
              'background-color': 'transparent',
            }"
            style="margin-top: 0.15em"
            v-model="labelName[lang.code]"
            type="text"
            class="hotel-input"
            placeholder="Traduci"
          ></jinput>
        </div>
      </div>
      <div class="button-wrapper" slot="footer">
        <jbutton
          type="border"
          style
          v-on:click.prevent.stop="$refs.translationModal.close()"
          class="footer-button"
          >Fatto</jbutton
        >
      </div>
    </jmodal>
    <!-- Fine modal traduzioni -->
    <!-- Modal selezione icona -->
    <jmodal :zindex="5002" :autoClose="true" ref="labelIconModal">
      <h4 slot="header" style="margin-right: 0.4em">Icone</h4>
      <div class="modal-body">
        <div class="label-icon-wrapper">
          <div
            :key="iconIndex"
            v-on:click="selectIcon(iconIndex)"
            class="label-icon icon"
            v-html="icon.src"
            v-for="(icon, iconIndex) in labelsIcons"
          ></div>
        </div>
      </div>
      <div class="button-wrapper" slot="footer">
        <jbutton
          type="border"
          style
          v-on:click.prevent.stop="$refs.translationModal.close()"
          class="footer-button"
          >Fatto</jbutton
        >
      </div>
    </jmodal>
    <!-- Fine modal seleziona icona -->
    <jmodal
      :iconClose="true"
      :zindex="5001"
      v-on:close="onClose"
      :autoClose="false"
      ref="modal"
    >
      <div class="header-title" slot="header" style="margin-right: 0.4em">
        <h4>{{ selectedLabel ? "Modifica etichetta" : "Nuova etichetta" }}</h4>
      </div>
      <div class="body">
        <div class="newLabel">
          <div class="label-title-wrapper">
            <span
              v-if="selectedIconIndex || canAddIcon"
              :class="{
                icon: !!labelsIcons[selectedIconIndex],
              }"
              v-html="
                !!labelsIcons[selectedIconIndex]
                  ? labelsIcons[selectedIconIndex].src
                  : ''
              "
              class="label-icon"
              v-on:click="openIconModal"
            ></span>
            <jinput
              :inputStyle="{
                'background-color': 'transparent',
                'border-bottom': '1px solid #EAEDED !important',
                'border-radius': '0',
              }"
              style="flex: 1"
              :noBorder="true"
              ref="new-label-name-input"
              v-model="labelName.it"
              type="text"
              class="hotel-input meal-name-input detail-text meal-entity-input"
              placeholder="Nome etichetta"
            ></jinput>
          </div>
          <div class="colors-box">
            <span
              :key="colorIndex"
              v-on:click.prevent.stop="selectColor(colorIndex)"
              :class="{
                selected: selectedColorIndex === colorIndex,
              }"
              class="color-item"
              v-for="(color, colorIndex) in colors"
              :style="{
                'background-color': colors[colorIndex].hex,
              }"
            >
              <span class="circle"></span>
            </span>
          </div>
          <div class="can-see-in-app-wrapper">
            <jcheckbox
              :checked="visibleInApp"
              v-model="visibleInApp"
            ></jcheckbox>
            <span class="can-see-in-app-title">
              Visibile nell' applicazione
              <span class="sub-title"
                >Quando spunti questa opzione, questa etichetta sarà visibile
                anche ai tuoi clienti quando effettueranno gli ordini</span
              >
            </span>
          </div>
        </div>
      </div>
      <div class="button-wrapper" slot="footer">
        <jbutton
          :loading="loading"
          type="border"
          style
          v-on:click.prevent.stop="toTranslation"
          class="footer-button"
          >Configura traduzioni</jbutton
        >
        <jbutton
          :loading="loading"
          type="border"
          style
          v-on:click.prevent.stop="selectedLabel ? onSave() : onNew()"
          class="footer-button"
          >{{ selectedLabel ? "Salva" : "Crea" }}</jbutton
        >
      </div>
    </jmodal>
  </div>
</template>

<script>
import { JPASTA_LABEL_COLORS, JPASTA_LANGUAGES } from "@/jpasta-sdk";
import Vue from "vue";
import _ from "lodash";
export default {
  props: ["newLabel", "saveLabel", "labels", "deleteLabel", "canAddIcon"],
  data: function () {
    return {
      labelName: {},
      selectedColorIndex: 0,
      colors: JPASTA_LABEL_COLORS,
      loading: false,
      iLabels: this.labels,
      selectedLabel: null,
      languages: JPASTA_LANGUAGES,
      labelsIcons: [],
      selectedIconIndex: undefined,
      visibleInApp: false,
    };
  },
  mounted() {},
  methods: {
    openIconModal: function () {
      if (!this.canAddIcon) return;

      return this.$refs.labelIconModal.open();
    },
    selectIcon: function (iconIndex) {
      this.selectedIconIndex = iconIndex;
      this.$refs.labelIconModal.close();
    },
    toTranslation: function () {
      this.$refs.translationModal.open();
    },
    toEditLabel: function (labelIndex) {
      var label = _.find(this.iLabels, (label) => {
        return label.get("localId") === labelIndex;
      });
      this.labelName = label.get("name");
      this.selectedColorIndex = label.get("colorIndex");
      this.selectedIconIndex = label.get("iconIndex");
      this.visibleInApp = !!label.get("visibleInApp");
      this.selectedLabel = label;
      Vue.nextTick(() => {
        this.$refs["new-label-name-input"].focus();
      });
    },
    toNewLabel: function () {
      this.selectedLabel = null;
      this.selectedColorIndex = 0;
      this.labelName = {};
      this.selectedIconIndex = undefined;
      this.visibleInApp = false;
      Vue.nextTick(() => {
        this.$refs["new-label-name-input"].focus();
      });
    },
    selectColor: function (colorIndex) {
      this.selectedColorIndex = colorIndex;
    },
    open: function (action = {}) {
      this.$refs.modal.open();

      switch (action.type) {
        case "new":
          return this.toNewLabel();
        case "edit":
          return this.toEditLabel(action.labelIndex);
        default:
          return;
      }
    },
    close: function () {
      this.$refs.modal.close();
    },
    onClose: function () {
      this.$emit("close");
    },
    onSave: function () {
      if (!this.saveLabel || !this.selectedLabel) return;

      this.loading = true;
      this.saveLabel(this.selectedLabel, {
        name: { ...this.labelName },
        colorIndex: this.selectedColorIndex,
        iconIndex: this.selectedIconIndex,
        visibleInApp: this.visibleInApp,
      }).then(() => {
        this.loading = false;
      });
    },
    onNew: function () {
      if (!this.newLabel) return;

      this.loading = true;
      this.newLabel({
        name: { ...this.labelName },
        colorIndex: this.selectedColorIndex,
        iconIndex: this.selectedIconIndex,
        visibleInApp: this.visibleInApp,
      }).then(() => {
        this.loading = false;
        this.labelName = {};
        this.selectedColorIndex = 0;
      });
    },
  },
  computed: {},
  watch: {
    labels: function (newLabels) {
      this.iLabels = newLabels;
    },
  },
};
</script>

<style scoped lang="scss">
.can-see-in-app-wrapper {
  text-align: left;
  display: flex;
  align-items: center;
  border-top: 1px solid lighten(#cccccc, 12%);
  padding: 0.4em 0;
  margin-top: 0.7em;

  .can-see-in-app-title {
    display: flex;
    flex-direction: column;

    .sub-title {
      font-size: 0.9em;
      opacity: 0.7;
    }
  }

  .jcheckbox {
    margin-right: 0.6em;
  }
}

.label-icon-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.label-title-wrapper {
  display: flex;
  align-items: center;
}

.label-icon {
  border: 2px dashed #cccccc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.label-icon.icon {
  border: 0px;
}

.lang-input {
  margin-top: 0.5em;
}

.label {
  font-size: 0.95em;
  opacity: 0.8;
  margin-left: 10px;
}

.newLabel {
  text-align: center;
}
.label-item {
  padding: 2px 4px;
  margin: 0.1em;
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.labels-wrapper {
  min-width: 200px;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
}

.edit-labels {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button-wrapper {
  display: flex;
  width: 100%;
  .footer-button:first-of-type {
    margin-left: auto;
  }

  .footer-button:not(:first-of-type) {
    margin-left: 0.4em;
  }
}

.body {
  width: 90%;
}

.colors-box {
  margin-top: 0.7em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.color-item {
  margin: 0.2em;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    display: none;
  }
}

.color-item.selected {
  .circle {
    display: inline;
  }
}
</style>
