<template>
  <el-row :gutter="16">
    <el-form label-position="top">
      <el-col :span="24">
        <el-form-item
          :error="
            showRoomDuplicateName
              ? 'Questo nome è già stato utilizzato'
              : undefined
          "
          label="Nome camera"
        >
          <jinput
            :inputDelay="200"
            ref="roomNameInput"
            :value="room.get('name')"
            v-on:change="setRoomName"
            type="text"
            placeholder="es. 237"
          ></jinput>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="12">
        <el-form-item label="Tipologia">
          <RoomTypeSelect
            :key="
              room.get('type') && room.get('type').get('localId')
                ? room.get('type').get('localId')
                : null
            "
            :value="sanitizeRoomType(room)"
            v-on:selected="onTypeChange"
          ></RoomTypeSelect>
        </el-form-item>
      </el-col> -->
    </el-form>
  </el-row>
</template>

<script>
import RoomTypeSelect from "./RoomTypeSelect";
export default {
  components: {
    //RoomTypeSelect
  },
  data: function () {
    return { showRoomDuplicateName: false };
  },
  props: {
    version: {},
    room: {},
    hotel: {},
  },
  mounted() {
    if (this.$refs.roomNameInput) this.$refs.roomNameInput.focus();
  },
  updated: function () {},
  methods: {
    setRoomProperty(propertyName, value) {
      const room = this.room;

      room.set(propertyName, value);

      this.$store.dispatch("rooms/saveObj", room);
    },
    setRoomName: function (name) {
      this.showRoomDuplicateName = false;

      if (
        _.find(this.rooms, (r) => {
          return r.get("name") === name;
        })
      ) {
        return (this.showRoomDuplicateName = true);
      }
      this.setRoomProperty("name", name);
    },
    async onTypeChange(type) {
      const room = this.room;
      room.set("type", type);

      this.$store.dispatch("rooms/saveObj", room);
    },
    sanitizeRoomType(room) {
      const type = room.get("type");

      if (!type) return null;
      return {
        id: type.get("localId"),
        name: type.get("name"),
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss"></style>
