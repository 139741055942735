import Parse from "parse";
import Jpasta from "./Jpasta";
import Hotel from "./Hotel";
import Room from "./Room";

export default class Table extends Jpasta {
  constructor() {
    super("Table");
  }

  public get(key: string) {
    if (key === "name") return this.getName();
    return super.get(key);
  }

  public set(key: any, val: any): false | this {
    if (key === "name") {
      this.setName(val);
      return false;
    }
    return super.set(key, val);
  }

  public getName(): string | undefined {
    const room = this.get("room") as Room;
    const hotel = this.get("hotel") as Hotel;
    const name = super.get("name");
    if (name === undefined && room && hotel && hotel.getTableIsSameAsRoom()) {
      return room.get("name");
    }
    return name;
  }

  public setName(val: string | undefined) {
    if (val === undefined) super.unset("name");
    else super.set("name", val);
  }
}

Parse.Object.registerSubclass("Table", Table);
