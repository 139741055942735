import Parse from "parse";
import Jpasta from "./Jpasta";
import Dish from "./Dish";
import _, { Dictionary } from "lodash";
import Hotel from "./Hotel";
import moment from "moment-timezone";
type OrderStatus =
  | "pending"
  | "approved"
  | "cancelled"
  | "delivered"
  | "rejected";
export default class ExtraMealOrder extends Jpasta {
  private dishQtyCache: Dictionary<number> = {};
  private initedCache: boolean = false;
  public isCompleted(): boolean {
    return !!this.get("completed");
  }

  public approve() {
    if (this.getStatus() !== "pending") return;
    this.set("status", "approved");
  }
  public reject() {
    if (this.getStatus() !== "pending") return;
    this.set("status", "rejected");
  }

  public isDelivered(): boolean {
    return this.getStatus() === "delivered";
  }

  public markAsDelivered() {
    if (this.getStatus() !== "approved") return;
    this.set("status", "delivered");
  }

  public urgencyLevel(): "normal" | "medium" | "high" {
    if (this.getStatus() !== "pending") return "normal";

    const hotel = this.get("hotel") as Hotel;
    if (!hotel) return "normal";
    const dueDate = this.getDueDate();
    if (!dueDate || !moment(dueDate).isValid()) return "normal";

    const now = moment().tz(hotel.getTimezone());

    const dueDateMom = moment(dueDate).tz(hotel.getTimezone());
    const diffInSecons = Math.abs(dueDateMom.diff(now, "seconds"));

    if (now.isAfter(dueDateMom)) return "high";

    if (diffInSecons <= 900) {
      return "high";
    } else if (diffInSecons <= 1800) {
      return "medium";
    } else {
      return "normal";
    }
  }

  public getStatus(): OrderStatus {
    const status = this.get("status");
    return status ? status : "pending";
  }

  public getBookTitle(): string | undefined {
    const book = this.get("book");
    if (!book) return undefined;
    const res: string[] = [];
    if (book.get("tableNumber")) {
      res.push(`Tav. ${book.get("tableNumber")}`);
    }
    if (book.get("roomNumber")) {
      res.push(`Cam. ${book.get("roomNumber")}`);
    }

    return res.join(" ");
  }

  public getOrderTitle(): string {
    const bookTitle = this.getBookTitle();
    if (bookTitle) return bookTitle;

    return this.get("personName");
  }

  private buildDishQtyCache() {
    const dishes = this.getDishesRaw();
    const groupedDishes = _.groupBy(dishes, (d) => d.get("localId"));
    //console.log("buildDishQtyCache", groupedDishes);
    _.forEach(groupedDishes, (g, dishLocalId) => {
      this.dishQtyCache[dishLocalId] = _.size(g);
    });
    this.initedCache = true;
  }

  constructor() {
    super("ExtraMealOrder");
  }

  private addDishToCache(dishLocalId) {
    this.dishQtyCache[dishLocalId] = this.dishQtyCache[dishLocalId] || 0;
    this.dishQtyCache[dishLocalId]++;
  }

  private removeDishFromCache(dishLocalId) {
    this.dishQtyCache[dishLocalId] = this.dishQtyCache[dishLocalId] || 0;
    if (this.dishQtyCache[dishLocalId] > 0) this.dishQtyCache[dishLocalId]--;
  }

  private getDishesRaw(): Dish[] {
    return this.get("dishes") || [];
  }
  public addDish(dish: Dish) {
    const dishes = this.getDishesRaw();
    dishes.push(dish);
    this.set("dishes", [...dishes]);
    this.addDishToCache(dish.get("localId"));
  }

  public setOrderDate(time: string) {
    const times = time.split(":") as string[];
    if (times.length !== 2) throw new Error("Invalid times");
    const hours = parseInt(times[0]);
    const minutes = parseInt(times[1]);

    const hotel = this.get("hotel") as Hotel;
    const date = moment()
      .tz(hotel.getTimezone())
      .startOf("day")
      .hours(hours)
      .minutes(minutes);

    this.set("time", time);
    this.set("dueDate", date.toDate());
  }

  public setIsDeliver(val: boolean) {
    this.set("isDelivery", val);
  }

  public isDelivery(): boolean {
    return this.get("isDelivery");
  }

  public setIsTakeAway(val: boolean) {
    this.set("isTakeAway", val);
  }

  public setDeliveryLocationNotes(val: string) {
    this.set("locationNotes", val);
  }

  public getDeliveryLocationNotes(): string {
    return this.get("locationNotes");
  }

  public orderDueDate(date: string) {
    this.set("dueDate", date);
  }

  public getDueDate(): string {
    return this.get("dueDate");
  }

  public getDishes(): Dish[] {
    return this.getDishesRaw();
  }

  public removeDish(dish: Dish) {
    const dishes = this.getDishesRaw();
    let alreadyDeleted = false;
    _.remove(dishes, (d) => {
      if (alreadyDeleted) return false;

      const toDelete = d.get("localId") === dish.get("localId");
      if (toDelete) alreadyDeleted = true;
      return true;
    });
    this.set("dishes", [...dishes]);
    this.removeDishFromCache(dish.get("localId"));
  }

  public getDishQty(dish: Dish): number {
    if (!this.initedCache) this.buildDishQtyCache();
    return this.dishQtyCache[dish.get("localId")] || 0;
    // return this.getDishQtyMemoized(dish);
    // const groupedDishes =
    //   _.groupBy(this.getDishesRaw(), (d) => d.get("localId")) || {};
    // return _.size(groupedDishes[dish.get("localId")]);
  }
  private dishesTotalPrice(): number {
    return _.reduce(
      _.uniqBy(this.getDishesRaw(), (d) => d.get("localId")),
      (sum, d) => {
        return sum + d.getPrice() * this.getDishQty(d);
      },
      0
    );
  }

  public getDeliveryCost(): number {
    if (!this.isDelivery()) return 0;
    const hotel = this.get("hotel") as Hotel;
    if (!hotel) return 0;
    return hotel.get("deliveryCost") || 0;
  }

  public getTotalCost(): number {
    const dishesPrice = this.dishesTotalPrice();
    return dishesPrice + this.getDeliveryCost();
  }
}

Parse.Object.registerSubclass("ExtraMealOrder", ExtraMealOrder);
