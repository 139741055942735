import _ from "lodash";
import moment from "moment-timezone";
import { findHotelRateByLocalId, FULL_BOARD } from "./constants";
import Jpasta from "./Jpasta";
import Hotel from "./Hotel";
import Parse from "parse";

export default class Book extends Jpasta {
  getDateByTimezone(dateKey, timezone?) {
    const _timezone = timezone ? timezone : this.get("hotel").get("timezone");
    return moment(this.get(dateKey)).tz(_timezone);
  }

  constructor() {
    super("Book");
  }

  public getPax(): number {
    return this.get("pax") || 0;
  }

  public setPax(val: number) {
    this.set("pax", val);
  }

  getMemberRoleName() {
    return "book_member_" + this.id;
  }

  public setTableNumber(val: string | undefined) {
    const hotel = this.get("hotel") as Hotel;
    if (hotel && hotel.getTableIsSameAsRoom()) {
      return this.setRoomNumber(val);
    }
    if (val === undefined) this.unset("tableNumber");
    else this.set("tableNumber", val);
  }

  public getTableNumber(): string | undefined {
    const hotel = this.get("hotel") as Hotel;
    if (hotel && hotel.getTableIsSameAsRoom()) return this.getRoomNumber();
    return this.get("tableNumber");
  }

  public getRoomNumber(): string | undefined {
    return this.get("roomNumber");
  }

  public setRoomNumber(val: string | undefined) {
    if (val === undefined) this.unset("roomNumber");
    else this.set("roomNumber", val);
  }

  async syncPeople(count) {
    const res = await Parse.Cloud.run("sync-people", {
      bookId: this.id,
      count: count,
    });

    return res.data;
  }

  getBookType() {
    return this.get("bookType") !== undefined
      ? this.get("bookType")
      : FULL_BOARD;
  }

  isIncluded = (calendarMeal) => {
    const rate = findHotelRateByLocalId(this.getBookType());

    if (!rate) return false;

    return (
      _.find(rate.includedMeals, (meal) => {
        return meal == calendarMeal.get("mealIndex");
      }) !== undefined
    );
  };

  isActive() {
    const checkIn = this.getDateByTimezone("checkin");
    const checkOut = this.getDateByTimezone("checkout");
    return (
      checkIn &&
      checkOut &&
      !this.get("stafferBook") &&
      moment()
        .tz(this.get("hotel").get("timezone"))
        .isBetween(checkIn, checkOut)
    );
  }

  getRefName() {
    return this.get("refName") ?? "";
  }

  getRefEmail() {
    return this.get("refEmail") ?? "";
  }
}

Parse.Object.registerSubclass("Book", Book);
