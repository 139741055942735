<template>
  <el-row
    style="flex: 1; height: 100%; width: 100vw"
    type="flex"
    justify="center"
  >
    <!-- Colonna a sinistra del menu  -->
    <el-col
      class="no-shrink sx-col"
      :xs="getMainColSizes('xs')"
      :sm="getMainColSizes('sm')"
      :md="getMainColSizes('md')"
      :lg="getMainColSizes('lg')"
      :xl="getMainColSizes('xl')"
      :class="{
        'infinite-wrapper': $mq !== 'xs' && $mq !== 'sm',
      }"
      :style="{
        'overflow-y': $mq === 'xs' || $mq === 'sm' ? 'visible' : 'auto',
      }"
    >
      <el-col
        class="main-container"
        :style="mainContainerStyle || {}"
        :class="{
          'increase-margin-top': isTutorial,
          'full-width': fullWidth,
        }"
        :xs="fullWidth ? 24 : 23"
        :sm="fullWidth ? 24 : 18"
        :md="fullWidth ? 24 : 16"
        :lg="fullWidth ? 24 : 16"
        :xl="fullWidth ? 24 : 14"
      >
        <el-row
          v-if="$slots['maincta'] || $slots['title']"
          class="flex-wrap mt-1 no-print"
          type="flex"
        >
          <el-col
            class="text-right justify-content-center justify-content-sm-start d-flex align-items-center"
            v-if="$slots.title"
            :xs="24"
            :sm="$slots.maincta ? 12 : 24"
            :md="$slots.maincta ? 12 : 24"
            :lg="$slots.maincta ? 12 : 24"
            :xl="$slots.maincta ? 12 : 24"
          >
            <h1 class="text-sm-left text-center w-100">
              <slot name="title"></slot>
            </h1>
          </el-col>
          <el-col
            v-if="$slots.maincta"
            class="text-right justify-content-center justify-content-sm-end d-flex align-items-center"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
            style="z-index: 2"
            :style="{
              'z-index': blinkingButton ? '3000' : '2',
            }"
          >
            <jtooltip
              trigger="manual"
              :show="blinkingButton && tooltipTitle && tooltipTitle.length"
              style="margin-left: 3px; margin-right: 3px; display: inline"
              :title="tooltipTitle"
              :content="tooltipText"
              ref="calendar-advice"
              :hideClose="true"
            >
              <jbutton
                ref="main-cta-ref"
                slot="target"
                v-on:click="$emit('click')"
                :class="{
                  'tutorial-button': blinkingButton,
                }"
                class="mt-2 mt-sm-0"
              >
                <slot name="maincta"></slot>
              </jbutton>
            </jtooltip>
          </el-col>
        </el-row>
        <el-row
          v-if="$slots['below-title']"
          class="flex-wrap mt-1 no-print"
          type="flex"
        >
          <el-col
            class="text-right justify-content-center justify-content-sm-start d-flex align-items-center"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <slot name="below-title"></slot>
          </el-col>
        </el-row>
        <el-row
          v-if="$slots.maincta || $slots['below-title'] || $slots.title"
          class="mt-2 no-print"
          style="height: 1px; background-color: #cccccc"
        ></el-row>
        <el-row
          :class="{
            'mt-2': $slots.maincta || $slots['below-title'] || $slots.title,
          }"
          :style="{
            ...(loadingForSync
              ? {
                  'min-height': '300px',
                }
              : {}),
            ...(mainSlotWrapperStyle || {}),
          }"
          class="mainSlotWrapper"
          v-loading="loadingForSync"
        >
          <slot v-if="!loadingForSync"></slot>
        </el-row>
      </el-col>
    </el-col>
    <!-- Fine colonna a sinistra del menu  -->
    <!-- Colonna del menu di destra -->
    <el-col
      v-if="showRightMenu"
      class="side-menu-wrapper no-print"
      style="padding: 10px 10px; padding-bottom: 20px"
      :xs="0"
      :sm="8"
      :md="8"
      :lg="6"
      :xl="4"
      :style="{
        'overflow-y': $mq === 'xs' || $mq === 'sm' ? 'auto' : 'auto',
      }"
    >
      <div v-if="closable" class="sideMenuHeader">
        <span class="icon-cross" v-on:click.prevent.stop="closeMenu"></span>
      </div>
      <slot name="rightmenu"></slot>
    </el-col>
    <mq-layout mq="md+">
      <div
        v-if="!opened && trigger === undefined"
        v-on:click.prevent.stop="openMenu"
        class="openMenu"
      >
        <span class="icon-burger-menu"></span>
      </div>
    </mq-layout>
    <!-- Fine colonna del menu di destra -->
  </el-row>
</template>

<script>
export default {
  props: {
    mainSlotWrapperStyle: {},
    mainContainerStyle: {},
    loadingForSync: {},
    fullWidth: {},
    blinkingButton: {},
    isTutorial: {},
    tooltipTitle: {},
    tooltipText: {},
    closable: {},
    trigger: {},
    defaultOpened: {
      default: true,
    },
  },
  data: function () {
    return {
      opened: true,
      mainColSizes: {
        withRightMenu: {
          xs: 23,
          sm: 16,
          md: 16,
          lg: 18,
          xl: 20,
        },
        withoutSizeMenu: {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
          xl: 24,
        },
      },
    };
  },
  mounted() {
    this.opened = this.defaultOpened;
  },
  methods: {
    openMenu: function () {
      this.opened = true;
    },
    closeMenu: function () {
      if (this.trigger !== undefined) {
        return this.$emit("close");
      }
      this.opened = false;
    },
    getMainColSizes: function (size) {
      return this.showRightMenu
        ? this.mainColSizes.withRightMenu[size]
        : this.mainColSizes.withoutSizeMenu[size];
    },
  },
  computed: {
    showRightMenu: function () {
      return (
        this.$slots.rightmenu &&
        !this.isTutorial &&
        (!this.closable ||
          (this.trigger === undefined && this.opened) ||
          this.trigger)
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants";
.mainSlotWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.openMenu {
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 12px;
  cursor: pointer;
  &:hover {
    background-color: darken(map-get($colors, "primary"), 4%);
  }
  background-color: map-get($colors, "primary");
  border-radius: 8px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.3);
  span {
    color: white;
    font-size: 20px;
  }
}
.sideMenuHeader {
  display: flex;
  margin-bottom: 10px;
  .icon-cross {
    margin-left: auto;
    font-size: 12px;

    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.fetching-data {
  min-height: 300px;

  @include media-breakpoint-down(sm) {
    min-height: 150px;
  }
}

.increase-margin-top {
  margin-top: 150px;
}
.tutorial-button {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@keyframes glowing {
  0% {
    background-color: map-get($colors, "primary");
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: darken(map-get($colors, "primary"), 10%);
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: darken(map-get($colors, "primary"), 20%);
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

// @ glowing {
//   0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
//   50% { background-color: #FF0000; -moz-box-shadow: 0 0 40px #FF0000; }
//   100% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
// }

// @-o-keyframes glowing {
//   0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
//   50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
//   100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
// }

// @keyframes glowing {
//   0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
//   50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
//   100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
// }

.side-menu-wrapper {
  background-color: darken(rgba(252, 253, 253, 1), 0%);
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.15);
}

.sx-col {
  padding: 0 0px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  @include media-breakpoint-down(sm) {
    height: 100%;
    display: block;
  }
  .main-container {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    height: 100%;
    display: flex;
    flex-direction: column;
    &.full-width {
      padding: 0px 10px;
    }

    @include media-breakpoint-down(sm) {
      height: 100%;
      justify-content: center;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 60px;
    }
  }
}
</style>
