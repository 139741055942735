<template>
  <div>
    <img
      :style="{
        'font-size': size ? size + 'px' : '40px',
      }"
      class="avatar"
      v-if="user.get('image')"
      :src="user.get('image')"
    />
    <div
      v-else
      :style="{
        'font-size': size ? size + 'px' : '40px',
      }"
      class="avatar d-flex align-items-center justify-content-center"
    >
      <span>{{ initials }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "size"],
  computed: {
    initials: function () {
      return this.user.get("name") && this.user.get("surname")
        ? this.user.get("name").substring(0, 1) +
            this.user.get("surname").substring(0, 1)
        : this.user.get("name") && this.user.get("name").length > 1
        ? this.user.get("name").substring(0, 2)
        : this.user.get("surname") && this.user.get("surname").length > 1
        ? this.user.get("surname").substring(0, 2)
        : this.user.get("username").substring(0, 2);
    },
  },
};
</script>

<style scoped>
.avatar {
  width: 1em;
  height: 1em;
  border-radius: 50%;
}

div.avatar {
  background-color: #ffa200;
}

div.avatar span {
  font-size: 0.45em;
  color: white;
  text-transform: uppercase;
}
</style>
