import Parse from "parse";
import { DishImage, JImageHelper, Jpasta } from "@/jpasta-sdk";
import generalActions from "./generalActions";
import generalMutations from "./generalMutations";
import Pica from "pica";
import Fuse from "fuse.js";
import moment from "moment-timezone";
const pica = Pica();
export default {
  namespaced: true,
  state: {
    objects: {},
    sortedIdMap: [],
    length: 0,
    className: "Dish",
    uploadingImages: {},
    fuse: null,
    version: 0,
  },

  mutations: {
    ...generalMutations,
    startUploading(state, dishLocalId) {
      state.uploadingImages[dishLocalId] = true;
      state.uploadingImages = {
        ...state.uploadingImages,
      };
    },
    stopUploading(state, dishLocalId) {
      state.uploadingImages[dishLocalId] = false;
      state.uploadingImages = {
        ...state.uploadingImages,
      };
    },
  },
  actions: {
    ...generalActions,
    attachImage: async function (
      { commit, dispatch, rootState, ...rest },
      { dish, file }
    ) {
      dish = _.cloneDeep(dish);
      //file = _.cloneDeep(file);

      if (file.type != "image/jpeg" && file.type != "image/png")
        return Promise.reject("L' immagine deve essere PNG o JPEG");
      commit("startUploading", dish.get("localId"));
      const oldImages = _.filter(rootState.dishImages.objects, (di) => {
        return di.belongsTo(dish, "dish");
      });
      await dispatch(
        "dishImages/deleteObjBatch",
        {
          objs: oldImages,
          genByProgram: true,
        },
        { root: true }
      );

      var name = Jpasta.randomLocalUUID();

      // commit("startUploading", { dish: dish, imageName: name });

      var imgHelper = new JImageHelper(file, pica);
      try {
        var thumbBase64 = await imgHelper.squareCropAndResize(400);
        var imageBase64 = await imgHelper.resizeWithMaxSize(1200);
      } catch (e) {
        commit("stopUploading", dish.get("localId"));

        return Promise.reject(
          "Ops! Si è verificato un errore durante il caricamento dell' immagine"
        );
      }

      var image = new Parse.File(name, { base64: imageBase64 });
      var thumb = new Parse.File(`${name}_thumb`, { base64: thumbBase64 });

      try {
        //throw "Ops! Si è verificato un errore durante il caricamento dell' immagine";
        var image = await image.save();
        var thumb = await thumb.save();

        const dishImage = new DishImage();
        dishImage.set("image", image);
        dishImage.set("thumb", thumb);
        dishImage.set("dish", dish);
        const newDishImage = await dispatch(
          "dishImages/saveObjSync",
          dishImage,
          { root: true }
        );

        dish.set("images", [newDishImage]);

        await dispatch("saveObj", dish);
      } catch (e) {
        console.log("error", e);
        commit("stopUploading", dish.get("localId"));
        throw new Error(
          "Ops! Si è verificato un errore durante il caricamento dell' immagine"
        );
      }
      commit("stopUploading", dish.get("localId"));

      // commit("stopUploading", { dish: dish, imageName: name });
    },
  },
  getters: {
    /* Restituisce i piatti filtrati in base a filters, alla stringa di ricerca searchString e appartenenti a parent (che può essere hotel o template) */
    // getFilteredDishesOfHotelOrTemplate: state => (
    //   parent,
    //   filters,
    //   searchString
    // ) => {
    //   var alreadyAdded = {};
    //   if (state.fuse) {
    //     if (searchString && searchString.length) {
    //       return _.reduce(
    //         state.fuse.search(searchString),
    //         (dishes, dishJson) => {
    //           let dish = state.objects[dishJson.localId];
    //           if (!dish.get("deleted") && dish.belongsTo(parent))
    //             dishes.push(dish);
    //           return dishes;
    //         },
    //         []
    //       );
    //     } else {
    //       return _.reduce(
    //         state.sortedIdMap,
    //         (dishes, localId) => {
    //           //console.count("state.sortedIdMap");
    //           if (alreadyAdded[localId]) return dishes;
    //           alreadyAdded[localId] = true;
    //           let dish = state.objects[localId];
    //           let courseFilter =
    //             filters && filters.courseFilter
    //               ? dish.matchFilter(
    //                   "courseIndex",
    //                   filters.courseFilter,
    //                   "noCourses"
    //                 )
    //               : true;
    //           let labelFilter =
    //             filters && filters.labelFilter
    //               ? dish.matchFilter("labels", filters.labelFilter, "noLabels")
    //               : true;
    //           if (
    //             !dish.get("deleted") &&
    //             dish.belongsTo(parent) &&
    //             courseFilter &&
    //             labelFilter
    //           ) {
    //             dishes.push(dish);
    //           }
    //           return dishes;
    //         },
    //         []
    //       );
    //     }
    //   } else {
    //     return [];
    //   }
    // }
  },
};
