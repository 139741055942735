var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$route.meta.noBar && !_vm.isTutorial)?_c('div',{staticClass:"main-wrap no-print"},[(_vm.$route.params.hotelid)?_c('div',{staticClass:"bottom-mobile-bar",class:{
      mobileBottomBar: _vm.$mq === 'xs' || _vm.$mq === 'sm',
      mobileOpened: _vm.$store.state.mobileMenuOpened,
    }},[(_vm.$route.params.hotelid)?_c('div',{staticClass:"icon-wrapper",class:{
        active: this.$route.name === 'HotelCalendar',
      },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toHotelCalendar.apply(null, arguments)}}},[_c('span',{staticClass:"i-label"},[_vm._v("Pasti")])]):_vm._e(),(_vm.$route.params.hotelid)?_c('div',{staticClass:"icon-wrapper",class:{
        active:
          this.$route.name === 'HotelMealBooks' ||
          this.$route.name === 'HotelMealBook',
      },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toHotelMealBook.apply(null, arguments)}}},[_c('span',{staticClass:"i-label"},[_vm._v("Ordini")])]):_vm._e(),(_vm.$route.params.hotelid)?_c('div',{staticClass:"icon-wrapper",class:{
        active: this.$route.name === 'BookList',
      },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toHotelBook.apply(null, arguments)}}},[_c('span',{staticClass:"i-label"},[_vm._v("Prenotazioni")])]):_vm._e()]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }