import { Object } from "parse";
import _ from "lodash";

export default class Jpasta extends Object {
  constructor(parseClassName) {
    super(parseClassName);
  }

  //Esegue ricorsibamente fino all'esaurimento degli items
  static async execRecursiveFind(
    {
      query,
      useMasterKey,
      sessionToken,
    }: { query: Parse.Query; useMasterKey?: boolean; sessionToken?: string },
    skip = 0
  ) {
    const LIMIT = 1000;
    query.limit(LIMIT + 1);
    if (skip) query.skip(skip);
    // if (lastKey) query.greaterThan(orderKey, lastKey);

    const res = await query.find({
      useMasterKey: useMasterKey,
      sessionToken,
    });

    if (res.length > LIMIT) {
      res.pop();

      return [
        ...res,
        ...(await Jpasta.execRecursiveFind(
          { query, useMasterKey, sessionToken },
          skip + res.length
        )),
      ];
    } else {
      return res;
    }
  }

  static getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  static randomString(size) {
    if (size === 0) {
      throw new Error("Zero-length randomString is useless.");
    }
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
      "abcdefghijklmnopqrstuvwxyz" +
      "0123456789";
    let objectId = "";
    for (let i = 0; i < size; i += 1) {
      objectId += chars.charAt(Jpasta.getRandomArbitrary(0, chars.length));
    }
    return objectId;
  }
  static randomLocalUUID() {
    return Jpasta.randomString(10);
  }

  belongsTo(obj, propertyName): boolean {
    try {
      const _propertyName = propertyName
        ? propertyName
        : obj.className.toLowerCase();

      return (
        (obj.id && this.get(_propertyName).id === obj.id) ||
        this.get(_propertyName).get("localId") === obj.get("localId")
      );
    } catch (e) {
      return false;
    }
  }

  /* voidPropertyFilterKey indica la proprietà di filterObject che memorizza se devono essere
  visualizzati gli oggetti la cui proprietà indicata da propertyName risulta undefined o vuota (nel caso di un oggetto)  */

  matchFilter(propertyName, filterObject, voidPropertyFilterKey) {
    if (typeof this.get(propertyName) === "object") {
      return (
        _.find(this.get(propertyName), (propertyItemValue, propertyItemKey) => {
          try {
            return (
              filterObject[propertyItemKey] ||
              filterObject[propertyItemValue.get("localId")]
            );
          } catch (e) {
            return false;
          }
        }) ||
        (voidPropertyFilterKey !== undefined &&
          !_.find(this.get(propertyName), (propertyItemValue) => {
            return propertyItemValue.get("localId");
          }) &&
          filterObject[voidPropertyFilterKey])
      );
    } else if (
      typeof this.get(propertyName) === "number" ||
      typeof this.get(propertyName) === "string"
    ) {
      return filterObject[this.get(propertyName)];
    } else if (typeof this.get(propertyName) === "undefined") {
      return voidPropertyFilterKey && filterObject[voidPropertyFilterKey];
    }
  }

  static getLocalizedName(name, locale, noNamePlaceholder) {
    let res;
    const firstPartOfLocale = locale.substring(0, locale.indexOf("-"));
    if (typeof name === "string") return name;

    if (locale.indexOf("-") != -1) {
      res = "";
    }
    try {
      if (locale in name && name[locale].length) {
        res = name[locale];
      } else if (
        locale.indexOf("-") != -1 &&
        firstPartOfLocale in name &&
        name[firstPartOfLocale].length
      ) {
        //c'è un trattino, provo solo con la prima parte della locale es en-us provo solo con en
        res = name[firstPartOfLocale];
      } else if ("it" in name && name.it.length) {
        res = name.it;
      } else {
        res = noNamePlaceholder;
      }
    } catch (e) {
      res = noNamePlaceholder;
    }

    return res;
  }
}
