<template>
  <el-select
    default-first-option
    :value="value"
    :filterable="true"
    :allow-create="true"
    value-key="id"
    :multiple="multiple"
    v-on:input="onTypeChange"
    placeholder="Seleziona o crea una nuova tipologia di camera"
  >
    <el-option
      v-for="item in roomTypeOptions"
      :key="item.id"
      :label="item.name"
      :value="item"
    ></el-option>
  </el-select>
</template>

<script>
import {
  Room,
  ROOM_TYPES,
  findJRoomTypeByLocalId,
  RoomType,
} from "@/jpasta-sdk";
export default {
  props: {
    value: {},
    multiple: {
      default: false,
    },
  },
  methods: {
    getRoomType(type) {
      let rt;
      if (typeof type === "string") {
        rt = new RoomType();
        rt.set("hotel", this.hotel);
        rt.set("name", type);
        this.$store.dispatch("roomTypes/saveObjSync", rt);
      } else {
        rt = _.find(this.roomTypes, (rt) => {
          return rt.get("localId") === type.id;
        });
      }
      return rt;
    },

    async onTypeChange(type) {
      if (this.multiple) {
        const res = [];
        _.forEach(type, (t) => {
          const rt = this.getRoomType(t);
          res.push(rt);
        });
        return this.$emit("selected", res);
      } else {
        const rt = this.getRoomType(type);
        return this.$emit("selected", rt);
      }
    },
  },
  computed: {
    roomTypes: function () {
      return _.filter(this.$store.state.roomTypes.objects, (r) => {
        return r.belongsTo(this.hotel, "hotel");
      });
    },

    hotel: function () {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },

    roomTypeOptions: function () {
      return _.map(this.roomTypes, (rt) => {
        return {
          id: rt.get("localId"),
          name: rt.get("name"),
        };
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>

<style lang="scss"></style>
