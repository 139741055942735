
import Vue from "vue";
import mixins from "vue-typed-mixins";
import HotelMealBook from "./HotelMealBook.vue";
import {
  CalendarMeal,
  MealOrder,
  Book,
  Hotel,
  BookPerson,
  Turn,
  Table,
} from "@/jpasta-sdk";
import _ from "lodash";
import { Dictionary } from "vue-router/types/router";
import moment from "moment-timezone";
export default mixins().extend({
  components: {
    HotelMealBook,
  },
  methods: {
    getTurnLocalId(turn: Turn | undefined): string | undefined {
      if (!turn) return undefined;

      return turn.get("localId");
    },
  },
  mounted() {},
  computed: {
    turn: function (): Turn | undefined {
      if (this.$route.params.turnid === undefined) return undefined;

      return this.$store.state.turns.objects[this.$route.params.turnid];
    },
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    booksByLocalId: function (): Dictionary<Book> {
      return _.keyBy(this.books, (b) => b.get("localId") as string);
    },
    books: function (): Book[] {
      return _.filter(
        this.$store.state.books.objects as Dictionary<Book>,
        (book) => {
          const tableName = book.getTableNumber();

          if (
            this.getTurnLocalId(this.turn) !==
            this.getTurnLocalId(this.turnByTableName[tableName as string])
          )
            return false;

          return (
            book.get("checkin") &&
            book.get("checkout") &&
            book.belongsTo(this.hotel, "hotel") &&
            moment(this.calendarMeal.get("date")).isBetween(
              book.get("checkin"),
              book.get("checkout")
            )
          );
        }
      );
    },
    tables: function (): Table[] {
      return _.filter(
        this.$store.state.tables.objects as Dictionary<Table>,
        (t) => {
          return _.size(t.get("name")) > 0 && t.belongsTo(this.hotel, "hotel");
        }
      );
    },
    turnByTableName: function (): Dictionary<Turn | undefined> {
      return _.reduce(
        this.tables,
        (res: Dictionary<Turn | undefined>, t) => {
          const turn = t.get("turn");
          res[t.get("name")] = turn;

          return res;
        },
        {}
      );
    },
    bookPeople: function (): BookPerson[] {
      return _.filter(
        this.$store.state.bookPersons.objects as Dictionary<BookPerson>,
        (person) => {
          const book: Book | undefined = person.get("book");
          if (!book) return false;
          return !!this.booksByLocalId[book.get("localId")];
        }
      );
    },

    normalOrders: function (): MealOrder[] {
      return _.filter(
        this.$store.state.mealOrders.objects as Dictionary<MealOrder>,
        (mo) => {
          const book = mo.get("book");
          if (!book) return false;
          return (
            !!this.booksByLocalId[book.get("localId")] &&
            mo.get("person") &&
            mo.belongsTo(this.calendarMeal, "calendarMeal")
          );
        }
      );
    },

    calendarMeal: function (): CalendarMeal {
      return this.$store.state.calendarMeals.objects[
        this.$route.params.calendarmealid
      ];
    },
  },
});
