
import Vue from "vue";
import mixins from "vue-typed-mixins";
import { ExtraMealOrder, Hotel } from "@/jpasta-sdk";
import _ from "lodash";
import { Dictionary } from "vue-router/types/router";
import ExtraMealOrderItem from "./ExtraMealOrderItem.vue";
import moment from "moment-timezone";
import firebase from "@/firebase";
import Parse from "parse";
export default mixins().extend({
  data(): {
    fbRef: firebase.database.Reference | undefined;
    showDeliveredOrders: boolean;
    showRejectedOrders: boolean;
  } {
    return {
      fbRef: undefined,
      showDeliveredOrders: false,
      showRejectedOrders: false,
    };
  },
  methods: {
    // onLastOrderDateChanged(snap: firebase.database.DataSnapshot) {
    //   const extraMealOrderQuery = new Parse.Query(ExtraMealOrder);
    //   extraMealOrderQuery.equalTo("hotel", this.hotel);
    //   extraMealOrderQuery.greaterThanOrEqualTo(
    //     "dueDate",
    //     moment()
    //       .subtract(3, "hours")
    //       .toDate()
    //   );
    //   this.$store.dispatch("extraMealOrders/getObjects", {
    //     query: extraMealOrderQuery
    //   });
    // }
  },
  mounted() {
    const hotel = this.hotel;
    if (this.notSeenExtraMealOrders.length > 0) {
      hotel.set("lastSeenExtraMealOrders", moment().toDate());
      this.$store.dispatch("hotels/saveObj", {
        obj: hotel,
        genByProgram: true,
      });
    }
  },

  // beforeDestroy() {
  //   if (!this.fbRef) return;

  //   this.fbRef.off("value", this.onLastOrderDateChanged);
  // },
  components: {
    ExtraMealOrderItem,
  },
  computed: {
    notSeenExtraMealOrders: function (): ExtraMealOrder[] {
      if (!this.hotel) return [];
      return _.filter(
        this.$store.state.extraMealOrders.objects as Dictionary<ExtraMealOrder>,
        (mo) => {
          const hotel = this.hotel;
          const lastSeenDate = hotel.get("lastSeenExtraMealOrders");
          //console.log("lastSeenDate", lastSeenDate);
          return (
            mo.belongsTo(hotel, "hotel") &&
            (!lastSeenDate ||
              moment(lastSeenDate).isBefore(mo.get("createdAt")))
          );
        }
      );
    },
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },

    items2render: function (): {
      key: string;
      order: ExtraMealOrder;
    }[] {
      return _.map(this.extraMealOrders, (order) => {
        return {
          key: order.get("localId"),
          order,
        };
      });
    },

    extraMealOrders(): ExtraMealOrder[] {
      // return _.filter(this.$);

      const orders = _.filter(
        this.$store.state.extraMealOrders.objects as Dictionary<ExtraMealOrder>,
        (order) =>
          (order.getStatus() !== "rejected" || this.showRejectedOrders) &&
          (!order.isDelivered() || this.showDeliveredOrders) &&
          order.belongsTo(this.hotel, "hotel") &&
          order.isCompleted() &&
          !!order.getDueDate() &&
          moment(order.getDueDate()).isValid()
      );
      return _.sortBy(orders, (o) => moment(o.getDueDate()).valueOf());
    },
  },
});
