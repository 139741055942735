
import {
  JPASTA_MEALS,
  Jpasta,
  findJCourseByLocalId,
  findJMealByLocalId,
  MealOrderDish,
  Dish,
  CalendarMeal,
  MealOrder,
  Book,
  Hotel,
  BookPerson,
  ExtraMealOrder,
} from "@/jpasta-sdk";
import moment from "moment-timezone";
import Parse from "parse";
import advices from "../advices";
import mixins from "vue-typed-mixins";
import _ from "lodash";
import { Dictionary } from "vue-router/types/router";

type Item2Render = {
  total: number;
  order: MealOrder;
  dishes: Item2RenderDish[];
};
type Item2RenderExtra = {
  total: number;
  order: ExtraMealOrder;
  dishes: Item2RenderDish[];
};

type Item2RenderDish = {
  qty: number;
  dish: Dish;
};

export default mixins().extend({
  props: {},
  data: function (): {
    syncingPMS: boolean;
    loadingForSync: boolean;
    advices: any;
  } {
    return {
      syncingPMS: false,
      loadingForSync: true,
      advices: advices,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    extraStatusTag(mo: ExtraMealOrder) {
      switch (mo.getStatus()) {
        case "cancelled":
          return "Cancellato";
        case "rejected":
          return "Rifiutato";
        case "pending":
          return "Rimasto in attesa";
        default:
          return undefined;
      }
    },
    getExtraMealOrderTitle(mo: ExtraMealOrder) {
      const date = mo.getDueDate();
      return `Ordine extra pasto ${moment(date).format("DD MMM YYYY HH:mm")}`;
    },
    // getExtraMealOrderPrice(mo: ExtraMealOrder, dish: Dish) {
    //   switch (mo.getStatus()) {

    //   }
    // },
    formatBookPeriod() {
      const checkin = this.book.get("checkin");
      const checkout = this.book.get("checkout");

      const res: string[] = [];

      if (checkin) {
        res.push(moment(checkin).format("DD MMMM YYYY"));
      }

      if (checkout) {
        res.push(moment(checkout).format("DD MMMM YYYY"));
      }

      return res.join(" - ");
    },
    getExtraMealOrderDishPrice(dish: Item2RenderDish) {
      return (dish.dish.getPrice() || 0) * dish.qty;
    },
    getDishPrice(dish: Item2RenderDish, order: MealOrder) {
      const cMeal: CalendarMeal = order.get("calendarMeal");

      return dish.dish.getDishPrice(this.book, cMeal) * dish.qty;
    },
    getDishTitle(dish: Item2RenderDish): string {
      return `x${dish.qty} ${Jpasta.getLocalizedName(
        dish.dish.get("name"),
        "it",
        "Piatto senza nome"
      )}`;
    },
    getMealOrderTitle(order: MealOrder): string {
      const cMeal: CalendarMeal = order.get("calendarMeal");
      const jMeal = findJMealByLocalId(cMeal.get("mealIndex"));
      if (!jMeal) return "";
      const mealName = Jpasta.getLocalizedName(jMeal.name, "it", "");
      const person: BookPerson = order.get("person");
      const res: string[] = [
        `${mealName} del ${moment(cMeal.get("date")).format(
          "DD MMM YYYY HH:mm"
        )}`,
      ];

      if (person && _.size(person.get("name")) > 0) {
        res.push(`${person.get("name")}`);
      }
      return res.join(" - ");
    },
    fetchData: async function (): Promise<void> {
      this.loadingForSync = true;
      const mealOrdersQuery = new Parse.Query(MealOrder);
      mealOrdersQuery.equalTo("book", this.book);

      const mealOrderDishesQuery = new Parse.Query(MealOrderDish);
      mealOrderDishesQuery.matchesKeyInQuery(
        "order",
        "objectId",
        mealOrdersQuery
      );

      const extraMealOrderQuery = new Parse.Query(ExtraMealOrder);
      extraMealOrderQuery.equalTo("book", this.book);

      const cMealQuery = new Parse.Query(CalendarMeal);
      cMealQuery.matchesKeyInQuery("objectId", "calendarMeal", mealOrdersQuery);

      const batch: Promise<any>[] = [];

      batch.push(
        this.$store.dispatch("mealOrders/getObjects", {
          query: mealOrdersQuery,
          clear: false,
        })
      );
      batch.push(
        this.$store.dispatch("calendarMeals/getObjects", {
          query: cMealQuery,
          clear: false,
        })
      );

      batch.push(
        this.$store.dispatch("mealOrderDishes/getObjects", {
          query: mealOrderDishesQuery,
          clear: false,
        })
      );
      batch.push(
        this.$store.dispatch("extraMealOrders/getObjects", {
          query: extraMealOrderQuery,
          clear: false,
        })
      );

      await Promise.all(batch);

      this.loadingForSync = false;
    },
    getDishesOf(order): MealOrderDish[] {
      return _.filter(this.$store.state.mealOrderDishes.objects, (odish) => {
        return odish.belongsTo(order, "order");
      });
    },
    canSyncWithPMS(): boolean {
      return false;
    },
    async syncWithPMS(): Promise<void> {
      this.syncingPMS = true;
      await Parse.Cloud.run("sync-sales-with-pms", {
        bookId: this.book.id,
      });
      this.syncingPMS = false;
    },
    getSize(obj): number {
      return _.size(obj);
    },
    getDish(dishLocalId): Dish {
      return this.$store.state.dishes.objects[dishLocalId];
    },
    getLocalizedName(name): string {
      return Jpasta.getLocalizedName(name, "it", "");
    },
    getCMealTitle: function (cMealLocalId): string {
      const cMeal = this.getCalendarMeal(cMealLocalId);
      const jMeal = findJMealByLocalId(cMeal.get("mealIndex"));
      if (!jMeal) return "";
      const mealName = this.getLocalizedName(jMeal.name);

      return `${mealName} delle ${this.formatTime(
        cMeal.get("date")
      )} del ${this.formatDate(cMeal.get("date"))}`;
    },

    formatDate: function (date): string {
      return moment(date).format("DD MMMM YYYY");
    },

    formatTime: function (date): string {
      return moment(date).format("HH:mm");
    },

    getCalendarMeal: function (cMealLocalId): CalendarMeal {
      return this.$store.state.calendarMeals.objects[cMealLocalId];
    },
  },
  computed: {
    extraMealOrders: function (): ExtraMealOrder[] {
      return _.filter(
        this.$store.state.extraMealOrders.objects as Dictionary<ExtraMealOrder>,
        (mo) => {
          return mo.belongsTo(this.book, "book");
        }
      );
    },
    extraItems2render: function (): Item2RenderExtra[] {
      return _.reduce(
        this.extraMealOrders,
        (res: Item2RenderExtra[], mo) => {
          const orderedDishes = _.uniqBy(mo.getDishes(), (d) =>
            d.get("localId")
          );
          const extraDishes: {
            qty: number;
            dish: Dish;
          }[] = _.map(orderedDishes, (d) => {
            const qty = mo.getDishQty(d);

            return {
              qty: qty,
              dish: d,
            };
          });

          res.push({
            total: mo.getTotalCost(),
            order: mo,
            dishes: extraDishes,
          });
          return res;
        },
        []
      );
    },
    totalExtra: function (): number {
      const totalExtra = _.reduce(
        this.extraItems2render,
        (sum: number, i) => {
          const order = i.order;
          switch (order.getStatus()) {
            case "approved":
            case "delivered":
              return sum + i.total;

            default:
              return sum;
          }
        },
        0
      );
      return totalExtra;
    },
    totalNormal: function (): number {
      const totalNormal = _.reduce(
        this.items2render,
        (sum: number, i) => {
          return sum + i.total;
        },
        0
      );
      return totalNormal;
    },
    total: function (): number {
      return this.totalNormal + this.totalExtra;
    },
    items2render: function (): Item2Render[] {
      return _.reduce(
        this.mealOrders,
        (res: Item2Render[], o) => {
          const oDishes = this.mealOrderDishesMap[o.get("localId")] || [];
          const cMeal: CalendarMeal = o.get("calendarMeal");
          const dishes: {
            qty: number;
            dish: Dish;
          }[] = _.map(oDishes, (oDish) => {
            return {
              qty: oDish.get("quantity") || 0,
              dish: oDish.get("dish"),
            };
          });

          const price = o.getTotalPrice(dishes, this.hotel, cMeal, this.book);

          res.push({
            total: price,
            order: o,
            dishes: dishes,
          });

          return res;
        },
        []
      );
    },
    extraOrders: function (): {
      [key: string]: MealOrder[];
    } {
      var orders = {};
      _.forEach(this.$store.state.mealOrders.objects, (order) => {
        if (!order.get("completed")) return;
        if (
          order.belongsTo(this.book) &&
          !this.book.isIncluded(order.get("calendarMeal"))
        ) {
          var key = order.get("calendarMeal").get("localId");
          orders[key] = orders[key] || [];
          orders[key].push(order);
        }
      });

      return orders;
    },
    mealOrderDishesMap: function (): { [key: string]: MealOrderDish[] } {
      return _.reduce(
        this.$store.state.mealOrderDishes.objects,
        (res, mod: MealOrderDish) => {
          if (!mod.get("quantity")) return res;
          const order = mod.get("order");
          if (!order) return res;
          const key = order.get("localId");
          res[key] = res[key] || [];
          res[key].push(mod);
          return res;
        },
        {}
      );
    },
    mealOrders: function (): MealOrder[] {
      const orders: MealOrder[] = _.filter(
        this.$store.state.mealOrders.objects,
        (m: MealOrder) => {
          return m.belongsTo(this.book, "book");
        }
      );

      return _.sortBy(orders, (o) => {
        const cMeal = o.get("calendarMeal");
        const date = cMeal.get("date");
        return moment(date).unix();
      });
    },
    book: function (): Book {
      return this.$store.state.books.objects[this.$route.params.bookid];
    },
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
  },
});
