import Parse from "parse";
import Jpasta from "./Jpasta";
import CalendarMeal from "./CalendarMeal";
import Hotel from "./Hotel";

export default class RestaurantOrder extends Jpasta {
  constructor() {
    super("RestaurantOrder");
  }

  public setTime(mealTime?: string) {
    if (mealTime) this.set("time", mealTime);
  }

  public setCalendarMeal(cMeal: CalendarMeal) {
    this.set("calendarMeal", cMeal);
  }

  public getCalendarMeal(): CalendarMeal | undefined {
    return this.get("calendarMeal");
  }

  public setHotel(hotel: Hotel) {
    this.set("hotel", hotel);
  }

  public markAsCompleted() {
    this.set("completed", true);
  }

  public setName(name: string) {
    this.set("name", name);
  }

  public setTable(table?: string) {
    if (table) this.set("table", table);
  }
}

Parse.Object.registerSubclass("RestaurantOrder", RestaurantOrder);
