import CSVParser from "./CSVParser";
import {
  BEB,
  FULL_BOARD,
  HALF_BOARD_WITH_DINNER,
  ONLY_BED,
  HALF_BOARD_WITH_DINNER_AND_DRINK,
  Hotel,
} from "@/jpasta-sdk";
import _ from "lodash";
import moment, { Moment } from "moment-timezone";

enum CSVCOL {
  ROOM = 2,
  REFNAME = 4,
  BOOKID,
  PAX1,
  PAX2,
  PAX3,
  PAX4,
  PAX5,
  CHECKINCHECKOUT,
  BOOKTYPE,
  EMAIL,
  LANG,
}

export default class Ericsoft3Parser extends CSVParser {
  private hotel: Hotel;
  constructor(file: File, hotel: Hotel) {
    super(file);
    this.hotel = hotel;
  }

  protected getValidBookTypes() {
    const authPMS = this.hotel.get("authPMS");
    const bbRates: string[] = _.get(authPMS, "ericsoft3.bbRates", []);
    const hbRates: string[] = _.get(authPMS, "ericsoft3.hbRates", []);
    const fbRates: string[] = _.get(authPMS, "ericsoft3.fbRates", []);
    return [...bbRates, ...hbRates, ...fbRates];
  }

  private mapRates(
    rates: string[],
    rateName: "bbRates" | "hbRates" | "fbRates" | "skipRates"
  ) {
    return _.map(rates, (r) => ({
      label: r,
      rateName: rateName,
    }));
  }

  protected parseBookType(type?: string): number | undefined {
    const authPMS = this.hotel.get("authPMS");
    const bbRates = _.get(authPMS, "ericsoft3.bbRates", []);
    const hbRates = _.get(authPMS, "ericsoft3.hbRates", []);
    const fbRates = _.get(authPMS, "ericsoft3.fbRates", []);
    const rates = {
      ..._.keyBy(this.mapRates(bbRates, "bbRates"), (r) => r.label),
      ..._.keyBy(this.mapRates(hbRates, "hbRates"), (r) => r.label),
      ..._.keyBy(this.mapRates(fbRates, "fbRates"), (r) => r.label),
    };

    const rate = rates[type as string];

    if (!rate) return undefined;
    switch (rate.rateName) {
      case "bbRates":
        return BEB;
      case "hbRates":
        return HALF_BOARD_WITH_DINNER;
      case "fbRates":
        return FULL_BOARD;
      default:
        return undefined;
    }
  }

  private getCheckinCheckout(row: string[]): {
    checkin: Moment | undefined;
    checkout: Moment | undefined;
  } {
    const checkinCheckout = this.getRowData(row, CSVCOL.CHECKINCHECKOUT) || "";

    const res = checkinCheckout.split("-");
    if (res.length < 2) return { checkin: undefined, checkout: undefined };

    const defaultCheckinTime = this.hotel.getDefaultCheckinTime();
    const defaultCheckoutTime = this.hotel.getDefaultCheckoutTime();

    const [checkinHours, checkinMinutes] = defaultCheckinTime.split(":");
    const [checkoutHours, checkoutMinutes] = defaultCheckoutTime.split(":");

    const checkin = moment(res[0].trim(), "DD/MM/YYYY");
    if (checkin.isValid()) {
      checkin.tz(this.hotel.getTimezone());
      checkin.startOf("day");
      checkin.hours(parseInt(checkinHours));
      checkin.minutes(parseInt(checkinMinutes));
    }
    const checkout = moment(res[1].trim(), "DD/MM/YYYY");

    if (checkout.isValid()) {
      checkout.tz(this.hotel.getTimezone());
      checkout.startOf("day");
      checkout.hours(parseInt(checkoutHours));
      checkout.minutes(parseInt(checkoutMinutes));
    }

    return {
      checkin: checkin,
      checkout: checkout,
    };
  }

  private getId(row: string[]) {
    return this.getRowData(row, CSVCOL.BOOKID);
    // const email = this.getRowData(row, CSVCOL.EMAIL);
    // const room = this.getRowData(row, CSVCOL.ROOM);
    // const { checkin, checkout } = this.getCheckinCheckout(row);
    // return `${
    //   checkin && checkin.isValid() ? checkin.format("DD/MM/YYYY") : ""
    // }-${
    //   checkout && checkout.isValid() ? checkout.format("DD/MM/YYYY") : ""
    // }-${email || ""}-${room || ""}`;
  }

  protected filterRows(rows: string[][]): string[][] {
    const authPMS = this.hotel.get("authPMS");

    const skipRates = _.get(authPMS, "ericsoft3.skipRates", []);
    console.log("skipRates", skipRates);
    const skipRatesMap = _.keyBy(
      this.mapRates(skipRates, "skipRates"),
      (r) => r.label
    );
    return _.filter(rows, (row) => {
      const bookType = this.getRowData(row, CSVCOL.BOOKTYPE);

      return bookType !== undefined ? !skipRatesMap[bookType] : true;
    });
  }

  private parseInt(str?: string) {
    if (!str) return 0;
    if (str === "") return 0;
    return parseInt(str) || 0;
  }

  private getPax(row: string[]) {
    const pax1 = this.parseInt(this.getRowData(row, CSVCOL.PAX1));
    const pax2 = this.parseInt(this.getRowData(row, CSVCOL.PAX2));
    const pax3 = this.parseInt(this.getRowData(row, CSVCOL.PAX3));
    const pax4 = this.parseInt(this.getRowData(row, CSVCOL.PAX4));
    const pax5 = this.parseInt(this.getRowData(row, CSVCOL.PAX5));
    return pax1 + pax2 + pax3 + pax4 + pax5;
  }

  protected parseRow(row: string[], index: number) {
    const { checkin, checkout } = this.getCheckinCheckout(row);
    const name = this.getRowData(row, CSVCOL.REFNAME);
    const email = this.getRowData(row, CSVCOL.EMAIL);
    const bookTypeRaw = this.getRowData(row, CSVCOL.BOOKTYPE);
    const bookType = this.parseBookType(bookTypeRaw);
    const bookId = this.getId(row);
    const roomName = this.getRowData(row, CSVCOL.ROOM);
    console.log("lang", roomName);
    const lang =
      _.size(this.getRowData(row, CSVCOL.LANG)) > 0
        ? _.toLower(this.getRowData(row, CSVCOL.LANG))
        : "it";

    const pax = this.getPax(row);
    const newRow: BookPlain = {
      checkIn: checkin ? checkin.toISOString() : undefined,
      checkOut: checkout ? checkout.toISOString() : undefined,
      name,
      email,
      bookType: bookType ? bookType : ONLY_BED,
      rawBookType: bookTypeRaw,
      bookId,
      roomNumber: roomName,
      deleted: false,
      pax: pax,
      rowIndex: index,
      lang,
    };

    return newRow;
  }

  //   public parse() {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.onload = (event: any) => {
  //         if (!event.target) return;
  //         try {
  //           const res: Papa.ParseResult<string[]> = Papa.parse(
  //             event.target.result as string
  //           );
  //           const rows: string[][] = res.data;
  //           this.parseRows(rows);

  //           //     console.log(csv)
  //           //   const rows = (event.target.result as string).split("\n");
  //           //   const sanitizeRows =
  //           //
  //           resolve();
  //         } catch (e) {
  //           reject();
  //         }
  //       };
  //       reader.readAsText(this.file);
  //     });
  //   }
}
