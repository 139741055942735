var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('jmodal',{ref:"translationModal",attrs:{"zindex":5002,"autoClose":true}},[_c('h4',{staticStyle:{"margin-right":"0.4em"},attrs:{"slot":"header"},slot:"header"},[_vm._v("Traduzioni")]),_c('div',{staticClass:"modal-body"},_vm._l((_vm.languages),function(lang,index){return _c('div',{key:index,staticClass:"input-field lang-input"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(lang.label))]),_c('jinput',{staticClass:"hotel-input",staticStyle:{"margin-top":"0.15em"},attrs:{"inputStyle":{
            'background-color': 'transparent',
          },"type":"text","placeholder":"Traduci"},model:{value:(_vm.labelName[lang.code]),callback:function ($$v) {_vm.$set(_vm.labelName, lang.code, $$v)},expression:"labelName[lang.code]"}})],1)}),0),_c('div',{staticClass:"button-wrapper",attrs:{"slot":"footer"},slot:"footer"},[_c('jbutton',{staticClass:"footer-button",attrs:{"type":"border"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$refs.translationModal.close()}}},[_vm._v("Fatto")])],1)]),_c('jmodal',{ref:"labelIconModal",attrs:{"zindex":5002,"autoClose":true}},[_c('h4',{staticStyle:{"margin-right":"0.4em"},attrs:{"slot":"header"},slot:"header"},[_vm._v("Icone")]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"label-icon-wrapper"},_vm._l((_vm.labelsIcons),function(icon,iconIndex){return _c('div',{key:iconIndex,staticClass:"label-icon icon",domProps:{"innerHTML":_vm._s(icon.src)},on:{"click":function($event){return _vm.selectIcon(iconIndex)}}})}),0)]),_c('div',{staticClass:"button-wrapper",attrs:{"slot":"footer"},slot:"footer"},[_c('jbutton',{staticClass:"footer-button",attrs:{"type":"border"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$refs.translationModal.close()}}},[_vm._v("Fatto")])],1)]),_c('jmodal',{ref:"modal",attrs:{"iconClose":true,"zindex":5001,"autoClose":false},on:{"close":_vm.onClose}},[_c('div',{staticClass:"header-title",staticStyle:{"margin-right":"0.4em"},attrs:{"slot":"header"},slot:"header"},[_c('h4',[_vm._v(_vm._s(_vm.selectedLabel ? "Modifica etichetta" : "Nuova etichetta"))])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"newLabel"},[_c('div',{staticClass:"label-title-wrapper"},[(_vm.selectedIconIndex || _vm.canAddIcon)?_c('span',{staticClass:"label-icon",class:{
              icon: !!_vm.labelsIcons[_vm.selectedIconIndex],
            },domProps:{"innerHTML":_vm._s(
              !!_vm.labelsIcons[_vm.selectedIconIndex]
                ? _vm.labelsIcons[_vm.selectedIconIndex].src
                : ''
            )},on:{"click":_vm.openIconModal}}):_vm._e(),_c('jinput',{ref:"new-label-name-input",staticClass:"hotel-input meal-name-input detail-text meal-entity-input",staticStyle:{"flex":"1"},attrs:{"inputStyle":{
              'background-color': 'transparent',
              'border-bottom': '1px solid #EAEDED !important',
              'border-radius': '0',
            },"noBorder":true,"type":"text","placeholder":"Nome etichetta"},model:{value:(_vm.labelName.it),callback:function ($$v) {_vm.$set(_vm.labelName, "it", $$v)},expression:"labelName.it"}})],1),_c('div',{staticClass:"colors-box"},_vm._l((_vm.colors),function(color,colorIndex){return _c('span',{key:colorIndex,staticClass:"color-item",class:{
              selected: _vm.selectedColorIndex === colorIndex,
            },style:({
              'background-color': _vm.colors[colorIndex].hex,
            }),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.selectColor(colorIndex)}}},[_c('span',{staticClass:"circle"})])}),0),_c('div',{staticClass:"can-see-in-app-wrapper"},[_c('jcheckbox',{attrs:{"checked":_vm.visibleInApp},model:{value:(_vm.visibleInApp),callback:function ($$v) {_vm.visibleInApp=$$v},expression:"visibleInApp"}}),_c('span',{staticClass:"can-see-in-app-title"},[_vm._v(" Visibile nell' applicazione "),_c('span',{staticClass:"sub-title"},[_vm._v("Quando spunti questa opzione, questa etichetta sarà visibile anche ai tuoi clienti quando effettueranno gli ordini")])])],1)])]),_c('div',{staticClass:"button-wrapper",attrs:{"slot":"footer"},slot:"footer"},[_c('jbutton',{staticClass:"footer-button",attrs:{"loading":_vm.loading,"type":"border"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toTranslation.apply(null, arguments)}}},[_vm._v("Configura traduzioni")]),_c('jbutton',{staticClass:"footer-button",attrs:{"loading":_vm.loading,"type":"border"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.selectedLabel ? _vm.onSave() : _vm.onNew()}}},[_vm._v(_vm._s(_vm.selectedLabel ? "Salva" : "Crea"))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }