
import Vue from "vue";
import mixins from "vue-typed-mixins";
import { Hotel, Jpasta, Meal, SNACK, JPASTA_MEALS } from "@/jpasta-sdk";
import _ from "lodash";
export default mixins().extend({
  data: function (): {
    meals: Meal[];
  } {
    return {
      meals: _.filter(JPASTA_MEALS, (meal) => {
        return meal.id !== SNACK;
      }),
    };
  },
  methods: {
    setMealPrice: function (mealIndex: number, price: number) {
      const hotel = this.hotel;
      hotel.setMealPrice(mealIndex, price);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
    getLocalizedName: function (name) {
      return Jpasta.getLocalizedName(name, "it", "");
    },
    changeNotIncludedMealPaymentType: function (type) {
      const hotel = this.hotel;
      hotel.setNotIncludedPaymentType(type);
      this.$store.dispatch("hotels/saveObj", hotel);
    },
  },
  computed: {
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
  },
});
