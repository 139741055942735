import Parse from "parse";
import Jpasta from "./Jpasta";
import Dish from "./Dish";
import RestaurantOrder from "./ResturantOrder";

export default class RestaurantOrderDish extends Jpasta {
  constructor() {
    super("RestaurantOrderDish");
  }

  public getOrder(): RestaurantOrder | undefined {
    return this.get("order");
  }

  public getAnnotations(): string[] {
    return this.get("annotations") || [];
  }

  public getAnnotationText(): string {
    const annotations = this.getAnnotations();
    return annotations.join(", ");
  }

  public setAnnotationText(annotationText: string) {
    const annotations: string[] = [];
    annotations.push(annotationText);
    this.set("annotations", annotations);
  }

  public getDish(): Dish | undefined {
    return this.get("dish");
  }

  public setDish(dish: Dish) {
    this.set("dish", dish);
  }

  public setQuantity(qty: number) {
    this.set("quantity", qty);
  }

  public setOrder(order: RestaurantOrder) {
    this.set("order", order);
  }

  public getQuantity(): number {
    return this.get("quantity") || 0;
  }
}

Parse.Object.registerSubclass("RestaurantOrderDish", RestaurantOrderDish);
