
import {
  Table,
  ROOM_TYPES,
  findJRoomTypeByLocalId,
  Rank,
  Hotel,
  Turn,
  JPASTA_MEALS,
  Meal,
  Jpasta,
} from "@/jpasta-sdk";
import _ from "lodash";
import DragList from "./DragList.vue";
import ColorHash from "color-hash";
import setScrollTopMixin from "@/mixins/setScrollTopMixin";
import Color from "color";
import TurnSelect from "./TurnSelect.vue";
import mixins from "vue-typed-mixins";
import EditTurnModal from "./EditTurnModal.vue";
let keyDownPressed = false;
let keyDownPressedTimeout;
const chash = new ColorHash({ lightness: 0.5, saturation: 0.4 });
let clickTimeout;
let mouseGoUp = false;
export default mixins().extend({
  mixins: [setScrollTopMixin],
  components: {
    DragList,
    TurnSelect,
    EditTurnModal,
  },
  data: function (): {
    editingRankLocalId: string | undefined;
    editingTurnLocalId: string | undefined;
    disalogVisibleTurn: boolean;
    displayMode: "byRank" | "byTurn";
    editingTableId?: string;
    searchTableString?: string;
    dialogVisible: boolean;
    dialogVisibleRank: boolean;
    showTableDuplicateName: boolean;

    meals: Meal[];
  } {
    return {
      editingTurnLocalId: undefined,
      editingRankLocalId: undefined,
      editingTableId: undefined,
      dialogVisible: false,

      dialogVisibleRank: false,
      displayMode: "byRank",
      showTableDuplicateName: false,
      searchTableString: undefined,
      disalogVisibleTurn: false,

      meals: JPASTA_MEALS,
    };
  },

  mounted() {
    // window.addEventListener("keyup", this.handleKeyUp);
    window.addEventListener("keydown", this.handleKeyDown);
    this.setScrollTop();
  },

  beforeDestroy() {
    // window.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("keydown", this.handleKeyDown);
  },

  methods: {
    setToTime(turn: Turn, time: string, mealIndex: number) {
      turn.setToTime(time, mealIndex);
      this.$store.dispatch("turns/saveObj", turn);
    },
    setFromTime(turn: Turn, time: string, mealIndex: number) {
      turn.setFromTime(time, mealIndex);
      this.$store.dispatch("turns/saveObj", turn);
    },
    getLocalizedName(name): string {
      return Jpasta.getLocalizedName(name, "it", "");
    },
    pad: function (number): any {
      number = typeof number == "number" ? number.toString() : number;

      return number < 10 ? "0" + number : number;
    },
    changeDisplayMode(mode) {
      this.displayMode = mode;
    },
    onSelectTurn(turn) {
      const table = this.editingTable;
      if (!table) return;
      table.set("turn", turn);
      this.$store.dispatch("tables/saveObj", table);
    },
    hasPreviousRank(rank): boolean {
      if (!rank) return false;
      return this.rankIndexMap[rank.get("localId")] > 0;
    },
    hasNextRank(rank): boolean {
      if (!rank) return false;
      return this.rankIndexMap[rank.get("localId")] < this.ranks.length - 1;
    },
    nextRank(rank): void {
      const index = this.rankIndexMap[rank.get("localId")] + 1;
      const newRank = this.ranks[index];
      this.editRank(newRank);
    },
    previousRank(rank): void {
      const index = this.rankIndexMap[rank.get("localId")] - 1;
      const newRank = this.ranks[index];
      this.editRank(newRank);
    },
    nextTable(table): void {
      const index = this.tablesIndexMapByLocalId[table.get("localId")] + 1;
      const newTable =
        this.$store.state.tables.objects[
          this.tablesIndexMapByTableIndex[index]
        ];
      this.editTable(newTable);
    },
    previousTable(table): void {
      const index = this.tablesIndexMapByLocalId[table.get("localId")] - 1;
      const newTable =
        this.$store.state.tables.objects[
          this.tablesIndexMapByTableIndex[index]
        ];
      this.editTable(newTable);
    },
    hasPreviousTable(table): boolean {
      if (!table) return false;

      return this.tablesIndexMapByLocalId[table.get("localId")] > 0;
    },
    hasNextTable(table): boolean {
      if (!table) return false;

      return (
        this.tablesIndexMapByLocalId[table.get("localId")] <
        this.tablesIndexMapByTableIndex.length - 1
      );
    },
    resetTimeout(): void {
      if (keyDownPressedTimeout) clearTimeout(keyDownPressedTimeout);
      keyDownPressedTimeout = setTimeout(() => {
        keyDownPressed = false;
        keyDownPressedTimeout = undefined;
      }, 500);
    },
    keyDownDebounce(): boolean {
      if (keyDownPressed) {
        this.resetTimeout();
        return true;
      }

      keyDownPressed = true;

      this.resetTimeout();

      return false;
    },
    handleKeyDown: function (e): void {
      if (e.keyCode === 13 && e.shiftKey) {
        e.preventDefault();
        if (this.keyDownDebounce()) return;
        if (this.editingRank) {
          this.newRank();
        } else {
          this.newTable();
        }
      }
    },
    changeTableRank: function ({ itemId, rowId }): void {
      const table = this.$store.state.tables.objects[itemId] as Table;

      if (rowId) {
        const rank = this.$store.state.ranks.objects[rowId];

        table.set("rank", rank);
      } else {
        console.log("UNSET RANK", rowId);
        table.unset("rank");
        console.log("table", table.get("rank"));
      }

      this.$store.dispatch("tables/saveObj", table);
    },
    changeTableTurn: function ({ itemId, rowId }): void {
      const table = this.$store.state.tables.objects[itemId];

      if (rowId) {
        const turn = this.$store.state.turns.objects[rowId];

        table.set("turn", turn);
      } else {
        table.unset("turn");
      }

      this.$store.dispatch("tables/saveObj", table);
    },

    setTableName: function (name): any {
      this.showTableDuplicateName = false;

      if (
        _.find(this.tables, (t) => {
          return t.get("name") === name;
        })
      ) {
        return (this.showTableDuplicateName = true);
      }
      this.setTableProperty("name", name);
    },
    deleteTurn: function (turn: Turn): any {
      return this.$dialog
        .confirm(
          "Vuoi davvero eliminare questo turno? Procedendo tutti i tavoli associati diventeranno senza turno",
          {
            okText: "Continua",
            cancelText: "Annulla",
          }
        )
        .then(() => {
          const tables: Table[] = this.getTablesOfTurn(turn);

          const table2save: Table[] = [];
          _.forEach(tables, (t) => {
            t.unset("turn");
            table2save.push(t);
          });

          this.$store
            .dispatch("tables/saveObjBatch", table2save)
            .then(() => {});

          this.$store.dispatch("turns/deleteObj", turn);
        })
        .catch(() => {});
    },
    deleteRank: function (rank): any {
      return this.$dialog
        .confirm(
          "Vuoi davvero eliminare questo rango? Procedendo tutti i tavoli associati diventeranno senza rango",
          {
            okText: "Continua",
            cancelText: "Annulla",
          }
        )
        .then(() => {
          const tables: Table[] = this.getTablesOfRank(rank);

          const table2save: Table[] = [];
          _.forEach(tables, (t) => {
            t.unset("rank");
            table2save.push(t);
          });

          this.$store
            .dispatch("tables/saveObjBatch", table2save)
            .then(() => {});

          this.$store.dispatch("ranks/deleteObj", rank);
        })
        .catch(() => {});
    },

    newRank: function (): any {
      const rank = new Rank();
      rank.set("hotel", this.hotel);
      return this.$store.dispatch("ranks/saveObj", rank).then((rank) => {
        this.editRank(rank);
      });
    },
    newTurn: function (): any {
      const turn = new Turn();
      turn.set("hotel", this.hotel);
      return this.$store.dispatch("turns/saveObj", turn).then((turn) => {
        this.editTurn(turn);
      });
    },

    deleteTable: function (table): any {
      return this.$dialog
        .confirm("Vuoi davvero eliminare questo tavolo?", {
          okText: "Continua",
          cancelText: "Annulla",
        })
        .then(() => {
          this.clearEditTable();
          return this.$store.dispatch("tables/deleteObj", table);
        })
        .catch(() => {});
    },
    setTableProperty(propertyName, value): any {
      const table = this.editingTable;
      if (!table) return;
      if (value === undefined || value === null) {
        table.unset(propertyName);
      } else {
        table.set(propertyName, value);
      }

      this.$store.dispatch("tables/saveObj", table);
    },
    setRankProperty(propertyName, value): any {
      const rank = this.editingRank;
      if (!rank) return;
      if (value === undefined || value === null) {
        rank.unset(propertyName);
      } else {
        rank.set(propertyName, value);
      }

      this.$store.dispatch("ranks/saveObj", rank);
    },
    setTurnProperty(propertyName: string, value: any): void {
      const turn = this.editingTurn;
      if (!turn) return;
      if (value === undefined || value === null) {
        turn.unset(propertyName);
      } else {
        turn.set(propertyName, value);
      }

      this.$store.dispatch("turns/saveObj", turn);
    },

    beforeCloseEditModal: function (): any {
      this.clearEditTable();
    },
    beforeCloseEditModalRank: function (): any {
      this.clearEditRank();
    },
    beforeCloseEditModalTurn: function (): any {
      console.log("beforeCloseEditModalTurn");
      this.clearEditTurn();
    },
    newTable: function (): any {
      const table = new Table();
      table.set("hotel", this.hotel);
      this.$store.dispatch("tables/saveObj", table).then((table) => {
        this.editTable(table);
      });
    },
    getTableName: function (table): any {
      const name = table.get("name");
      return name && name.length ? name : "Senza nome";
    },
    editTable: function (table): any {
      this.showTableDuplicateName = false;
      this.editingTableId = table.get("localId");
      this.dialogVisible = true;
      this.$nextTick(() => {
        if (this.$refs.tableNameInput && this.$mq !== "xs") {
          const el: HTMLInputElement = this.$refs
            .tableNameInput as HTMLInputElement;

          el.focus();
        }
      });
    },
    editTurn: function (turn: Turn): void {
      this.editingTurnLocalId = turn.get("localId");
      this.disalogVisibleTurn = true;
      this.$nextTick(() => {
        if (this.$refs.turnNameInput && this.$mq !== "xs") {
          const el: HTMLInputElement = this.$refs
            .turnNameInput as HTMLInputElement;
          el.focus();
        }
      });
    },
    editRank: function (rank: Rank): any {
      this.editingRankLocalId = rank.get("localId");
      this.dialogVisibleRank = true;
      this.$nextTick(() => {
        if (this.$refs.rankNameInput && this.$mq !== "xs") {
          const el: HTMLInputElement = this.$refs
            .rankNameInput as HTMLInputElement;
          el.focus();
        }
      });
    },
    clearEditTable: function (): any {
      this.editingTableId = undefined;
      this.dialogVisible = false;
    },
    clearEditRank: function (): any {
      this.editingRankLocalId = undefined;
      this.dialogVisibleRank = false;
    },
    clearEditTurn: function (): any {
      this.editingTurnLocalId = undefined;
      this.disalogVisibleTurn = false;
    },
  },
  computed: {
    editingRank: function (): Rank | undefined {
      if (!this.editingRankLocalId) return undefined;
      return this.$store.state.ranks.objects[this.editingRankLocalId];
    },
    editingTurn: function (): Turn | undefined {
      if (!this.editingTurnLocalId) return undefined;
      return this.$store.state.turns.objects[this.editingTurnLocalId];
    },
    turns: function (): Turn[] {
      return _.filter(this.$store.state.turns.objects, (t: Turn) => {
        return t.belongsTo(this.hotel, "hotel");
      });
    },
    buildRowsByRank: function (): any {
      return _.memoize(
        () => {
          return [
            {
              id: null,
              name: "Senza rango",
              items: _.map(this.getTablesOfRank(null), (t) => {
                return {
                  name: t.get("name"),
                  id: t.get("localId"),
                };
              }),
            },
            ..._.map(this.ranks, (r) => {
              return {
                id: r.get("localId"),
                name: r.get("name"),
                items: _.map(this.getTablesOfRank(r), (t) => {
                  return {
                    name: t.get("name"),
                    id: t.get("localId"),
                  };
                }),
              };
            }),
          ];
        },
        () => {
          return (
            this.$store.state.ranks.version +
            "-" +
            this.$store.state.tables.version +
            "-" +
            this.displayMode
          );
        }
      );
    },

    buildRowsByTurn: function (): any {
      return _.memoize(
        () => {
          return [
            {
              id: null,
              name: "Senza turno",
              items: _.map(this.getTablesOfTurn(null), (t) => {
                return {
                  name: t.get("name"),
                  id: t.get("localId"),
                };
              }),
            },
            ..._.map(this.turns, (t) => {
              return {
                id: t.get("localId"),
                name: t.get("name"),
                items: _.map(this.getTablesOfTurn(t), (t) => {
                  return {
                    name: t.get("name"),
                    id: t.get("localId"),
                  };
                }),
              };
            }),
          ];
        },
        () => {
          return (
            this.$store.state.turns.version +
            "-" +
            this.$store.state.tables.version +
            "-" +
            this.displayMode
          );
        }
      );
    },
    editingTable: function (): Table | null {
      return this.editingTableId
        ? this.$store.state.tables.objects[this.editingTableId]
        : null;
    },
    getTablesOfRank: function (): (rank: Rank | null) => Table[] {
      return _.memoize(
        (rank: Rank | null) => {
          const tables = _.filter(this.tables, (t) => {
            return rank !== null
              ? t.belongsTo(rank, "rank")
              : t.get("rank") === undefined || t.get("rank") === null;
          });

          return _.sortBy(tables, (t) => {
            const val = parseInt(t.get("name")) || Infinity;
            return val;
          });
        },
        (rank) => {
          return (
            (rank !== null ? rank.get("localId") : "notable") +
            "-" +
            this.$store.state.tables.version
          );
        }
      );
    },
    getTablesOfTurn: function (): (turn: Turn | null) => Table[] {
      return _.memoize(
        (turn: Turn | null) => {
          const tables = _.filter(this.tables, (t) => {
            return turn !== null
              ? t.belongsTo(turn, "turn")
              : t.get("turn") === undefined || t.get("turn") === null;
          });

          return _.sortBy(tables, (t) => {
            const val = parseInt(t.get("name")) || Infinity;
            return val;
          });
        },
        (turn) => {
          return (
            (turn !== null ? turn.get("localId") : "notable") +
            "-" +
            this.$store.state.tables.version
          );
        }
      );
    },
    rankIndexMap: function (): any {
      return _.reduce(
        this.ranks,
        (res, r, index) => {
          res[r.get("localId")] = index;
          return res;
        },
        {}
      );
    },
    ranks: function (): Rank[] {
      return _.filter(this.$store.state.ranks.objects, (r) => {
        return r.belongsTo(this.hotel, "hotel");
      });
    },
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    tablesIndexMapByLocalId: function (): any {
      return _.reduce(
        this.tablesIndexMapByTableIndex,
        (res, tId, index) => {
          res[tId] = index;
          return res;
        },
        {}
      );
    },
    tablesIndexMapByTableIndex: function (): any {
      const rows = this.buildRowsByRank();
      const res: any[] = [];
      let tableIndex = 0;
      _.forEach(rows, (r) => {
        _.forEach(r.items, (t) => {
          res[tableIndex] = t.id;
          tableIndex++;
        });
      });
      return res;
    },
    tables: function (): Table[] {
      return _.filter(this.$store.state.tables.objects, (t) => {
        return t.belongsTo(this.hotel, "hotel");
      });
    },
  },
});
