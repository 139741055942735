
import moment from "moment-timezone";
let mouseGoUp = false;
import {
  findJMealByLocalId,
  Meal,
  Turn,
  CalendarMeal,
  Hotel,
  BookPerson,
  Book,
} from "@/jpasta-sdk";
import ProgressBar from "@/components/ui/ProgressBar.vue";
import mixins from "vue-typed-mixins";
import _, { Dictionary } from "lodash";
export default mixins().extend({
  components: {
    ProgressBar,
  },
  props: {
    meals: {
      type: Array as () => CalendarMeal[],
    },
    hotel: {
      type: Object as () => Hotel,
    },
    bookPeopleMap: {
      type: Object as () => Dictionary<BookPerson[]>,
    },
    onClick: {
      type: Function as () => any,
    },
  },
  methods: {
    getMeal(mealIndex): Meal | undefined {
      return findJMealByLocalId(mealIndex);
    },
    computeOrderProgressColor(cMeal) {
      const progress = this.computeOrderProgress(cMeal);
      if (progress < 40) {
        return "rgba(244, 68, 63,0.7)";
      } else if (progress < 80) {
        return "rgba(222,207,0,0.7)";
      } else {
        return "rgba(79,196,0,0.7)";
      }
    },
    getLocalBook(book: Book): Book | undefined {
      if (!book) return undefined;
      if (!book.get("localId")) return undefined;

      return this.$store.state.books.objects[book.get("localId")];
    },
    totalIncludedPeopleOf(cMeal: CalendarMeal): number {
      const people = _.filter(
        this.$store.state.bookPersons.objects as Dictionary<BookPerson>,
        (bp) => {
          const book = this.getLocalBook(bp.get("book"));

          if (!book) return false;
          if (!book.isIncluded(cMeal)) return false;
          if (!book.belongsTo(this.hotel, "hotel")) return false;
          if (book.get("stafferBook")) return false;
          return (
            book.get("checkin") &&
            book.get("checkout") &&
            bp.canOrder(cMeal, undefined)
          );
        }
      );

      return people.length;
      // const activeBooks = _.filter(
      //   this.$store.state.books.objects as Dictionary<Book>,
      //   book => {
      //     return (
      //       book.get("checkin") &&
      //       book.get("checkout") &&
      //       book.isIncluded(cMeal) &&
      //       book.belongsTo(this.hotel, "hotel") &&
      //       moment(cMeal.get("date")).isBetween(
      //         book.get("checkin"),
      //         book.get("checkout")
      //       )
      //     );
      //   }
      // );

      // const totalPeople = _.reduce(
      //   activeBooks,
      //   (res, book) => {
      //     if (!book.isIncluded(cMeal)) return res;

      //     return (res =
      //       res +
      //       (this.bookPeopleMap[book.get("localId")]
      //         ? this.bookPeopleMap[book.get("localId")].length
      //         : 0));
      //   },
      //   0
      // );

      // return totalPeople;
    },

    totalIncludedCompletedOrdersOf(cMeal): number {
      return _.filter(this.$store.state.mealOrders.objects, (mealOrder) => {
        const book: Book = mealOrder.get("book");
        const person: BookPerson = mealOrder.get("person");
        return (
          person &&
          book &&
          book.get("checkin") &&
          book.get("checkout") &&
          book.isIncluded(cMeal) &&
          mealOrder.belongsTo(cMeal, "calendarMeal") &&
          mealOrder.get("completed")
        );
      }).length;
    },
    computeOrderProgress(cMeal): number {
      const totalPeople = this.totalIncludedPeopleOf(cMeal);
      const totalCompletedOrders = this.totalIncludedCompletedOrdersOf(cMeal);

      if (totalPeople <= 0) return 100;

      return Math.round((totalCompletedOrders / totalPeople) * 100);
    },
    isInCurrentPeriod(cMeal): boolean {
      return moment().isBetween(cMeal.get("fromDate"), cMeal.get("toDate"));
    },
    mouseUp: function (): void {
      mouseGoUp = true;
    },
    goToMeal: function (meal): any {
      mouseGoUp = false;
      setTimeout(() => {
        if (!mouseGoUp) return;
        if (this.onClick) {
          return this.onClick(meal);
        }

        this.toMealBookDetail(meal);
      }, 150);
    },
    toMealBookDetail: function (meal): void {
      if (this.turns.length <= 0) {
        this.$router
          .push({
            name: "HotelMealBook",
            params: {
              hotelid: this.hotel.get("localId"),
              calendarmealid: meal.get("localId"),
            },
          })
          .catch((e) => {});
      } else {
        this.$router
          .push({
            name: "MealBookTurnScreen",
            params: {
              hotelid: this.hotel.get("localId"),
              calendarmealid: meal.get("localId"),
            },
          })
          .catch((e) => {});
      }
    },
    formatMealHours: function (date): string {
      return moment(date)
        .tz(this.hotel.get("timezone") || "Europe/Rome")
        .format("HH:mm");
    },
    getMealsDateFromMeals(meals): string | null {
      if (!meals.length) return null;
      return moment(meals[0].get("date"))
        .tz(this.hotel.get("timezone"))
        .format("DD MMMM YYYY");
    },
  },
  computed: {
    turns: function (): Turn[] {
      return _.filter(this.$store.state.turns.objects, (turn) =>
        turn.belongsTo(this.hotel, "hotel")
      );
    },
  },
});
