var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"room",staticClass:"room",attrs:{"data-itemnumber":_vm.item}},[_vm._l((_vm.booksByItem),function(book){return _c('div',{key:book.book.get('localId'),ref:`book-${book.book.get('localId')}`,refInFor:true,staticClass:"book-gantt",class:{
      'display-hidden': _vm.dragging == book.book.get('localId'),
    },style:({
      left: book.offset,
      width: book.width,
      'background-color': book.color,
    }),attrs:{"data-book":book.book.get('localId'),"title":book.book.get('refName')
        ? book.book.get('refName')
        : book.book.get('refEmail')},on:{"dragstart":_vm.onDragStart,"contextmenu":function($event){$event.preventDefault();},"touchstart":function($event){return _vm.onTouchStart(book.book, $event)},"mousedown":function($event){$event.preventDefault();return _vm.onMouseDownInternal(book.book, $event)}}},[_c('span',{staticClass:"name",staticStyle:{"margin-left":"auto","margin-right":"auto"}},[_vm._v(_vm._s(book.book.get("refName") ? book.book.get("refName") : book.book.get("refEmail")))])])}),_c('days',{staticStyle:{"width":"100%"},attrs:{"item":_vm.item,"dayWidth":_vm.dayWidth,"days":_vm.days},on:{"startBookCreation":_vm.startBookCreation}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }