<template>
  <div class="register-wrapper">
    <jcard v-if="step === 0" class="register-card">
      <h2 class="text-center" slot="header">Come ti chiami?</h2>
      <div class="body">
        <jinput
          v-on:tabpressed="$refs.surname.focus()"
          v-on:enterpressed="toStep1"
          ref="nameinput"
          type="text"
          class="register-input"
          placeholder="Nome"
          v-model="name"
        ></jinput>
        <jinput
          v-on:enterpressed="toStep1"
          type="text"
          class="register-input"
          placeholder="Cognome"
          v-model="surname"
          ref="surname"
        ></jinput>
        <jbutton v-on:click.prevent.stop="toStep1" class="register-button"
          >Avanti</jbutton
        >
      </div>
    </jcard>
    <jcard v-if="step === 1" class="register-card">
      <h2 class="text-center" slot="header">Scegli la tua password</h2>
      <div class="body">
        <p class="explanation">Minimo 8 caratteri</p>
        <jinput
          v-on:enterpressed="toStep2"
          ref="passwordinput"
          type="password"
          class="register-input"
          placeholder="Password"
          v-model="password"
        ></jinput>
        <jbutton
          :loading="loading"
          v-on:click.prevent.stop="toStep2"
          class="register-button"
          >Avanti</jbutton
        >
      </div>
    </jcard>
  </div>
</template>

<script>
import Parse from "parse";
export default {
  data: function () {
    return {
      name: "",
      surname: "",
      password: "",
      email: "",
      step: 0,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.query.step) {
      switch (parseInt(this.$route.query.step, 10)) {
        case 0:
          this.email = this.$route.query.email;
          this.name = "";
          this.surname = "";
          this.step = 0;
          this.$nextTick(() => {
            this.$refs.nameinput.focus();
          });
          break;
        case 1:
          this.step = 1;
          this.name = this.$route.query.name;
          this.surname = this.$route.query.surname;
          this.email = this.$route.query.email;
          this.$nextTick(() => {
            this.$refs.passwordinput.focus();
          });
          break;
      }
    } else {
      this.step = 0;
      this.email = this.$route.query.email;
      this.$nextTick(() => {
        this.$refs.nameinput.focus();
      });
    }
  },
  methods: {
    toStep1: function () {
      if (this.name.length && this.surname.length) {
        this.$router.push({
          name: this.$route.name,
          query: {
            name: this.name,
            surname: this.surname,
            step: 1,
            ...this.$route.query,
          },
        });
        this.step = 1;
        this.$nextTick(() => {
          this.$refs.passwordinput.focus();
        });
      } else {
        this.$msg("I campi nome e cognome sono richiesti");
      }
    },
    toStep2: async function () {
      if (this.password.length > 7) {
        this.loading = true;
        try {
          await this.$store.dispatch("users/signUp", {
            username: this.email,
            email: this.email,
            password: this.password,
            name: this.name,
            surname: this.surname,
          });

          return this.$router
            .push({
              name: "NewHotel",
            })
            .catch((e) => {});
        } catch (e) {
          this.loading = false;
          return this.$msg("Ops, qualcosa non è proprio andato a buon fine :(");
        }
      } else {
        this.$msg("Inserisci una password valida (minimo 8 caratteri)");
      }
    },
  },
};
</script>

<style scoped>
.register-input {
  width: 90%;
  margin-top: 0.8em;
}

.register-button {
  margin-top: 1em;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2em;
}

.register-card {
  font-size: 16px;
}

.explanation {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.4);
}

.register-card {
  width: 90%;
}

@media only screen and (min-width: 768px) {
  .register-card {
    max-width: 560px;
  }

  .register-input {
    width: 70%;
  }
}

.register-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #f4443f;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
