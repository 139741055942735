import Parse from "parse";
import Jpasta from "./Jpasta";
import moment from "moment";

export type AgeType = "baby" | "child" | "normal";

export default class BookPerson extends Jpasta {
  constructor() {
    super("BookPerson");
  }

  public getAgeType(): AgeType | undefined {
    //return this.get("ageType");
    return "normal";
  }

  public setAgeType(v: AgeType) {
    this.set("ageType", v);
  }

  public canOrder(calendarMeal, book) {
    book = book ? book : this.get("book");

    if (book.get("stafferBook")) return true;

    const checkin = moment(this.get("checkin") || book.get("checkin"));
    const checkout = moment(this.get("checkout") || book.get("checkout"));

    return moment(calendarMeal.get("date")).isBetween(checkin, checkout);
  }
}

Parse.Object.registerSubclass("BookPerson", BookPerson);
