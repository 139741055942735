
import _ from "lodash";
import moment from "moment-timezone";
import Vue from "vue";
import Parse from "parse";
import EditMealBookListItem from "./EditMealBookListItem.vue";
import mixins from "vue-typed-mixins";
import {
  getMealDate,
  JPASTA_COURSES,
  JPASTA_MEALS,
  MealOrder,
  MealOrderDish,
  Jpasta,
  findJMealByLocalId,
  DRINK,
  Book,
  CalendarMeal,
  Hotel,
  BookPerson,
  Dish,
} from "@/jpasta-sdk";
import { EditMealBook } from "@/components/Hotel/MealBook/EditMealBook";
export default mixins().extend({
  components: {
    EditMealBookListItem,
  },
  data: function (): {
    loadingForSync: boolean;
    annotationModalVisible: boolean;
    editingOrderLocalId: string | undefined;
    editingDishLocalId: string | undefined;
    version: number;
  } {
    return {
      loadingForSync: true,
      annotationModalVisible: false,
      editingOrderLocalId: undefined,
      editingDishLocalId: undefined,
      version: 0,
    };
  },
  mounted(): any {
    if (this.useNewInterfaceForOrderEdit) return;
    _.debounce(async () => {
      await this.fetchData();

      await this.syncOrders();

      _.debounce(() => {
        if (this.$refs["recycler-scroller"])
          //@ts-ignore
          this.$refs["recycler-scroller"].forceUpdate();
      }, 1200)();
    }, 200)();
  },
  methods: {
    goBack: function () {
      this.$router.push({
        name: "HotelMealBook",
        params: {
          hotelid: this.hotel.get("localId"),
          calendarmealid: this.$route.params.calendarmealid,
        },
      });
    },
    setOrderCompleted: function (order: MealOrder, val: "yes" | "no") {
      order.set("completed", val === "yes");
      return this.$store.dispatch("mealOrders/saveObj", order);
    },
    setOrderLocationNotes: function (order: MealOrder, text: string) {
      order.set("locationExpl", text);
      return this.$store.dispatch("mealOrders/saveObj", order);
    },
    setModeOf: function (
      order: MealOrder,
      mode: "delivery" | "takeaway" | "normal"
    ) {
      switch (mode) {
        case "delivery":
          order.set("delivery", true);
          order.set("takeAway", false);
          break;
        case "takeaway":
          order.set("delivery", false);
          order.set("takeAway", true);
          break;
        case "normal":
          order.set("delivery", false);
          order.set("takeAway", false);
          break;
      }

      return this.$store.dispatch("mealOrders/saveObj", order);
    },
    getModeOf: function (order: MealOrder): "delivery" | "takeaway" | "normal" {
      if (!order.get("delivery") && !order.get("takeAway")) return "normal";
      else if (order.get("delivery")) return "delivery";
      else return "takeaway";
    },
    getEditOrderTitle: function (): any {
      if (!this.editingOrder) return "";
      const person = this.editingOrder.get("person");
      if (!person) return "";

      return `Modifica ordine per ${person.get("name")}`;
    },
    beforeCloseAnnotationModal: function (): any {
      this.annotationModalVisible = false;
      this.editingOrderLocalId = undefined;
    },
    openEditModal: function ({ orderLocalId }): any {
      this.editingOrderLocalId = orderLocalId;
      // this.editingDishLocalId = dishLocalId;
      this.annotationModalVisible = true;
    },
    fetchData: async function (): Promise<any> {
      this.loadingForSync = true;
      const bookPeopleQuery = new Parse.Query(BookPerson);
      bookPeopleQuery.equalTo("book", this.book);

      const mealOrdersQuery = new Parse.Query(MealOrder);
      mealOrdersQuery.equalTo("calendarMeal", this.calendarMeal);

      const batch: Promise<any>[] = [];
      batch.push(
        this.$store.dispatch("bookPersons/getObjects", {
          query: bookPeopleQuery,
          //clear: true
        })
      );
      batch.push(
        this.$store.dispatch("mealOrders/getObjects", {
          query: mealOrdersQuery,
          clear: true,
        })
      );

      let mealOrderDishesQuery = new Parse.Query(MealOrderDish);
      mealOrderDishesQuery.matchesKeyInQuery(
        "order",
        "objectId",
        mealOrdersQuery
      );

      batch.push(
        this.$store.dispatch("mealOrderDishes/getObjects", {
          query: mealOrderDishesQuery,
          clear: true,
        })
      );

      // Promise.all(batch).then(() => {

      // });

      await Promise.all(batch);

      this.loadingForSync = false;
    },
    getFormattedDate: function (date): any {
      return moment(date)
        .tz(this.hotel.get("timezone"))
        .format("ddd D MMMM YYYY [ore] HH:mm");
    },

    syncOrders: async function (): Promise<any> {
      const batch: Promise<any>[] = [];

      _.forEach(this.bookPeople, (person) => {
        let mealOrder = _.find(this.mealOrders, (order) => {
          return order.belongsTo(person, "person");
        });

        if (mealOrder) return;

        mealOrder = new MealOrder();
        mealOrder.set("calendarMeal", this.calendarMeal);
        mealOrder.set("book", this.book);
        mealOrder.set("person", person);
        mealOrder.set("hotel", this.hotel);
        batch.push(
          this.$store.dispatch("mealOrders/saveObjSync", {
            obj: mealOrder,
            genByProgram: true,
          })
        );
      });
      //console.log("SYNCINC", batch.length, person);
      await Promise.all(batch);
    },

    isHalfPortion: function (orderLocalId, dishLocalId): boolean {
      const order = this.$store.state.mealOrders.objects[orderLocalId];
      const dish = this.$store.state.dishes.objects[dishLocalId];
      const odish = this.getOdishBy(order, dish);
      if (!odish) return false;
      return odish.isHalfPortion();
    },
    setHalfPortion: function (
      orderLocalId: string,
      dishLocalId: string,
      value: boolean
    ) {
      const order = this.$store.state.mealOrders.objects[orderLocalId];
      const dish = this.$store.state.dishes.objects[dishLocalId];
      const odish = this.getOdishBy(order, dish);
      if (!odish) return;
      odish.setHalfPortion(value);
      this.$store.dispatch("mealOrderDishes/saveObj", odish);
    },

    getLocalizedName: function (name): any {
      return Jpasta.getLocalizedName(name, "it", "Nessun nome");
    },

    setDishAnnotation: function (orderLocalId, dishLocalId, value): any {
      const order = this.$store.state.mealOrders.objects[orderLocalId];
      const dish = this.$store.state.dishes.objects[dishLocalId];
      const odish = this.getOdishBy(order, dish);

      if (!odish) return;

      odish.set("annotation", value);

      this.$store.dispatch("mealOrderDishes/saveObj", odish);
    },

    getDishAnnotation: function (orderLocalId, dishLocalId): any {
      try {
        let order = this.$store.state.mealOrders.objects[orderLocalId];
        return _.find(this.$store.state.mealOrderDishes.objects, (oDish) => {
          return (
            oDish.belongsTo(order, "order") &&
            oDish.get("dish").get("localId") === dishLocalId &&
            oDish.get("quantity") >= 1
          );
        }).get("annotation");
      } catch (e) {
        return "";
      }
    },
    getOdishBy: function (order, dish): MealOrderDish {
      return this.odishByOrderAndDishMap[
        `${order.get("localId")}-${dish.get("localId")}`
      ];
    },
    getMealOrderDishesOf: function (order: MealOrder): MealOrderDish[] {
      return _.filter(
        this.$store.state.mealOrderDishes.objects,
        (mod: MealOrderDish) => {
          return mod.belongsTo(order, "order") && mod.get("dish");
        }
      );
    },
    getOrderedDishByOrder: function (order: MealOrder): Dish[] {
      const oDishes = this.getMealOrderDishesOf(order);

      return _.map(
        _.filter(oDishes, (od) => {
          return (od.get("quantity") || 0) > 0;
        }),
        (od) => {
          return od.get("dish");
        }
      );
    },
  },
  computed: {
    EditMealBookReact() {
      return EditMealBook;
    },
    useNewInterfaceForOrderEdit: function (): boolean {
      return this.hotel.get("useNewInterfaceForOrderEdit");
    },
    editingOrder: function (): MealOrder | null {
      if (!this.editingOrderLocalId) return null;
      return this.$store.state.mealOrders.objects[this.editingOrderLocalId];
    },
    mealOrderDishes: function (): MealOrderDish[] {
      return _.filter(
        this.$store.state.mealOrderDishes.objects,
        (mod: MealOrderDish) => {
          const order = mod.get("order");
          const dish = mod.get("dish");
          return !!(order && dish);
        }
      );
    },
    odishByOrderAndDishMap: function (): { [key: string]: MealOrderDish } {
      return _.keyBy(this.mealOrderDishes, (mod) => {
        const order = mod.get("order");
        const dish = mod.get("dish");
        return `${order.get("localId")}-${dish.get("localId")}`;
      });
    },

    book: function (): Book {
      return this.$store.state.books.objects[this.$route.params.bookid];
    },

    calendarMeal: function (): CalendarMeal {
      return this.$store.state.calendarMeals.objects[
        this.$route.params.calendarmealid
      ];
    },
    meal: function (): any {
      let meal = findJMealByLocalId(this.calendarMeal.get("mealIndex"));

      return meal;
    },
    hotel: function (): Hotel {
      return this.$store.state.hotels.objects[this.$route.params.hotelid];
    },
    items2render: function (): any {
      const items = _.map(this.displayedMealOrders, (o) => {
        return {
          version: this.$store.state.mealOrders.version,
          order: _.cloneDeep(o),
          key: o.get("localId"),
          realVersion: this.version,
        };
      });

      return items;
    },
    displayedMealOrders: function (): MealOrder[] {
      return _.filter(this.mealOrders, (order) => {
        const person: BookPerson = order.get("person");
        return !!person && !!person.canOrder(this.calendarMeal, undefined);
      });
    },
    mealOrders: function (): MealOrder[] {
      return _.filter(this.$store.state.mealOrders.objects, (order) => {
        return (
          order.belongsTo(this.book, "book") &&
          order.belongsTo(this.calendarMeal, "calendarMeal")
        );
      });
    },
    bookPeople: function (): BookPerson[] {
      return _.filter(this.$store.state.bookPersons.objects, (person) => {
        return person.belongsTo(this.book, "book");
      });
    },
  },
});
