<template>
  <div class="outer-div">
    <div
      class="jinput"
      :class="{
        'no-border': noBorder,
        disabled: disabled,
      }"
    >
      <input
        v-if="type !== 'textarea'"
        class="input-inner"
        v-on:keyup.up.prevent.stop
        v-on:keyup.down.prevent.stop
        v-on:keydown.up.prevent.stop="$emit('up')"
        v-on:keydown.down.prevent.stop="$emit('down')"
        v-on:keydown.delete="deletePressed"
        v-on:keydown.tab="$emit('tabpressed', $event)"
        v-on:blur="onBlur"
        v-on:keyup.esc="escPressed"
        v-on:focus="openList"
        v-on:click.stop.prevent="clicked($event)"
        ref="input"
        v-on:keyup.enter="enterPressed($event)"
        v-on:input="setInput"
        :min="min"
        :max="max"
        :value="input"
        :disabled="disabled"
        :type="type"
        :placeholder="placeholder"
        :class="{
          title: title,
          underlined: underlined,
          'text-center': textCenter,
        }"
        :style="inputStyle ? inputStyle : {}"
      />
      <textarea
        v-else
        class="input-inner"
        v-on:keyup.up.prevent.stop
        v-on:keyup.down.prevent.stop
        v-on:keydown.up.prevent.stop="$emit('up')"
        v-on:keydown.down.prevent.stop="$emit('down')"
        v-on:keydown.delete="deletePressed"
        v-on:keydown.tab="$emit('tabpressed', $event)"
        v-on:blur="onBlur"
        v-on:keyup.esc="escPressed"
        v-on:focus="openList"
        v-on:click.stop.prevent="clicked($event)"
        ref="input"
        v-on:keyup.enter="enterPressed($event)"
        v-on:input="setInput"
        :min="min"
        :max="max"
        :value="input"
        :disabled="disabled"
        :type="type"
        :placeholder="placeholder"
        :class="{
          title: title,
          underlined: underlined,
          'text-center': textCenter,
        }"
        :style="inputStyle ? inputStyle : {}"
      >
      </textarea>
      <jbutton
        style="flex-shrink: 0"
        v-if="innerButtonText"
        v-on:click.prevent.stop="$emit('inner-button-clicked')"
        type="text"
        :disabled="buttonInnerDisabled"
        >{{ innerButtonText }}</jbutton
      >
      <jspinner
        style="flex-shrink: 0"
        class="jinput-spinner"
        type="border-secondary"
        :loading="loading || wait4delay"
      ></jspinner>
      <img v-if="search" src="../../assets/search.svg" class="search-icon" />
    </div>

    <jbutton v-if="buttonText" class="input-button">{{ buttonText }}</jbutton>
    <div
      :style="{
        top: top + 'px',
      }"
      ref="listWrapper"
      class="listWrapper"
      v-show="opened && list && itemToRender.length"
    >
      <slot>
        <ul>
          <li
            v-on:mousedown="itemClicked(item, $event)"
            class="default-li"
            :key="index"
            v-for="(item, index) in itemToRender"
          >
            {{ label ? item[label] : item.label }}
          </li>
        </ul>
      </slot>
    </div>
  </div>
</template>

<script type="text/javascript">
let timeout = undefined;
export default {
  props: {
    loading: {},
    disabled: {},
    showSpinnerOnDelay: { default: true },
    type: {},
    placeholder: {},
    buttonInnerDisabled: {},
    value: {},
    noBorder: {},
    title: {},
    inputStyle: {},
    list: {},
    items: {},
    label: {},
    filter: {},
    customFilterParams: {},
    inputDelay: {},
    search: {},
    min: {},
    max: {},
    buttonText: {},
    underlined: {},
    textCenter: {},
    numberInput: {},
    innerButtonText: {},
  },

  data: function () {
    return {
      input: this.value,
      opened: false,
      top: 0,
      left: 0,
      defaultDelay: 0,
      FOCUS_TYPES: {
        REAL: "real",
        FAKE: "fake",
      },
      wait4delay: false,
    };
  },
  computed: {
    itemToRender: function () {
      return this.filter
        ? _.filter(this.items, (item) => {
            return this.filter(item, this.input, this.customFilterParams);
          })
        : this.items;
    },
  },
  methods: {
    setInput: function (e) {
      let val = e.target.value;

      if (this.numberInput || this.type == "number") {
        val = parseFloat(val) || 0;

        if (this.max !== undefined && val > this.max)
          return (this.input = this.max);
        if (this.min !== undefined && val < this.min)
          return (this.input = this.min);
      }

      this.input = val;
    },
    deletePressed: function () {
      if (!this.input.length) this.$emit("delete");
    },
    onBlur: function () {
      this.opened = false;

      this.$emit("blur");
    },

    escPressed: function (e) {
      this.opened = false;
      this.$refs.input.blur();
    },

    enterPressed: function (ev) {
      if (this.list && this.itemToRender.length) {
        this.$emit("itemClicked", this.itemToRender[0]);
      }
      this.opened = false;
      this.$emit("enterpressed", ev);
    },

    blurInput: function () {
      return new Promise(() => {
        this.opened = false;
      });
    },
    openList: function () {
      if (this.list) {
        this.opened = true;
        this.$nextTick(() => {
          var wrap = this.$refs["listWrapper"];
          if (!wrap) return;
          /*var parent = wrap.parentElement
						var match = this.$refs['input']
						var elWidrh = wrap.clientWidth*/
          var height = this.$refs.input.clientHeight;
          this.top = this.$refs.input.offsetTop + height;

          /*this.left = match[0].offsetLeft + ((width - elWidrh) / 2)*/
        });
      }

      this.$emit("focus");
    },
    clicked: function (e) {
      this.$emit("click", e);
    },
    hasInteracted: function () {
      var interacted = false;

      const onTouchStart = () => {
        interacted = true;
        document.removeEventListener(onTouchStart);
      };

      document.addEventListener("touchstart", onTouchStart);

      return interacted;
    },

    getFocusType: function () {
      return this.hasInteracted() || !this.isIos()
        ? this.FOCUS_TYPES.REAL
        : this.FOCUS_TYPES.FAKE;
    },

    isIos: function () {
      return !!window.navigator.userAgent.match(/iPad|iPhone/i);
    },

    focus: function () {
      return this.$refs.input.focus();
    },
    itemClicked: function (item, e) {
      this.opened = false;
      this.$emit("itemClicked", item, e);
    },
  },
  watch: {
    input: function (newInput) {
      this.$emit("input", newInput);

      const delay =
        this.inputDelay !== undefined ? this.inputDelay : this.defaultDelay;
      if (delay > 0 && this.showSpinnerOnDelay) {
        this.wait4delay = true;
      }

      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        this.$emit("change", this.input);

        this.wait4delay = false;
        timeout = undefined;
      }, delay);
    },
    value: function (newValue) {
      this.input = newValue;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants";

.resizable {
  resize: vertical;
}
.search-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.jinput.disabled {
  opacity: 0.7;
  &,
  input {
    cursor: not-allowed;
  }
}

.input-button {
  position: absolute;
  right: 4px;
  z-index: 1;
  top: 4px;
}

.jinput {
  display: flex;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: $border-radius;
  .jinput-spinner {
    margin-left: auto;
    margin-right: 10px;
  }
  .input-inner {
    height: 38px;
    border: 0px solid #cccccc;
    background-color: #ffffff;
    outline: none;
    padding: 0 10px;
    display: block;
    color: #2c3e50;
    font-size: 1em;
    width: 100%;
    -webkit-appearance: none;

    &[type="textarea"] {
      padding: 8px;
    }

    &::placeholder {
      color: map-get($colors, "secondary");
      opacity: $placeholder-opacity;
    }
  }
}

.no-border {
  border: 0 !important;
}

.jinput {
  .input-inner.underlined {
    height: auto;
    border-bottom: 1px solid transparentize(map-get($colors, "secondary"), 0.6) !important;
    border-radius: 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.outer-div {
  position: relative !important;
}

.jinput:focus {
  border: 1px solid darken(#cccccc, 15%);
}

.input-inner.title {
  font-size: 2em;
  font-weight: 600;
}

.listWrapper {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
  border-radius: 4px;

  z-index: 500;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    li.default-li {
      padding: 8px 10px;
      cursor: pointer;

      font-size: 1em;
    }

    li.default-li:first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    li.default-li:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    li.default-li:hover {
      background-color: rgba(244, 68, 63, 0.8);
      color: white;
    }
  }
}
</style>
