<template>
  <div
    :class="{
      clickable: !!clickable,
    }"
    class="jcard"
  >
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  props: {
    clickable: {},
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";
.jcard {
  border: 1px solid #cccccc;
  border-radius: 16px;
  background-color: #ffffff;
  font-size: 16px;
  box-shadow: 0 5px 15px 0 rgba(78, 90, 99, 0.15);
  padding: 1.2em 1.2em;
  &.clickable:hover {
    background-color: map-get($colors, "list-bg-hover");
  }
}

/*	.jcard .header {
		margin-bottom: 20px;
	}
*/
</style>
