
import Vue from "vue";
import mixins from "vue-typed-mixins";
import {
  MealOrder,
  MealOrderDish,
  Hotel,
  Book,
  Dish,
  Jpasta,
  CalendarMeal,
  findJCourseByLocalId,
} from "@/jpasta-sdk";
import _, { Dictionary } from "lodash";

type ReportDishItem = {
  totalQty: number;
  halfPortionQty: number;
  annotations: string[];
  name: string;
  dish: Dish;
};

type ReportDataItem = {
  book: Book;

  dishes: ReportDishItem[];
};

export default mixins().extend({
  props: {
    title: {
      type: String as () => string,
    },

    mealOrders: {
      type: Array as () => MealOrder[],
    },
    mealOrderDishesMap: {
      type: Object as () => Dictionary<MealOrderDish[]>,
    },
    hotel: {
      type: Object as () => Hotel,
    },
    calendarMeal: {
      type: Object as () => CalendarMeal,
    },
  },
  methods: {
    keepOrder(dishes: ReportDishItem[]) {
      return _.sortBy(
        dishes,
        (d) => {
          const courseIndex = d.dish.get("courseIndex");
          const jCourse = findJCourseByLocalId(courseIndex);
          if (!jCourse) return Infinity;
          return jCourse.pos;
        },
        (d) => {
          const pos = this.cMealDishesOrder[d.dish.get("localId")];
          if (pos === undefined) return Infinity;
          return pos;
        }
      );
    },
  },
  mounted() {},
  computed: {
    cMealDishesOrder: function (): Dictionary<number> {
      return _.reduce(
        this.calendarMeal.get("dishes"),
        (res, d, index) => {
          res[d.get("localId")] = index;
          return res;
        },
        {}
      );
      _.keyBy(this.calendarMeal.get("dishes"), (d, index) => index);
    },
    mealOrdersByBook: function (): Dictionary<MealOrder[]> {
      const mealOrders = _.filter(this.mealOrders, (mo) => !!mo.get("book"));

      return _.groupBy(mealOrders, (mo) =>
        (mo.get("book") as Book).get("localId")
      );
    },
    reportData: function (): ReportDataItem[] {
      const res = _.reduce(
        this.mealOrdersByBook,
        (res: ReportDataItem[], morders) => {
          const book = (_.first(morders) as MealOrder).get("book") as Book;
          const item: ReportDataItem = {
            book,
            dishes: [],
          };

          const dishesIndexMap: Dictionary<number> = {};

          _.forEach(morders, (mo) => {
            const mods = this.mealOrderDishesMap[mo.get("localId")];
            _.forEach(mods, (mod) => {
              const dish = mod.get("dish") as Dish;
              if (!dish) return;
              let index = dishesIndexMap[dish.get("localId")];
              if (index === undefined) {
                item.dishes.push({
                  name: Jpasta.getLocalizedName(dish.get("name"), "it", ""),
                  totalQty: 0,
                  halfPortionQty: 0,
                  annotations: [],
                  dish,
                });
                index = item.dishes.length - 1;
              }

              dishesIndexMap[dish.get("localId")] = index;

              item.dishes[index].totalQty += mod.get("quantity") || 0;
              if (mod.isHalfPortion())
                item.dishes[index].halfPortionQty += mod.get("quantity") || 0;
              const annotation = mod.get("annotation") as string;
              if (_.size(annotation) > 0)
                item.dishes[index].annotations.push(annotation);
            });
          });

          res.push(item);

          return res;
        },
        []
      );

      return _.map(res, (r) => {
        r.dishes = this.keepOrder(r.dishes);
        return r;
      });
    },
  },
});
