<template>
  <div
    class="rootContainer"
    :class="{
      first: index === 0,
    }"
  >
    <div class="person-wrapper">
      <div class="d-flex align-items-center person-header">
        <span class="person-number">#{{ index + 1 }}</span>
        <span
          v-on:click.prevent.stop="deleteBookPerson(person)"
          style="
            width: 15px;
            height: 15px;
            margin-left: auto;
            cursor: pointer;
            opacity: 0.4;
          "
          >&#10005;</span
        >
      </div>
      <div class="person-body">
        <el-row :gutter="16">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="input-field">
              <span class="label">Nome</span>
              <jinput
                v-on:change="setPersonProperty(person, 'name', $event)"
                :value="person.get('name')"
                type="text"
                class="hotel-input"
                placeholder
              ></jinput>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="input-field">
              <span class="label">Cognome</span>
              <jinput
                v-on:change="setPersonProperty(person, 'surname', $event)"
                :value="person.get('surname')"
                type="text"
                class="hotel-input"
                placeholder
              ></jinput>
            </div>
          </el-col>
          <el-col style="margin-top: 10px" :span="24">
            <span class="label">
              Check-in Check-out:
              <jtooltip
                trigger="hover"
                title="Check-in e Check-out per questa persona"
                :autoHide="true"
                style="margin-left: 3px; margin-right: 3px"
                :content="advices.book.personDates"
              >
                <img
                  slot="target"
                  style="
                    opacity: 0.6;
                    cursor: pointer;
                    width: 13px;
                    height: 13px;
                  "
                  src="../../assets/info.svg"
                />
              </jtooltip>
            </span>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-date-picker
              class="book-datetime-picker w-100"
              :value="
                peopleCheckInValues[person.get('localId')] ||
                person.get('checkin') ||
                book.get('checkin')
              "
              :picker-options="{
                firstDayOfWeek: 1,
                disabledDate: (date) => {
                  return personCheckInDisabled(date, person);
                },
              }"
              v-on:input="setPersonCheckIn(person.get('localId'), $event)"
              v-on:change="setPersonProperty(person, 'checkin', $event)"
              placeholder="Check In"
              popper-class="book-datetime-popper"
              type="datetime"
              :default-time="defaultCheckinTime"
              :ref="`person-checkin-${person.get('localId')}`"
              :format="dateFormat"
            ></el-date-picker>
          </el-col>
          <el-col
            class="fix-datepicker-mobile"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <el-date-picker
              :picker-options="{
                firstDayOfWeek: 1,
                disabledDate: (date) => {
                  return personCheckOutDisabled(date, person);
                },
              }"
              class="book-datetime-picker w-100 checkoutInput"
              :value="
                peopleCheckOutValues[person.get('localId')] ||
                person.get('checkout') ||
                book.get('checkout')
              "
              v-on:input="setPersonCheckOut(person.get('localId'), $event)"
              v-on:change="setPersonProperty(person, 'checkout', $event)"
              placeholder="Check Out"
              popper-class="book-datetime-popper"
              type="datetime"
              :ref="`person-checkout-${person.get('localId')}`"
              :default-time="defaultCheckoutTime || undefined"
              :format="dateFormat"
            ></el-date-picker>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { IdState } from "vue-virtual-scroller";
import moment from "moment-timezone";
import advices from "../advices";
export default {
  data: function () {
    return {
      peopleCheckInValues: {},
      peopleCheckOutValues: {},
      advices,
      dateFormat: "dd MMMM yyyy HH:mm",
    };
  },
  props: {
    book: {},
    index: {},
    person: {},
    hotel: {},
  },
  computed: {
    defaultCheckinTime: function () {
      return this.hotel.getDefaultCheckinTime();
    },
    defaultCheckoutTime: function () {
      return this.hotel.getDefaultCheckoutTime();
    },
  },
  methods: {
    setPersonCheckOut: function (personLocalId, value) {
      this.peopleCheckOutValues = {
        ...this.peopleCheckOutValues,
        [personLocalId]: value,
      };
    },
    setPersonCheckIn: function (personLocalId, value) {
      this.peopleCheckInValues = {
        ...this.peopleCheckInValues,
        [personLocalId]: value,
      };
    },
    personCheckInDisabled: function (date, person) {
      if (!person || !this.book) return true;

      if (!person.get("checkout") && !this.book.get("checkout")) return false;

      var checkout = person.get("checkout")
        ? person.get("checkout")
        : this.book.get("checkout");

      return moment(date).isAfter(moment(checkout));
    },
    personCheckOutDisabled: function (date, person) {
      if (!person || !this.book) return true;
      //   if (moment().isAfter(moment(date))) return true;

      if (!person.get("checkin") && !this.book.get("checkin")) return false;

      var checkin = person.get("checkin")
        ? person.get("checkin")
        : this.book.get("checkin");

      return moment(date).isBefore(moment(checkin));
    },
    setPersonProperty: function (person, propertyName, value) {
      person.set(propertyName, value);

      if (propertyName === "checkin") {
        this.$refs[`person-checkin-${person.get("localId")}`].hidePicker();
        this.$refs[`person-checkout-${person.get("localId")}`].focus();
      }

      this.$store.dispatch("bookPersons/saveObj", person);
    },
    deleteBookPerson: function (person) {
      return this.$dialog
        .confirm(
          "Sei sicuro di voler rimuovere questa persona da questa prenotazione?",
          {
            okText: "Continua",
            cancelText: "Annulla",
          }
        )
        .then(() => {
          this.$store.dispatch("bookPersons/deleteObj", person).then(() => {});
        })
        .catch((e) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/constants.scss";

@include media-breakpoint-down(sm) {
  .checkoutInput {
    margin-top: 10px;
  }
}
.label {
  font-weight: 600;
  padding-left: 10px;
  min-height: 13px;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  img {
    margin-left: 2px;
  }
}
.person-number {
  font-weight: bold;
}

.rootContainer:not(.first) {
  padding-top: 2.5em;
}
.person-wrapper {
  /*box-shadow: 0 0 3px rgba(0,0,0,0.15);*/
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 4px;
}

.person-wrapper .person-header {
  padding: 15px 15px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  background-color: rgb(250, 251, 251);
}

.person-wrapper .person-body {
  padding: 15px 15px;
}
</style>

<style lang="scss"></style>
