<template>
  <span v-on:click="clicked">
    <el-checkbox
      v-model="checkedModel"
      :checked="checked"
      class="jcheckbox"
      v-on:change="$emit('change', $event)"
      ref="checkbox"
    >
      <slot></slot>
    </el-checkbox>
  </span>
</template>

<script>
export default {
  props: ["value", "checked"],
  mounted() {},
  data: function () {
    return {
      checkedModel: false,
    };
  },
  methods: {
    clicked() {},
  },
  watch: {
    checkedModel: function (newCheckedModel) {
      this.$emit("input", newCheckedModel);
    },
    value: function (newValue) {
      this.checkedModel = newValue;
    },
  },
};
</script>

<style>
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: transparent;
  border: 2px solid #f4443f;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border: 2px solid #f4443f;
}

.el-checkbox__input.is-checked + .el-checkbox__label,
.el-checkbox__label {
  color: #4e5a63;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 19px;
}

.el-checkbox__inner::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  border: 2px solid #f4443f;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 6px;
  position: absolute;
  top: 2px;
  -webkit-transform: rotate(45deg) scaleY(0);
  transform: rotate(45deg) scaleY(0);
  width: 3px;
  -webkit-transition: -webkit-transform 0.15s
    cubic-bezier(0.71, -0.46, 0.88, 0.6) 50ms;
  transition: -webkit-transform 0.15s cubic-bezier(0.71, -0.46, 0.88, 0.6) 50ms;
  transition: transform 0.15s cubic-bezier(0.71, -0.46, 0.88, 0.6) 50ms;
  transition: transform 0.15s cubic-bezier(0.71, -0.46, 0.88, 0.6) 50ms,
    -webkit-transform 0.15s cubic-bezier(0.71, -0.46, 0.88, 0.6) 50ms;
  transition: transform 0.15s cubic-bezier(0.71, -0.46, 0.88, 0.6) 50ms,
    -webkit-transform 0.15s cubic-bezier(0.71, -0.46, 0.88, 0.6) 50ms;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.el-checkbox__inner:hover {
  border: 2px solid #f4443f;
}
.el-checkbox__inner {
  width: 20px;
  height: 20px;
  border: 2px solid #cccccc;
  border-radius: 8px;
  background-color: #ffffff;
}
</style>
