import Parse from "parse";
import Jpasta from "./Jpasta";

export default class Label extends Jpasta {
  constructor() {
    super("Label");
  }
}

Parse.Object.registerSubclass("Label", Label);
