<template>
  <span
    :style="getBadgeStyle('#' + getColor(text))"
    v-on:click.prevent.stop="$emit('click')"
    class="badge"
    :class="{
      danger: text === dangerText,
    }"
  >
    {{ text }}
  </span>
</template>

<script>
export default {
  props: ["text", "dangerText", "badgeColor"],
  data: function () {
    return {};
  },
  methods: {
    getBadgeStyle: function (color) {
      if (this.badgeColor) {
        return {
          "background-color": this.badgeColor,
          color: "white",
        };
      }

      if (this.isLight(color)) {
        return {
          "background-color": color,
          color: "#212121",
        };
      } else {
        return {
          "background-color": color,
          color: "white",
        };
      }
    },
    getContrastYIQ: function (hexcolor) {
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? true : false;
    },
    isLight: function (c) {
      var c = c.substring(1); // strip #
      return this.getContrastYIQ(c);
    },
    hashCode: function (str) {
      // java String#hashCode
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    },

    intToRGB: function (i) {
      var c = (i & 0x00ffffff).toString(16).toUpperCase();

      return "00000".substring(0, 6 - c.length) + c;
    },

    getColor: function (str) {
      return this.intToRGB(this.hashCode(str));
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.badge {
  font-size: 0.9em;
  margin-left: 10px;
  margin-bottom: 7px;
  padding: 2px 4px;
  margin-right: auto;
  border-radius: 4px;
  color: white;
  background-color: #4da0ff;
  cursor: pointer;
  border: 1px solid transparent;
}

.badge.danger {
  background-color: #f4443f !important;
  color: white !important;
}
</style>
