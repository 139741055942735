
import Vue from "vue";
import mixins from "vue-typed-mixins";
//@ts-ignore
import QRCode from "qrcode";
import _ from "lodash";
import { Hotel, Book } from "@/jpasta-sdk";
export default mixins().extend({
  props: {
    showModal: {
      type: Boolean as () => boolean,
    },
    book: {
      type: Object as () => Book,
    },
  },
  data: function (): {} {
    return {};
  },
  mounted() {},

  methods: {
    onClose() {
      this.$emit("close");
    },
    mountQrCode() {
      const canvas = this.$refs.qrcodePrint as HTMLCanvasElement;
      console.log("canvas", canvas);
      if (!canvas) return;
      this.loadQrCode(canvas);
    },
    loadQrCode(el: HTMLCanvasElement) {
      const url = this.book.get("branchIoUrl");
      console.log("url", url);
      if (!url) return;
      return new Promise<void>((resolve, reject) => {
        QRCode.toCanvas(el, url, function (error) {
          if (error) return reject();
          resolve();
        });
      });
    },
  },
  watch: {
    showModal: {
      handler: function (newVal) {
        if (!newVal) return;
        this.$nextTick(() => {
          this.mountQrCode();
        });
      },
      immediate: true,
    },
  },
});
