<template>
  <el-radio
    :class="{
      noLabel: noLabel,
    }"
    v-model="radioModel"
    :label="label"
    class="jradio"
  ></el-radio>
</template>

<script>
export default {
  props: ["value", "label", "noLabel"],
  mounted() {},
  data: function () {
    return {
      radioModel: this.value,
    };
  },
  methods: {},
  watch: {
    radioModel: function (newRadioModel) {
      this.$emit("input", newRadioModel);
    },
    value: function (newValue) {
      this.radioModel = newValue;
    },
  },
};
</script>

<style>
.noLabel .el-radio__input + .el-radio__label {
  display: none;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #f4443f;
  background: #f4443f;
}
</style>
