import Parse from "parse";

export default class Role extends Parse.Role {
  // constructor(name: string, ACL: ParseClient.ACL) {
  //   super(name, ACL);
  // }

  getStoreKey() {
    return this.get("name");
  }
}
//@ts-ignore
Parse.Object.registerSubclass("_Role", Role);
