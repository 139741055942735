import Parse from "parse";
import Jpasta from "./Jpasta";

export default class UserBookEmail extends Jpasta {
  constructor() {
    super("UserBookEmail");
  }
}

Parse.Object.registerSubclass("UserBookEmail", UserBookEmail);
