import generalMutations from "./generalMutations";
import generalActions from "./generalActions";
import Parse from "parse";
import { User, Jpasta } from "@/jpasta-sdk";
import Cookies from "js-cookie";
import _ from "lodash";
export default {
  namespaced: true,
  state: {
    objects: {},
    current: null,
    className: "_User",
  },
  mutations: {
    ...generalMutations,
    setCurrent(state, user) {
      state.current = user;
    },
  },
  actions: {
    ...generalActions,
    async verifyUserExists({ commit, dispatch }, email) {
      try {
        var response = await Parse.Cloud.run("verify-user-exists", {
          email: email,
        });
        return Promise.resolve(response);
      } catch (e) {
        dispatch("handleParseError", e, { root: true });
        return Promise.reject(e);
      }
    },
    async logOut({ commit, dispatch, rootState }) {
      try {
        await User.logOut();
        commit("setCurrent", null);
        commit("emptyObjects");
        return Promise.resolve();
      } catch (e) {
        dispatch("handleParseError", e, { root: true });
        return Promise.reject();
      }
    },
    async spottyLogin({ commit, dispatch, rootState }) {
      const spottyToken = Cookies.get("jpasta-login");
      commit("emptyObjects");
      try {
        await Parse.User.become(spottyToken);
        const user = User.current();
        commit("setCurrent", user);
      } catch (e) {
        console.log("error", e);
      }

      Cookies.remove("jpasta-login", { path: "/", domain: ".spottywifi.it" });
    },
    async saveObj(vuexObj, obj) {
      if (vuexObj.state.current && obj.id === vuexObj.state.current.id) {
        vuexObj.commit("setCurrent", obj);
      }
      await generalActions.saveObj(vuexObj, obj);
      if (vuexObj.state.current && obj.id === vuexObj.state.current.id) {
        vuexObj.commit("setCurrent", obj);
      }
    },
    saveCurrentUser({ commit, dispatch }, user) {},
    async logIn({ commit, dispatch, state }, { username, password }) {
      /* Per un bug di parse chiamare il metodo statico User.logIn(username, password) imposta
       l'utente attuale alla classe ParseUser anche se Parse.Object.registerSubclass è stato invocato con una classe differente.
       Come workaround chiamo il metodo non statico logIn su un istanza della sotto classe di Parse.User (un istanza della classe User di Jpasta) */
      var user = new User();
      user.set("username", username);
      user.set("password", password);
      try {
        var loggedUser = await user.logIn();
        commit("setCurrent", loggedUser);
        return Promise.resolve(loggedUser);
      } catch (e) {
        dispatch("handleParseError", e, { root: true });
        return Promise.reject(e);
      }
    },
    async signUp({ commit, dispatch, state }, fields) {
      var user = new User();
      user.set("localId", Jpasta.randomLocalUUID());
      _.forEach(fields, (value, key) => {
        user.set(key, value);
      });
      try {
        var loggedUser = await user.signUp(null);
        commit("setCurrent", loggedUser);
        return Promise.resolve(loggedUser);
      } catch (e) {
        dispatch("handleParseError", e, { root: true });
        return Promise.reject(e);
      }
    },
  },
};
