<template>
  <div>
    <div v-if="opened">
      <div
        class="jmodal"
        :style="{
          'z-index': zindex ? zindex : 3000,
        }"
      >
        <div
          class="container"
          :class="{
            'min-size': minSize,
            'full-screen': fullScreen,
            'force-full-screen': forceFullScreen,
            md: size === 'md',
            lg: size === 'lg',
          }"
        >
          <div class="header">
            <div
              style="
                margin-right: 0.5em;
                display: flex;
                align-items: center;
                width: 100%;
              "
            >
              <slot name="header"></slot>
            </div>
            <span v-on:click.prevent.stop="onClose" class="icon clickable"
              >&#10005;</span
            >
          </div>
          <div class="jmodal-body">
            <slot></slot>
          </div>
          <div class="footer">
            <slot name="footer"></slot>
          </div>
        </div>
        <div v-on:click="onClose" class="mask"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    forceFullScreen: {},
    fullScreen: {},
    autoClose: {},
    zindex: {},
    iconClose: {},
    minSize: {},
    size: {
      default: "md",
    },
  },
  data: function () {
    return {
      opened: false,
    };
  },
  mounted() {
    window.addEventListener("keyup", this.checkEsc);
  },
  beforeDestroy: function () {
    window.removeEventListener("keyup", this.checkEsc);
  },
  methods: {
    checkEsc: function (e) {
      if (e.keyCode === 27 && this.opened) {
        this.onClose();
      }
    },
    open: function () {
      this.opened = true;

      var body = document.getElementsByTagName("body")[0];
      body.classList.add("ios-body-popup-fix");
    },
    close: function () {
      this.opened = false;
      var body = document.getElementsByTagName("body")[0];
      body.classList.remove("ios-body-popup-fix");
    },
    onClose: function (e) {
      this.$emit("close", e);
      if (this.autoClose) this.opened = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/constants.scss";

@keyframes come-down {
  from {
    margin-top: 50px;
  }
  to {
    margin-top: 100px;
  }
}

.jmodal {
  position: fixed;
  left: 0;
  right: 0;
  height: auto;
  z-index: 3000;
  background-color: white;

  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 15px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(md) {
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .mask {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;

    z-index: 0;
  }

  .container {
    min-height: 40%;

    &.md {
      width: 736px;
    }

    &.force-full-screen {
      width: calc(100vw - 30px);
      height: calc(100vh - 30px);
      max-width: 100%;

      &.min-size {
        min-height: 600px;
      }
    }

    @include media-breakpoint-down(md) {
      width: calc(100vw - 30px);
      height: calc(100vh - 30px);
      max-width: 100%;

      &.min-size {
        min-height: 600px;
      }
    }

    @include media-breakpoint-up(md) {
      margin-top: 100px;
      animation-name: come-down;
      animation-duration: 0.2s;
    }

    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    max-width: calc(100vw - 30px);
    max-height: calc(100vh - 30px);
    .header {
      flex-shrink: 0;
      display: flex;
      padding: 10px;
      border-bottom: 1px solid transparentize(black, 0.95);
      background-color: darken(white, 3%);
      align-items: center;
      .close {
        opacity: 0.6;
        cursor: pointer;
        font-size: 14px;
        text-decoration: underline;
      }

      .jbutton:first-of-type {
        margin-left: auto;
      }
    }

    .jmodal-body {
      display: flex;
      flex-direction: column;
      padding: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1;
      position: relative;
    }
    .footer {
      flex-shrink: 0;
      padding: 10px;
      background-color: darken(white, 3%);
      border-top: 1px solid rgba(0, 0, 0, 0.05);
    }

    .footer:empty {
      display: none;
    }
  }
}
</style>
