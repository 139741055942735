import _ from "lodash";
import moment from "moment-timezone";
import { Course, Rate, Meal } from "./types";

export const PARSE_QUERY_LIMIT = 100000;

export const JPASTA_LABEL_COLORS = [
  { hex: "#5499C7" },
  { hex: "#48C9B0" },
  { hex: "#229954" },
  { hex: "#A569BD" }, //viola
  { hex: "#F7DC6F" },
  { hex: "#F5B041" },
  { hex: "#A04000" },
  { hex: "#34495E" },
  { hex: "#C0392B" },
  { hex: "#922B21" },
];

export const CELERY_ALLERGEN = 0;
export const CRUSTACEANS_ALLERGEN = 1;
export const DAIRY_PRODUCTS_ALLERGEN = 2;
export const EGG_ALLERGEN = 3;
export const GLUTEN_ALLERGEN = 4;
export const LUPINS_ALLERGEN = 5;
export const MOLLUSKS_ALLERGEN = 6;
export const MUSTARD_ALLERGEN = 7;
export const NUTS_ALLERGEN = 8;
export const PEANUTS_ALLERGEN = 9;
export const SESAME_GRAINS_ALLERGEN = 10;
export const VEGETARIAN = 11;
export const VEGAN = 12;
export const ISLAM = 13;
export const SULFITES = 14;
export const SOY = 15;
export const FISH = 16;

export const JPASTA_ALLERGENS = [
  {
    id: CELERY_ALLERGEN,
    name: {
      it: "Sedano",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Celery.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Celery.png",
  },
  {
    id: CRUSTACEANS_ALLERGEN,
    name: {
      it: "Crostacei",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Crustaceans.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Crustaceans.png",
  },
  {
    id: DAIRY_PRODUCTS_ALLERGEN,
    name: {
      it: "Latticini",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/DairyProducts.png",
    pngIcon:
      "https://jpasta-app.s3.eu-central-1.amazonaws.com/DairyProducts.png",
  },
  {
    id: EGG_ALLERGEN,
    name: {
      it: "Uova",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Egg.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Egg.png",
  },
  {
    id: GLUTEN_ALLERGEN,
    name: {
      it: "Glutine",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Gluten.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Gluten.png",
  },
  {
    id: LUPINS_ALLERGEN,
    name: {
      it: "Lupini",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Lupins.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Lupins.png",
  },
  {
    id: MOLLUSKS_ALLERGEN,
    name: {
      it: "Molluschi",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Mollusks.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Mollusks.png",
  },
  {
    id: MUSTARD_ALLERGEN,
    name: {
      it: "Mostarda",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Mustard.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Mustard.png",
  },
  {
    id: NUTS_ALLERGEN,
    name: {
      it: "Frutta a guscio",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Nuts.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Nuts.png",
  },
  {
    id: PEANUTS_ALLERGEN,
    name: {
      it: "Arachidi",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Peanuts.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Peanuts.png",
  },
  {
    id: SESAME_GRAINS_ALLERGEN,
    name: {
      it: "Sesamo",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/SesameGrains.png",
    pngIcon:
      "https://jpasta-app.s3.eu-central-1.amazonaws.com/SesameGrains.png",
  },
  {
    id: VEGETARIAN,
    name: {
      it: "Adatto ai vegetariani",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Vegetarian.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Vegetarian.png",
  },
  {
    id: VEGAN,
    name: {
      it: "Adatto ai vegani",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Vegan.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Vegan.png",
  },
  {
    id: ISLAM,
    name: {
      it: "Senza derivati suini",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Islam.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Islam.png",
  },
  {
    id: SULFITES,
    name: {
      it: "Solfiti",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Sulfites.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Sulfites.png",
  },
  {
    id: SOY,
    name: {
      it: "Soia",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Soy.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Soy.png",
  },
  {
    id: FISH,
    name: {
      it: "Pesce",
    },
    icon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Fish.png",
    pngIcon: "https://jpasta-app.s3.eu-central-1.amazonaws.com/Fish.png",
  },
];

export const JPASTA_LANGUAGES = [
  { code: "it", label: "Italiano" },
  { code: "en", label: "Inglese" },
  { code: "de", label: "Tedesco" },
  { code: "fr", label: "Francese" },
  { code: "pl", label: "Polacco" },
  { code: "nl", label: "Olandese" },
  { code: "ru", label: "Russo" },
  { code: "es", label: "Spagnolo" },
];

export function getLanguagesByCode() {
  return _.keyBy(JPASTA_LANGUAGES, (language) => {
    return language.code;
  });
}

export const BREAKFAST = 0;
export const LAUNCH = 1;
export const SNACK = 2;
export const DINNER = 3;

export const JPASTA_MEALS: Meal[] = [
  {
    id: BREAKFAST,
    name: {
      it: "Colazione",
      en: "Breakfast",
      de: "Frühstück",
    },
  },
  {
    id: LAUNCH,
    name: {
      it: "Pranzo",
      en: "Lunch",
      de: "Mittagessen",
    },
  },
  {
    id: DINNER,
    name: {
      it: "Cena",
      en: "Dinner",
      de: "Abendessen",
    },
  },
];

export const BEB = 0;
export const HALF_BOARD_WITH_LUNCH = 1;
export const HALF_BOARD_WITH_DINNER = 2;
export const FULL_BOARD = 3;
export const ONLY_BED = 4;
export const FULL_BOARD_WITH_DRINK = 5;
export const HALF_BOARD_WITH_LUNCH_AND_DRINK = 6;
export const HALF_BOARD_WITH_DINNER_AND_DRINK = 7;

export const HOTEL_RATES: Rate[] = [
  {
    id: BEB,
    withDrink: false,
    includedMeals: [BREAKFAST, SNACK],
    name: {
      it: "Bed & Breakfast",
    },
  },
  {
    id: HALF_BOARD_WITH_LUNCH,
    withDrink: false,
    includedMeals: [BREAKFAST, LAUNCH, SNACK],
    name: {
      it: "Mezza pensione con pranzo",
    },
  },
  {
    id: HALF_BOARD_WITH_DINNER,
    withDrink: false,
    includedMeals: [BREAKFAST, SNACK, DINNER],
    name: {
      it: "Mezza pensione con cena",
    },
  },
  {
    id: FULL_BOARD,
    withDrink: false,
    includedMeals: [BREAKFAST, LAUNCH, SNACK, DINNER],
    name: {
      it: "Pensione completa",
    },
  },
  {
    id: ONLY_BED,
    withDrink: false,
    includedMeals: [],
    name: {
      it: "Solo pernottamento",
    },
  },
  {
    id: FULL_BOARD_WITH_DRINK,
    withDrink: true,
    includedMeals: [BREAKFAST, LAUNCH, SNACK, DINNER],
    name: {
      it: "Pensione completa con bevande",
    },
  },
  {
    id: HALF_BOARD_WITH_LUNCH_AND_DRINK,
    includedMeals: [BREAKFAST, SNACK, LAUNCH],
    withDrink: true,
    name: {
      it: "Mezza pensione con pranzo e bevande",
    },
  },
  {
    id: HALF_BOARD_WITH_DINNER_AND_DRINK,
    includedMeals: [BREAKFAST, SNACK, DINNER],
    withDrink: true,
    name: {
      it: "Mezza pensione con cena e bevande",
    },
  },
];

export const FREE_TRIAL_DAYS = 30;

export const STARTER = 0;
export const FIRST_COURSE = 1;
export const SECOND_COURSE = 2;
export const FRUIT = 3;
export const DESSERT = 4;
export const DRINK = 5;
export const SIDE = 6;
export const CAFE_FOOD = 7;
export const CAFE_DRINK = 8;
export const WINES = 9;
export const APPETIZER = 10;
export const SPECIAL_COURSE = 11;

export const JPASTA_COURSES: Course[] = [
  {
    id: SPECIAL_COURSE,
    name: {
      it: "Piatti speciali",
      en: "Special dishes",
      de: "Spezialitäten",
    },
    color: {
      hex: "#1C8D49",
    },
    //isSpecial: true,
    pos: 0,
  },
  {
    id: STARTER,
    name: {
      it: "Antipasti",
      en: "Starter",
      de: "Vorspeise",
    },
    color: {
      hex: "#A93226",
    },
    pos: 1,
  },
  {
    id: FIRST_COURSE,
    name: {
      it: "Primi",
      en: "First courses",
      de: "Vorspeise",
    },
    color: {
      hex: "#5B2C6F",
    },
    pos: 2,
  },
  {
    id: SECOND_COURSE,
    name: {
      it: "Secondi",
      en: "Second courses",
      de: "Hauptgericht",
    },
    color: {
      hex: "#1A5276",
    },
    pos: 3,
  },
  {
    id: SIDE,
    name: {
      it: "Contorni",
      en: "Side Dishes",
      de: "Konturen",
    },
    color: {
      hex: "#851C8D",
    },
    //isSpecial: true,
    pos: 4,
  },
  {
    id: FRUIT,
    name: {
      it: "Frutta",
      en: "Fruit",
      de: "Obst",
    },
    color: {
      hex: "#0E6655",
    },
    pos: 5,
  },
  {
    id: DESSERT,
    name: {
      it: "Dessert",
      en: "Dessert",
      de: "Nachtisch",
    },
    color: {
      hex: "#BA4A00",
    },
    pos: 6,
  },
  {
    id: DRINK,
    name: {
      it: "Bevande",
      en: "Drinks",
      de: "Getränke",
    },
    color: {
      hex: "#BA4A00",
    },
    isSpecial: true,
    pos: 7,
  },
  {
    id: CAFE_FOOD,
    name: {
      it: "Mangiare per la colazione",
      en: "Breakfast food",
      de: "Frühstück",
    },
    color: {
      hex: "#851C8D",
    },
    //isSpecial: true,
    pos: 8,
  },
  {
    id: CAFE_DRINK,
    name: {
      it: "Bevande per la colazione",
      en: "Breakfast drink",
      de: "Frühstücksgetränk",
    },
    color: {
      hex: "#851C8D",
    },
    //isSpecial: true,
    pos: 9,
  },
  {
    id: WINES,
    name: {
      it: "Vini",
      en: "Wines",
      de: "Weine",
    },
    color: {
      hex: "#1C1C8D",
    },
    //isSpecial: true,
    pos: 10,
  },
  {
    id: APPETIZER,
    name: {
      it: "Aperitivo",
      en: "Appetizer",
      de: "Vorspeise",
    },
    color: {
      hex: "#1C8D49",
    },
    //isSpecial: true,
    pos: 11,
  },
];

export const COMFORT = 0;
export const SUITE = 1;
export const ROOM_TYPES = [
  {
    id: COMFORT,
    name: {
      it: "Comfort",
    },
  },
  {
    id: SUITE,
    name: {
      it: "Suite",
    },
  },
];

export function findJCourseByLocalId(courseLocalId): Course | undefined {
  return _.find(JPASTA_COURSES, (course) => {
    return !!(course.id === parseInt(courseLocalId));
  });
}
export function findJRoomTypeByLocalId(roomLocalId) {
  return _.find(ROOM_TYPES, (course) => {
    return course.id === parseInt(roomLocalId);
  });
}
export function findJMealByLocalId(mealLocalId) {
  return _.find(JPASTA_MEALS, (meal) => {
    return meal.id === parseInt(mealLocalId);
  });
}
export function findHotelRateByLocalId(hotelRateLocalId): Rate | undefined {
  return _.find(HOTEL_RATES, (rate) => {
    return rate.id === parseInt(hotelRateLocalId);
  });
}
export function findAllergenByLocalId(allergenLocalId) {
  return _.find(JPASTA_ALLERGENS, (allergen) => {
    return allergen.id === parseInt(allergenLocalId);
  });
}

export function getFirstMeal(calendarCell, timezone) {
  const mealWithFromDate = _.map(
    calendarCell.get("meals"),
    (meal, mealLocalId) => {
      const mealDate = getMealDate(calendarCell, timezone, mealLocalId);

      const fromDate = moment(mealDate || "").subtract(meal.fromHours, "hours");
      const newMeal = _.cloneDeep(meal);
      newMeal.fromDate = fromDate;
      return newMeal;
    }
  );

  const orderedMeals = _.sortBy(mealWithFromDate, (meal) => {
    return meal.fromDate.valueOf();
  });

  return orderedMeals.length ? orderedMeals[0] : null;
}

export function getMealDate(calendarCell, timezone, mealLocalId) {
  try {
    const meal = calendarCell.get("meals")[mealLocalId];

    return moment
      .tz(calendarCell.get("calendarDate"), timezone)
      .hours(meal.hours)
      .minutes(meal.minutes);
  } catch (e) {
    console.warn((e as Error).message);
    return null;
  }
}

export function getMealFromDate(calendarCell, timezone, mealLocalId) {
  try {
    const meal = calendarCell.get("meals")[mealLocalId];
    const fromDate = getMealDate(calendarCell, timezone, mealLocalId);
    if (!fromDate) throw new Error("missing from date");
    return fromDate.subtract(meal.fromHours, "hours");
  } catch (e) {
    console.warn((e as Error).message);
    return null;
  }
}

export function getMealToDate(calendarCell, timezone, mealLocalId) {
  try {
    const meal = calendarCell.get("meals")[mealLocalId];
    const toDate = getMealDate(calendarCell, timezone, mealLocalId);
    if (!toDate) throw new Error("missing to date");
    return toDate.subtract(meal.toHours, "hours");
  } catch (e) {
    console.warn((e as Error).message);
    return null;
  }
}
