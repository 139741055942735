
import { createElement } from "react";
import { createRoot, Root } from "react-dom/client";
import mixins from "vue-typed-mixins";
export default mixins().extend({
  inheritAttrs: false,
  props: {
    component: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      reactRoot: null,
    } as {
      reactRoot: Root | null;
    };
  },
  methods: {
    updateReactComponent() {
      this.reactRoot?.render(createElement(this.component as any, this.$attrs));
    },
  },
  mounted() {
    this.reactRoot = createRoot(this.$refs.container as any);
    this.updateReactComponent();
  },
  destroyed() {
    this.reactRoot?.unmount();
  },
  watch: {
    $attrs: {
      deep: true,
      handler() {
        this.updateReactComponent();
      },
    },
  },
});
